import { Markup } from 'interweave'
import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'

function SectionFive(props) {
  const [data,setdata]= useState(props.data)
  console.log('data')
 console.log(data)
  useEffect(() => {
    setdata(props.data)
  }, [props.data])
 
    console.log('sectionfive')
    console.log(data)
  return (

  <div className="section reviews-section" style={{background:data?.section_5_color || ''}}>
  <div className="container text-center">
    <div className="row">
      <div className="col-xl-8 offset-xl-2 col-lg-10 offset-lg-1">
        <h2 className="mb-4">{data?.section_five_title }</h2>
        { <Markup content={data?.section_five_subtitle1} />}
      </div>
    </div>
    <div className="row my-3">
      <div className="col-md-6 col-lg-4 mb-3">
        <div className="review-quote">
          {<Markup content={data?.section_five_subtitle2} /> }
        </div>
    
      </div>
      <div className="col-md-6 col-lg-4 mb-3">
        <div className="review-quote">
         {<Markup content={data?.section_five_subtitle3} /> }
        </div>
       
      </div>
      <div className="col-md-6 col-lg-4 mb-3">
        <div className="review-quote">
         {<Markup content={data?.section_five_subtitle4} />}
        </div>
      
      </div>
    </div>
    {/* <div className="row">
    {
      (data?.section_five_head1 || data?.section_five_image1 || data?.section_five_foot1 || data?.section_five_rating1) && 
      <div className="col-6 col-sm-6 col-lg-3 mb-3 mt-md-60 pr-2 pr-lg-3">
        <NavLink
          to={'/'}            className="card card-treatment-review h-100"
        >
          <div className="card-body">
            <h4>
            {  data?.section_five_head1}
              
            
            </h4>
            <div className="review-img">
              <img
                src={ data?.section_five_image1 }
                className="img-fluid"
                alt="issue occured"
              />
            </div>
            <p className="review-desc">{ data?.section_five_foot1}</p>
        
            <p className="review-stars">
              <img
                src="assets/img/star.png"
                className="img-fluid"
                alt="issue occured"
              />
            </p>
            <p className="review-count">{data?.section_five_rating1 }</p>
          </div>
        </NavLink>
      </div>
    }
    {
      (data?.section_five_head2 || data?.section_five_image2 || data?.section_five_foot2 || data?.section_five_rating2) && 
      <div className="col-6 col-sm-6 col-lg-3 mb-3 mt-md-60 pl-2 pl-lg-3 left5">
        <NavLink
          to={'/'}            className="card card-treatment-review h-100"
        >
          <div className="card-body">
            <h4 className="allow-wider">{data?.section_five_head2 }</h4>
            <div className="review-img">
              <img
                src={data?.section_five_image2 }
                className="img-fluid"
                alt="issue occured"
              />
            </div>
            <p className="review-desc">{ data?.section_five_foot2}</p>
         
            <p className="review-stars">
              <img
                src="assets/img/star.png"
                className="img-fluid"
                alt="issue occured"
              />
            </p>
            <p className="review-count">{data?.section_five_rating2}</p>
          </div>
        </NavLink>
      </div>
    }
      
    {
      (data?.section_five_head3 || data?.section_five_image3 || data?.section_five_foot3 || data?.section_five_rating3) && 
      <div className="col-6 col-sm-6 col-lg-3 mb-3 mt-md-60 pr-2 pr-lg-3 right5">
        <NavLink
          to={'/'}            className="card card-treatment-review h-100"
        >
          <div className="card-body">
          <h4>
            { data?.section_five_head3 }
            </h4>
            
            <div className="review-img">
              <img
                src={data?.section_five_image3}
                className="img-fluid"
                alt="issue occured"
              />
            </div>
            <p className="review-desc">{ data?.section_five_foot3}</p>
        
            <p className="review-stars">
              <img
                src="assets/img/star.png"
                className="img-fluid"
                alt="issue occured"
              />
            </p>
            <p className="review-count">{ data?.section_five_rating3 }</p>
          </div>
        </NavLink>
      </div>
    }
    
    {
      (data?.section_five_head4 || data?.section_five_image4 || data?.section_five_foot4 || data?.section_five_rating4) && 
      <div className="col-6 col-sm-6 col-lg-3 mb-3 mt-md-60 pl-2 pl-lg-3 left5">
        <NavLink
          to={'/'}            className="card card-treatment-review h-100"
        >
          <div className="card-body">
               <h4>
               {data?.section_five_head4}
            </h4>

            <div className="review-img">
              <img
                src={data?.section_five_image4 }
                className="img-fluid"
                alt="issue occured"
              />
            </div>
            <p className="review-desc">{ data?.section_five_foot4}</p>
          
            <p className="review-stars">
              <img
                src="assets/img/star.png"
                className="img-fluid"
                alt="issue occured"
              />
            </p>
            <p className="review-count">{data?.section_five_rating4 }</p>
          </div>
        </NavLink>
      </div>
    }
      
    </div> */}
  </div>
</div>
  )
}

export default SectionFive