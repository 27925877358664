import React, { useEffect, useState } from 'react'
import { Markup } from 'interweave'
import './blogsection.css'
function BlogSectionFour(props) {
  const [data,setdata]= useState(props.data)
  const [tableHeading,settableHeading] = useState([])
  useEffect(() => {
    setdata(props.data)
    // console.log("heading",props?.section_four_heading[0])
    settableHeading(props?.data?.section_four_heading)
  }, [props.data])
 
    console.log('sectionone')
    console.log(data)
 
  return (
    <>
       <section className='pb-5 pl-3 pr-3 blog_section_3 my-4'>
                {
                  (data?.section_four_title) &&
                  <p className='blog-color-primary blog-main-heading'>{data?.section_four_title}</p>
                }
                
                

                <div className='container-fluid p-0'>
                {/* {
                  <Markup content={data?.section_one_subtitle} /> 
                } */}
                {
                  (data?.section_four_description) &&
                  <Markup content= {data?.section_four_description} />
                }
              
           
              <div className='my-2'>
               <div className='table-responsive'>
                 <table class="table">
                <thead>
                  <tr>
                  {
                    
                    <>
                    {
                      (data?.section_four_heading[0] && data?.section_four_heading[0]?.sec4head0) &&
                      <th scope="font-weight-bold">{data?.section_four_heading[0]?.sec4head0}</th>
                    }
                    {
                      (data?.section_four_heading[0] && data?.section_four_heading[0]?.sec4head1) && 
                      <th scope="col font-weight-bold">{data?.section_four_heading[0]?.sec4head1}</th>
                    }
                    {
                      (data?.section_four_heading[0] && data?.section_four_heading[0]?.sec4head2) &&
                      <th scope="col">{data?.section_four_heading[0]?.sec4head2}</th>
                    }
                    {
                      (data?.section_four_heading[0] && data?.section_four_heading[0]?.sec4head3) && 
                      <th scope="col">{data?.section_four_heading[0]?.sec4head3}</th>
                    }
                   {
                    (data?.section_four_heading[0] && data?.section_four_heading[0]?.sec4head4) &&
                    <th scope="col">{data?.section_four_heading[0]?.sec4head4}</th>
                   }
                    
                    
                    
                    </>
                  }
                    
                  </tr>
                </thead>
                <tbody>
             

                {
                 
                 <>
                 <tr>
                 {
                   (data?.section_four_row1[0]?.sec4row1column0) && 
                   <td className="font-weight-bold">{data?.section_four_row1[0]?.sec4row1column0}</td>
                 }
                 {
                   (data?.section_four_row1[0]?.sec4row1column1) &&
                   <td >{data?.section_four_row1[0]?.sec4row1column1}</td>
                 }
                 {
                   (data?.section_four_row1[0]?.sec4row1column2) &&
                   <td >{data?.section_four_row1[0]?.sec4row1column2}</td>
                 }
                 {
                   (data?.section_four_row1[0]?.sec4row1column3) &&
                   <td >{data?.section_four_row1[0]?.sec4row1column3}</td>
                 }
                 {
                   (data?.section_four_row1[0]?.sec4row1column4) &&
                   <td >{data?.section_four_row1[0]?.sec4row1column4}</td>
                 }
                   
                 </tr>
                 </>
               }

                {
                 
                 <>
                 <tr>
                 {
                   (data?.section_four_row2[0]?.sec4row2column0) && 
                   <td className="font-weight-bold">{data?.section_four_row2[0]?.sec4row2column0}</td>
                 }
                 {
                   (data?.section_four_row2[0]?.sec4row2column1) &&
                   <td >{data?.section_four_row2[0]?.sec4row2column1}</td>
                 }
                 {
                   (data?.section_four_row2[0]?.sec4row2column2) &&
                   <td >{data?.section_four_row2[0]?.sec4row2column2}</td>
                 }
                 {
                   (data?.section_four_row2[0]?.sec4row2column3) &&
                   <td >{data?.section_four_row2[0]?.sec4row2column3}</td>
                 }
                 {
                   (data?.section_four_row2[0]?.sec4row2column4) &&
                   <td >{data?.section_four_row2[0]?.sec4row2column4}</td>
                 }
                   
                 </tr>
                 </>
               }
              
                {
                 
                 <>
                 <tr>
                 {
                   (data?.section_four_row3[0]?.sec4row3column0) && 
                   <td className="font-weight-bold">{data?.section_four_row3[0]?.sec4row3column0}</td>
                 }
                 {
                   (data?.section_four_row3[0]?.sec4row3column1) &&
                   <td>{data?.section_four_row3[0]?.sec4row3column1}</td>
                 }
                 {
                   (data?.section_four_row3[0]?.sec4row3column2) &&
                   <td>{data?.section_four_row3[0]?.sec4row3column2}</td>
                 }
                 {
                   (data?.section_four_row3[0]?.sec4row3column3) &&
                   <td>{data?.section_four_row3[0]?.sec4row3column3}</td>
                 }
                 {
                   (data?.section_four_row3[0]?.sec4row3column4) &&
                   <td>{data?.section_four_row3[0]?.sec4row3column4}</td>
                 }
                   
                 </tr>
                 </>
               }
             
                {
                 
                 <>
                 <tr>
                 {
                   (data?.section_four_row4[0]?.sec4row4column0) && 
                   <td className="font-weight-bold">{data?.section_four_row4[0]?.sec4row4column0}</td>
                 }
                 {
                   (data?.section_four_row4[0]?.sec4row4column1) &&
                   <td>{data?.section_four_row4[0]?.sec4row4column1}</td>
                 }
                 {
                   (data?.section_four_row4[0]?.sec4row4column2) &&
                   <td>{data?.section_four_row4[0]?.sec4row4column2}</td>
                 }
                 {
                   (data?.section_four_row4[0]?.sec4row4column3) &&
                   <td>{data?.section_four_row4[0]?.sec4row4column3}</td>
                 }
                 {
                   (data?.section_four_row4[0]?.sec4row4column4) &&
                   <td>{data?.section_four_row4[0]?.sec4row4column4}</td>
                 }
                   
                 </tr>
                 </>
               }

                {
                 
                  <>
                  <tr>
                  {
                    (data?.section_four_row5[0]?.sec4row5column0) && 
                    <td className="font-weight-bold">{data?.section_four_row5[0]?.sec4row5column0}</td>
                  }
                  {
                    (data?.section_four_row5[0]?.sec4row5column1) &&
                    <td>{data?.section_four_row5[0]?.sec4row5column1}</td>
                  }
                  {
                    (data?.section_four_row5[0]?.sec4row5column2) &&
                    <td>{data?.section_four_row5[0]?.sec4row5column2}</td>
                  }
                  {
                    (data?.section_four_row5[0]?.sec4row5column3) &&
                    <td>{data?.section_four_row5[0]?.sec4row5column3}</td>
                  }
                  {
                    (data?.section_four_row5[0]?.sec4row5column4) &&
                    <td>{data?.section_four_row5[0]?.sec4row5column4}</td>
                  }
                    
                  </tr>
                  </>
                }

                {
                  <>
                  <tr>
                  {
                    (data?.section_four_row6[0]?.sec4row6column0) &&
                    <td className="font-weight-bold">{data?.section_four_row6[0]?.sec4row6column0}</td>
                  }
                  {
                    (data?.section_four_row6[0]?.sec4row6column1) &&
                    <td >{data?.section_four_row6[0]?.sec4row6column1}</td>
                  }
                  {
                    (data?.section_four_row6[0]?.sec4row6column2) &&
                    <td >{data?.section_four_row6[0]?.sec4row6column2}</td>
                  }
                  {
                    (data?.section_four_row6[0]?.sec4row6column3) &&
                    <td >{data?.section_four_row6[0]?.sec4row6column3}</td>
                  }
                  {
                    (data?.section_four_row6[0]?.sec4row6column4) &&
                    <td >{data?.section_four_row6[0]?.sec4row6column4}</td>
                  }
                    
                  </tr>
                  </>
                }
                {
                  <>
                  <tr>
                  {
                    (data?.section_four_row7[0]?.sec4row7column0) &&
                    <td className="font-weight-bold">{data?.section_four_row7[0]?.sec4row7column0}</td>
                  }
                  {
                    (data?.section_four_row7[0]?.sec4row7column1) &&
                    <td >{data?.section_four_row7[0]?.sec4row7column1}</td>
                  }
                  {
                    (data?.section_four_row7[0]?.sec4row7column2) &&
                    <td >{data?.section_four_row7[0]?.sec4row7column2}</td>
                  }
                  {
                    (data?.section_four_row7[0]?.sec4row7column3) &&
                    <td >{data?.section_four_row7[0]?.sec4row7column3}</td>
                  }
                  {
                    (data?.section_four_row7[0]?.sec4row7column4) &&
                    <td >{data?.section_four_row7[0]?.sec4row7column4}</td>
                  }
                  </tr>
                  </>
                }

                {
                  <>
                  <tr>
                  {
                    (data?.section_four_row8[0]?.sec4row8column0) &&
                    <td className="font-weight-bold">{data?.section_four_row8[0]?.sec4row8column0}</td>
                  }
                  {
                    (data?.section_four_row8[0]?.sec4row8column1) &&
                    <td>{data?.section_four_row8[0]?.sec4row8column1}</td>
                  }
                  {
                    (data?.section_four_row8[0]?.sec4row8column2) &&
                    <td>{data?.section_four_row8[0]?.sec4row8column2}</td>
                  }
                  {
                    (data?.section_four_row8[0]?.sec4row8column3) &&
                    <td>{data?.section_four_row8[0]?.sec4row8column3}</td>
                  }
                  {
                    (data?.section_four_row8[0]?.sec4row8column4) &&
                    <td>{data?.section_four_row8[0]?.sec4row8column4}</td>
                  }
                  </tr>
                  </>
                }

                {
                  <>
                  <tr>
                  {
                    (data?.section_four_row9[0]?.sec4row9column0) &&
                    <td className="font-weight-bold">{data?.section_four_row9[0]?.sec4row9column0}</td>
                  }
                  {
                    (data?.section_four_row9[0]?.sec4row8column1) &&
                    <td >{data?.section_four_row9[0]?.sec4row9column1}</td>
                  }
                  {
                    (data?.section_four_row9[0]?.sec4row8column2) &&
                    <td >{data?.section_four_row9[0]?.sec4row9column2}</td>
                  }
                  {
                    (data?.section_four_row9[0]?.sec4row8column3) &&
                    <td >{data?.section_four_row9[0]?.sec4row9column3}</td>
                  }
                  {
                    (data?.section_four_row9[0]?.sec4row8column4) &&
                    <td >{data?.section_four_row9[0]?.sec4row9column4}</td>
                  }
                  </tr>
                  </>
                }

                {
                  <>
                  <tr>
                  {
                    (data?.section_four_row10[0]?.sec4row10column0) &&
                    <td className="font-weight-bold">{data?.section_four_row10[0]?.sec4row10column0}</td>
                  }
                  {
                    (data?.section_four_row10[0]?.sec4row8column1) &&
                    <td >{data?.section_four_row10[0]?.sec4row10column1}</td>
                  }
                  {
                    (data?.section_four_row10[0]?.sec4row8column2) &&
                    <td >{data?.section_four_row10[0]?.sec4row10column2}</td>
                  }
                  {
                    (data?.section_four_row10[0]?.sec4row8column3) &&
                    <td >{data?.section_four_row10[0]?.sec4row10column3}</td>
                  }
                  {
                    (data?.section_four_row10[0]?.sec4row8column4) &&
                    <td >{data?.section_four_row10[0]?.sec4row10column4}</td>
                  }
                  </tr>
                  </>
                }

                  {/* <tr>
                    <td className="font-weight-bold">1</td>
                    <td>Mark</td>
                    <td>Otto</td>
                    <td>@mdo</td>
                    <td>@mdo</td>
                  </tr>
                  <tr>
                    <td className="font-weight-bold">2</td>
                    <td>Jacob</td>
                    <td>Thornton</td>
                    <td>@fat</td>
                    <td>@fat</td>
                  </tr>
                  <tr>
                    <td className="font-weight-bold">2</td>
                    <td>Jacob</td>
                    <td>Thornton</td>
                    <td>@fat</td>
                    <td>@fat</td>
                  </tr>
                  <tr>
                    <td className="font-weight-bold">2</td>
                    <td>Jacob</td>
                    <td>Thornton</td>
                    <td>@fat</td>
                    <td>@fat</td>
                  </tr> */}
                </tbody>
                 </table>
               </div>
              </div>
              

                </div>
                
               

                
                </section>
    </>
  )
}

export default BlogSectionFour
