import axios from "axios";
import React, { Fragment, useEffect, useState } from "react";
import {NavLink, useNavigate} from 'react-router-dom';
import { Markup } from 'interweave';
import Swal from "sweetalert2";
import { GrInstallOption } from "react-icons/gr";

const Footer=()=>{
  const [footersData,setfootersData] = useState('')
  const [footerHead,setfooterHead] = useState([])
  const [sideButton,setsideButton] = useState([])

  const getSideButtonData = async() =>{
    await axios.get(`${process.env.REACT_APP_BASE_URL}api/get_book_btn`)
    // ('https://clubxtremeapp.com/app/trustedclinics/api/footer_links')
    .then(res=>{
      console.log('get_book_btn')
      console.log(res.data?.detail)
      setsideButton(res.data?.detail)
    })
    .catch(err=>{
      console.log(err)
    })
  }
  useEffect(()=>{
    getSideButtonData()
  },[])
  
  useEffect(()=>{
 
  const DataGet=async()=>{
    await axios.get(`${process.env.REACT_APP_BASE_URL}api/footerhead_data`).

    then((response)=> {
     
      setfooterHead(response.data?.detail)
    } ).
    catch((error)=> console.log("error",error))
  }

  DataGet();

},[])



// console.log( footersData?.footer_title)

    return (
        <>
        <div className="layout-footer">

        <div className="section find-you-local-clinic-section bg-inverse bg-radial-inverse">
    <div className="container">
      <div className="row">
        <div className="col-xl-8 offset-xl-2 col-lg-10 offset-lg-1 text-center">
          <h2 className="mb-lg-4">{footerHead?.footer_title}</h2>
        {<Markup content={footerHead?.footer_description } />}
        </div>
      </div>
      <div className="row">
        <div className="col-md-10 offset-md-1 col-lg-6 offset-lg-3  col-xl-6 offset-xl-3 text-center">
          <form
            // className="form"
            // id="findYourClinicForm"
          >
            {/* <input type="hidden" name="CRAFT_CSRF_TOKEN" defaultValue="" /> */}
            <div className="form-group mb-2">
              <label htmlFor="postcode" className="sr-only">
                Postcode
              </label>
              <div className="input-group">
                <input
                  type="text"
                  name="postcode"
                  id="postcode"
                  className="form-control"
                  placeholder="Enter Your Post Code"
                  required
                />
                <div className="input-group-append text-purple">
                  <span className="input-group-text" id="basic-addon2">
                    <i className="fas fa-map-marker-alt" />
                  </span>
                </div>
              </div>
            </div>
            <div className="form-group mb-3">
              <div
                id="rollerLoader"
                className="text-center"
                style={{ display: "none" }}
              >
                <div
                  data-v-01d979ac=""
                  className="lds-roller"
                  style={{ width: 24, height: 24 }}
                >
                  <div
                    data-v-01d979ac=""
                    style={{
                      animationDuration: "1.2s",
                      transformOrigin: "12px 12px",
                      animationDelay: "-0.036s"
                    }}
                  >
                    <div
                      data-v-01d979ac=""
                      className="div-after"
                      style={{
                        width: "2.1px",
                        height: "2.1px",
                        margin: "-1.2px 0px 0px -1.2px",
                        background: "rgb(255, 255, 255)",
                        top: "18.9px",
                        left: "18.9px"
                      }}
                    />
                  </div>
                  <div
                    data-v-01d979ac=""
                    style={{
                      animationDuration: "1.2s",
                      transformOrigin: "12px 12px",
                      animationDelay: "-0.072s"
                    }}
                  >
                    <div
                      data-v-01d979ac=""
                      className="div-after"
                      style={{
                        width: "2.1px",
                        height: "2.1px",
                        margin: "-1.2px 0px 0px -1.2px",
                        background: "rgb(255, 255, 255)",
                        top: "18.9px",
                        left: "18.9px"
                      }}
                    />
                  </div>
                  <div
                    data-v-01d979ac=""
                    style={{
                      animationDuration: "1.2s",
                      transformOrigin: "12px 12px",
                      animationDelay: "-0.108s"
                    }}
                  >
                    <div
                      data-v-01d979ac=""
                      className="div-after"
                      style={{
                        width: "2.1px",
                        height: "2.1px",
                        margin: "-1.2px 0px 0px -1.2px",
                        background: "rgb(255, 255, 255)",
                        top: "20.4px",
                        left: "16.8px"
                      }}
                    />
                  </div>
                  <div
                    data-v-01d979ac=""
                    style={{
                      animationDuration: "1.2s",
                      transformOrigin: "12px 12px",
                      animationDelay: "-0.144s"
                    }}
                  >
                    <div
                      data-v-01d979ac=""
                      className="div-after"
                      style={{
                        width: "2.1px",
                        height: "2.1px",
                        margin: "-1.2px 0px 0px -1.2px",
                        background: "rgb(255, 255, 255)",
                        top: "21.3px",
                        left: "14.4px"
                      }}
                    />
                  </div>
                  <div
                    data-v-01d979ac=""
                    style={{
                      animationDuration: "1.2s",
                      transformOrigin: "12px 12px",
                      animationDelay: "-0.18s"
                    }}
                  >
                    <div
                      data-v-01d979ac=""
                      className="div-after"
                      style={{
                        width: "2.1px",
                        height: "2.1px",
                        margin: "-1.2px 0px 0px -1.2px",
                        background: "rgb(255, 255, 255)",
                        top: "21.6px",
                        left: 12
                      }}
                    />
                  </div>
                  <div
                    data-v-01d979ac=""
                    style={{
                      animationDuration: "1.2s",
                      transformOrigin: "12px 12px",
                      animationDelay: "-0.216s"
                    }}
                  >
                    <div
                      data-v-01d979ac=""
                      className="div-after"
                      style={{
                        width: "2.1px",
                        height: "2.1px",
                        margin: "-1.2px 0px 0px -1.2px",
                        background: "rgb(255, 255, 255)",
                        top: "21.3px",
                        left: "9.6px"
                      }}
                    />
                  </div>
                  <div
                    data-v-01d979ac=""
                    style={{
                      animationDuration: "1.2s",
                      transformOrigin: "12px 12px",
                      animationDelay: "-0.252s"
                    }}
                  >
                    <div
                      data-v-01d979ac=""
                      className="div-after"
                      style={{
                        width: "2.1px",
                        height: "2.1px",
                        margin: "-1.2px 0px 0px -1.2px",
                        background: "rgb(255, 255, 255)",
                        top: "20.4px",
                        left: "7.2px"
                      }}
                    />
                  </div>
                  <div
                    data-v-01d979ac=""
                    style={{
                      animationDuration: "1.2s",
                      transformOrigin: "12px 12px",
                      animationDelay: "-0.288s"
                    }}
                  >
                    <div
                      data-v-01d979ac=""
                      className="div-after"
                      style={{
                        width: "2.1px",
                        height: "2.1px",
                        margin: "-1.2px 0px 0px -1.2px",
                        background: "rgb(255, 255, 255)",
                        top: "18.9px",
                        left: "5.1px"
                      }}
                    />
                  </div>
                </div>{" "}
                <span className="waiting-msg">
                  Please wait while we get your location
                </span>
              </div>
              <button
                type="button"
                id="useLocationBtn"
                className="btn text-white"
              >
                <i className="fas fa-crosshairs text-white" /> { footerHead?.footer_location}
              </button>
            </div>
            <button type="button" className="btn btn-gold btn-rounded btn-lg">
            { footerHead?.footer_button?.substring(0,27)}
            </button>
          </form>{" "}
        </div>
      </div>
    </div>
  </div>
       <Footer_Link/>
       </div>
       {
        (sideButton?.btn_name) && 
      
       <div className="mobile-fixed-menu">
            <div className="container">
                <div className="mobile-fixed-menu-row">
                    <a href={sideButton?.btn_link} className="col-12 p-2 mt-2">
                        {/* <img className="desktopold" src="https://trustedclinics.com/images/footer/consultation-white.png" height={28} alt=""/> */}
                                                            <span className="gold">{sideButton?.btn_name}</span>
                                                </a>
                </div>
            </div>
        </div>
        }
      </>
    )

    
}



export default Footer



export  const Footer_Link=()=>{
  const [FooterLinkData,setFooterLinkData] = useState([])
  const [footerData,setFooterData] = useState({})
  console.log(footerData)
  const navigate=useNavigate()
const handleClick = () => {
  // setToggleMenu(!toggleMenu)
  // setToggleMenu1(!toggleMenu1)
  Swal.fire({
    title: "Are you sure you want to logout?",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#BE93D1",
    cancelButtonColor: "#d33",
    confirmButtonText: "Yes",
  }).then((result) => {
    if (result.isConfirmed) {
      Swal.fire({
        title: "success",
        text: "Logout Successfully.",
        icon: "success",
        confirmButtonColor: "#BE93D1",
      });

      localStorage.clear();
      // window.location.href='https://trustedclinics.co.uk/pwa_web/#/'
      window.location.reload()
      // navigate("/");
      console.log('clear local ')
    }
  });
};

const user = localStorage.getItem("lab");
const lab = JSON.parse(user);
const user1 = localStorage.getItem("user");
const doctor = JSON.parse(user1);
const user2 = localStorage.getItem("patient");
const patient = JSON.parse(user2);
const user3 = localStorage.getItem("staff");
const staff = JSON.parse(user3);
const [userData, setUserData] = useState([]);
const [toggleMenu, setToggleMenu] = useState(false);
const [toggleMenu1, setToggleMenu1] = useState(false);
const [toggleLogout, setToggleLogout] = useState(false);

console.log("cookie-value", userData);

useEffect(() => {
  // window.cookieStore.getAll().then(x=> setUserData(JSON.parse(x[0].value))) ;
  const checkLocalStorage = () => {
    if (lab) setUserData(lab);
    else if (doctor) setUserData(doctor);
    else if (patient) setUserData(patient);
    else if (staff) setUserData(staff);
    };
    checkLocalStorage();

    // Set up an interval to check localStorage periodically (e.g., every second)
    const intervalId = setInterval(checkLocalStorage, 100); // Adjust the interval as needed

    // Clean up the interval on component unmount
    return () => clearInterval(intervalId);
}, []);
  // console.log(FooterLinkData)
  // const [supportsPWA, setSupportsPWA] = useState(false);
  // const [promptInstall, setPromptInstall] = useState(null);

  // useEffect(() => {
  //         console.log('in use')// I see in the console
  //     const handler = e => {
  //         console.log('in handler') // I do not see in the console
  //         e.preventDefault();
  //         setSupportsPWA(true);
  //         setPromptInstall(e);
  //     };
  //     window.addEventListener("beforeinstallprompt", handler);
  //     return () => window.removeEventListener("transitionend", handler);
  //     }, []);
  //     const onClick = e => {
  //         alert('click')
  //         e.preventDefault();
  //         if (promptInstall) {
  //             promptInstall.prompt();
  //         }else{
  //                 return;
  //         };
  //     };

  
  const [footerLinks,setfooterLinks] = useState([])
  const [footerLinks1,setfooterLinks1] = useState([])
  const [footerLinks2,setfooterLinks2] = useState([])
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  console.log(footerLinks)
  

  // install app


useEffect(() => {
  const handleBeforeInstallPrompt = (event) => {
    event.preventDefault();
    setDeferredPrompt(event);
  };

  window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

  return () => {
    window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
  };
}, []);

const handleInstall = () => {
  if (deferredPrompt) {
    deferredPrompt.prompt();
    deferredPrompt.userChoice.then((choiceResult) => {
      if (choiceResult.outcome === 'accepted') {
        console.log('User accepted the install prompt');
      } else {
        console.log('User dismissed the install prompt');
      }
      setDeferredPrompt(null);
    });
  }
};

  const getFooterDataLinks = async() =>{
    await axios.get('https://trustedclinics.co.uk/backend/api/footer_links')
    // ('https://clubxtremeapp.com/app/trustedclinics/api/footer_links')
    .then(res=>{
      console.log('footer links')
      console.log(res.data?.detail)
      setfooterLinks(res.data?.detail)
    })
    .catch(err=>{
      console.log(err)
    })
  }


  const getFooterDataLinks1 = async() =>{
    await axios.get('https://trustedclinics.co.uk/backend/api/footer_links2')
    // ('https://clubxtremeapp.com/app/trustedclinics/api/footer_links')
    .then(res=>{
      console.log('footer links')
      console.log(res.data?.detail)
      setfooterLinks1(res.data?.detail)
    })
    .catch(err=>{
      console.log(err)
    })
  }

  const getFooterDataLinks2 = async() =>{
    await axios.get('https://trustedclinics.co.uk/backend/api/footer_links3')
    // ('https://clubxtremeapp.com/app/trustedclinics/api/footer_links')
    .then(res=>{
      console.log('footer links')
      console.log(res.data?.detail)
      setfooterLinks2(res.data?.detail)
    })
    .catch(err=>{
      console.log(err)
    })
  }


  const DataGet=async()=>{
    await axios.get('https://trustedclinics.co.uk/backend/api/header_footer_logo_text')
    // ('https://arowiz.com/trustedclinics/api/header_footer_logo_text').

    .then((response)=> {
     
      // setFooterLinkData(response.data?.detail)
      setFooterData(response.data?.detail)
    } ).
    catch((error)=> console.log("error",error))
  }
  useEffect(()=>{
    getFooterDataLinks()
    getFooterDataLinks1()
    getFooterDataLinks2()

  DataGet();

},[])

  return (
//     <Fragment>
//  <section id="footer-top" className="p-80px-tb bg-light">
//         <div className="container">
//           <div className="row">
//             <div className="col-lg-6 col-md-12 col-sm-12">
//               <div className="row">
//                 <div className="single-widget col-md-7 col-sm-6">
               
//                   <NavLink
//                     className="widget-logo"
//                     to={'/'}
//                   >
//                     <img
//                       // src= { FooterLinkData?.footer_logo}
//                       src={'https://trustedclinics.co.uk/pwa_web/static/media/logo-main.01c62427da3c5eb3f020.png'}
//                       alt=""
//                     />
//                   </NavLink>
//                   <ul>
//                   {
//                     footerLinks.map((item,index)=>{
//                       return <li>
//                       <NavLink
//                         className=""
//                         to={`${item.link}`}
//                       >
//                         {item.menu_name}
//                       </NavLink>
//                     </li>
//                     })
//                   }
                    
//                      <li>
//                       <NavLink
//                         className=""
//                         to={'/'}
//                       >
//                         Treatments
//                       </NavLink>
//                     </li>
//                     <li>
//                       <NavLink
//                         className=""
//                         to={'/'}
//                       >
//                         Dental Implants
//                       </NavLink>
//                     </li>
//                     <li>
//                       <NavLink
//                         className=""
//                         to={'/'}
//                       >
//                         Full Mouth Reconstruction
//                       </NavLink>
//                     </li>
//                     <li>
//                       <NavLink
//                         className=""
//                         to={'/'}
//                       >
//                         Invisalign Clear Aligners
//                       </NavLink>
//                     </li>
//                     <li>
//                       <NavLink
//                         className=""
//                         to={'/'}
//                       >
//                         Dental Braces
//                       </NavLink>
//                     </li>
//                     <li>
//                       <NavLink
//                         className=""
//                         to={'/'}
//                       >
//                         Veneers
//                       </NavLink>
//                     </li>
//                     <li>
//                       <NavLink
//                         className=""
//                         to={'/'}
//                       >
//                         Crowns &amp; Bridges
//                       </NavLink>
//                     </li>
//                     <li>
//                       <NavLink
//                         className=""
//                         to={'/'}
//                       >
//                         Teeth Whitening
//                       </NavLink>
//                     </li> 
//                   </ul>
//                   <div className="_footer-add address-wrap"></div>
//                 </div>
//                 <div className="single-widget col-md-5 col-sm-6 padding-wid">
//                   <ul>
//                     <li>
//                       <NavLink
//                         className=""
//                         to={'/'}
//                       >
//                         Dentists Near Me &amp; Pricing
//                       </NavLink>
//                     </li>
//                     <li>
//                       <NavLink
//                         className=""
//                         to={'/'}
//                       >
//                         About Us
//                       </NavLink>
//                     </li>
//                     <li>
//                       <NavLink
//                         className=""
//                         to={'/'}
//                       >
//                         Contact Us
//                       </NavLink>
//                     </li>
//                     <li>
//                       <NavLink
//                         className=""
//                         to={'/'}
//                       >
//                         Dental Care FAQs
//                       </NavLink>
//                     </li>
//                     <li>
//                       <NavLink
//                         className=""
//                         to={'/'}
//                       >
//                         Dental Articles
//                       </NavLink>
//                     </li>
//                     <li>
//                       <NavLink
//                         className=""
//                         to={'/'}
//                       >
//                         Dental News
//                       </NavLink>
//                     </li>
//                   </ul>
//                 </div>
//               </div>
//             </div>
//             <div className="col-lg-6 col-md-12 col-sm-12">
//               <div className="row">
//                 <div className="single-widget col-md-6 col-sm-6 padding-wid">
//                   <ul className="recent-post">
//                     <li>
//                       <NavLink to={'/'}>
//                         Terms &amp; Conditions
//                       </NavLink>
//                     </li>
//                     <li>
//                       <NavLink to={'/'}>
//                         Privacy Policy
//                       </NavLink>
//                     </li>
//                     <li>
//                       <NavLink to={'/'}>
//                         Cookie Policy
//                       </NavLink>
//                     </li>
//                     <li>
//                       <NavLink to={'/'}>
//                         Sitemap
//                       </NavLink>
//                     </li>
//                   </ul>
//                 </div>
//                 <div className="single-widget col-md-6 col-sm-6 footer-last padding-wid">
//                   <div className="_footer-add address-wrap">
//                 {  <Markup content={FooterLinkData?.footer_text} />}
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </section>
//     </Fragment>
<Fragment>
 <section id="footer-top" className="p-80px-tb bg-light pb-5 ">
        <div className="container">
          <div className="row footer-row-div">
            <div className="col-lg-6 col-md-12 col-sm-12">
              <div className="row  footer-row-div">
                <div className="single-widget col-md-7 col-6">
               
                  <NavLink
                    className="widget-logo"
                    to={'/'}
                  >
                    <img
                      src= {
                         footerData.footer_logo||
                      'https://trustedclinics.co.uk/static/media/logo-main.01c62427da3c5eb3f020.png'
                      }
                      alt=""
                    />
                  </NavLink>
                  {/* <li><NavLink to='/Dentists%20Partner/232'> Dentist Area </NavLink></li> */}

                  {/* <div className="_footer-add address-wrap" style={{fontWeight:'100'}}>
     
     <Markup content={footerData?.footer_text} />
  
 </div> */}
{/*                   
                  <ul>
                  
                    <li>
                      <NavLink
                        className=""
                        to={'/'}
                      >
                        Home
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        className=""
                        to={'/'}
                      >
                        Treatments
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        className=""
                        to={'/'}
                      >
                        Dental Implants
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        className=""
                        to={'/'}
                      >
                        Full Mouth Reconstruction
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        className=""
                        to={'/'}
                      >
                        Invisalign Clear Aligners
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        className=""
                        to={'/'}
                      >
                        Dental Braces
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        className=""
                        to={'/'}
                      >
                        Veneers
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        className=""
                        to={'/'}
                      >
                        Crowns &amp; Bridges
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        className=""
                        to={'/'}
                      >
                        Teeth Whitening
                      </NavLink>
                    </li>
                  </ul> */}

{/* <div className="col-xl-3 pb-3 order-xl-2">
        <div className="row h-100">
          <div className="col px-2">
            <div className="card card-body card-purple card-headline-pricing text-center h-100">
              <div className="row">
                <div className="col-12 col-lg-12">
                  <span>From</span>
                  <span className="monthly-price">£28</span>
                  <span className="monthly-price-pm">Per Month</span>
                </div>
                <div className="col-12 col-lg-12">
                  <span className="total-price">(or £1,795)</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
                  <ul>
               
                    {
                      footerLinks.map((val)=>{
                        return(
                          <li key={val.link_id} className="hover-underline-animation">
                      <a
                        className=""
                        href={val.link}
                      >
                      {val.menu_name}
                      </a>
                    </li>
                        )
                      })
                    }
                  </ul>

                  {/* https://trustedclinics.co.uk/backend/api/footer_links2 */}
                  <div className="_footer-add address-wrap"></div>
                </div>
                <div className="single-widget col-md-5 col-6 padding-wid" style={{paddingTop:'60px'}}>
                  {/* <ul>
                    <li>
                      <NavLink
                        className=""
                        to={'/'}
                      >
                        Dentists Near Me &amp; Pricing
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        className=""
                        to={'/'}
                      >
                        About Us
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        className=""
                        to={'/'}
                      >
                        Contact Us
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        className=""
                        to={'/'}
                      >
                        Dental Care FAQs
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        className=""
                        to={'/'}
                      >
                        Dental Articles
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        className=""
                        to={'/'}
                      >
                        Dental News
                      </NavLink>
                    </li>
                  </ul> */}


                  <ul>
                    {
                      footerLinks1.map((val)=>{
                        return(
                          <li key={val.link_id} className="hover-underline-animation">
                      <a
                        className=""
                        href={val.link}
                      >
                      {val.menu_name}
                      </a>
                    </li>
                        )
                      })
                    }
                    {/* <li className="hover-underline-animation"><NavLink to={'/Web_Ifreame_doctor'}>Doctor Logindummy</NavLink></li>
                    <li className="hover-underline-animation"><NavLink to={'/Web_Ifreame_lab'}>Lab Doctor Logindummy</NavLink></li>
                    <li className="hover-underline-animation" onClick={handleClick} style={{cursor:'pointer'}}>Logoutdummy</li> */}

                 


                    {(userData.length===0)?
                    <>
                    <li className="btn btn-gold"
                       style={{
                      // marginTop: "15px",
                      fontSize: "15px",
                      border: "1px solid #d4a54e",
                      width: "140px",
                      // minWidth:"80%",
                      padding: "8px",
                      background: " #d4a54e",
                      color: "",
                      marginLeft: 0,
                      borderRadius:'8px'
                    }}
                    ><NavLink to={'/doctor-app'} className='text-white px-2'>Doctor Login</NavLink></li>
                    <li className="btn btn-gold"
                       style={{
                      // marginTop: "15px",
                      fontSize: "15px",
                      border: "1px solid #d4a54e",
                      width: "140px",
                      // minWidth:"80%",
                      padding: "8px",
                      background: " #d4a54e",
                      color: "",
                      marginLeft: 0,
                      borderRadius:'8px',
                      marginTop:'8px'
                    }}
                    ><NavLink to={'/lab-app'} className='text-white'>Lab Login</NavLink></li>

                    </>
                    :
                    (<>
                    {(userData.role_status===1)&&
                          <li className="hover-underline-animation"  
                            // onClick={() => setToggleMenu1(!toggleMenu1)}
                            >  <NavLink to={"/doctor-app"} >App Home</NavLink></li>
                        }
                        {(userData.role_status===2)&&
                           <li className="hover-underline-animation"  
                            // onClick={() => setToggleMenu1(!toggleMenu1)}
                            >  <NavLink to={"/patient-app"} >App Home</NavLink></li>
                        }
                        {(userData.role_status===3)&&
                           <li className="hover-underline-animation"  
                            // onClick={() => setToggleMenu1(!toggleMenu1)}
                            > <NavLink to={"/lab-app"} >App Home</NavLink></li>
                        }
                        {(userData.role_status===5)&&
                           <li className="hover-underline-animation"  
                            // onClick={() => setToggleMenu1(!toggleMenu1)}
                            > <NavLink to={"/clinic-app"} >App Home</NavLink></li>
                        }
                    <li className="hover-underline-animation" onClick={handleClick} style={{cursor:'pointer'}}>Logout</li>
                    </>)}
                  </ul>




                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12">
              <div className="row">
                <div className="single-widget col-md-6 col-sm-6 padding-wid tarmandconditionhide">
                  {/* <ul className="recent-post">
                    <li>
                      <NavLink to={'/'}>
                        Terms &amp; Conditions
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to={'/'}>
                        Privacy Policy
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to={'/'}>
                        Cookie Policy
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to={'/'}>
                        Sitemap
                      </NavLink>
                    </li>
                  </ul> */}


                  
                  <ul className="recent-post">
                    {
                      footerLinks2.map((val)=>{
                        return(
                          <li key={val.link_id} className="hover-underline-animation">
                      <a
                        className=""
                        href={val.link}
                      >
                      {val.menu_name}
                      </a>
                    </li>
                        )
                      })
                    }
                    
                    <li className="hover-underline-animation"><NavLink to='/blog'>Blog</NavLink></li>
                    {/* <li className="hover-underline-animation"><NavLink to='/ViewAllArticle'>View All Article</NavLink></li> */}

                    <li className="hover-underline-animation"><NavLink to='/GetUserLocation'>Get User Location</NavLink></li>
                    {(userData.length===0) &&
                    <>
                    <li className="btn btn-gold"
                       style={{
                      // marginTop: "15px",
                      fontSize: "15px",
                      border: "1px solid #d4a54e",
                      width: "140px",
                      padding: "8px",
                      background: " #d4a54e",
                      color: "",
                      marginLeft: 0,
                      borderRadius:'8px'
                    }}
                    ><NavLink to={'/clinic-app'} className='text-white'>Clinic Login</NavLink></li>
                    </>
                   }
                  </ul>
                </div>
                <div className="single-widget col-md-6 col-sm-6 footer-last padding-wid col-12 col-sm-12 col-md-3 company info top-addres-footer">
                  <div className="_footer-add address-wrap" style={{fontWeight:'100'}}>
     
                      <Markup content={footerData?.footer_text} />
                   
                  </div>
                  {/* <button 
                  onClick={handleInstall}
                  className="btn btn-gold" style={{
  fontSize: "15px",
  border: "1px solid #d4a54e",
  width: "130px",
  padding: "8px",
  paddingRight:"1px",
  background: "#d4a54e",
  boxShadow: "5px",
  color: "white",
  marginLeft: 0,
  borderRadius: '8px',
  display: 'flex', // Ensure the icon and text align properly
  alignItems: 'center', // Center the icon vertically with the text
  gap: '8px' // Optional: space between text and icon
}} >
  Install App
<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-cloud-arrow-down mt-1" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M7.646 10.854a.5.5 0 0 0 .708 0l2-2a.5.5 0 0 0-.708-.708L8.5 9.293V5.5a.5.5 0 0 0-1 0v3.793L6.354 8.146a.5.5 0 1 0-.708.708z"/>
  <path d="M4.406 3.342A5.53 5.53 0 0 1 8 2c2.69 0 4.923 2 5.166 4.579C14.758 6.804 16 8.137 16 9.773 16 11.569 14.502 13 12.687 13H3.781C1.708 13 0 11.366 0 9.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383m.653.757c-.757.653-1.153 1.44-1.153 2.056v.448l-.445.049C2.064 6.805 1 7.952 1 9.318 1 10.785 2.23 12 3.781 12h8.906C13.98 12 15 10.988 15 9.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 4.825 10.328 3 8 3a4.53 4.53 0 0 0-2.941 1.1z"/>
</svg>
</button> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  )
}