import React, { useState, useEffect, useRef } from "react";
import Header from "../Pages/Header&Footer/Header";
import Footer from "../Pages/Header&Footer/Footer";
import { IoEyeSharp } from "react-icons/io5";
import { useNavigate, useParams } from 'react-router-dom'
import { BsFillEyeSlashFill } from 'react-icons/bs';
import axios from "axios";


const ThankYou_Page = () => {
    const [passwordType, setPasswordType] = useState("password");
    const [addPassword, setAddPassword] = useState('');
    // const [confirmPassword,setConfirmPassword] = useState('')
    const [eamilErr, setEmailErr] = useState('');
    const { id } = useParams()
    const navigate = useNavigate()





    const anchorRef = useRef(null);
    const timeout = 3000;

    //   useEffect(() => {
    //     const timeoutId = setTimeout(() => {
    //       if (anchorRef.current) {
    //         alert('function is calling')
    //         anchorRef.current.click();
    //       }
    //     }, timeout);

    //     return () => {
    //       clearTimeout(timeoutId);
    //     };
    //   }, []);

    const PasswordChange=(e) => {
        setAddPassword(e.target.value) ;
        setEmailErr('')
    }

    const PasswordSubmit = (event) => {
        event.preventDefault();

        // if (addPassword.length > 6 || addPassword.length < 15  ) {
        //     // alert('Mobile number must contains 10 digit',addPassword )
        //     return false;
        //   }
        addPassword.length > 5 && addPassword.length < 16 ? SavePassword()  : setEmailErr('Password Must Have 6 to 15 Digit Long')


    }
    const SavePassword = async () => {
        const formdata = new FormData();
        formdata.append("user_id", Number(id));
        formdata.append("password", addPassword);
        formdata.append('confirm_password', addPassword)

        console.log(addPassword)
        await axios.post(`${process.env.REACT_APP_BASE_URL}api/signup_password_update`, formdata, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        }).then(response => {
            console.log(response)
            if (response.data.status === true) {
                // navigate("/thankyou-page");
                navigate('/Web_Ifreame')
        //                 const timeoutId = setTimeout(() => {
        //   if (anchorRef.current) {
        //     alert('function is calling')
        //     // anchorRef.current.click();
        //     // window.open('https://trustedclinics.co.uk/doctor_pwa/#/')
        //      // window.open('https://trustedclinics.co.uk/doctor_pwa/#/') Web_Ifreame
            
        //   }
        // }, timeout);

        // return () => {
        //   clearTimeout(timeoutId);
        // };
            }
            else {
                alert('Password are not Match ')
            }
        }).catch(error => console.log(error))

    }



    return (
        <>
            <Header />
            <div className="layout-main">
                {/* <a ref={anchorRef} href="https://trustedclinics.co.uk/doctor_pwa/#/" /> */}


                <div className="section static-page-main-section bg-light-blue">
                    <div className="container">
                        <h1 className="page-title ">Thank You</h1>
                        <div className="main-content text-center"><p>Thank you for requesting a free consultation with My Clinique. One of the team will be in contact very shortly. </p></div>



                        <h3 className="page-title " style={{fontWeight:"550"}}>If you don't want to join us then create your account</h3>

                        <form className="form my-4" onSubmit={PasswordSubmit}>
                            <div className="col-12 col-lg-4  mx-auto" >
                                <div className="form-group">
                                    <div className="flex" style={{ display: "flex" }}>
                                        <input type={passwordType} style={{ borderColor: '#684286' }}
                                            className="form-control icon-pencil input-outline-purple input-square"
                                            name="password" value={addPassword} onChange={PasswordChange} min={6} max={15}
                                            id="telephone" placeholder='Please Enter Your Password' aria-label="password" required />
                                        <PasswordCheck
                                            className="eyesIcon"
                                            setPasswordType={setPasswordType}
                                            passwordType={passwordType} />
                                    </div>
                                    <span className="login-page-web ">
                        {eamilErr}
                      </span>
                                    {/* <div className="flex pt-3" style={{ display: "flex" }}>
  <input type='password' style={{    borderColor: '#684286'}} 
  className="form-control icon-pencil input-outline-purple input-square"
  name="confirmPassword"    value={confirmPassword} onChange={(e)=>setConfirmPassword(e.target.value) } min={6} max={15}
  id="telephone" placeholder='Please Enter Your Password' aria-label="password"      required/>
  <PasswordCheck
    className="eyesIcon"
    setPasswordType={setPasswordType}
    passwordType={passwordType} />
</div> */}

                                </div>

                            </div>
                            <div className="text-center mt-3 mt-xl-2">
                                <button type="submit" className="btn btn-md btn-rounded btn-pink book-consult-button-inline ">
                                    <a className='text-white'>
                                        {/* href={data?.section_51_right_button_link} */}
                                        Submit
                                    </a>
                                </button>
                            </div>
                        </form>
                        <div className="main-content text-center"><p>Thank you for Submit  your Informetion  a book  free  consultation with My Clinique. One of the team will be in contact very shortly. </p></div>

                    </div>
                </div>




                <div className="section reviews-section section-22620 bg-blue">
                    <div className="container">
                        <h2 className="section-title text-color">Reviews</h2>
                        <div className="row">
                            <div className="col-lg-10 col-xl-8 mx-auto text-center">
                                <div className="introduction text-color"><p className="text-color">Here are some reviews from real MyClinique patients...</p></div>
                            </div>
                        </div>
                        <div className="row reviews justify-content-center">
                            <div className="col-md-6 col-lg-4 mb-2">
                                <p className="review text-color text-color">I had my consultation with Dr Buddha. He was by far the most professional compared to the other clinics I went to for consultations this week. His colleague Lisa was very friendly and helpful.</p>
                                <p className="patient-name text-color">Sue Green</p>
                            </div>
                            <div className="col-md-6 col-lg-4 mb-2">
                                <p className="review text-color" >So I went to see myclinique this week for a Invisalign consultation and I was very impressed, I saw a lady called Lisa and a man called Mark, I told them what a great consultation it was because I was informed of lot's of options and were given lots of information about Invisalign that other clinics never told me before.</p>
                                <p className="patient-name text-color">Natalia Marjovic</p>
                            </div>
                            <div className="col-md-6 col-lg-4 mb-2">
                                <p className="review text-color">MyClinique is the best clinic in Northampton my daughter got her Invisalign here with the gentleman and I get my anti-wrinkle injections here.</p>
                                <p className="patient-name text-color">Marianne Donovan</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

                       export default ThankYou_Page;

                        const PasswordCheck = ({setPasswordType, passwordType}) => {


    const PasswordText = () => {

        if (passwordType === "password") {
                            setPasswordType("text")
                        } else {
                            setPasswordType("password")
                        }
    }
                        return <span className="eyesIcon"  >
                            {passwordType === "password" ? <BsFillEyeSlashFill onClick={PasswordText} style={{ cursor: 'pointer' }} /> :
                                <IoEyeSharp onClick={PasswordText} style={{ cursor: 'pointer' }} />}</span>
}