import { Markup } from 'interweave'
import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'

function SectionFiftySeven(props) {
  const [data,setdata]= useState(props.data)
  console.log('data')
 console.log(data)
  useEffect(() => {
    setdata(props.data)
  }, [props.data])
 
    console.log('section 57')
    console.log(data)
  
  return (


<>
<div className="section find-you-local-clinic-section bg-inverse bg-radial-inverse" style={{background:data?.section_57_color || ''}}> 
    <div className="container">
      <div className="row">
        <div className="col-xl-8 offset-xl-2 col-lg-10 offset-lg-1 text-center">
          <h2 className="mb-lg-4">{data?.section_57_title}</h2>

       
        </div>
      </div>
      <div className="row">
        <div className="col-md-10 offset-md-1 col-lg-6 offset-lg-3  col-xl-6 offset-xl-3 text-center">
      

           
        
            <button type="submit" className="btn btn-gold btn-rounded btn-lg">
              <a href={data?.section_57_button_link} className='text-white'>
                {data?.section_57_button}
              </a>
            </button>
        
        </div>
      </div>
    </div>
  </div>
</>
  )
}

export default SectionFiftySeven