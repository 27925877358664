import { Markup } from 'interweave'
import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'

function SectionEight(props) {
  const [data,setdata]= useState(props.data)
  console.log('data')
 console.log(data)
  useEffect(() => {
    setdata(props.data)
  }, [props.data])
 
    console.log('sectionfive')
    console.log(data)
  return (
// <div className="section cost-benefits-section bg-off-white">
//     <div className="container text-center">
//         <div className="row">
//             <div className="col-xl-10  col-lg-10 offset-lg-1">
//                 <h1 className="mb-4">{data?.section_one_title || "About Us" }</h1>
//                 <p>{<Markup content={  data?.section_one_detail}/>  || `Trusted Clinics is the most extensive comparison website
//                      for healthcare clinics across the country.`
//                      }</p>
                     
//             </div>
//         </div>

      


//         <div className="row centered m-auto cost-benefits-section-icons pb-3 d-flex justify-content-center">
//             <div className="col-6 col-md-6 col-lg-4 pr-2 pl-2 mb-3">
//                 <div className="card card-treatment h-100">
//                     <div className="card-body">
//                         <div className="treatment-icon">
//                             <img className="icon-one" src={data?.section_one_image1 || "assets/img/search.png"} width="42" height="39" style={{maxHeight: '37px',marginTop: '6px'}} alt="Finance Available"/>
//                         </div>
//                         <h4>{data?.section_one_data1 || "Instant Search Results Tailored To Your Needs."}</h4>
//                     </div>
//                 </div>
//             </div>
//             <div className="col-6 col-md-6 col-lg-4 pr-2 pl-2 mb-3">
//                 <div className="card card-treatment h-100">
//                     <div className="card-body">
//                         <div className="treatment-icon">
//                             <img src={data?.section_one_image2 || "assets/img/question.png"} className="" width="44" height="48"style={{maxHeight: '37px',marginTop: '6px'}} alt="In-Clinic Private Payment Plans"/>
//                         </div>
//                         <h4> {data?.section_one_data2 || "Clear, Easily Accessible Information."}</h4>
//                     </div>
//                 </div>
//             </div>
//             <div className="col-6 col-md-6 col-lg-4 pr-2 pl-2 mb-3">
//                 <div className="card card-treatment h-100">
//                     <div className="card-body">
//                         <div className="treatment-icon">
//                             <img src={data?.section_one_image3 || "assets/img/treatmentplan.png"} className="" width="35" height="49" style={{maxHeight: '37px',marginTop: '6px'}} alt="Exclusive Trusted Clinics Discounts"/>
//                         </div>
//                         <h4>{ data?.section_one_data3 || "Essential Details And Treatment Plans Relevant To You."}</h4>
//                     </div>
//                 </div>
//             </div>
//             <div className="col-6 col-md-6 col-lg-4 pr-2 pl-2 mb-3">
//                 <div className="card card-treatment h-100">
//                     <div className="card-body">
//                         <div className="treatment-icon">
//                             <img className="icon-four" src={data?.section_one_image4 || "assets/img/icon-pound.png"}  width="45" height="49" style={{maxHeight: '37px',marginTop: '6px'}} alt="Spread The Cost Of Your Treatment Over (Up To) 5 Years."/>
//                         </div>
//                         <h4>{data?.section_one_data4 || "Exclusive Trusted Clinics Pricing"}</h4>
//                     </div>
//                 </div>
//             </div>
//             <div className="col-6 col-md-6 col-lg-4 pr-2 pl-2 mb-3">
//                 <div className="card card-treatment h-100">
//                     <div className="card-body">
//                         <div className="treatment-icon">
//                             <img className="icon-four" src={data?.section_one_image5 ||  "assets/img/clinic.png"} width="45" height="49" style={{maxHeight: '37px',marginTop: '6px'}} alt="Spread The Cost Of Your Treatment Over (Up To) 5 Years."/>
//                         </div>
//                         <h4>{data?.section_one_data5 || "The Trusted Clinics Approval."}</h4>
//                     </div>
//                 </div>
//             </div>
//         </div>
//         <div className="row">
//             <div className="col-xl-10  col-lg-10 offset-lg-1 text-center">
//                 <p>{data?.section_one_footer ||  `We regularly review and update the clinics on Trusted Clinics, giving you the peace of mind that the 
//                     clinic you choose is the clinic you can trust`}.</p>
//             </div>
//         </div>
//     </div>
// </div> 
<div className="section cost-benefits-section bg-off-white" style={{background:data?.section_8_color || ''}}>
    <div className="container text-center">
        <div className="row">
            <div className="col-xl-10  col-lg-10 offset-lg-1">
                <h1 className="mb-4">{data?.section_eight_title}</h1>
                
                {
                    <Markup content={  data?.section_eight_detail}/>  
                     }
                     
            </div>
        </div>

      


        <div className="row centered m-auto cost-benefits-section-icons pb-3 d-flex justify-content-center">
        {
            (data?.section_eight_image1 || data?.section_eight_data1) && 
            <div className="col-6 col-md-6 col-lg-4 pr-2 pl-2 mb-3">
                <div className="card card-treatment h-100">
                    <div className="card-body">
                        <div className="treatment-icon">
                            <img className="icon-one" src={data?.section_eight_image1} width="42" height="39" style={{maxHeight: '37px',marginTop: '6px'}} alt={data?.section_eight_image1_alt}/>
                        </div>
                        <h4>{data?.section_eight_data1}</h4>
                    </div>
                </div>
            </div>
        }
            
        {
            (data?.section_eight_image2 || data?.section_eight_data2) && 
            <div className="col-6 col-md-6 col-lg-4 pr-2 pl-2 mb-3">
                <div className="card card-treatment h-100">
                    <div className="card-body">
                        <div className="treatment-icon">
                            <img src={data?.section_eight_image2} className="" width="44" height="48"style={{maxHeight: '37px',marginTop: '6px'}} alt={data?.section_eight_image2_alt}/>
                        </div>
                        <h4> {data?.section_eight_data2}</h4>
                    </div>
                </div>
            </div>
        }
        
        {
            (data?.section_eight_image3 || data?.section_eight_data3) && 
            <div className="col-6 col-md-6 col-lg-4 pr-2 pl-2 mb-3">
                <div className="card card-treatment h-100">
                    <div className="card-body">
                        <div className="treatment-icon">
                            <img src={data?.section_eight_image3} className="" width="35" height="49" style={{maxHeight: '37px',marginTop: '6px'}} alt={data?.section_eight_image3_alt}/>
                        </div>
                        <h4>{ data?.section_eight_data3}</h4>
                    </div>
                </div>
            </div>
        }
            
        {
            (data?.section_eight_image4 || data?.section_eight_data4) && 
            <div className="col-6 col-md-6 col-lg-4 pr-2 pl-2 mb-3">
                <div className="card card-treatment h-100">
                    <div className="card-body">
                        <div className="treatment-icon">
                            <img className="icon-four" src={data?.section_eight_image4}  width="45" height="49" style={{maxHeight: '37px',marginTop: '6px'}} alt={data?.section_eight_image4_alt}/>
                        </div>
                        <h4>{data?.section_eight_data4}</h4>
                    </div>
                </div>
            </div>

        }

        {
            (data?.section_eight_image5 || data?.section_eight_data5) &&
            <div className="col-6 col-md-6 col-lg-4 pr-2 pl-2 mb-3">
                <div className="card card-treatment h-100">
                    <div className="card-body">
                        <div className="treatment-icon">
                            <img className="icon-four" src={data?.section_eight_image5} width="45" height="49" style={{maxHeight: '37px',marginTop: '6px'}} alt={data?.section_eight_image5_alt}/>
                        </div>
                        <h4>{data?.section_eight_data5}</h4>
                    </div>
                </div>
            </div>
        }
            
            
        </div>
        <div className="row">
            <div className="col-xl-10  col-lg-10 offset-lg-1 text-center">
                {
                  <Markup content={data?.section_eight_footer} />
                }
            </div>
        </div>
    </div>
</div>
  )
}

export default SectionEight