import { Markup } from 'interweave'
import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'

function SectionFiftyFive(props) {
  const [data,setdata]= useState(props.data)
  console.log('data')
 console.log(data)
  useEffect(() => {
    setdata(props.data)
  }, [props.data])
 
    console.log('section 40')
    console.log(data)
  
  return (


<div className="section bg-off-white partners" id="trusted" 
style={{background:data?.section_55_color || ''}}
>
<div className="container">
    <div className="row">
        <div className="col-xl-8 offset-xl-2 col-lg-10 offset-lg-1 pb-0 text-center">
            <h2 className="mb-4 initial-text-transform test-page-text-image"><img className='test-page-image' src={data?.section_55_header_image} alt={data?.section_55_header_image1_alt}/> {data?.section_55_title?.substring(0,30)}</h2>
        </div>

        <div className="col-12 mt-3">
            <div className="table-responsive">
                <table className="table">
                    <thead>
                    <tr>
                        <th scope="col"></th>
                        <th scope="col" className='image-in-table'>
                            <img width="144" className="table-head-imge" src={data?.section_55_header_image} alt={data?.section_55_header_image1_alt                                                                                                                            }/>
                        </th>
                        <th scope="col">
                        {data?.section_55_colomn3}
                        </th>
                        <th scope="col">
                        {data?.section_55_colomn4}
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <th width="25%" scope="row">{data?.section_55_row1}</th>
                        <td width="25%">{data?.section_55_row1_data1}</td>
                        <td width="25%">{data?.section_55_row1_data2}</td>
                        <td width="25%">{data?.section_55_row1_data3}</td>
                    </tr>
                    <tr>
                        <th scope="row">{data?.section_55_row2}</th>
                        <td>{data?.section_55_row2_data1}</td>
                        <td>{data?.section_55_row2_data2}</td>
                        <td>{data?.section_55_row2_data3}</td>
                    </tr>
                    <tr>
                        <th scope="row">{data?.section_55_row3}</th>
                        <td>{data?.section_55_row3_data1}</td>
                        <td>{data?.section_55_row3_data2}</td>
                        <td>{data?.section_55_row3_data3}</td>
                    </tr>
                    <tr>
                        <th scope="row">{data?.section_55_row4}</th>
                        <td>{data?.section_55_row4_data1}</td>
                        <td>{data?.section_55_row4_data2}</td>
                        <td>{data?.section_55_row4_data3}</td>
                    </tr>
                    <tr>
                        <th scope="row">{data?.section_55_row5}</th>
                        <td>{data?.section_55_row5_data1}</td>
                        <td>{data?.section_55_row5_data2}</td>
                        <td>{data?.section_55_row5_data3}</td>
                    </tr>
                    <tr>
                        <th scope="row">{data?.section_55_row6}</th>
                        <td>{data?.section_55_row6_data1}</td>
                        <td>{data?.section_55_row6_data2}</td>
                        <td>{data?.section_55_row6_data3}</td>
                    </tr>
                    <tr>
                        <th scope="row">{data?.section_55_row7}</th>
                        <td>{data?.section_55_row7_data1}</td>
                        <td>{data?.section_55_row7_data2}</td>
                        <td>{data?.section_55_row7_data3}</td>
                    </tr>
                    <tr>
                        <th scope="row">{data?.section_55_row8}</th>
                        <td>{data?.section_55_row8_data1}</td>
                        <td>{data?.section_55_row8_data2}</td>
                        <td>{data?.section_55_row8_data3}</td>
                    </tr>
                    <tr>
                        <th scope="row">{data?.section_55_row9}</th>
                        <td>{data?.section_55_row9_data1}</td>
                        <td>{data?.section_55_row9_data2}</td>
                        <td>{data?.section_55_row9_data3}</td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <div className="col-12 mt-md-3 pb-3 pb-md-0">
            <p className="mb-1">{data?.section_55_footer}</p>
        </div>
    </div>
</div>
</div>
  )
}

export default SectionFiftyFive