import { Markup } from 'interweave'
import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'

function SectionThirtyTwo(props) {
  const [data, setdata] = useState(props.data)
  console.log('data')
  console.log(data)
  useEffect(() => {
    setdata(props.data)
  }, [props.data])

  console.log('section 32')
  console.log(data)

  return (

    <div className="main-section" style={{background:data?.section_32_color || ''}}>
      <div className="container text-center p-5">
        <h1 className="page-title " style={{color:'#253959'}}>{data?.section_32_title}</h1>
        <div className="gallery-container">
          <div
            className="grid gallery-grid"
            itemScope=""
            itemType={data?.section_32_image1 || data?.section_32_image1_alt}
            data-pswp-uid={1}
            style={{ position: "relative", height: 54 }}
          >
            {/* <figure
            itemProp="associatedMedia"
            itemScope=""
            itemType="http://schema.org/ImageObject"
            className="grid-item col-12 col-md-6"
            style={{ position: "absolute", left: "0%", top: 0 }}
          >
            <a
              href="https://cloud1.coe-website.securestaging.co.uk/images/gallery/_galleryZoom/shutterstock_1165261312-2.jpg?mtime=20200608233146&focal=none&tmtime=20200731063335"
              className="card card-gallery-item size-2x1"
              itemProp="contentUrl"
              data-size="1200x412"
            >
              <img
                src="https://cloud1.coe-website.securestaging.co.uk/images/gallery/_gallery_2x1/shutterstock_1165261312-2.jpg?mtime=20200608233146&focal=none&tmtime=20200731063334"
                itemProp="thumbnail"
                alt="Test 1 alt"
              />
            </a>
            <figcaption itemProp="caption description" className="overlay-text">
              <span>Test 1 overlay</span>
            </figcaption>
          </figure>
          <figure
            itemProp="associatedMedia"
            itemScope=""
            itemType="http://schema.org/ImageObject"
            className="grid-item col-6 col-md-3"
            style={{ position: "absolute", left: "50%", top: 0 }}
          >
            <a
              href="https://cloud1.coe-website.securestaging.co.uk/images/gallery/_galleryZoom/shutterstock_1141097963.jpg?mtime=20200609093238&focal=none&tmtime=20200731063335"
              className="card card-gallery-item size-1x1"
              itemProp="contentUrl"
              data-size="1199x800"
            >
              <img
                src="https://cloud1.coe-website.securestaging.co.uk/images/gallery/_gallery_1x1/shutterstock_1141097963.jpg?mtime=20200609093238&focal=none&tmtime=20200731063334"
                itemProp="thumbnail"
                alt=""
              />
            </a>
          </figure> */}
            <div className="grid-sizer col-6 col-md-3" />

          </div>
          <div className="grid gallery-grid" itemscope itemtype="http://schema.org/ImageGallery">

            <div className='card-flex-stories'>

              <div className='d-flex' style={{justifyContent:'center'}}>
                <figure itemprop="associatedMedia" itemscope itemtype="http://schema.org/ImageObject" className="grid-item col-6 col-md-3 col-lg-6 ">
                  <a className="card card-gallery-item size-1x1" itemprop="contentUrl" data-size="800x800">
                    <img src={data?.section_32_image1} alt={data?.section_32_image1_alt} itemprop="thumbnail" />
                  </a>
                </figure>
                <figure itemprop="associatedMedia" itemscope itemtype="http://schema.org/ImageObject" className="grid-item col-6 col-md-3 col-lg-6 ">
                  <a className="card card-gallery-item size-1x1" itemprop="contentUrl" data-size="800x800">
                    <img  src={data?.section_32_image2} alt={data?.section_32_image2_alt} itemprop="thumbnail"  />
                  </a>
                </figure>
              </div>

              <div  className='d-flex'  style={{justifyContent:'center'}}>
                <figure itemprop="associatedMedia" itemscope itemtype="http://schema.org/ImageObject" className="grid-item col-6 col-md-3 col-lg-6 ">
                  <a className="card card-gallery-item size-1x1" itemprop="contentUrl" data-size="800x800">
                    <img  src={data?.section_32_image3} alt={data?.section_32_image3_alt} itemprop="thumbnail"  />
                  </a>
                </figure>
                <figure itemprop="associatedMedia" itemscope itemtype="http://schema.org/ImageObject" className="grid-item col-6 col-md-3 col-lg-6 ">
                  <a className="card card-gallery-item size-1x1" itemprop="contentUrl" data-size="800x800">
                    <img  src={data?.section_32_image4} alt={data?.section_32_image4_alt} itemprop="thumbnail" />
                  </a>
                </figure>
              </div>

            </div>
            <div className='card-flex-stories'>

              <div  className='d-flex' style={{justifyContent:'center'}}>

                <figure itemprop="associatedMedia" itemscope itemtype="http://schema.org/ImageObject" className="grid-item col-6 col-md-3 col-lg-6 ">
                  <a className="card card-gallery-item size-1x1" itemprop="contentUrl" data-size="800x800">
                    <img  src={data?.section_32_image5} alt={data?.section_32_image5_alt} itemprop="thumbnail"  />
                  </a>
                </figure>
                <figure itemprop="associatedMedia" itemscope itemtype="http://schema.org/ImageObject" className="grid-item col-6 col-md-3 col-lg-6 ">
                  <a className="card card-gallery-item size-1x1" itemprop="contentUrl" data-size="800x800">
                    <img  src={data?.section_32_image6} alt={data?.section_32_image6_alt} itemprop="thumbnail"  />
                  </a>
                </figure>
              </div>
    
              <div  className='d-flex' style={{justifyContent:'center'}}>
              <figure itemprop="associatedMedia" itemscope itemtype="http://schema.org/ImageObject" className="grid-item col-6 col-md-3 col-lg-6 ">
                <a className="card card-gallery-item size-1x1" itemprop="contentUrl" data-size="800x800">
                  <img  src={data?.section_32_image7} alt={data?.section_32_image7_alt} itemprop="thumbnail"  />
                </a>
              </figure>
              <figure itemprop="associatedMedia" itemscope itemtype="http://schema.org/ImageObject" className="grid-item col-6 col-md-3 col-lg-6 ">
                <a className="card card-gallery-item size-1x1" itemprop="contentUrl" data-size="800x800">
                  <img  src={data?.section_32_image8} alt={data?.section_32_image8_alt} itemprop="thumbnail"  />
                </a>
              </figure>
              </div>


            </div>

          </div>
        </div>
      </div>
    </div>
  )
}

export default SectionThirtyTwo