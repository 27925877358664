import { Markup } from 'interweave'
import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'

function SectionThirtyFour(props) {
  const [data,setdata]= useState(props.data)
  console.log('data')
 console.log(data)
  useEffect(() => {
    setdata(props.data)
  }, [props.data])
 
    console.log('section 34')
    console.log(data)

  return (
<>
<div className="section range-section section-5250 bg-off-white" style={{background:data?.section_34_color || ''}}>
    <div className="container">
      <div className="row">
        <div className="col-xl-8 offset-xl-2 col-lg-10 offset-lg-1 pb-0 text-center mb-5">
          <h2 className="mb-4">{data?.section_34_title || 'The Invisalign product range'}</h2>
          <div className='mb-4'>
          {
            <Markup content={data?.section_34_description} /> 
          }
            
          </div>
        </div>
        <div className="col-12">
          <div className="row text-center mb-3 d-flex justify-content-center">
          {
            (data?.section_34_image1 || data?.section_34_detail1 || data?.section_34_price1) &&
            <div className="col-sm-12 col-md-6 col-lg-4 pb-3 px-sm-3 mt-70 card-body-margin">
              <a
                href={data?.sec_34_link1}
                className="card card-treatment-range invisalign h-100"
              >
                <div
                  className="card-body-bg"
                  style={{
                    backgroundImage:
                      "url(https://cloud1.coe-website.securestaging.co.uk/images/treatment-range/_card/image2_200510_160250.png?mtime=20200514033337&focal=none&tmtime=20200729131102)",
                    backgroundPosition: "bottom center"
                  }}
                >
                  <div className="card-body">
                    <h3>
                      <span className="img">
                      {
                        (data?.section_34_image1) &&
                        <img
                          src={data?.section_34_image1}
                          className="img-fluid pt-3"
                          alt={data?.section_34_image1_alt}
                        />
                      }
                        
                      </span>
                      <span className="sr-only">Invisalign i7</span>{" "}
                    </h3>
                    <div className="desc">

                      <p>
                      {data?.section_34_detail1}
                        
                      </p>
                    </div>
                    <span className="price-from-circle price-from-section34">
                      From
                      <br />
                      <b>{data?.section_34_price1}</b>
                      <br />
                      Per Month
                    </span>
                 
                  </div>
                  <div className='preview-image-treatments center'>
                      <img className='mx-auto'src={data?.section_34_image4} alt={data?.section_34_image4_alt} />
                    </div>
                </div>
              </a>
            </div>
          }
        {
            (data?.section_34_image2 || data?.section_34_detail2 || data?.section_34_price2) &&
            <div className="col-sm-12 col-md-6 col-lg-4 pb-3 px-sm-3 mt-70 card-body-margin">
              <a
                href={data?.sec_34_link2}
                className="card card-treatment-range invisalign h-100"
              >
                <div
                  className="card-body-bg"
                  style={{
                    backgroundImage:
                      "url(https://cloud1.coe-website.securestaging.co.uk/images/treatment-range/_card/image3_200510_160252.png?mtime=20200514033337&focal=none&tmtime=20200729131059)",
                    backgroundPosition: "bottom center"
                  }}
                >
                  <div className="card-body">
                    <h3>
                      <span className="img">
                      {
                        (data?.section_34_image2) && 
                        <img
                          src={data?.section_34_image2}
                          className="img-fluid pt-3"
                          alt={data?.section_34_image2_alt}
                        />
                      }
                        
                      </span>
                      <span className="sr-only">Invisalign Lite</span>{" "}
                    </h3>
                    <div className="desc">
                      <p>
                      {
                        data?.section_34_detail2 
                      }
                        
                      </p>
                    </div>
                    <span className="price-from-circle price-from-section34">
                      From
                      <br />
                      <b>{data?.section_34_price2}</b>
                      <br />
                      Per Month
                    </span>
                   
                  </div>
                  <div className='preview-image-treatments center'>
                      <img className='mx-auto' src={data?.section_34_image5} alt={data?.section_34_image5_alt} />
                    </div>
                </div>
              </a>
            </div>
        }
        {
            (data?.section_34_image3 || data?.section_34_detail3 || data?.section_34_price3) &&
            <div className="col-sm-12 col-md-6 col-lg-4 pb-3 px-sm-3 mt-70 card-body-margin">
              <a
                href={data?.sec_34_link3}
                className="card card-treatment-range invisalign h-100"
              >
                <div
                  className="card-body-bg"
                  style={{
                    backgroundImage:
                      "url(https://cloud1.coe-website.securestaging.co.uk/images/treatment-range/_card/image2_200510_160250.png?mtime=20200514033337&focal=none&tmtime=20200729131102)",
                    backgroundPosition: "bottom center"
                  }}
                >
                  <div className="card-body">
                    <h3>
                      <span className="img">
                      {
                        (data?.section_34_image3) &&
                        <img
                          src={data?.section_34_image3}
                          className="img-fluid pt-3"
                          alt={data?.section_34_image3_alt}
                        />
                      }
                        
                      </span>
                      <span className="sr-only">Invisalign Full</span>{" "}
                    </h3>
                    <div className="desc">
                      <p>
                      {
                        data?.section_34_detail3 
                      }
                       
                      </p>
                    </div>
                    <span className="price-from-circle price-from-section34">
                      From
                      <br />
                      <b>{data?.section_34_price3}</b>
                      <br />
                      Per Month
                    </span>
                 
                  </div>
                  <div className='preview-image-treatments center'>
                      <img className='mx-auto'src={data?.section_34_image6} alt={data?.section_34_image6_alt}  />
                    </div>
                </div>
              </a>
            </div>
        }
            
          </div>
        </div>
        <div className="col-xl-8 offset-xl-2 col-lg-10 offset-lg-1 text-center">
          <div className='form-css-hidden'>

       
        {
            (data?.section_34_button) &&
            <a
            href={data?.sec_34_button_link}
            className="btn btn-md btn-rounded btn-pink book-consult-button text-white"
          >
           {data?.section_34_button}
          </a>
        }
          
        </div>  
         </div>
      </div>
   
    </div>
  </div>
</>

  )
}

export default SectionThirtyFour