import React from 'react'
import Header from '../Pages/Header&Footer/Header'
import Footer from '../Pages/Header&Footer/Footer'

const ViewAllArticle = () => {
    const view=[
        {
      id:'1',
      name:'Trusted Clinics News StoriesTrusted Clinics News StoriesTrusted Clinics News Stories'
    },
    {
        id:'1',
        name:`Trusted Clinics News StoriesTrusted Clinics News StoriesTrusted Clinics News Stories
        Trusted Clinics News StoriesTrusted Clinics News StoriesTrusted Clinics News Stories
        `
      },
      {
        id:'1',
        name:`Trusted Clinics News StoriesTrusted Clinics News StoriesTrusted Clinics News Stories
        Trusted Clinics News StoriesTrusted Clinics News StoriesTrusted Clinics News Stories
        Trusted Clinics News StoriesTrusted Clinics News StoriesTrusted Clinics News Stories`
      },
      {
        id:'1',
        name:`Trusted Clinics News StoriesTrusted Clinics News StoriesTrusted Clinics News Stories
        Trusted Clinics News StoriesTrusted Clinics News StoriesTrusted Clinics News Stories
        Trusted Clinics News StoriesTrusted Clinics News StoriesTrusted Clinics News StoriesTrusted 
        Clinics News StoriesTrusted Clinics News StoriesTrusted Clinics News Stories`
      },
      {
        id:'1',
        name:'Trusted Clinics News StoriesTrusted Clinics News StoriesTrusted Clinics News Stories'
      }
      ,
      {
        id:'1',
        name:`Trusted Clinics News StoriesTrusted Clinics News StoriesTrusted Clinics News Stories
        Trusted Clinics News StoriesTrusted Clinics News StoriesTrusted Clinics News Stories
        Trusted Clinics News StoriesTrusted Clinics News StoriesTrusted Clinics News Stories`
      }
]
  return (
 <>
 

<Header/>
      <div className="news-b mb-3"
      style={{
        backgroundColor:'#6dc1c8'
      }}>
          <div className="container">
            <div className="row mb-lg-5 py-5">
              <div className="col-md-7 col-lg-6 col-xl-6 text-center ">
                <h1 className='text-white'>Trusted Clinics News Stories</h1>
                <p className='text-white'>At Trusted Clinics, we are highly invested in the world of dentistry and are committed to keeping up to date with all the latest dental news. In turn, we share that news with you. Ensure you are always kept in the loop and made aware of all dental updates by taking a look through our dental stories. Learn more about current trends and
                  developments in the world of dentistry.</p> 
                <p className='text-white'>The dental world is ever changing and developments are constantly being formulated. From progressive, innovative treatments to highly technical dentistry equipment, there are always new and exciting advancements being made. Whether you have an interest in teeth straightening treatments such as clear aligners, or more traditional dental work like crowns, bridges and dental implants, this is the place to receive updated information about the
                  procedures and how they are ever improving.</p> 
                <p className='text-white'>
                  Click on one of the news stories below to have a more in-depth look into the latest
                  developments happening in world of dentistry.   
                </p> 
              </div>
              <div className="col-md-4">
                {/* <img className="mobile" src="https://trustedclinics.com/images/pages/pricing/new/ipad.png" alt="News banner image" /> */}
              </div>
            </div>
          </div>
        </div> 


<div>
<div className='container'>
<div className='row'>

{view.map((val)=>{
    return(
        <div className="grid-item col-md-6 col-lg-4" 
// style={{position: 'absolute', left: '0%', top: '0px'}}
>
              <div className="card card-news-grid p-2">
                <a href="" className="card-image">
                  <img
                //    src="https://cloud1.coe-website.securestaging.co.uk/images/header-images/_cardImage/hero-couple-4_200605_082022.jpg?mtime=20200605092022&focal=none&tmtime=20200805163736" 
                src='https://trustedclinics.com/images/pages/dentist-near-me/trustednationwide-logo.png'
                   alt="Is it too late to straighten my teeth?" className="img-fluid" />
                </a>
                <div className="card-body text-center">
                  <h3 className="card-title blue">Is it too late to straighten my teeth?</h3>
                  <div className="preview-text">
                    <p>There are many people who dislike the way their teeth look, but <a href="https://trustedclinics.com/dental/treatments/invisalign-clear-aligners/">clear braces for adults</a> can offer a discreet way in which you can straighten your teeth.</p>
                  </div>
                  <hr />
      <div className='mb-2'>
      <p className="post-date mb-0"><strong>Date:</strong> 5th Aug 2020</p>
                  <p className="category mb-0"><strong>Category:</strong> Invisalign</p>
                  </div>

                  <a href="https://trustedclinics.com/dental/articles/is-it-too-late-to-straighten-my-teeth/" className="btn btn-pink btn-md btn-rounded mt-3">Read More Information</a>
                </div>
              </div>
            </div>
    )
})}
  
</div>
</div>
</div>

<Footer/>
 </>
  )
}

export default ViewAllArticle