import { Markup } from 'interweave'
import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'

function SectionFifteen(props) {
  const [data,setdata]= useState(props.data)
  console.log('data')
 console.log(data)
  useEffect(() => {
    setdata(props.data)
  }, [props.data])
 
    console.log('sectionfifteen')
    console.log(data)
  
  return (
  
  <div className="section cost-benefits-section bg-off-white bg-white" style={{background:data?.section_15_color || ''}}>
  <div className="container text-center">
    <div className="row">
      <div className="col-xl-10  col-lg-10 offset-lg-1">
     {<Markup content={ data?.section_fifteen_detail}/>}
      </div>
    </div>
  </div>
  <div className="container-fluid">
  {
    (data?.section_fifteen_image) &&
    <div className="row">
      <div className="col-12 contact-relative contact-relative-contactus">
        <img src={ data?.section_fifteen_image} alt={data?.section_Fifteen_image1_alt} width={200} height={200} className="contact-absolute  contact-absolute-contactus" />
      </div>
    </div>
  }
    
  </div>
</div>
  )
}

export default SectionFifteen