import axios from 'axios'
import moment from 'moment'
import  React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import "./blog.css"
const LatestBlog=()=>{
    const [BlogLatest,setBlogLatest] = useState([])
    const [fetchLatest,setfetchLatest]=  useState(false)
    const pagename = localStorage.getItem("current_page_name") || ""
    useEffect(() => {
    
        Get_Blog_Data()
      }, [])
     
         const Get_Blog_Data=async()=>{
    
          // get_latest_blog
    await axios.get(`${process.env.REACT_APP_BASE_URL}api/get_latest_blog_by_pagename/${pagename}`).
    then(res=>{
        if(res.data.data === false){
            setfetchLatest(false)
        }
        else{
            setfetchLatest(true)
        }
        setBlogLatest(res.data?.detail)
        
  
      })
      .catch(error=>{
        console.log(error)
        
      })
  }
 
return(
    <>
      {
             fetchLatest && <div className="widget widget-latest-post p-3" style={{borderRadius:"30px"}}>
                <div className="widget-title">
                  <h3>RECENT POSTS</h3>
                </div>
                <div className="widget-body">
                 
                  {
                  BlogLatest &&  BlogLatest?.slice(0,5)?.map(blog => 
                      <NavLink to={`/blog/${blog?.category_name}/${blog?.page_name}`}>
                    <div className="latest-post-aside media mb-2 p-2 row border" 
               style={{background:"white",borderRadius:"20px"}}
                    >
                    <div className='col-4 p-0 m-0 letest_box_img_box'>
                      <a >
                        <img 
                        style={{
                          width:'100px',
                          height:'100px',
                          borderRadius:"10px"
                        }}
                        className='border '
                         src={blog?.image}
                        title alt="" />
                      </a>
                      <div className="image-overlay"></div>

                  </div>
                  <div className='col-8'  style={{
                          width:'100%',
                          height:'100px',
                       
                        }}>
                      <div className="" >
                        <span ><a className='text-dark letest_blog_text' >
                        {(blog?.title && blog?.title.length > 40) ? `${blog?.title.slice(0, 40)}...` : blog?.title}
                        </a></span>
                      </div>
                      <div className="lpa-meta"
                     
                      >
                        {/* <a className="name" >
                          {blog.name} 
                        </a>  */}
                        <br></br>
                        <a className="text-dark" style={{
                          position:"absolute",
                          bottom:"0px"
                        }} >
                        {moment(blog.created_at).format('LL')}
                        </a>
                        

                      </div>
                  </div>
                  
                    {/* <div className="lpa-left media-body col-8">
                  
                      <div className="lpa-title" style={{maxWidth:"160px"}}>
                        <span ><a className='text-dark' >{blog?.title}</a></span>
                      </div>
                      <div className="lpa-meta">
                        <a className="name" >
                          {blog.name}
                        </a>
                    

                      </div>
                    </div>
                    
                    <div className="lpa-right col-4">
                      <a >
                        <img 
                        style={{
                          width:'100%',
                          height:'60px',
                          borderRadius:"10px"
                        }}
                        className='border '
                         src={blog?.image}
                        title alt="" />
                      </a>
                    </div> */}
                 
                  </div>
                  </NavLink>
                 ) }
                </div>
              </div>}
    </>
)
}

export default LatestBlog