import { Markup } from 'interweave'
import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'

function SectionFortyNine(props) {
  const [data,setdata]= useState(props.data)
//   console.log('data')
 console.log(data)
  useEffect(() => {
    setdata(props.data)
  }, [props.data])
 
    // console.log('sectionfive')
    console.log(data)
  return (
  <>
      <div className="section reviews-section section-6575 bg-white" style={{background:data?.section_49_color || ''}} >
    <div className="container">
      <div className="row">
        <div className="col-xl-8 offset-xl-2 col-lg-10 offset-lg-1 pb-0 text-center">
          <h2 className="mb-4">{data?.section_49_title}</h2>
          <div>
          { <Markup content={data?.section_49_subtitle1} />}
            {/* <p>
              At Trusted Clinics, we value the opinions of patients and
              encourage them to share their experiences so that the high
              standards of treatment can be upheld. See reviews from customers
              who have completed their fixed brace treatment.
            </p> */}
          </div>
        </div>
      </div>
      <div className="row mt-2 mb-1 d-flex justify-content-center">
        <div className="col-md-6 col-lg-4">
          <div className="review-container">
            <div className="review-quote">
            { <Markup content={data?.section_49_subtitle2} />}
              {/* <p>
                I had braces fitted in Poland and needed to finish the treatment
                in the UK. My first doctor was Polmedics, but they kept seeing
                me, charging me and I saw no improvement. The clinic was
                recommended on the Polish forums and despite being in Derby I
                travelled there. The results are amazing and I am so happy to
                have made this choice.
              </p> */}
            </div>
            <p className="reviewer mb-0 mt-3">
              Patient:
              <span>Imogen E</span>
            </p>
            <p className="reviewer-stars">
              <i className="fas fa-star" />
              <i className="fas fa-star" />
              <i className="fas fa-star" />
              <i className="fas fa-star" />
              <i className="fas fa-star" />{" "}
            </p>
          </div>
        </div>
        <div className="col-md-6 col-lg-4">
          <div className="review-container">
            <div className="review-quote">
            { <Markup content={data?.section_49_subtitle3} />}
              {/* <p>
                I had my consultation...by far the most professional compared to
                the other clinics...very friendly and helpful. I have just
                signed up to get braces as my daughter had her braces and now
                her teeth look brilliant, I am confident mine can be improved
                too. Bonus free consultation, paid 50 pounds for a less
                informative consultation elsewhere.
              </p> */}
            </div>
            <p className="reviewer mb-0 mt-3">
              Patient:
              <span>Sue G</span>
            </p>
            <p className="reviewer-stars">
              <i className="fas fa-star" />
              <i className="fas fa-star" />
              <i className="fas fa-star" />
              <i className="fas fa-star" />
              <i className="fas fa-star" />{" "}
            </p>
          </div>
        </div>
        <div className="col-md-6 col-lg-4">
          <div className="review-container">
            <div className="review-quote">
            { <Markup content={data?.section_49_subtitle4} />}
              {/* <p>
                For some time I was thinking of getting braces, I was unhappy
                with my front teeth. I talked this through with my dentist who
                immediately offered a solution- Six Month Smiles at £4000 but he
                was unsure about the lower teeth. I had seen good reviews...and
                thought I would get a consultation. They were professional and
                friendly from the outset...the results are beyond my
                expectations.
              </p> */}
            </div>
            <p className="reviewer mb-0 mt-3">
              Patient:
              <span>Bever F</span>
            </p>
            <p className="reviewer-stars">
              <i className="fas fa-star" />
              <i className="fas fa-star" />
              <i className="fas fa-star" />
              <i className="fas fa-star" />
              <i className="fas fa-star" />{" "}
            </p>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12 text-center">
          <p className="text-center">
            {/* <a
            href={data?.section_49_link}
            //   href="https://trustedclinics.com/dental/treatments/dental-braces/reviews/"
              className="text-link"
            >
              Read more fixed brace reviews
              <i className="fas fa-angle-right" />
            </a> */}
            {
                <Markup content={data?.section_49_link} />
            }
          </p>
          <a
            href={data?.sec_49_button_link}
            className="btn btn-md btn-rounded btn-pink book-consult-button text-white"
          >
          {data?.section_49_button}
            {/* Book Your Free Consultation Now */}
          </a>
        </div>
      </div>
    </div>
  </div>
  </>
  )
}

export default SectionFortyNine


