import axios from 'axios'
import { Markup } from 'interweave'
import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'

function SectionForteen(props) {
  const [data,setdata]= useState(props.data)
  console.log('data')
 console.log(data)
  useEffect(() => {
    setdata(props.data)
  }, [props.data])
 
    console.log('sectionfourteen')
    console.log(data)
  
    
  return (
  <div className="section contact-form bg-inverse" style={{background:data?.section_14_color || ''}}>
    <div className="container text-center">
      <div className="row">
        <div className="col-xl-8 offset-xl-2 col-lg-10 offset-lg-1">
          <h2 className="mb-4">{data?.section_fourteen_title}</h2>
          <div className="card form-card bg-white">
            <div className="card-body text-left">
              <form  className="form"  >
                <input type="hidden" name="CRAFT_CSRF_TOKEN"  />
                <div className="row">
                {
                  (data?.section_fourteen_head1) &&
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label style={{  color: "#4a4a4a"}} >{data?.section_fourteen_head1}</label>
                      <input type="text" className="form-control" name="name" id="restore-name"  value=""   placeholder={`${data?.section_fourteen_head1}`}  required />
                    </div>
                  </div>
                }
                
                {
                  (data?.section_fourteen_head2) &&
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label style={{  color: "#4a4a4a"}}>{data?.section_fourteen_head2}</label>
                      <input type="email" className="form-control" name="email" id="restore-email"  value=""   placeholder={`${data?.section_fourteen_head2}`}    required />
                    </div>
                  </div>
                }
                
                {
                  (data?.section_fourteen_head3) &&
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label style={{  color: "#4a4a4a"}} >{data?.section_fourteen_head3}</label>
                      <input type="text" className="form-control" name="phone" id="restore-telephone"  value=""  placeholder={`${data?.section_fourteen_head3}`}    required />
                    </div>
                  </div>
                }
                
                {
                  (data?.section_fourteen_head4) &&
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label style={{  color: "#4a4a4a"}}>{data?.section_fourteen_head4}</label>
                      <input type="text" className="form-control" name="treatment" id="restore-postcode"  value=""  placeholder={`${data?.section_fourteen_head4}`}    required />
                    </div>
                  </div>
                }
                
                {
                  (data?.section_fourteen_head5) &&
                  <div className="col-12">
                    <div className="form-group">
                      <label style={{  color: "#4a4a4a"}}>{data?.section_fourteen_head5}</label>
                      <textarea type="text" rows={4} className="form-control" name="comment" id="restore-postcode"   value= ""   placeholder={`${data?.section_fourteen_head5}`}     required />
                    </div>
                  </div>
                }
                  
                {
                  (data?.section_fourteen_head6) &&
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label style={{  color: "#4a4a4a"}}>{data?.section_fourteen_head6}</label>
                      <input type="text" className="form-control" name="postcode" id="restore-postcode"  value=""  placeholder={`${data?.section_fourteen_head6}`}   required />
                    </div>
                  </div>
                }
                
                {
                  (data?.section_fourteen_checkbox) &&
                  <div className="col-sm-6 mt-5">
                    <div className="form-group" >
                      <label htmlFor="agree_to_terms" className="text-normal"  style={{  color: "#4a4a4a"}}>
                      <input type="checkbox" name="agree_to_terms" style={{height:"25px",width:'25px',maxWidth:'25px',marginRight:'5px'}}  value=""    required />
                    <span style={{position:'relative',top:'-5px',fontSize:'14px'}}>{data?.section_fourteen_checkbox}</span>
                      </label>
                    
                    </div>
                  </div>
                }
                  
                </div>
                {
                  (data?.section_fourteen_button) &&
                  <div className="text-center mt-2">
                  <button type="submit" className="btn btn-md btn-rounded btn-pink book-consult-button-inline">
                  {data?.section_fourteen_button}
                  </button>
                </div>
                }
                
              </form>
            </div>
          </div>
          <p className="text-white">{data?.section_fourteen_footer}</p>
        </div>
      </div>
    </div>
  </div>

  )
}

export default SectionForteen