import { Markup } from 'interweave'
import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'

function SectionThree(props) {
  const [data,setdata]= useState(props.data)
  console.log('data')
 console.log(data)
  useEffect(() => {
    setdata(props.data)
  }, [props.data])
 
    console.log('sectionthree')
    console.log(data)
    let image = 'https://picsum.photos/200'
  return (

// <div className="section treatments-section">
//<div className="container text-center">
  //</div><div className="row">
//       <div className="col-xl-8 offset-xl-2 col-lg-10 offset-lg-1">
//         <h2 className="mb-4">{data?.section_three_title}</h2>
//       {
//         <Markup content={data?.section_three_subtitle} />
//       }
       
//       </div>
//     </div>
//     <div className="row text-center my-3 ">
//     {
//       (data?.section_three_head1 || data?.section_three_image1) && 
//       <div className="col-6 col-sm-6 col-lg-3 mb-3 mt-md-60 pr-2 pr-lg-3">
//         <NavLink
//           to={'/'}  className="card card-treatment-img dental-implants h-100"
//           style={{backgroundImage:`url(${data?.section_three_image1}),linear-gradient(#fff 13%,#fff,#ecf2f2,#ecf2f2 60% 100%)`,backgroundSize: '135px 120px,100%',backgroundPosition: '100% 100%'}} >
//           <div className="card-body">
//             <h3>{data?.section_three_head1}</h3>
//           </div>
//         </NavLink>
//       </div>
//     }
      
//     {
//       (data?.section_three_head2 || data?.section_three_image2) &&
//       <div className="col-6 col-sm-6 col-lg-3 mb-3 mt-md-60 pl-2 pl-lg-3 left5">
//         <NavLink
//           to={'/'} className="card card-treatment-img full-mouth-reconstruction h-100"
//           style={{backgroundImage:`url(${data?.section_three_image2}),linear-gradient(#fff 13%,#fff,#ecf2f2,#ecf2f2 60% 100%)`,backgroundSize: '135px 120px,100%',backgroundPosition: '100% 100%'}}>
//           <div className="card-body">
//             <h3>{data?.section_three_head2 }</h3>
//           </div>
//         </NavLink>
//       </div>

//     }

//     {
//       (data?.section_three_head3 || data?.section_three_image3) &&
//       <div className="col-6 col-sm-6 col-lg-3 mb-3 mt-md-60 pr-2 pr-lg-3 right5">
//         <NavLink
//           to={'/'}  className="card card-treatment-img invisalign-aligners h-100"
//           style={{backgroundImage:`url(${data?.section_three_image3}),linear-gradient(#fff 13%,#fff,#ecf2f2,#ecf2f2 60% 100%)`,backgroundSize: '135px 120px,100%',backgroundPosition: '100% 100%'}}>
//           <div className="card-body">
//             <div className="text-center mb-1">
//               {/* <img
//                 src="assets/img/invisalign-icon.png"
//                 width={153}
//                 height={35}
//                 alt="issue occured"
//               /> */}
//             </div>
//             <h3>{data?.section_three_head3}</h3>
//           </div>
//         </NavLink>
//       </div>
//     }
      
//     {
//       (data?.section_three_head4 || data?.section_three_image4) &&
//       <div className="col-6 col-sm-6 col-lg-3 mb-3 mt-md-60 pl-2 pl-lg-3 left5">
//         <NavLink
//           to={'/'}            className="card card-treatment-img veneers h-100"
//           style={{backgroundImage:`url(${data?.section_three_image4}),linear-gradient(#fff 13%,#fff,#ecf2f2,#ecf2f2 60% 100%)`,backgroundSize: '135px 120px,100%',backgroundPosition: '100% 100%'}}>
//           <div className="card-body">
//             <h3>{data?.section_three_head4}</h3>
//           </div>
//         </NavLink>
//       </div>
//     }

//     {
//       (data?.section_three_head5 || data?.section_three_image5) &&
//       <div className="col-6 col-sm-6 col-lg-3 mb-3 mt-md-60 pr-2 pr-lg-3 right5">
//         <NavLink
//           to={'/'}            className="card card-treatment-img fixed-and-lingual-braces h-100"
//           style={{backgroundImage:`url(${data?.section_three_image5}),linear-gradient(#fff 13%,#fff,#ecf2f2,#ecf2f2 60% 100%)`,backgroundSize: '135px 120px,100%',backgroundPosition: '100% 100%'}}>
//           <div className="card-body">
//             <h3>{data?.section_three_head5}</h3>
//           </div>
//         </NavLink>
//       </div>
//     }
      
//     {
//       (data?.section_three_head6 || data?.section_three_image6) &&
//       <div className="col-6 col-sm-6 col-lg-3 mb-3 mt-md-60 pl-2 pl-lg-3 left5">
//         <NavLink
//           to={'/'}            className="card card-treatment-img crowns-and-bridges h-100"
//           style={{backgroundImage:`url(${data?.section_three_image6}),linear-gradient(#fff 13%,#fff,#ecf2f2,#ecf2f2 60% 100%)`,backgroundSize: '135px 120px,100%',backgroundPosition: '100% 100%'}}>
//           <div className="card-body">
//             <h3>{data?.section_three_head6 }</h3>
//           </div>
//         </NavLink>
//       </div>
//     }
     
//     {
//       (data?.section_three_head7 || data?.section_three_image7) &&
//       <div className="col-6 col-sm-6 col-lg-3 mb-3 mt-md-60 pr-2 pr-lg-3 right5">
//         <NavLink
//           to={'/'}  className="card card-treatment-img fillers h-100"
//           style={{backgroundImage:`url(${data?.section_three_image7}),linear-gradient(#fff 13%,#fff,#ecf2f2,#ecf2f2 60% 100%)`,backgroundSize: '135px 120px,100%',backgroundPosition: '100% 100%'}}>
//           <div className="card-body">
//             <h3>{data?.section_three_head7 }</h3>
//           </div>
//         </NavLink>
//       </div>
//     }
      
//     {
//       (data?.section_three_head8 || data?.section_three_image8) &&
//       <div className="col-6 col-sm-6 col-lg-3 mb-3 mt-md-60 pl-2 pl-lg-3 left5">
//         <NavLink
//           to={'/'} className="card card-treatment-img emergency-treatment h-100"
//           style={{backgroundImage:`url(${data?.section_three_image8}),linear-gradient(#fff 13%,#fff,#ecf2f2,#ecf2f2 60% 100%)`,backgroundSize: '135px 120px,100%',backgroundPosition: '100% 100%'}}>
//           <div className="card-body">
//             <h3>{data?.section_three_head8 }</h3>
//           </div>
//         </NavLink>
//       </div>
//     }
      
//     </div>
//     <div className="row">
//       <div className="col-xl-8 offset-xl-2 col-lg-10 offset-lg-1">
//       {
//         <Markup content={data?.section_three_footer} /> 
//       }
       
//        {
//         (data?.section_three_footer_button) && 
//         <NavLink
//           to={'/'}            className="btn btn-lg btn-rounded btn-pink book-consult-button d-table"
//         >
//          {data?.section_three_footer_button}
//         </NavLink>
//        }
        
//       </div>
//     </div>
//   </div>
// </div>
<div className="section treatments-section" style={{background:data?.section_3_color || ''}}>
<div className="container text-center">
  <div className="row">
    <div className="col-xl-8 offset-xl-2 col-lg-10 offset-lg-1">
      <h2 className="mb-4">{data?.section_three_title}</h2>
      {
        <Markup content={data?.section_three_subtitle} /> 
      }
    </div>
  </div>
  <div className="row text-center my-3 hide-score">
  {  (data?.section_three_head1 || data?.section_three_image1) && 
    <div className="col-6 col-sm-6 col-lg-3 mb-3 mt-md-60 pr-2 pr-lg-3">
      <a href={data?.sec_3_link1} className="card card-treatment-img dental-implants h-100"               
                style={{backgroundImage:`url(${data?.section_three_image1}),linear-gradient(#fff 13%,#fff,#ecf2f2,#ecf2f2 60% 100%)` ,backgroundSize: '135px 120px,100%',backgroundPosition: '100% 100%'}}>
        <div className="card-body">
        <h3>{data?.section_three_head1}</h3>
        </div>
      </a>
    </div>}

    {  (data?.section_three_head2 || data?.section_three_image2) && 
    <div className="col-6 col-sm-6 col-lg-3 mb-3 mt-md-60 pr-2 pr-lg-3">
      <a href={data?.sec_3_link2}  className="card card-treatment-img full-mouth-reconstruction h-100"               
                style={{backgroundImage:`url(${data?.section_three_image2}),linear-gradient(#fff 13%,#fff,#ecf2f2,#ecf2f2 60% 100%)`,backgroundSize: '135px 120px,100%',backgroundPosition: '100% 100%'}}>
        <div className="card-body">
        <h3>{data?.section_three_head2}</h3>
        </div>
      </a>
    </div>}

    {  (data?.section_three_head3 || data?.section_three_image3) && 
    <div className="col-6 col-sm-6 col-lg-3 mb-3 mt-md-60 pr-2 pr-lg-3">
      <a href={data?.sec_3_link3}   className="card card-treatment-img invisalign-aligners h-100"               
                style={{backgroundImage:`url(${data?.section_three_image3}),linear-gradient(#fff 13%,#fff,#ecf2f2,#ecf2f2 60% 100%)`,backgroundSize: '135px 120px,100%',backgroundPosition: '100% 100%'}}>
        <div className="card-body">
        <h3>{data?.section_three_head3}</h3>
        </div>
      </a>
    </div>}
    
    {  (data?.section_three_head4 || data?.section_three_image4) && 
    <div className="col-6 col-sm-6 col-lg-3 mb-3 mt-md-60 pr-2 pr-lg-3">
      <a href={data?.sec_3_link4}   className="card card-treatment-img veneers h-100"               
                style={{backgroundImage:`url(${data?.section_three_image4}),linear-gradient(#fff 13%,#fff,#ecf2f2,#ecf2f2 60% 100%)`,backgroundSize: '135px 120px,100%',backgroundPosition: '100% 100%'}}>
        <div className="card-body">
        <h3>{data?.section_three_head4}</h3>
        </div>
      </a>
    </div>}


    {  (data?.section_three_head5 || data?.section_three_image5) && 
    <div className="col-6 col-sm-6 col-lg-3 mb-3 mt-md-60 pr-2 pr-lg-3">
      <a href={data?.sec_3_link5}   className="card card-treatment-img fixed-and-lingual-braces h-100"               
                style={{backgroundImage:`url(${data?.section_three_image5}),linear-gradient(#fff 13%,#fff,#ecf2f2,#ecf2f2 60% 100%)`,backgroundSize: '135px 120px,100%',backgroundPosition: '100% 100%'}}>
        <div className="card-body">
        <h3>{data?.section_three_head5}</h3>
        </div>
      </a>
    </div>}
    
    {  (data?.section_three_head6 || data?.section_three_image6) && 
    <div className="col-6 col-sm-6 col-lg-3 mb-3 mt-md-60 pr-2 pr-lg-3">
      <a href={data?.sec_3_link6}   className="card card-treatment-img crowns-and-bridges h-100"               
                style={{backgroundImage:`url(${data?.section_three_image6}),linear-gradient(#fff 13%,#fff,#ecf2f2,#ecf2f2 60% 100%)`,backgroundSize: '135px 120px,100%',backgroundPosition: '100% 100%'}}>
        <div className="card-body">
        <h3>{data?.section_three_head6}</h3>
        </div>
      </a>
    </div>}


    {  (data?.section_three_head7 || data?.section_three_image7) && 
    <div className="col-6 col-sm-6 col-lg-3 mb-3 mt-md-60 pr-2 pr-lg-3">
      <a href={data?.sec_3_link7}   className="card card-treatment-img fillers h-100"               
                style={{backgroundImage:`url(${data?.section_three_image7}),linear-gradient(#fff 13%,#fff,#ecf2f2,#ecf2f2 60% 100%)`,backgroundSize: '135px 120px,100%',backgroundPosition: '100% 100%'}}>
        <div className="card-body">
        <h3>{data?.section_three_head7}</h3>
        </div>
      </a>
    </div>}
    
    {  (data?.section_three_head8 || data?.section_three_image8) && 
    <div className="col-6 col-sm-6 col-lg-3 mb-3 mt-md-60 pr-2 pr-lg-3">
      <a href={data?.sec_3_link8}   className="card card-treatment-img emergency-treatment h-100"               
                style={{backgroundImage:`url(${data?.section_three_image8}),linear-gradient(#fff 13%,#fff,#ecf2f2,#ecf2f2 60% 100%)`,backgroundSize: '135px 120px,100%',backgroundPosition: '100% 100%'}}>
        <div className="card-body">
        <h3>{data?.section_three_head8}</h3>
        </div>
      </a>
    </div>}
    {/* <div className="col-6 col-sm-6 col-lg-3 mb-3 mt-md-60 pl-2 pl-lg-3">
      <a href className="card card-treatment-img full-mouth-reconstruction h-100">
        <div className="card-body">
          <h3>Full Mouth Reconstruction</h3>
        </div>
      </a>
    </div>
    <div className="col-6 col-sm-6 col-lg-3 mb-3 mt-md-60 pr-2 pr-lg-3">
      <a href className="card card-treatment-img invisalign-aligners h-100">
        <div className="card-body">
          <div className="text-center mb-1">
            <img src="assets/img/invisalign-icon.png" width={153} height={35} alt="Invisalign Clear Aligners" />
          </div>
          <h3>Clear Aligners</h3>
        </div>
      </a>
    </div>
    <div className="col-6 col-sm-6 col-lg-3 mb-3 mt-md-60 pl-2 pl-lg-3">
      <a href className="card card-treatment-img veneers h-100">
        <div className="card-body">
          <h3>Veneers</h3>
        </div>
      </a>
    </div>
    <div className="col-6 col-sm-6 col-lg-3 mb-3 mt-md-60 pr-2 pr-lg-3">
      <a href className="card card-treatment-img fixed-and-lingual-braces h-100">
        <div className="card-body">
          <h3>Fixed &amp; Lingual Braces</h3>
        </div>
      </a>
    </div>
    <div className="col-6 col-sm-6 col-lg-3 mb-3 mt-md-60 pl-2 pl-lg-3">
      <a href className="card card-treatment-img crowns-and-bridges h-100">
        <div className="card-body">
          <h3>Crowns &amp; Bridges</h3>
        </div>
      </a>
    </div>
    <div className="col-6 col-sm-6 col-lg-3 mb-3 mt-md-60 pr-2 pr-lg-3">
      <a href className="card card-treatment-img fillers h-100">
        <div className="card-body">
          <h3>Fillers &amp; Anti-Wrinkle Injections</h3>
        </div>
      </a>
    </div>
    <div className="col-6 col-sm-6 col-lg-3 mb-3 mt-md-60 pl-2 pl-lg-3">
      <a href className="card card-treatment-img emergency-treatment h-100">
        <div className="card-body">
          <h3>Emergency Treatment</h3>
        </div>
      </a>
    </div> */}
  </div>
  
  <div className="row">
    <div className="col-xl-8 offset-xl-2 col-lg-10 offset-lg-1 ">
    {
         <Markup content={data?.section_three_footer} /> 
       }
      <div className='form-css-hidden'>
      {
         (data?.section_three_footer_button) && 
         <a  href={data?.sec_3_button_link}       className="btn btn-lg btn-rounded btn-pink book-consult-button d-table text-white "
         >
          {data?.section_three_footer_button}
         </a>
        }
      </div>
      
        
       </div>
    </div>
</div>
</div>
  )
}

export default SectionThree