import { Markup } from 'interweave'
import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'

function SectionEighteen(props) {
  const [data,setdata]= useState(props.data)
  console.log('data')
 console.log(data)
  useEffect(() => {
    setdata(props.data)
  }, [props.data])
 
    console.log('SectionEighteen')
    console.log(data)
  
  return (
        <div className="faq bg-linear-inverse" style={{background:data?.section_18_color || ''}}>
          <div className="container">
            <div className="row py-5">
              <div className=" col-xl-8 offset-xl-2 col-lg-10 offset-lg-1 text-center" style={{color:'white'}}>
                <h1 className="mb-lg-1 text-white">{data?.section_eighteen_title}</h1>
                {
                  <Markup content={data?.section_eighteen_description} />
                }
                {/* <h2 className="mb-4 text-white">{ data?.section_eighteen_subtitle }</h2> */}
              </div>
            </div>
          </div>
        </div>
 
  )
}

export default SectionEighteen