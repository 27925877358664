import { Markup } from 'interweave'
import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'

function SectionFourtyOne(props) {
  const [data,setdata]= useState(props.data)
  console.log('data')
 console.log(data)
  useEffect(() => {
    setdata(props.data)
  }, [props.data])
 
    console.log('section 41')
    console.log(data)
  return (

<div className="page-dentists-near-me" style={{background:data?.section_41_color || ''}}>
          <div className="layout-main">
            <div className="bg-image-locations">
              <div className="section initial-section bg-inverse">
                <div className="container">
                  <div className="dentist-near-me-top">
                    <div className="text-center text-xl-right mb-4">
                      <h1 className="page-title">{data?.section_41_title}</h1>
                      <div className="desc">
                        {
                            <Markup content={data?.section_41_description} /> 
                        }
                      </div>
                    </div>
                    <div className="row text-center">
                      <div className="col-12 col-xl-5 p-big-circle  form-css-hidden">
                        <div className="circle circle-1">
                          <div className="content">
                            <div className="image-holder-pig">
                              <img src={data?.section_41_banner1} alt={data?.section_41_banner1_img_alt} />
                            </div>
                            <p className="main-strapline" style={{color:'#253959',fontWeight:'500'}}>Compare the UK's best clinics &amp; <em style={{fontWeight:'700'}}>save up to 50%</em> on your treatment.</p>
                            <img className="bottom" src={data?.section_41_banner2} alt={data?.section_41_banner2_img_alt} />
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-xl-7">
                        <div className="row">
                          <div className="col-md-4 p-circle">
                            <div className="circle circle-2">
                              <div className="content">
                                <div className="image-holder">
                                  <img height={40} src={data?.section_41_image1} alt={data?.section_41_image2_alt} />
                                </div>
                                <p style={{color:'#253959',fontWeight:'500'}} >{data?.section_41_head1}</p>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-4 p-circle">
                            <div className="circle circle-3">
                              <div className="content">
                                <div className="image-holder">
                                  <img height={40} src={data?.section_41_image2} alt={data?.section_41_image3_alt} />
                                </div>
                                <p style={{color:'#253959',fontWeight:'500'}} >{data?.section_41_head2}</p>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-4 p-circle">
                            <div className="circle circle-4">
                              <div className="content">
                                <div className="image-holder">
                                  <img height={40} src={data?.section_41_image3} alt={data?.section_41_image4_alt} />
                                </div>
                                <p style={{color:'#253959',fontWeight:'500'}} >{data?.section_41_head3}</p>
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-12">
                            <h3 className="mb-3 mt-5">{data?.section_41_right_heading}</h3>
                            <form action={data?.sec_41_button_link} method="post" className="find-clinic-form-1 mx-auto mb-5" style={{ maxWidth: '350px' }}>
                              <input type="hidden" name="CRAFT_CSRF_TOKEN" defaultValue />
                              <div className="form-group" style={{  width:"90%",  transform: "translate(5%, 5%)"}}>
                                <label htmlFor="postcode" className="sr-only">Postcode</label>
                                <div className="input-group">
                                  <input type="text"  name="postcode" id="postcode" className="form-control" placeholder={`${data?.section_41_right_placeholder}`} />
                                  <div className="input-group-append text-purple">
                                    <span className="input-group-text" id="basic-addon2"><i className="fas fa-map-marker-alt" /></span>
                                  </div>
                                </div>
                              </div>
                              <div className="form-group">
                                <div id="rollerLoader" className="text-center" style={{ display: 'none' }}>
                                  <div data-v-01d979ac className="lds-roller" style={{ width: '24px', height: '24px' }}>
                                    <div data-v-01d979ac style={{ animationDuration: '1.2s', transformOrigin: '12px 12px', animationDelay: '-0.036s' }}>
                                      <div data-v-01d979ac className="div-after" style={{ width: '2.1px', height: '2.1px', margin: '-1.2px 0px 0px -1.2px', background: 'rgb(255, 255, 255)', top: '18.9px', left: '18.9px' }} /></div>
                                    <div data-v-01d979ac style={{ animationDuration: '1.2s', transformOrigin: '12px 12px', animationDelay: '-0.072s' }}>
                                      <div data-v-01d979ac className="div-after" style={{ width: '2.1px', height: '2.1px', margin: '-1.2px 0px 0px -1.2px', background: 'rgb(255, 255, 255)', top: '18.9px', left: '18.9px' }} /></div>
                                    <div data-v-01d979ac style={{ animationDuration: '1.2s', transformOrigin: '12px 12px', animationDelay: '-0.108s' }}>
                                      <div data-v-01d979ac className="div-after" style={{ width: '2.1px', height: '2.1px', margin: '-1.2px 0px 0px -1.2px', background: 'rgb(255, 255, 255)', top: '20.4px', left: '16.8px' }} /></div>
                                    <div data-v-01d979ac style={{ animationDuration: '1.2s', transformOrigin: '12px 12px', animationDelay: '-0.144s' }}>
                                      <div data-v-01d979ac className="div-after" style={{ width: '2.1px', height: '2.1px', margin: '-1.2px 0px 0px -1.2px', background: 'rgb(255, 255, 255)', top: '21.3px', left: '14.4px' }} /></div>
                                    <div data-v-01d979ac style={{ animationDuration: '1.2s', transformOrigin: '12px 12px', animationDelay: '-0.18s' }}>
                                      <div data-v-01d979ac className="div-after" style={{ width: '2.1px', height: '2.1px', margin: '-1.2px 0px 0px -1.2px', background: 'rgb(255, 255, 255)', top: '21.6px', left: '12px' }} />
                                    </div><div data-v-01d979ac style={{ animationDuration: '1.2s', transformOrigin: '12px 12px', animationDelay: '-0.216s' }}>
                                      <div data-v-01d979ac className="div-after" style={{ width: '2.1px', height: '2.1px', margin: '-1.2px 0px 0px -1.2px', background: 'rgb(255, 255, 255)', top: '21.3px', left: '9.6px' }} />
                                    </div><div data-v-01d979ac style={{ animationDuration: '1.2s', transformOrigin: '12px 12px', animationDelay: '-0.252s' }}>
                                      <div data-v-01d979ac className="div-after" style={{ width: '2.1px', height: '2.1px', margin: '-1.2px 0px 0px -1.2px', background: 'rgb(255, 255, 255)', top: '20.4px', left: '7.2px' }} />
                                    </div><div data-v-01d979ac style={{ animationDuration: '1.2s', transformOrigin: '12px 12px', animationDelay: '-0.288s' }}>
                                      <div data-v-01d979ac className="div-after" style={{ width: '2.1px', height: '2.1px', margin: '-1.2px 0px 0px -1.2px', background: 'rgb(255, 255, 255)', top: '18.9px', left: '5.1px' }} /></div></div>
                                  <span className="waiting-msg">Please wait while we get your location</span>
                                </div>
                                <button type="button" id="useLocationBtn" className="btn text-white">
                                  <i className="fas fa-crosshairs text-white" /> {data?.section_41_right_location}
                                </button>
                              </div>
                              <button type="submit" className="btn btn-gold btn-rounded btn-lg">{data?.section_41_right_button}</button>
                            </form>
                            <hr className="light" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-xl-8 text- prie-and-pig-circle">
              <div className="row">
              <div className="col-md-4 p-circle">
                  <div className="circle circle-2">
                    <div className="content">
                      <div className="image-holder-pig d-flex">
                            <img src={data?.section_41_banner1} alt={data?.section_41_banner1_img_alt} width={'100px'} height={'auto'} />
                            <p className="main-strapline pt-3" style={{color:'#253959',fontWeight:'500'}}>Compare the UK's best clinics &amp; <em style={{fontWeight:'700'}}>save up to 50%</em> on your treatment.</p>
                          </div>
                        
                    </div>
                  </div>
                </div>
                </div>
                </div>
                    <div className="section">
                      <div className="row">
                        <div className="col-lg-10 offset-lg-1 text-center">
                          <div className="col-lg-12 pb-0 text-center">
                            <div className="content content-top">
                            {
                              <Markup content={data?.section_41_footer1} />
                            }
                            </div>
                          </div>
                          { data?.section_41_footer_image && <div className="col-xl-8 offset-xl-2 col-lg-10 offset-lg-1 mb-4">
                            <div className="card form-card bg-white">
                              <img className="dentist-location-image mx-auto" src={data?.section_41_footer_image} alt={data?.section_41_image4_alt} />
                            </div>
                          </div>}
                          <div className="col-lg-12 pb-0 text-center">
                            <div className="content content-bottom">
                            {
                              <Markup content={data?.section_41_footer2} />
                            }
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          {/* <div className="section bg-grey">
              <div className="container">
                <h2>Click on the search results below to find trusted dental clinics in Brentwood:</h2>
                <hr />
                <div className="results-sorting">
                  <div className="results-type mr-auto">Results: <strong>All</strong></div>
                  <label htmlFor="sortBy">Sort By</label>
                  <div className="select-field">
                    <select name="sort-by" id="sortBy" className="form-control max-width-200">
                      <option value>-- Please Select --</option>
                    </select>
                  </div>
                  <label htmlFor="filterGroup">Reviews</label>
                  <div className="select-field">
                    <select name="sort-by" id="sortBy" className="form-control max-width-200">
                      <option value>-- Please Select --</option>
                    </select>
                  </div>
                </div>
                <div className="alert alert-info text-center">
                  <h4>No results found</h4>
                  <p>There are currently no results for this location. Check back again soon, we're adding more clinics all the time.</p>
                </div>
              </div>
            </div> */}
            {/* <div className="section bg-grey">
            <div className="container">
              <h2>
                To find a suitable clinic in Hemel Hempstead, search the results below:
              </h2>
              <hr />
              <div className="results-sorting">
                <div className="results-type mr-auto">Results: <strong>All</strong></div>
                <label htmlFor="sortBy">Sort By</label>
                <div className="select-field">
                  <select name="sort-by" id="sortBy" className="form-control max-width-200">
                    <option value>-- Please Select --</option>
                  </select>
                </div>
                <label htmlFor="filterGroup">Reviews</label>
                <div className="select-field">
                  <select name="sort-by" id="sortBy" className="form-control max-width-200">
                    <option value>-- Please Select --</option>
                  </select>
                </div>
              </div>
              <div className="clinics-results">
                <div className="card clinic-listing-card best-match">
                  <div className="card-row-1">
                    <div className="d-flex">
                      <div className="branding">
                        <div className="logo-container p-1">
                          <img 
                          // src="assets/img/dUUZ0GCCvGQrDUpZiM5p.png" 
                          src="https://crm.trustedclinics.com/storage/images/clinic-logos/dUUZ0GCCvGQrDUpZiM5p.png"
                          className="img-fluid max" alt="Alexandra Dental" />
                        </div>
                        <div className="logo-title" style={{float:'left'}}>
                          <h4><a href>Alexandra Dental</a></h4>
                        </div>
                      </div>
                      <div className="contact">
                        <a href="tel:01442 256335" className="tel">01442 256335</a><br />
                        <p className="address">98 Alexandra Road, Hemel Hempstead, HP2 4AG</p>
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div className="card-row-2">
                    <div className="d-flex">
                      <div className="img-block">
                      </div>
                      <div className="profile">
                        <div className="d-flex">
                          <div className="treatments" >
                           <div className="row">
                           <h4 style={{float:'left',paddingLeft:'10px'}}>Treatments</h4>
                           </div>
                            <div className="row">
                              <div className="col-6">
                              <NavLink  to={'/alexandra_dental'} className="text-primary" style={{cursor:'pointer',float:'left'}}>View all offered treatments <i className="fas fa-chevron-right" /></NavLink>
                              </div>
                            </div>
                          </div>
                          <div className="reviews">
                            <div className="card review-score-card card-body">
                              <p className="desc">Excellent</p>
                              <p className="reviews-score">(9.5/10)</p>
                              <p className="review-stars">
                                <i className="fas fa-star" /><i className="fas fa-star" /><i className="fas fa-star" /><i className="fas fa-star" /><i className="fas fa-star" />                          </p>
                              <p className="reviews-count">2,224 Reviews</p>
                            </div>
                          </div>
                        </div>
                        <div className="clinic-intro">
                          <p className="mb-0">The practice dates back to 1976 and has built a strong reputation based on strong patient care, a highly trained team and the latest technologies, all delivered in a caring and friendly environment. <br />
                            Today their services offered are general appointments, cosmetics, braces, implants and advanced straightening technologies such as Invisalign. <br />
                            The practice has invested a considerable amount of time and money over the past few years upgrading the practice and improving their facilities. They have embraced state of the art techniques designed to improve their service in every respect.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div className="card-row-3">
                    <div className="d-flex">
                      <div className="languages">
                        <b style={{float:'left'}}>Languages Spoken: </b>
                        <span style={{float:'left'}}>English </span>
                      </div>
                      <div className="buttons text-right">
                        <NavLink  to={'/alexandra_dental'} className="more-info mr-3" style={{cursor:'pointer'}}>View more information <i className="fas fa-chevron-right" /></NavLink>
                        <a href className="btn btn-pink btn-rounded btn-smaller text-white button-show-and-hide">Book your Free Consultation</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
            <div className="modal fade" id="remoteClinicModal" tabIndex={-1} role="dialog" aria-labelledby="remoteClinicModalLabel" aria-hidden="true">
              <div className="modal-dialog modal-xl">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="remoteClinicModalLabel" />
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">×</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <iframe src="./Brentwood-Dentist near me_files/saved_resource.html" frameBorder={0} />
                  </div>
                </div>
              </div>
            </div> 
            <div className="section bg-grey">
            <div className="container">
              <h2>
              Approved Trusted Clinics in Leicester
              </h2>
              <hr />
              <div className="results-sorting">
                <div className="results-type mr-auto">Results: <strong>All</strong></div>
                <label htmlFor="sortBy">Sort By</label>
                <div className="select-field">
                  <select name="sort-by" id="sortBy" className="form-control max-width-200">
                    <option value>-- Please Select --</option>
                  </select>
                </div>
                <label htmlFor="filterGroup">Reviews</label>
                <div className="select-field">
                  <select name="sort-by" id="sortBy" className="form-control max-width-200">
                    <option value>-- Please Select --</option>
                  </select>
                </div>
              </div>
              <div className="alert alert-info text-center">
                <h4>No results found</h4>
                <p>There are currently no results for this location. Check back again soon, we're adding more clinics all the time.</p>
              </div>
            </div>
          </div> */}
          </div>
        </div>
  )
}

export default SectionFourtyOne