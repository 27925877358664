import { Markup } from 'interweave'
import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'

function SectionFourtyThree(props) {
  const [data,setdata]= useState(props.data)
  console.log('data')
 console.log(data)
  useEffect(() => {
    setdata(props.data)
  }, [props.data])
 
    console.log('section 43')
    console.log(data)
  return (

<>
<div className="section main-section section-single-post bg-off-white" style={{background:data?.section_43_color || ''}}>
    <div className="container">
      <div className="main-content">
       {
        <Markup content={data?.section_43_description} />
        }
        
      </div>
    </div>
  </div>
  <link href="./05-4 Zirconia implants_files/photoswipe.css" rel="stylesheet" />
</>
  )
}

export default SectionFourtyThree