import { Markup } from 'interweave'
import React, { useEffect, useState } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import axios from 'axios';


function SectionFiftyOne(props) {
  const [data,setdata]= useState(props.data)
    const [signinData,setSignInData] = useState([])
  const navigate = useNavigate()
  const [eamilErr, setEmailErr] = useState('');
  const [phoneErr, setPhoneErr] = useState('');

  useEffect(() => {
    setdata(props.data)
  }, [props.data])
 
    console.log('section 51')
  

  const SignInHandler=(event)=>{
    const names = event.target.name;
    const values = event.target.value;
    
    setSignInData( data=>({  ...data ,[names] : values    }))
  
  }

  const SubmitHandler= async(event)=>{
    event.preventDefault();
    // console.log(signinData.mobile.length > 10)


    if (signinData.mobile.length > 10 || signinData.mobile.length < 10  ) {
        // alert('Mobile number must contains 10 digit')
        setPhoneErr('Mobile number must contains 10 digit')
        return false;
      }else {
        setPhoneErr('')
      }

 

    console.log(signinData)
    const formdata = new FormData();
    formdata.append("name",signinData.name );
    formdata.append("email", signinData.email);
    formdata.append("phone", signinData.mobile);
    formdata.append("user_type", "2");
    
    await axios.post(`${process.env.REACT_APP_BASE_URL}api/signup`,formdata, {
        headers: {
            "Content-Type": "multipart/form-data",
          },
    }).then(response=>{
        console.log(response.data)
        if (response.data.status === true) {
            navigate(`/thankyou-page/${response.data.data.id}`); 
          } 
          else{
            setEmailErr('Email is Alredy exist')
          }
    }).catch(error => console.log(error))
 
    // navigate("/thankyou-page");
  }
 

console.log(eamilErr)
  return (


<div className="layout-main" 
style={{background:data?.section_51_color || '#674086'}}
>
<div className="main-section dentist-banner" style={{background:"#674086  url('https://trustedclinics.com/images/dentist-banner.jpg?69011f11da09f6fb8df9228c863d16cf') no-repeat "}}>
      <div className="container container-test-page">
          <div className="row mb-lg-3">

              <div className="col-lg-10 col-xl-7">
                  <h1 className="page-title mb-4" style={{fontWeight:'400',color:'#fff'}}>
                    {/* Dental marketing specialist.<br/>
                      Results guaranteed. */}
                      {data?.section_51_title}
                  </h1>
              </div>

              <div className="col-md-6 col-lg-6 col-xl-7 boxes-block-banner">
                  <div className="row mb-2 mb-sm-0 mb-md-0 mb-lg-3">
                      <div className="col-xl-8">
                          <div className="row">
                             {(data?.section_51_image1 || data?.section_51_head1) && <div className="col-6 col-sm-6 pb-3 px-2">
                                  <div className="card card-body card-headline partner-banner-cards justify-content-center h-100">
                                      <img src={data?.section_51_image1} alt={data?.section_51_image1_alt}/>
                                      <h4>{data?.section_51_head1}</h4>
                                  </div>
                              </div>}
                             {(data?.section_51_image2 || data?.section_51_head2) && <div className="col-6 col-sm-6 pb-3 px-2">
                                  <div className="card card-body card-headline partner-banner-cards justify-content-center h-100">
                                      <img src={data?.section_51_image2} alt={data?.section_51_image2_alt}/>
                                      <h4>{data?.section_51_head2}</h4>
                                  </div>
                              </div>}
                             {(data?.section_51_image3 || data?.section_51_head3) && <div className="col-6 col-sm-6 pb-3 px-2">
                                  <div className="card card-body card-headline partner-banner-cards justify-content-center h-100">
                                      <img src={data?.section_51_image3} alt={data?.section_51_image3_alt}/>
                                      <h4>{data?.section_51_head3}</h4>
                                  </div>
                              </div>}
                              {(data?.section_51_image4 || data?.section_51_head4) &&<div className="col-6 col-sm-6 pb-3 px-2">
                                  <div className="card card-body card-headline partner-banner-cards justify-content-center h-100">
                                      <img className="smaller" src={data?.section_51_image4} alt={data?.section_51_image4_alt}/>
                                      <h4>{data?.section_51_head4}</h4>
                                  </div>
                              </div>}
                          </div>
                      </div>
                      <div className="col-xl-4 pb-3 price-tag-top">
                          <div className="row h-100">
                              <div className="col px-2">
                                  <div className="card card-body card-gold card-headline-pricing text-center h-100">
                                      <div className="row">
                                          <div className="col-12 col-lg-12" style={{color:'#fff'}}>
                                              <img src={data?.section_51_image5} alt={data?.section_51_image5_alt}/>
                                              {/* <span className="year-price">£350,000</span>
                                              <span className="year-price-pm-top">guaranteed</span>
                                              <span className="year-price-pm">per year</span>
                                              
                                          </div>
                                          <div className="col-12 col-lg-12">
                                                  <span className="strapline">Additional income guaranteed per practice*</span>
                                                  <span className="text-center pt-3" style={{color: "#fff"}}>(Income guarantee package required)</span> */}
                                                 { <Markup content={data?.section_51_head5} className='dentist-patner-page-price' />}
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>

              <div className="col-12 col-md-6 col-lg-6 col-xl-5">
                  <div className="p-big-circle test-page-price-circle-p-big">
                      <div className="circle circle-1  test-page-price-circle">
                          <div className="content test-page-price-circle-content">
                              <div className="card form-card demonstration-form test-page-price-demonstration">
  <div className="card-body text-left">
      <h3 className="text-center pb-3 test-page-price-demonstration-text">{data?.section_51_right_title}</h3>

      <form onSubmit={SubmitHandler} className="form">
          <input type="hidden" name="CRAFT_CSRF_TOKEN" value="KqpbApST4e_LRS4ECVLHEvtCIzDhH1acbVO6w7vYpjX4cH0hjTqW7E6HbGzS3Jmk-hQdNX8wvSDIIFJctmlv9gtg1ZDUoJ5ekSgVYMp-3os="/>
          <input type="hidden" name="partner_type" value="dentists"/>
          <div className="row">
              <div className="col-12 col-lg-12">
                  <div className="form-group">
                                              <div className="pencil-wrapper pencil-wrapper-test-page">
                          <input type="text" style={{    borderColor: '#684286'}} name="name" id="full_name" placeholder={data?.section_51_right_head1} value={signinData.full_name } onChange={SignInHandler} aria-label="Name" className="form-control icon-pencil input-outline-purple input-square" required />
                      </div>
                  </div>
              </div>
              <div className="col-12 col-lg-12">
                  <div className="form-group">
                                              <div className="pencil-wrapper pencil-wrapper-test-page">
                          <input type="text"  style={{    borderColor: '#684286'}} name="patient_name" id="practice_name" placeholder={data?.section_51_right_head2} value={signinData.patient_name } onChange={SignInHandler}   aria-label="Practice Name" className="form-control icon-pencil input-outline-purple input-square" required />
                      </div>
                  </div>
              </div>
              <div className="col-12 col-lg-12">
                  <div className="form-group">
                                              <div className="pencil-wrapper pencil-wrapper-test-page">
                          <input type="number"  style={{    borderColor: '#684286'}} name="mobile" id="telephone" placeholder={data?.section_51_right_head3} value={signinData.mobile } onChange={SignInHandler}  aria-label="telephone" className="form-control icon-pencil input-outline-purple input-square" required />
                          <span className="login-page-web ">
                        {phoneErr}
                      </span>
                      </div>
                  </div>
              </div>
              <div className="col-12 col-lg-12">
                  <div className="form-group">
                                              <div className="pencil-wrapper pencil-wrapper-test-page">
                          
                          <input type="email"  style={{    borderColor: '#684286'}} name="email" value={signinData.email } onChange={SignInHandler}  id="email"  placeholder={data?.section_51_right_head4}  aria-label="email" className="form-control icon-pencil input-outline-purple input-square" required />
                          <span className="login-page-web ">
                        {eamilErr}
                      </span>
                      </div>
                  </div>
              </div>
          </div>
          <div className="text-center mt-3 mt-xl-2">
              <button type="submit" className="btn btn-md btn-rounded btn-pink book-consult-button-inline ">
                <a  className='text-white'>
                {/* href={data?.section_51_right_button_link} */}
                  {data?.section_51_right_button?.substring(0,19)}
                  </a>
              </button>
          </div>

      </form>
  </div>
</div>                            </div>
                      </div>
                  </div>
              </div>


          </div>
      </div>
  </div>
</div>
  )
}

export default SectionFiftyOne