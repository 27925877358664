// import React, { Fragment, useEffect }  from 'react';


// const Web_Ifreame_Sign=()=>{
 
//     return (
//        <Fragment>
//         <iframe
//         src='https://trustedclinics.co.uk/doctor_pwa/#/sign'
//         // src='https://trustedclinics.co.uk/patient_pwa/#/sign'
//         // src='http://localhost:3000/doctor_pwa#/sign'
//          width="100%"
//          height="100%"
//          style={{position:'absolute'}}
//         />
       
//        </Fragment>
//     )
// }

// export default  Web_Ifreame_Sign;


// export  const Web_Ifreame_PatientLogin=()=>{
//     return (
//        <Fragment>
//         <iframe
//         src='https://trustedclinics.co.uk/doctor_pwa/#/patient/login'
//         // src='https://trustedclinics.co.uk/patient_pwa/#/'
//         // src='http://localhost:3000/doctor_pwa#/'
//          width="100%"
//          height="100%"
//          style={{position:'absolute'}}
//         />
       
//        </Fragment>
//     )
// }

// export  const Web_Ifreame_DoctorLogin=()=>{
//     return (
//        <Fragment>
//         <iframe
//         src='https://trustedclinics.co.uk/doctor_pwa/#/doctor/login'
//         // src='https://trustedclinics.co.uk/patient_pwa/#/'
//         // src='http://localhost:3000/doctor_pwa#/'
//          width="100%"
//          height="100%"
//          style={{position:'absolute'}}
//         />
       
//        </Fragment>
//     )
// }
// export  const Web_Ifreame_LabLogin=()=>{
//     return (
//        <Fragment>
//         <iframe
//         src='https://trustedclinics.co.uk/doctor_pwa/#/lab/login'
//         // src='https://trustedclinics.co.uk/patient_pwa/#/'
//         // src='http://localhost:3000/doctor_pwa#/'
//          width="100%"
//          height="100%"
//          style={{position:'absolute'}}
//         />
       
//        </Fragment>
//     )
// }
// export  const Web_Ifreame_Login=()=>{
//     return (
//        <Fragment>
//         <iframe
//         src='https://trustedclinics.co.uk/doctor_pwa/#/'
//         // src='https://trustedclinics.co.uk/patient_pwa/#/'
//         // src='http://localhost:3000/doctor_pwa#/'
//          width="100%"
//          height="100%"
//          style={{position:'absolute'}}
//         />
       
//        </Fragment>
//     )
// }

// export  const Web_Ifreame_DoctorHome=()=>{
//     return (
//        <Fragment>
//         <iframe
//         src='https://trustedclinics.co.uk/doctor_pwa/#/doctor/login'
//         // src='https://trustedclinics.co.uk/patient_pwa/#/'
//         // src='http://localhost:3000/doctor_pwa#/'
//          width="100%"
//          height="100%"
//          style={{position:'absolute'}}
//         />
       
//        </Fragment>
//     )
// }

// export  const Web_Ifreame_PatientHome=()=>{
//     return (
//        <Fragment>
//         <iframe
//         src='https://trustedclinics.co.uk/doctor_pwa/#/patient-home'
//         // src='https://trustedclinics.co.uk/patient_pwa/#/'
//         // src='http://localhost:3000/doctor_pwa#/'
//          width="100%"
//          height="100%"
//          style={{position:'absolute'}}
//         />
       
//        </Fragment>
//     )
// }

// export  const Web_Ifreame_LabHome=()=>{
//     return (
//        <Fragment>
//         <iframe
//         src='https://trustedclinics.co.uk/doctor_pwa/#/lab/login'
//         // src='https://trustedclinics.co.uk/patient_pwa/#/'
//         // src='http://localhost:3000/doctor_pwa#/'
//          width="100%"
//          height="100%"
//          style={{position:'absolute'}}
//         />
       
//        </Fragment>
//     )
// }

// export  const Web_Ifreame_ClinicHome=()=>{
//     return (
//        <Fragment>
//         <iframe
//         src='https://trustedclinics.co.uk/doctor_pwa/#/clinic/login'
//         // src='https://trustedclinics.co.uk/patient_pwa/#/'
//         // src='http://localhost:3000/doctor_pwa#/'
//          width="100%"
//          height="100%"
//          style={{position:'absolute'}}
//         />
       
//        </Fragment>
//     )
// }




import React, { Fragment, useState } from 'react';
import { BeatLoader } from "react-spinners"; 
const Loader = () => (

//   <div style={{
//     position: 'fixed',
//     top: '50%',
//     left: '50%',
//     transform: 'translate(-50%, -50%)',
//     zIndex: 10,
//     fontSize: '20px',
//     fontWeight: 'bold',
//   }}>
//     Loading...
//   </div>
<div style={{width:'100%',height:'100vh',display:'flex',alignItems:'center',justifyContent:'center'}}>
<BeatLoader       
        loading={true}
        color="orange"
        size={15}
        aria-label="Loading Spinner"
        data-testid="loader"
      />
</div>
);

const Web_Ifreame_Sign = () => {
  const [loading, setLoading] = useState(true);

  return (
    <Fragment>
      {loading && <Loader />}
      <iframe
        src='https://trustedclinics.co.uk/doctor_pwa/#/sign'
        width="100%"
        height="100%"
        style={{ position: 'absolute', border: 'none' }}
        onLoad={() => setLoading(false)}
      />
    </Fragment>
  );
};

export default Web_Ifreame_Sign;

export const Web_Ifreame_PatientLogin = () => {
  const [loading, setLoading] = useState(true);

  return (
    <Fragment>
      {loading && <Loader />}
      <iframe
        src='https://trustedclinics.co.uk/doctor_pwa/#/patient/login'
        width="100%"
        height="100%"
        style={{ position: 'absolute', border: 'none' }}
        onLoad={() => setLoading(false)}
      />
    </Fragment>
  );
};

export const Web_Ifreame_DoctorLogin = () => {
  const [loading, setLoading] = useState(true);

  return (
    <Fragment>
      {loading && <Loader />}
      <iframe
        src='https://trustedclinics.co.uk/doctor_pwa/#/doctor/login'
        width="100%"
        height="100%"
        style={{ position: 'absolute', border: 'none' }}
        onLoad={() => setLoading(false)}
      />
    </Fragment>
  );
};

export const Web_Ifreame_LabLogin = () => {
  const [loading, setLoading] = useState(true);

  return (
    <Fragment>
      {loading && <Loader />}
      <iframe
        src='https://trustedclinics.co.uk/doctor_pwa/#/lab/login'
        width="100%"
        height="100%"
        style={{ position: 'absolute', border: 'none' }}
        onLoad={() => setLoading(false)}
      />
    </Fragment>
  );
};

export const Web_Ifreame_Login = () => {
  const [loading, setLoading] = useState(true);

  return (
    <Fragment>
      {loading && <Loader />}
      <iframe
        src='https://trustedclinics.co.uk/doctor_pwa/#/'
        width="100%"
        height="100%"
        style={{ position: 'absolute', border: 'none' }}
        onLoad={() => setLoading(false)}
      />
    </Fragment>
  );
};

export const Web_Ifreame_DoctorHome = () => {
  const [loading, setLoading] = useState(true);

  return (
    <Fragment>
      {loading && <Loader />}
      <iframe
        src='https://trustedclinics.co.uk/doctor_pwa/#/doctor/login'
        width="100%"
        height="100%"
        style={{ position: 'absolute', border: 'none' }}
        onLoad={() => setLoading(false)}
      />
    </Fragment>
  );
};

export const Web_Ifreame_PatientHome = () => {
  const [loading, setLoading] = useState(true);

  return (
    <Fragment>
      {loading && <Loader />}
      <iframe
        src='https://trustedclinics.co.uk/doctor_pwa/#/patient-home'
        width="100%"
        height="100%"
        style={{ position: 'absolute', border: 'none' }}
        onLoad={() => setLoading(false)}
      />
    </Fragment>
  );
};

export const Web_Ifreame_LabHome = () => {
  const [loading, setLoading] = useState(true);

  return (
    <Fragment>
      {loading && <Loader />}
      <iframe
        src='https://trustedclinics.co.uk/doctor_pwa/#/lab/login'
        width="100%"
        height="100%"
        style={{ position: 'absolute', border: 'none' }}
        onLoad={() => setLoading(false)}
      />
    </Fragment>
  );
};

export const Web_Ifreame_ClinicHome = () => {
  const [loading, setLoading] = useState(true);

  return (
    <Fragment>
      {loading && <Loader />}
      <iframe
        src='https://trustedclinics.co.uk/doctor_pwa/#/clinic/login'
        width="100%"
        height="100%"
        style={{ position: 'absolute', border: 'none' }}
        onLoad={() => setLoading(false)}
      />
    </Fragment>
  );
};

