import React, { useEffect, useState }  from 'react';

import { Route,Routes  } from 'react-router-dom';


import Home from './Pages/Home/Home';


import  About from './Pages/About_Us/About_Us'
import Articles from './Pages/Articles/Articles';
import Contact_Us from './Pages/Contact_Us/Contact_Us'
// import Dentists_Near_Me from './Pages/Dentists_Near_Me/Dentists_Near_Me';
import FAQs from './Pages/FAQs/FAQs';
import News from './Pages/News/News';
import Pricing from './Pages/Pricing/Pricing';


// {/* treatments */}
//   {/* dental implants */}
import Supportedbridge from './Pages/Treatments/Dental Implants/Supportedbridge';
import Multiple_dental_implants from './Pages/Treatments/Dental Implants/Multiple_dental_implants'
import Dental_Implants from './Pages/Treatments/Dental Implants/Dental_Implants';
import Teeth_In_A_Day from './Pages/Treatments/Dental Implants/Teeth_In_A_Day';
import Full_Mouth_Reconstruction from './Pages/Treatments/Dental Implants/Full_Mouth_Reconstruction';
import Reviews from './Pages/Treatments/Dental Implants/Reviews';
import Dental_Impants_Gallery from './Pages/Treatments/Dental Implants/Dental_Impants_Gallery';
import Dental_impants_FQAs from './Pages/Treatments/Dental Implants/Dental_impants_FQAs';
import Dental_Implants_Pricing from './Pages/Treatments/Dental Implants/Dental_Implants_Pricing';
import Single_Tooth_Replacement_Implant from './Pages/Treatments/Dental Implants/Single_Tooth_Replacement_Implant';
import PermanentRetained_DentalImplants from './Pages/Treatments/Dental Implants/Permanent_Retained_Dental_Implants';


// {/* dentist near me  */}
//     {/* GreterLondon page */}

import Croydon from './Pages/Dentists_Near_Me/graterLondon/Croydon';///Dentists_Near_Me/graterLondon/Croydon
import Brentwood from './Pages/Dentists_Near_Me/graterLondon/Brentwood';
import Fulham from './Pages/Dentists_Near_Me/graterLondon/Fulham';
import HarleyStreet from './Pages/Dentists_Near_Me/graterLondon/HarleyStreet';
import CannotStreet from './Pages/Dentists_Near_Me/graterLondon/CannotStreet';
import LiverpoolStreet from './Pages/Dentists_Near_Me/graterLondon/LiverpoolStreet';
import Twickenham from './Pages/Dentists_Near_Me/graterLondon/Twickenham';
import LondonCannot from './Pages/Dentists_Near_Me/graterLondon/LondonCannot';

    // {/* dentist near me  */}
    // {/* south east page */}
import Ayleshbury from './Pages/Dentists_Near_Me/Southeast/Ayleshbury';
import Brighton from './Pages/Dentists_Near_Me/Southeast/Brighton';
import HighWycobe from './Pages/Dentists_Near_Me/Southeast/HighWycobe';
import Maidenhead from './Pages/Dentists_Near_Me/Southeast/Maidenhead';
import Maidstone from './Pages/Dentists_Near_Me/Southeast/Maidston';
import Marlow from './Pages/Dentists_Near_Me/Southeast/Marlow';
import MiltonKeynes from './Pages/Dentists_Near_Me/Southeast/MiltonKeynes';
import Portsmouth from './Pages/Dentists_Near_Me/Southeast/Portsmouth';
import Oxfort from './Pages/Dentists_Near_Me/Southeast/Oxfort';
import Reading from './Pages/Dentists_Near_Me/Southeast/Reading.js';
import Southamton from './Pages/Dentists_Near_Me/Southeast/Southamton.js';
import Winchester from './Pages/Dentists_Near_Me/Southeast/Winchester';
import Workingham from './Pages/Dentists_Near_Me/Southeast/Workingham';

    // {/* dentist near me  */}
    // {/* South west  page */}

import Bristal from './Pages/Dentists_Near_Me/Southwest/Bristal.js';
import Swindon from './Pages/Dentists_Near_Me/Southwest/Swindon.js';
import Torbay from './Pages/Dentists_Near_Me/Southwest/Torbay.js';

   // {/* dentist near me  */}
    // {/* Nort west  page */}
import Blackpool from './Pages/Dentists_Near_Me/Nortwest/Blackpool.js';
import Liverpool from './Pages/Dentists_Near_Me/Nortwest/Liverpool.js';
import Macclesfield from './Pages/Dentists_Near_Me/Nortwest/Macclesfield';
import Manchester from './Pages/Dentists_Near_Me/Nortwest/Manchester';
import Preston from './Pages/Dentists_Near_Me/Nortwest/Preston';

// {/* treatments */}
//     {/*    Invisalign Clear Aligners Treatments  Page   */}
import Invisalign_clear_aligners from './Pages/Treatments/Invisalign_Clear_Aligners_Treatments/Invisalign_clear_aligners';
import Invisalign_Express from './Pages/Treatments/Invisalign_Clear_Aligners_Treatments/Invisalign_Express';
import Invisalign_Full from './Pages/Treatments/Invisalign_Clear_Aligners_Treatments/Invisalign_Full';
import Invisalign_gallery from './Pages/Treatments/Invisalign_Clear_Aligners_Treatments/Invisalign_gallery';
import Invisalign_reviews from './Pages/Treatments/Invisalign_Clear_Aligners_Treatments/Invisalign_reviews';
import Invisalign_FAQs from './Pages/Treatments/Invisalign_Clear_Aligners_Treatments/Invisalign_FAQs';
import Invisalign_pricing from './Pages/Treatments/Invisalign_Clear_Aligners_Treatments/Invisalign_pricing';
import Invisalign_Lite from './Pages/Treatments/Invisalign_Clear_Aligners_Treatments/Invisalign_Lite'

// {/* treatments */}
//     {/*    Dental braces  Page   */}
import Dental_Braces from './Pages/Treatments/Dental Braces Treatments/Dental_Braces';
import Fixed_Braces from './Pages/Treatments/Dental Braces Treatments/Fixed_braces';
import Quick_straight_Teeth from './Pages/Treatments/Dental Braces Treatments/Quick_Straight_Teeth';
import Inman_Aligner from './Pages/Treatments/Dental Braces Treatments/Inman_Aligner';
import Damon_Braces from './Pages/Treatments/Dental Braces Treatments/Damon_Braces';
import Incognito from './Pages/Treatments/Dental Braces Treatments/Incognito';
import Six_Month_Smiles from './Pages/Treatments/Dental Braces Treatments/Six_Month_Smiles';
import Lingual_Dental_Creacs from './Pages/Treatments/Dental Braces Treatments/Lingual_Dental_Creacs';
import Dental_braces_FAQs from './Pages/Treatments/Dental Braces Treatments/Dental_braces_FAQs';
import Dental_braces_Gallery from './Pages/Treatments/Dental Braces Treatments/Dental_braces_Gallery';
import Dental_Braces_Reviews from './Pages/Treatments/Dental Braces Treatments/Dental _braces_Reviews';

// {/* treatments */}
// {/*  Veneers   */}

import Veneers from './Pages/Treatments/Veneers/Veneers';
import Veneers_Reviews from './Pages/Treatments/Veneers/Veneers_Reviews';
import Veneers_Pricing from './Pages/Treatments/Veneers/Veneers_Pricing';
import Veneers_FAQs from './Pages/Treatments/Veneers/Venners_FAQS'
import Veneers_Gallery from './Pages/Treatments/Veneers/Veneers_Gallery.js'

// Teeth_whitening

import Teeth_whitening from './Pages/Treatments/Teeth_whitening/Teeth_whitening';
import Teeth_whitening_FAQs from './Pages/Treatments/Teeth_whitening/Teeth_whitening_FAQs';
import Teeth_whitening_Review from './Pages/Treatments/Teeth_whitening/Teeth_whitening_Review'; 
import Teeth_whitening_Pricing from './Pages/Treatments/Teeth_whitening/Teeth_whitening_Pricing';
import Teeth_whitening_Gallery from './Pages/Treatments/Teeth_whitening/Teeth_whitening_Gallery';


import Crowns_bridges from './Pages/Treatments/Crowns & bridges/Crowns_bridges';
import Crowns_bridges_Review from './Pages/Treatments/Crowns & bridges/Crowns_bridges_Review';
import Crowns_bridges_Pricing from './Pages/Treatments/Crowns & bridges/Crowns_bridges_Pricing'; 
import Crowns_bridges_FAQs  from './Pages/Treatments/Crowns & bridges/Crowns_bridges_FAQs'
import Crowns_bridges_Gallery from './Pages/Treatments/Crowns & bridges/Crowns_bridges_Gallery'



import Fillers_AntiWrinkle_Injections from './Pages/Treatments/Fillers_&_Anti-Wrinkle_Injections/Fillers_AntiWrinkle_Injections';
import Fillers_AntiWrinkle_Injections_FAQs from './Pages/Treatments/Fillers_&_Anti-Wrinkle_Injections/Fillers_AntiWrinkle_Injections_FAQs';
import Fillers_AntiWrinkle_Injections_Gallery from './Pages/Treatments/Fillers_&_Anti-Wrinkle_Injections/Fillers_AntiWrinkle_Injections_Gallery';
import Fillers_AntiWrinkle_Injections_Review from './Pages/Treatments/Fillers_&_Anti-Wrinkle_Injections/Fillers_AntiWrinkle_Injections_Review';


import Emergency_Treatments from './Pages/Treatments/Emergency_Treatments/Emergency_Treatments';
import Emergency_Treatments_Review from './Pages/Treatments/Emergency_Treatments/Emergency_Treatments_Review';

//view All Treatments
import Views_All_TReatments from './Pages/Treatments/Views_All_Treatments'
import CommonTest from './Pages/CommonTest';
import axios from 'axios';
import Test from './Pages/Test';

import Blog from './Blog_Folder/Blog.js';
import ViewBlog from './Blog_Folder/BlogById';
import ViewAllArticle from './Blog_Folder/View_All_Artical'
import { ScrollToTop } from './Blog_Folder/Scroll_Top';
import GetUserLocation from './Blog_Folder/GetUserLoaction';
import ThankYou_Page from './Blog_Folder/ThankYou_Page';
import EnterPassword_page from './Blog_Folder/EnterPassword';
import NotFound from './Blog_Folder/NotFound';
import Web_Ifreame_Sign,{Web_Ifreame_ClinicHome, Web_Ifreame_DoctorHome, Web_Ifreame_DoctorLogin, Web_Ifreame_LabHome, Web_Ifreame_LabLogin, Web_Ifreame_Login, Web_Ifreame_PatientHome, Web_Ifreame_PatientLogin} from './Blog_Folder/Ifream_File';



function App() {
   const [mainMenuData ,setmainMenuData] = useState([])
   const [testMenuData ,settestMenuData] = useState([])
   const [fetchData,setfetchData] = useState(true);


   // let decision = true
   const getMainMenuData = async() =>{
      await axios.get('https://trustedclinics.co.uk/backend/api/header_links')
      .then(res=>{
        console.log('main menu')
        setmainMenuData(res.data?.detail)
      })
      .catch(error=>{
        console.log(error)
      })
   }
   
   const getTestMenuData = async() =>{
      // await axios.get('https://trustedclinics.co.uk/backend/api/header_links')
      await axios.get(`${process.env.REACT_APP_BASE_URL}api/header_links`)
      .then(res=>{
        console.log('test menu1')
       console.log(res.data?.data)
       if(res.data.data === false){
         setfetchData(false)
         // decision=false
       }else{
         setfetchData(true)
         // decision=true
       }
         
       
       console.log('ye h')
      //  console.log(decision)
        settestMenuData(res.data?.detail)
      })
      .catch(error=>{
        console.log(error)
      })
   }
  
   // https://clubxtremeapp.com/app/trustedclinics/api/header_links
   useEffect(() => {
      // getMainMenuData()
      getTestMenuData()
   }, []);

  
   const routes = [];

   // Add routes for the main items
   testMenuData.forEach((item) => {
     if (item.submenu_check === 1) {
       item?.Submenu_name.forEach((subitem) => {
         subitem.Submenuitem_name.forEach((subsubitem) => {
           routes.push({
             path: `/${subitem.page_name}/${subsubitem.page_name}`,
             element: <Test />
           });
         });
       });
     }
   });
  return (
      <>
       <ScrollToTop />
         

    <Routes >
    <Route path='/Web_Ifreame' element={<Web_Ifreame_Login />} />
    {/* <Route path='/Web_Ifreame_patient' element={<Web_Ifreame_PatientLogin />} /> */}
    <Route path='/Web_Ifreame_doctor' element={<Web_Ifreame_DoctorLogin />} />
    <Route path='/Web_Ifreame_lab' element={<Web_Ifreame_LabLogin />} />
    <Route path='/Web_Ifreame_doctorhome' element={<Web_Ifreame_DoctorHome />} />
    <Route path='/Web_Ifreame_patienthome' element={<Web_Ifreame_PatientHome />} />
    <Route path='/Web_Ifreame_labhome' element={<Web_Ifreame_LabHome />} />

    <Route path='/doctor-app' element={<Web_Ifreame_DoctorHome />} />
    <Route path='/patient-app' element={<Web_Ifreame_PatientHome />} />
    <Route path='/lab-app' element={<Web_Ifreame_LabHome />} />
    <Route path='/clinic-app' element={<Web_Ifreame_ClinicHome />} />
    
    <Route path='/Web_Ifreame_Signin' element={<Web_Ifreame_Sign />} />
    <Route element={<NotFound />} />
       {/* <HashRouter> */}
   
 {/* <Route path='/' element={<Home/>} /> */}
 <Route path='/' element={<Test/>} />
 <Route path='/common' element={<CommonTest/>} />
 <Route path='/test' element={<Test/>} />
 {/* <Route path='/enter-password-page' element={<EnterPassword_page />} /> */}
<Route path='/thankyou-page/:id' element={<ThankYou_Page />} />


<Route path='/blog' element={<Blog />} />
<Route path='/blog/:category/:id' element={<ViewBlog />} />
{/* <Route path='/blog_by_id/:id' element={<ViewBlog />} /> */}
<Route path='/ViewAllArticle' element={<ViewAllArticle />} />
<Route path='/GetUserLocation' element={<GetUserLocation />} />


 {
  
    testMenuData.map((item,index)=>{
      return(
         <>

         <Route path={`/:menu/:id`} element={<Test/>} />
        
         </>
      )
   })

 }

 {
  
  testMenuData.map((item,index)=>{
    return(
       <>
       <Route path={`/:id`} element={<Test />} />
       </>
    )
 })

}


 {
   testMenuData.map((item,index)=>{
       (item.submenu_check === 1) && 
         item?.Submenu_name.map((subitem)=>{
            subitem.Submenuitem_name.map((subsubitem)=>{
               return  <Route path={`/:menu/:id`} element={<Test/>} />
            })
         })
      
   })
 }

 {/* {
   testMenuData.map((item,index)=>{
       (item.submenu_check === 1) && 
         item?.Submenu_name.map((subitem)=>{
            subitem.Submenuitem_name.map((subsubitem)=>{
               return  <Route path={`/${subitem.page_name}/${subsubitem.page_name}`} element={<Test/>} />
            })
         })
      
   })
 } */}
 {/* {routes.map((route, index) => (
          <Route key={index} path={route.path} element={route.element} />
        ))} */}
 {/* <Route path='Page/submenu/:id' element={<Test/>} /> */}
{/* treatments */}
  {/* dental implants */}

 {/* <Route path='/dentle-implants' element={<Dental_Implants/>}/>
    <Route  path="/multiple-dental-implants" element={<Multiple_dental_implants /> } />
    <Route  path="/single-dental-implants" element={<Single_Tooth_Replacement_Implant /> } />
    <Route path='/permanent-retained-dental-implants' element={<PermanentRetained_DentalImplants/>} />
    <Route  path="/implant-supported-bridg" element={<Supportedbridge /> } />
    <Route path='/teeth-in-aday' element={<Teeth_In_A_Day />}/>
    <Route path='/ful-mouth-reconstruction' element={<Full_Mouth_Reconstruction/>}/>
    <Route path='/reviews' element={<Reviews/>} /> 
    <Route path='/gallery' element={<Dental_Impants_Gallery/>} />
    <Route path='/dental-implants/faqs' element={<Dental_impants_FQAs/>} />
    <Route path='/dental-implants/pricing'  element={<Dental_Implants_Pricing/>} />  */}


{/* treatments */}
    {/*    Invisalign Clear Aligners Treatments  Page   */}

 {/* <Route path='/clear-aligners' element={<Invisalign_clear_aligners />} />
    <Route path='/invisalign-express' element={<Invisalign_Express/>} />
    <Route path='/invisalign-lite' element={<Invisalign_Lite/>} />
    <Route path='/invisalign-full' element={<Invisalign_Full/>} />
    <Route path='/invisalign-reviews' element={<Invisalign_reviews/>} />
    <Route path='/invisalign-pricing' element={<Invisalign_pricing/>} />
    <Route path='/invisalign-faqs' element={<Invisalign_FAQs/>} />
    <Route path='/invisalign-gallery' element={<Invisalign_gallery/>} />  */}

{/* treatments */}
    {/*    Dental braces  Page   */}

 {/* <Route path='/dental-braces' element={<Dental_Braces/>} />
    <Route path='/dental-fixed-braces' element={<Fixed_Braces />} />
    <Route path='/dental-braces-quickStainghtTeeth' element={<Quick_straight_Teeth />} />
    <Route path='/dental-braces-inman-aliger' element={<Inman_Aligner/>} />
    <Route path='/dental-braces-damon-braces' element={<Damon_Braces/>} /> 
    <Route path='/dental-brace-six-month-smiles' element={<Six_Month_Smiles/>} />
    <Route path='/dental-brace-lingual-dental-creacs' element={<Lingual_Dental_Creacs/>} />
    <Route path='/dental-braces-incognito' element={<Incognito/>} />
    <Route path='/dental-braces-faqs' element={<Dental_braces_FAQs/>} />
    <Route path='/dental-braces-gallery' element={<Dental_braces_Gallery/>} />
    <Route path='/dental-braces-reviews' element={<Dental_Braces_Reviews/>} /> */}

{/*  */}
{/* treatments */}
{/*  Veneers   */}
{/* <Route path='/veneers' element={<Veneers/>} />
<Route path='/veneers-reviews' element={<Veneers_Reviews/>} />
<Route path='/veneers-pricing' element={<Veneers_Pricing/>} />
<Route path='/veneers-faqs' element={<Veneers_FAQs/>} />
<Route path='/veneers-gallery' element={<Veneers_Gallery />} /> */}

{/* treatments */}
{/*  Crowns & bridges   */}

{/* <Route path='/crowns-bridges' element={<Crowns_bridges/>} />
<Route path='/crowns-bridges-review' element={<Crowns_bridges_Review />}/>
<Route path='/crowns-bridges-pricing' element={<Crowns_bridges_Pricing/>}  />
<Route path='/crowns-bridges-faqs' element={<Crowns_bridges_FAQs/>}/>
<Route path='/crowns-bridges-gallery' element={<Crowns_bridges_Gallery />} /> */}

{/* Teeth_whitening */}
{/* <Route path='/teeth-whitening' element={<Teeth_whitening/>} />
<Route path='/teeth-whitening-faqs' element={<Teeth_whitening_FAQs />} />
<Route path='/teeth-whitening-review' element={<Teeth_whitening_Review/>} />
<Route path='/teeth-whitening-pricing' element={<Teeth_whitening_Pricing/>}/>
<Route path='/teeth-whitening-gallery' element={<Teeth_whitening_Gallery/>}/> */}

{/* treatments */}
 {/* Fillers & Anti-Wrinkle Injections 
<Route path='/Fillers-anti-wrinkle-injections' element={<Fillers_AntiWrinkle_Injections/>}/>
<Route path='/Fillers-anti-wrinkle-injections-faqs' element={<Fillers_AntiWrinkle_Injections_FAQs/>} />
<Route path='/Fillers-anti-wrinkle-injections-gallery' element={<Fillers_AntiWrinkle_Injections_Gallery/>}/>
<Route path='/Fillers-anti-wrinkle-injections-review' element={<Fillers_AntiWrinkle_Injections_Review/>}/> */}

{/* treatments */}
 {/* Emergency Treatments  
<Route path='/emergency-treatments' element={<Emergency_Treatments/>} />
<Route path='/emergency-treatments-review' element={<Emergency_Treatments_Review/>} /> */}
{/* View all Treatments  */}
{/* <Route path='/view-all-Tteatments' element={<Views_All_TReatments/>} /> */}

{/* dentist near me  */}
    {/* GreterLondon page */}
 {/* <Route  path="/brentwood" element={<Brentwood/>} />
    <Route  path="/croydon" element={<Croydon />} />
    <Route  path="/fulham" element={<Fulham/>} />
    <Route  path="/harley" element={<HarleyStreet/>} />
    <Route  path="/cannot" element={<CannotStreet/>} />
    <Route  path="/londoncannot" element={<LondonCannot/>} />
    <Route  path="/livepool" element={<LiverpoolStreet/>} />
    <Route  path="/twickenham" element={<Twickenham/>} />  */}


    {/* dentist near me  */}
    {/* south east page */}
 {/* <Route  path="/ayleshbury" element={<Ayleshbury/>} />
    <Route  path="/brighton" element={<Brighton/>} />
    <Route  path="/highwycombe" element={<HighWycobe/>} />
    <Route  path="/maidenhead" element={<Maidenhead/>} />
    <Route  path="/maidstone" element={<Maidstone/>} />
    <Route  path="/marlow" element={<Marlow/>} />
    <Route  path="/milton" element={<MiltonKeynes/>} /> 
    <Route  path="/port" element={<Portsmouth/>} /> 
    <Route  path="/oxfort" element={<Oxfort/>} />
    <Route  path="/reading" element={<Reading/>} />
    <Route  path="/south" element={<Southamton/>} />
    <Route  path="/winchester" element={<Winchester/>} />
    <Route  path="/workingham" element={<Workingham/>} /> 
 <Route  path="/brentwood" element={<Brentwood /> } /> 
 <Route  path="/bristal" element={<Bristal/>} /> 
<Route  path="/swindon" element={<Swindon/>} />
<Route  path="/torbay" element={<Torbay/>} />  */}

       {/* dentist near me  */}
       {/* north west  page */}
{/* 
     <Route  path="/blackpool" element={<Blackpool/>}/>
<Route  path="/liverpool" element={<Liverpool/>}/>
<Route  path="/maccles" element={<Macclesfield/>}/>
<Route  path="/manchester" element={<Manchester/>}/>
<Route  path="/preston" element={<Preston/>}/>  */}


{/* <li><NavLink to='/alexandra_dental'>View Treatments</NavLink></li>
                     <li><NavLink to='/DentistsNearMe_New'>View All Dentists Near Me</NavLink></li> */}
                    {/* <NavLink to='/blog'>Blog</NavLink></li> */}

     {/* <Route  path="/pricing" element={<Pricing /> } />  

    
           <Route  path="/about" element={<About/>} /> 


   <Route  path="/articles" element={<Articles /> } /> 


   <Route  path="/contactus" element={<Contact_Us /> } /> 

   <Route  path="/faqs" element={<FAQs /> } /> 

    <Route  path="/news" element={<News /> } />   */}
   {/* </HashRouter> */}
   
  </Routes> 
  </>       
  )
}
  

export default App;
//Treatments-Dental-implants-Permanent-retained-dentalImplants



//http://192.168.1.10:3000/pwa_web