import { Markup } from 'interweave'
import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'

function SectionThirtyFive(props) {
  const [data,setdata]= useState(props.data)
  console.log('data')
 console.log(data)
  useEffect(() => {
    setdata(props.data)
  }, [props.data])
 
    console.log('section 35')
    console.log(data)

  return (
<>
<div className="content-section section-5274 " style={{background:data?.section_35_color || '#684286'}}>
    <div
      className="section section-background pageid-middle-5242"
      style={{
        backgroundImage:
          "url(https://cloud1.coe-website.securestaging.co.uk/images/backgrounds/bg-invisalign-holder.jpg?mtime=20200514033311&focal=none)",
        backgroundPosition: "bottom right"
      }}
    >
      <div className="container">
        <div className="row">
          <div className="col-xl-8 offset-xl-2 col-lg-10 offset-lg-1 pb-2 text-center">
            <h2 className="mb-4 text-white">{data?.section_35_title}</h2>
            <div>
            {
              <Markup content={data?.section_35_description} className='review-div-center-span' /> 
            }
             
              {/* <p>
                Invisalign braces are made from clear, medical grade plastic,
                which allows them to fit discreetly over the teeth and be far
                less noticeable than the metal wire braces that are commonly
                associated with teeth straightening.{" "}
              </p>
              <p>
                Invisalign teeth aligners are not just more subtle than metal
                braces. They're also more comfortable, more hygienic and less
                disruptive. You won't need to change what you eat, your speech
                will not be impacted and it is virtually pain-free.
                Additionally, Invisalign aligners typically require less time
                spent at dentist appointments than traditional braces, and
                thanks to visualisation software, you are able see what the
                final results will look like before you even begin treatment.
              </p> */}
            </div>
          </div>
        </div>
        <div className="text-center form-css-hidden">
        {
            (data?.section_35_button) &&
            <a
            href={data?.sec_35_button_link}
            className="btn btn-md btn-rounded btn-pink text-white"
          >
            {data?.section_35_button}
          </a>
        }
          
        </div>
      </div>
    </div>
  </div>
</>

  )
}

export default SectionThirtyFive