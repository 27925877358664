import { Markup } from 'interweave'
import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'


function SectionTwentyThree(props) {
  const [data,setdata]= useState(props.data)
  console.log('data')
 console.log(data)
  useEffect(() => {
    setdata(props.data)
  }, [props.data])
 
    console.log('section 23')
    console.log(data)
  return (
<>
<div className="section range-section section-3995 bg-white" style={{background:data?.section_23_color || ''}}>
            <div className="container">
              <div className="row">
                <div className="col-xl-8 offset-xl-2 col-lg-10 offset-lg-1 pb-0 text-center">
                  <h2 className="mb-4">{data?.section_23_title}</h2>
                  <div>{ <Markup content={data?.section_23_description} />}</div>
                </div>
                <div className="col-12" >
                {/* style={{marginTop:"100px"}} */}

                  <div className="row text-center mb-3 d-flex justify-content-center hide-score" >
                  {
                    (data?.section_23_title1 || data?.section_23_detail1 || data?.section_23_price1) &&
                    <div className="col-6 col-sm-6 col-lg-4 pb-4 px-sm-3 mt-70 add-image-new pt-lg-5 pt-md-5 pt-sm-5">
                      <a href={data?.section_23_link1} className="card card-treatment-range  h-100">
                        <div className="card-body-bg" style={{backgroundImage: 'url(https://cloud1.coe-website.securestaging.co.uk/images/treatment-range/_card/image2.png?mtime=20200514033336&focal=none&tmtime=20200729125406)', backgroundPosition: 'bottom right' }}>
                          <div className="card-body card-body-single-teatments " >
                            <h3 style={{fontWeight:'bold'}}  className="pt-lg-3 pt-md-3">{data?.section_23_title1}</h3>
                            <div className="desc desc-treatment tarmandcondition-hide"><p>{data?.section_23_detail1}</p></div>
                            <span className=" total-price-single-teatments price-circal-new ">
                              From<br />
                              <b>{data?.section_23_price1}</b><br />
                              Per Month
                            </span>
                          </div>
                        </div>
                        <div className="col-auto" style={{position:'absolute',bottom:'0' }}>
                          <img src={data?.section_23_image1} 
                            alt={data?.section_23_image1_alt} style={{width:"130px"}} className='mx-auto'
                          />
                        </div>
                      </a>
                    </div>
                  }
                    
                  {
                    (data?.section_23_title2 || data?.section_23_detail2 || data?.section_23_price2) &&
                    <div className="col-6 col-sm-6 col-lg-4 pb-4 px-sm-3 mt-70 add-image-new pt-lg-5 pt-md-5 pt-sm-5">
                      <a href={data?.section_23_link2} className="card card-treatment-range  h-100">
                        <div className="card-body-bg" style={{ backgroundImage: 'url(https://cloud1.coe-website.securestaging.co.uk/images/treatment-range/_card/image3.png?mtime=20200514033337&focal=none&tmtime=20200729125407)', backgroundPosition: 'bottom right' }}>
                          <div className="card-body card-body-single-teatments ">
                            <h3 style={{fontWeight:'bold'}}  className="pt-lg-3 pt-md-3">{data?.section_23_title2}</h3>
                            <div className="desc desc-treatment tarmandcondition-hide"><p>{data?.section_23_detail2}</p></div>
                            <span className=" total-price-single-teatments price-circal-new ">
                              From<br />
                              <b>{data?.section_23_price2}</b><br />
                              Per Month
                            </span>
                          </div>
                        </div>
                        <div className="col-auto" style={{position:'absolute',bottom:'0' }}>
                          <img src={data?.section_23_image2} 
                            alt={data?.section_23_image2_alt} style={{width:"130px"}} className='mx-auto'
                          />
                        </div>
                      </a>
                    </div>
                  }

                  {
                    (data?.section_23_title3 || data?.section_23_detail3 || data?.section_23_price3) &&
                    <div className="col-6 col-sm-6 col-lg-4 pb-4 px-sm-3 mt-70 add-image-new pt-lg-5 pt-md-5 pt-sm-5">
                      <a href={data?.section_23_link3} className="card card-treatment-range  h-100">
                        <div className="card-body-bg" style={{ backgroundImage: 'url(https://cloud1.coe-website.securestaging.co.uk/images/treatment-range/_card/image4.png?mtime=20200514033337&focal=none&tmtime=20200729125407)', backgroundPosition: 'bottom center' }}>
                          <div className="card-body card-body-single-teatments ">
                            <h3 style={{fontWeight:'bold'}}  className="pt-lg-3 pt-md-3">{data?.section_23_title3}</h3>
                            <div className="desc desc-treatment tarmandcondition-hide"><p>{data?.section_23_detail3}</p></div>
                            <span className=" total-price-single-teatments price-circal-new ">
                              From<br />
                              <b>{data?.section_23_price3}</b><br />
                              Per Month
                            </span>
                          </div>
                        </div>
                        <div className="col-auto" style={{position:'absolute',bottom:'0' }}>
                          <img src={data?.section_23_image3} 
                            alt={data?.section_23_image3_alt} style={{width:"130px"}} className='mx-auto'
                          />
                        </div>
                      </a>
                    </div>
                  }
                    
                  {
                    (data?.section_23_title4 || data?.section_23_detail4 || data?.section_23_price4) &&
                    <div className="col-6 col-sm-6 col-lg-4 pb-4 px-sm-3 mt-70 add-image-new pt-lg-5 pt-md-5 pt-sm-5">
                      <a href={data?.section_23_link4} className="card card-treatment-range  h-100">
                        <div className="card-body-bg" style={{ backgroundImage: 'url(https://cloud1.coe-website.securestaging.co.uk/images/treatment-range/_card/image5.png?mtime=20200514033337&focal=none&tmtime=20200729125408)', backgroundPosition: 'bottom center' }}>
                          <div className="card-body card-body-single-teatments ">
                            <h3 style={{fontWeight:'bold'}}  className="pt-lg-3 pt-md-3">{data?.section_23_title4}</h3>
                            <div className="desc desc-treatment tarmandcondition-hide"><p>{data?.section_23_detail4}</p></div>
                            <span className=" total-price-single-teatments price-circal-new ">
                              From<br />
                              <b>{data?.section_23_price4}</b><br />
                              Per Month
                            </span>
                          </div>
                        </div>
                        <div className="col-auto" style={{position:'absolute',bottom:'0' }}>
                          <img src={data?.section_23_image4} 
                            alt={data?.section_23_image4_alt} style={{width:"130px"}} className='mx-auto'
                          />
                        </div>
                      </a>
                    </div>
                  } 
                    
                  
                  {
                    (data?.section_23_title5 || data?.section_23_detail5 || data?.section_23_price5) &&
                    <div className="col-6 col-sm-6 col-lg-4 pb-4 px-sm-3 mt-70 add-image-new pt-lg-5 pt-md-5 pt-sm-5">
                      <a href={data?.section_23_link5} className="card card-treatment-range  h-100">
                        <div className="card-body-bg" style={{ backgroundImage: 'url(https://cloud1.coe-website.securestaging.co.uk/images/treatment-range/_card/image6.png?mtime=20200514033337&focal=none&tmtime=20200729125408)', backgroundPosition: 'bottom center' }}>
                          <div className="card-body card-body-single-teatments ">
                            <h3 style={{fontWeight:'bold'}}  className="pt-lg-3 pt-md-3">{data?.section_23_title5}</h3>
                            <div className="desc desc-treatment tarmandcondition-hide "><p>{data?.section_23_detail5}</p></div>
                            <span className=" total-price-single-teatments price-circal-new ">
                              From<br />
                              <b>{data?.section_23_price5}</b><br />
                              Per Month
                            </span>
                          </div>
                        </div>
                        <div className="col-auto" style={{position:'absolute',bottom:'0' }}>
                          <img src={data?.section_23_image5} 
                            alt={data?.section_23_image5_alt} style={{width:"130px"}} className='mx-auto'
                          />
                        </div>
                      </a>
                    </div>
                  }
                    
                  {
                    (data?.section_23_title6 || data?.section_23_detail6 || data?.section_23_price6) &&
                    <div className="col-6 col-sm-6 col-lg-4 pb-4 px-sm-3 mt-70 add-image-new pt-lg-5 pt-md-5 pt-sm-5">
                      <a href={data?.section_23_link6} className="card card-treatment-range  h-100">
                        <div className="card-body-bg" style={{ backgroundImage: 'url(https://cloud1.coe-website.securestaging.co.uk/images/treatment-range/_card/Implants.png?mtime=20200514033334&focal=none&tmtime=20200729135613)', backgroundPosition: 'bottom left' }}>
                          <div className="card-body card-body-single-teatments ">
                            <h3 style={{fontWeight:'bold'}}  className="pt-lg-3 pt-md-3">{data?.section_23_title6}</h3>
                            <div className="desc desc-treatment tarmandcondition-hide"><p>{data?.section_23_detail6}</p></div>
                            <span className=" total-price-single-teatments price-circal-new ">
                              From<br />
                              <b>{data?.section_23_price6}</b><br />
                              Per Month
                            </span>
                          </div>
                        </div>
                        <div className="col-auto" style={{position:'absolute',bottom:'0' }}>
                          <img src={data?.section_23_image6} 
                            alt={data?.section_23_image6_alt} style={{width:"130px"}} className='mx-auto'
                          />
                        </div>
                      </a>
                    </div>
                  }
                    
                  </div>
                </div>
                <div className="col-xl-8 offset-xl-2 col-lg-10 offset-lg-1 text-center">
                  <div>
                    {<Markup content={data?.section_23_footer} />}
                  </div>
                  {
                    (data?.sec_23_button_link) && 
                    <a href="https://trustedclinics.com/dental/price-comparison/" className="btn btn-md btn-rounded btn-pink book-consult-button tarmandcondition-hide">
                    {data?.section_23_button}
                    </a>
                  }
                  
                </div>
              </div>
            </div>
          </div>
</>
  )
}

export default SectionTwentyThree