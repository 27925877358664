// import React from 'react';
// import ReactDOM from 'react-dom/client';
// import {  BrowserRouter, } from 'react-router-dom';
// import App from './App';
// import swDev from './serviceWorker';
// // import  PWAPrompt from 'react-ios-pwa-prompt'
// const root = ReactDOM.createRoot(document.getElementById('root'))
// let deferredPrompt;

// window.addEventListener('beforeinstallprompt', (event) => {
//   console.log('beforeinstallprompt event fired');
//   // Prevent the mini-infobar from appearing on mobile
//   event.preventDefault();
//   // Stash the event so it can be triggered later
//   deferredPrompt = event;

//   // Update UI to notify the user they can add to home screen
//   showInstallPromotion();
// });

// function showInstallPromotion() {
//   const installButton = document.getElementById('install-button');
//   if (installButton) {
//     installButton.style.display = 'block';
//   }
// }

// function hideInstallPromotion() {
//   const installButton = document.getElementById('install-button');
//   if (installButton) {
//     installButton.style.display = 'none';
//   }
// }

// document.addEventListener('DOMContentLoaded', () => {
//   const installButton = document.getElementById('install-button');
//   if (installButton) {
//     installButton.addEventListener('click', () => {
//       // Hide the app provided install promotion
//       hideInstallPromotion();
//       if (deferredPrompt) {
//         // Show the install prompt
//         deferredPrompt.prompt();
//         // Wait for the user to respond to the prompt
//         deferredPrompt.userChoice.then((choiceResult) => {
//           if (choiceResult.outcome === 'accepted') {
//             console.log('User accepted the install prompt');
//           } else {
//             console.log('User dismissed the install prompt');
//           }
//           // Clear the deferredPrompt variable since it can only be used once
//           deferredPrompt = null;
//         });
//       }
//     });
//   }
// });

// root.render( 
//   <React.Fragment>
//     <BrowserRouter >
//     <button id="install-button" style={{display:"none"}}>Install App</button>
//     <App />

//     </BrowserRouter>
//     </React.Fragment>
//  )
// // If you want your app to work offline and load faster, you can change
// // unregister() to register() below. Note this comes with some pitfalls.
// // Learn more about service workers: https://bit.ly/CRA-PWA
// // serviceWorker.register();    {/* <PWAPrompt copyTitle="PWA Project" /> */}
// swDev()


// import React from 'react';
// import ReactDOM from 'react-dom/client';
// import { BrowserRouter } from 'react-router-dom';
// import App from './App';
// import swDev from './serviceWorker';
// const root = ReactDOM.createRoot(document.getElementById('root'));
// const installDivStyle = {
//   display: 'none',
//   position: 'fixed',
//   bottom: '0',
//   right: '0',
//   backgroundColor: 'white',
//   color: '#fff',
//   padding: '10px',
//   borderRadius: '5px',
//   boxShadow: '0 0 10px rgba(0,0,0,0.5)',
//   zIndex: 1000,
//   width: '300px',
// };


// const closeButtonStyle = {
//   alignSelf: 'flex-end',
//   background: 'none',
//   border: 'none',
//   color: 'black',
//   fontSize: '16px',
//   cursor: 'pointer',
// };


// let deferredPrompt;

// window.addEventListener('beforeinstallprompt', (event) => {
//   console.log('beforeinstallprompt event fired');
//   event.preventDefault();
//   deferredPrompt = event;

//   showInstallPromotion();
// });

// function showInstallPromotion() {
//   const installDiv = document.getElementById('install-div');
//   if (installDiv) {
//     installDiv.style.display = 'block';
//   }
// }

// function hideInstallPromotion() {
//   const installDiv = document.getElementById('install-div');
//   if (installDiv) {
//     installDiv.style.display = 'none';
//   }
// }

// const handleInstall = () => {
//   hideInstallPromotion();
//   if (deferredPrompt) {
//     deferredPrompt.prompt();
//     deferredPrompt.userChoice.then((choiceResult) => {
//       if (choiceResult.outcome === 'accepted') {
//         console.log('User accepted the install prompt');
//       } else {
//         console.log('User dismissed the install prompt');
//       }
//       deferredPrompt = null;
//     });
//   }
// };

// const handleClose = () => {
//   hideInstallPromotion();
// };

// root.render(
//   <React.Fragment>
//     <BrowserRouter>
     
//       <div id="install-div" style={installDivStyle}>
//         <div >
//           <div className='d-flex justify-content-between'>
//           <div>
//           <h2 className='text-gray-300'>Install App</h2>
//           </div>
//           <div>
//           <button style={closeButtonStyle} onClick={handleClose}>X</button>
//           </div>
          
//           </div>
          
          
//           <p>Install our app to get the best experience!</p>
//           <button onClick={handleInstall} className='btn btn-xs' style={{background:"#D4A54E"}}>Install</button>
//         </div>
//       </div>

//       <App  />
//     </BrowserRouter>
//   </React.Fragment>
// );

// swDev(); // Register the service worker


// import React, { useEffect } from 'react';
// import ReactDOM from 'react-dom/client';
// import { BrowserRouter } from 'react-router-dom';
// import App from './App';
// import swDev from './serviceWorker';
// import * as serviceWorker from './serviceWorker';
// import PWAPrompt from 'react-ios-pwa-prompt';

// const root = ReactDOM.createRoot(document.getElementById('root'));
// const installDivStyle = {
//   display: 'none',
//   position: 'fixed',
//   bottom: '0',
//   right: '0',
//   backgroundColor: 'white',
//   color: '#000',
//   padding: '10px',
//   borderRadius: '5px',
//   boxShadow: '0 0 10px rgba(0,0,0,0.5)',
//   zIndex: 1000,
//   width: '300px',
// };

// const closeButtonStyle = {
//   alignSelf: 'flex-end',
//   background: 'none',
//   border: 'none',
//   color: 'black',
//   fontSize: '16px',
//   cursor: 'pointer',
// };

// let deferredPrompt;

// window.addEventListener('beforeinstallprompt', (event) => {
//   console.log('beforeinstallprompt event fired');
//   event.preventDefault();
//   deferredPrompt = event;

//   showInstallPromotion();
// });

// function showInstallPromotion() {
//   const installDiv = document.getElementById('install-div');
//   if (installDiv) {
//     installDiv.style.display = 'block';
//   }
// }

// function hideInstallPromotion() {
//   const installDiv = document.getElementById('install-div');
//   if (installDiv) {
//     installDiv.style.display = 'none';
//   }
// }

// const handleInstall = () => {
//   hideInstallPromotion();
//   if (deferredPrompt) {
//     deferredPrompt.prompt();
//     deferredPrompt.userChoice.then((choiceResult) => {
//       if (choiceResult.outcome === 'accepted') {
//         console.log('User accepted the install prompt');
//       } else {
//         console.log('User dismissed the install prompt');
//       }
//       deferredPrompt = null;
//     });
//   }
// };

// const handleClose = () => {
//   hideInstallPromotion();
// };

// const showInstallPrompt = () => {
//   // This function is called when the app is detected on an iOS device
//   const installDiv = document.getElementById('install-div');
//   if (installDiv) {
//     installDiv.style.display = 'block';
//     installDiv.innerHTML = `
//       <div>
//         <div className='d-flex justify-content-between'>
//           <div>
//             <h2 className='text-gray-300'>Install App</h2>
//           </div>
//           <div>
//             <button style="${closeButtonStyle}" onclick="document.getElementById('install-div').style.display='none'">X</button>
//           </div>
//         </div>
//         <p>Install our app to get the best experience!<br>Open this page in Safari and use the "Add to Home Screen" option.</p>
//       </div>
//     `;
//   }
// };

// // Detect if the user is on an iOS device
// function isIos() {
//   return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
// }

// function isStandalone() {
//   return window.navigator.standalone;
// }

// function showInstallBanner() {
//   const installBanner = document.getElementById('install-div');
//   if (isIos() && !isStandalone()) {
//     installBanner.style.display = 'block';
//   }
// }


// document.addEventListener('DOMContentLoaded', showInstallBanner);




// root.render(
//   <React.Fragment>
//     <BrowserRouter>
//     <PWAPrompt />
//       <div id="install-div" style={installDivStyle}>
//         <div>
//           <div className='d-flex justify-content-between'>
//             <div>
//               <h2 className='text-gray-300'>Install App</h2>
//             </div>
//             <div>
//               <button style={closeButtonStyle} onClick={handleClose}>X</button>
//             </div>
//           </div>
//           <p>Install our app to get the best experience!</p>
//           <button onClick={handleInstall} className='btn btn-xs' style={{background:"#D4A54E"}}>Install</button>
//         </div>
//       </div>
//       <App />
//     </BrowserRouter>
//   </React.Fragment>
// );

// // swDev(); // Register the service worker
// serviceWorker.register()



import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import ReactIosPwaPrompt from 'react-ios-pwa-prompt';
import * as serviceWorker from './serviceWorker';
const logo = './dr444sples.png';
const root = ReactDOM.createRoot(document.getElementById('root'));

const installDivStyle = {
  display: 'none',
  position: 'fixed',
  bottom: '0',
  right: '0',
  backgroundColor: 'white',
  color: '#000',
  padding: '10px',
  borderRadius: '5px',
  boxShadow: '0 0 10px rgba(0,0,0,0.5)',
  zIndex: 1000,
  width: '300px',
};

const closeButtonStyle = {
  alignSelf: 'flex-end',
  background: 'none',
  border: 'none',
  color: 'black',
  fontSize: '16px',
  cursor: 'pointer',
};

let deferredPrompt;

window.addEventListener('beforeinstallprompt', (event) => {
  console.log('beforeinstallprompt event fired');
  event.preventDefault();
  deferredPrompt = event;
  showInstallPromotion();
});

function showInstallPromotion() {
  const installDiv = document.getElementById('install-div');
  if (installDiv) {
    installDiv.style.display = 'block';
  }
}

function hideInstallPromotion() {
  const installDiv = document.getElementById('install-div');
  if (installDiv) {
    installDiv.style.display = 'none';
  }
}

const handleInstall = () => {
  hideInstallPromotion();
  if (deferredPrompt) {
    deferredPrompt.prompt();
    deferredPrompt.userChoice.then((choiceResult) => {
      if (choiceResult.outcome === 'accepted') {
        console.log('User accepted the install prompt');
      } else {
        console.log('User dismissed the install prompt');
      }
      deferredPrompt = null;
    });
  }
};

const handleClose = () => {
  hideInstallPromotion();
};

function isIos() {
  return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
}

function isStandalone() {
  return window.navigator.standalone;
}

function showInstallBanner() {
  const installDiv = document.getElementById('install-div');
  if (installDiv && deferredPrompt) {
    installDiv.style.display = 'block';
  }
}

document.addEventListener('DOMContentLoaded', showInstallBanner);

const AppWrapper = () => {
  const [showIosPrompt, setShowIosPrompt] = useState(false);

  useEffect(() => {
    if (isIos() && !isStandalone()) {
      setShowIosPrompt(true);
    }
  }, []);

  return (
    <>
      <BrowserRouter>
        <div id="install-div" style={installDivStyle}>
          <div>
            <div className='d-flex justify-content-between'>
              <div>
                <h2 className='text-gray-300'>Install App</h2>
              </div>
              <div>
                <button style={closeButtonStyle} onClick={handleClose}>X</button>
              </div>
            </div>
            <p>Install our app to get the best experience!</p>
            <button onClick={handleInstall} className='btn btn-xs' style={{ background: "#D4A54E" }}>Install</button>
          </div>
        </div>
        <App />
      </BrowserRouter>
      <ReactIosPwaPrompt
        promptOnVisit={1}
        timesToShow={3}
        copyTitle="Install App"
        copyBody="This app can be installed on your home screen for a better experience."
        copyClosePrompt="Close"
        copyInstallBtnLabel="Install"
        appIconPath={logo}
        onClose={() => setShowIosPrompt(false)}
      />
    </>
  );
};

root.render(
  <React.Fragment>
    <AppWrapper />
  </React.Fragment>
);

serviceWorker.register();



// import React, { useEffect, useState } from 'react';
// import ReactDOM from 'react-dom/client';
// import { BrowserRouter } from 'react-router-dom';
// import App from './App';
// import ReactIosPwaPrompt from 'react-ios-pwa-prompt';
// import * as serviceWorker from './serviceWorker';

// const root = ReactDOM.createRoot(document.getElementById('root'));

// const installDivStyle = {
//   display: 'none',
//   position: 'fixed',
//   bottom: '0',
//   right: '0',
//   backgroundColor: 'white',
//   color: '#000',
//   padding: '10px',
//   borderRadius: '5px',
//   boxShadow: '0 0 10px rgba(0,0,0,0.5)',
//   zIndex: 1000,
//   width: '300px',
// };

// const closeButtonStyle = {
//   alignSelf: 'flex-end',
//   background: 'none',
//   border: 'none',
//   color: 'black',
//   fontSize: '16px',
//   cursor: 'pointer',
// };

// let deferredPrompt;

// window.addEventListener('beforeinstallprompt', (event) => {
//   console.log('beforeinstallprompt event fired');
//   event.preventDefault();
//   deferredPrompt = event;
//   showInstallPromotion();
// });

// function showInstallPromotion() {
//   const installDiv = document.getElementById('install-div');
//   if (installDiv) {
//     installDiv.style.display = 'block';
//   }
// }

// function hideInstallPromotion() {
//   const installDiv = document.getElementById('install-div');
//   if (installDiv) {
//     installDiv.style.display = 'none';
//   }
// }

// const handleInstall = () => {
//   hideInstallPromotion();
//   if (deferredPrompt) {
//     deferredPrompt.prompt();
//     deferredPrompt.userChoice.then((choiceResult) => {
//       if (choiceResult.outcome === 'accepted') {
//         console.log('User accepted the install prompt');
//       } else {
//         console.log('User dismissed the install prompt');
//       }
//       deferredPrompt = null;
//     });
//   }
// };

// const handleClose = () => {
//   hideInstallPromotion();
// };

// function isIos() {
//   return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
// }

// function isStandalone() {
//   return window.navigator.standalone;
// }

// function showInstallBanner() {
//   const installDiv = document.getElementById('install-div');
//   if (installDiv && deferredPrompt) {
//     installDiv.style.display = 'block';
//   }
// }

// document.addEventListener('DOMContentLoaded', showInstallBanner);

// const AppWrapper = () => {
//   const [showIosPrompt, setShowIosPrompt] = useState(false);

//   useEffect(() => {
//     const iosPromptDismissed = localStorage.getItem('iosPromptDismissed');
//     if (isIos() && !isStandalone() && !iosPromptDismissed) {
//       setShowIosPrompt(true);
//     }
//   }, []);

//   const handleIosPromptClose = () => {
//     setShowIosPrompt(false);
//     localStorage.setItem('iosPromptDismissed', 'true');
//   };

//   return (
//     <>
//       <BrowserRouter>
//         <div id="install-div" style={installDivStyle}>
//           <div>
//             <div className='d-flex justify-content-between'>
//               <div>
//                 <h2 className='text-gray-300'>Install App</h2>
//               </div>
//               <div>
//                 <button style={closeButtonStyle} onClick={handleClose}>X</button>
//               </div>
//             </div>
//             <p>Install our app to get the best experience!</p>
//             <button onClick={handleInstall} className='btn btn-xs' style={{ background: "#D4A54E" }}>Install</button>
//           </div>
//         </div>
//         <App />
//       </BrowserRouter>
//       <ReactIosPwaPrompt
//         isOpen={showIosPrompt}
//         promptOnVisit={1}
//         timesToShow={3}
//         copyTitle="Install App"
//         copyBody="This app can be installed on your home screen for a better experience."
//         copyClosePrompt="Close"
//         copyInstallBtnLabel="Install"
//         onClose={handleIosPromptClose}
//       />
//     </>
//   );
// };

// root.render(
//   <React.Fragment>
//     <AppWrapper />
//   </React.Fragment>
// );

// serviceWorker.register();
