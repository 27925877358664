import React, { useEffect, useState } from 'react'
import { Markup } from 'interweave'
import './blogsection.css'
function BlogSectionThree(props) {
  const [data,setdata]= useState(props.data)
  const [videoIds, setVideoIds] = useState([]);
  useEffect(() => {
    setdata(props.data)
  }, [props.data])
 
    console.log('blogsectiontwo')
    console.log(data)

  useEffect(() => {
    const youtubeRegex = /https:\/\/www\.youtube\.com\/embed\/([\w-]+)/g;
    const matches = data?.section_three_description?.match(youtubeRegex);
    if (matches) {
      const videoIds = matches.map((match) => match.match(/https:\/\/www\.youtube\.com\/embed\/([\w-]+)/)[1]);
      setVideoIds(videoIds);
    } else {
      setVideoIds([]);
    }
  }, [data?.section_three_description]);

    // Function to replace YouTube links with video players in the paragraph
    const renderParagraphWithVideos = () => {
      const paragraphs = data?.section_three_description?.split('\n');
      const updatedParagraphs = paragraphs.map((p) => {
        const youtubeMatch = p.match(/https:\/\/www\.youtube\.com\/embed\/([\w-]+)/);
        if (youtubeMatch) {
          return (
            <div key={youtubeMatch[1]} className="video">
              <iframe
                width="100%"
                height="315"
                src={`https://www.youtube.com/embed/${youtubeMatch[1]}`}
                title={`YouTube video player ${youtubeMatch[1]}`}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              ></iframe>
            </div>
          );
        } else {
          return <p key={p} dangerouslySetInnerHTML={{ __html: p }} />;
        }
      });
  
      return updatedParagraphs;
    };

  return (
    <>
       <section className='pb-5 pl-3 pr-3 blog_section_2'>
                {
                  (data?.section_three_title) && 
                  <p className='blog-color-primary blog-main-heading'>{data?.section_three_title}</p>
                }
                
                

                <div className='container-fluid p-0'>
                {/* {
                  <Markup content={data?.section_one_subtitle} /> 
                } */}
                {videoIds.length > 0 ?(
        <div className="video-container"  style={{fontSize:'13px'}}>{renderParagraphWithVideos()}</div>):
                <Markup content= {data?.section_three_description} />

      }
                {/* <p className='para'>Let's discuss some of the best teeth aligners available in the UK in more detail. There is a range of different features to consider, so there should be something for everybody!</p> */}
                {
                  (data?.section_three_video) &&
                  <video width="100%" height="315" controls>
                    <source src={data?.section_three_video} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                }
                  
                </div>
                
               

                
                </section>
    </>
  )
}

export default BlogSectionThree
