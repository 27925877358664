import React, { Fragment, useEffect, useState } from "react";
import { NavLink, Outlet, useNavigate } from "react-router-dom";
import $ from "jquery";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import { HiViewList } from "react-icons/hi";
import { RxCross1, RxDropdownMenu } from "react-icons/rx";
import { IoIosArrowBack } from "react-icons/io";
import "./Header.css";
import axios from "axios";
import Swal from "sweetalert2";
import { AiFillCaretDown, AiOutlineMenuFold, AiOutlineMore } from "react-icons/ai";

import Cookies from "js-cookie";


const Header = () => {
  const [toggle, setToggle] = useState(false);
  const [menuData, setmenuData] = useState([]);
  const [mainMenuData, setmainMenuData] = useState([]);
  const [testMenuData, settestMenuData] = useState([]);
  const [subMenuData, setsubMenuData] = useState([]);
  const [fetchData, setfetchData] = useState(true);
  const [arrayClass, setarrayClass] = useState([]);
  const [subMenuData1, setsubMenuData1] = useState([]);
  const [footerData, setFooterData] = useState({});
  const [menuToggle, setMenuToggle] = useState(false);
  const [menuToggle1, setMenuToggle1] = useState(false);

  const user = localStorage.getItem("lab");
  const lab = JSON.parse(user);
  const user1 = localStorage.getItem("user");
  const doctor = JSON.parse(user1);
  const user2 = localStorage.getItem("patient");
  const patient = JSON.parse(user2);
  const user3 = localStorage.getItem("staff");
  const clinic = JSON.parse(user3);
  const [userData, setUserData] = useState([]);
  const [toggleMenu, setToggleMenu] = useState(false);
  const [toggleMenu1, setToggleMenu1] = useState(false);

  const [toggleLogout, setToggleLogout] = useState(false);

  console.log("cookie-value", userData);

  useEffect(() => {
    // window.cookieStore.getAll().then(x=> setUserData(JSON.parse(x[0].value))) ;
    const checkLocalStorage = () => {
    if (lab) setUserData(lab);
    else if (doctor) setUserData(doctor);
    else if (patient) setUserData(patient);
    else if (clinic) setUserData(clinic);
    };
    checkLocalStorage();

    // Set up an interval to check localStorage periodically (e.g., every second)
    const intervalId = setInterval(checkLocalStorage, 100); // Adjust the interval as needed

    // Clean up the interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  // console.log("user dattttttttbxvdgh",userData)

  let subMenuClassName = [];
  // https://arowiz.com/trustedclinics/api/treatment_submenu
  // https://trustedclinics.co.uk/backend/
  const getData = async () => {
    await axios
      .get(`${process.env.REACT_APP_BASE_URL}api/treatment_submenu`)
      .then((res) => {
        setmenuData(res.data?.detail);
        const menuClassName = res.data?.detail.map((item, index) => {
          return (
            item.menu_name_class !== "Crowns-&-bridges" && item.menu_name_class
          );
        });

        $(`${menuClassName[0]}`).addClass("active");
        $(`.item.${menuClassName[0]},.dp-advertising.${menuClassName[0]}`).css(
          "display",
          "contents"
        );
        menuClassName.filter((menuitem, index) => {
          return (
            index > 0 &&
            $(`.item.${menuitem}, .dp-advertising.${menuitem}`).css(
              "display",
              "none"
            )
          );
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // const getMainMenuData = async() =>{
  //   await axios.get('https://arowiz.com/trustedclinics/api/header_links')
  //   .then(res=>{
  //     console.log('main menu')
  //     setmainMenuData(res.data?.detail)
  //   })
  //   .catch(error=>{
  //     console.log(error)
  //   })
  // }

  const getTestMenu = async () => {
    await axios
      .get(`${process.env.REACT_APP_BASE_URL}api/header_links`)

      // await axios.get('https://trustedclinics.co.uk/backend/api/header_links')
      .then((res) => {
        console.log("headerdata ", res);
        console.log("test menu");
        if (res.data?.data === false) {
          setfetchData(false);

          // decision=false
        } else {
          setfetchData(true);

          // decision=true
        }
        console.log("res.data?.detail", res.data?.detail);
        const subMenuData = res.data?.detail.filter((item, index) => {
          return item.submenu_check === 1 && item.Submenu_name;
        });
        setsubMenuData1(subMenuData);
        console.log("hi i m submenu");
        console.log(subMenuData);

        const subMenuClassData = subMenuData.map((item, index) => {
          console.log(item);
          return item.Submenu_name.map((subitem, index) => {
            return subitem?.Submenu_name_class;
          });
        });

        // subMenuClassData.map((item)=>{

        //     arrayClass.push(item)

        // })
        subMenuClassData.forEach((item) => {
          arrayClass.push(item);
        });

        // subMenuData.map((subMenuItem,index)=>{
        //   $(`${subMenuItem.Submenu_name[0]?.Submenu_name_class}`).addClass("active");
        //   $(`.item.${subMenuItem.Submenu_name[0]?.Submenu_name_class},.dp-advertising.${subMenuItem.Submenu_name[0]?.Submenu_name_class}`).css('display', 'contents')
        //   subMenuItem.Submenu_name.filter((menuitem,index)=>{
        //     return (index > 0) &&
        //     $(`.item.${menuitem?.Submenu_name_class}, .dp-advertising.${menuitem?.Submenu_name_class}`).css('display', 'none')
        //   })
        // })

        // const subMenuClassData = subMenuData.map((item,index)=>{
        //    return item.Submenu_name.map((subitem,index)=>{
        //       return subitem?.Submenu_name_class
        //    })
        // })
        // subMenuClassName = subMenuClassData
        // setarrayClass((prevArray) => [...prevArray, subMenuClassData])
        // setarrayClass(subMenuClassData)
        //  subMenuClassData.map((item)=>{

        //     arrayClass.push(item)

        // })

        console.log("submenu class data");
        console.log(subMenuClassData);
        console.log(subMenuClassName);
        console.log(res.data?.detail);
        settestMenuData(res.data?.detail);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    // getMainMenuData()
    getData();
    getTestMenu();

    $(document).ready(function () {
      console.log("i m from console");
      console.log(arrayClass);
      console.log("...oo");
      console.log(subMenuClassName);
      arrayClass.map((menuClassName, index) => {
        console.log("inner console");
        console.log(menuClassName);
        menuClassName.forEach(myfunction);
        //  alert('jii')
        function myfunction(item) {
          $(`li.${item}`).mouseenter(function () {
            $(`.item.${item},.dp-advertising.${item}`).css(
              "display",
              "contents"
            );
            menuClassName.filter((menuitem, index) => {
              return (
                menuitem !== item &&
                $(`.item.${menuitem}, .dp-advertising.${menuitem}`).css(
                  "display",
                  "none"
                )
              );
            });
          });
        }
      });
    });

    //   $(document).ready( function(){
    //     const menuClassName = menuData.map((item,index)=>{
    //       return (item.menu_name_class !== 'Crowns-&-bridges') && item.menu_name_class
    //     })

    //     menuClassName.forEach(myfunction)
    //       function myfunction(item){

    //         $(`li.${item}`).mouseenter( function(){
    //         $(`.item.${item},.dp-advertising.${item}`).css('display', 'contents')
    //         menuClassName.filter((menuitem,index)=>{
    //           return (menuitem !== item) &&
    //           $(`.item.${menuitem}, .dp-advertising.${menuitem}`).css('display', 'none')
    //         })
    //       })
    //     }

    // });

    //   $(document).ready( function(){
    //     $('li.invisalign-clear-aligners').mouseenter( function(){
    //         $('.item.dental-implants, .dp-advertising.dental-implants, .item.dental-braces, .dp-advertising.dental-braces, .item.veneers, .dp-advertising.veneers, .item.crowns-and-bridges, .dp-advertising.crowns-and-bridges, .item.teeth-whitening, .dp-advertising.teeth-whitening, .item.fillers, .dp-advertising.fillers, .item.emergency-treatment, .dp-advertising.emergency-treatment').css('display', 'none')
    //     });
    //     $('li.invisalign-clear-aligners').mouseenter( function(){
    //         $('.item.invisalign-clear-aligners, .dp-advertising.invisalign-clear-aligners').css('display', 'contents')
    //     });

    //     // -------------
    //     $('li.dental-implants').mouseenter( function(){
    //         $('.item.invisalign-clear-aligners, .dp-advertising.invisalign-clear-aligners, .item.dental-braces, .dp-advertising.dental-braces, .item.veneers, .dp-advertising.veneers, .item.crowns-and-bridges, .dp-advertising.crowns-and-bridges, .item.teeth-whitening, .dp-advertising.teeth-whitening, .item.fillers, .dp-advertising.fillers, .item.emergency-treatment, .dp-advertising.emergency-treatment').css('display', 'none')
    //     });
    //     $('li.dental-implants').mouseenter( function(){
    //         $('.item.dental-implants, .dp-advertising.dental-implants').css('display', 'contents')
    //     });

    //     // -------------
    //     $('li.dental-braces').mouseenter( function(){
    //         $('.item.invisalign-clear-aligners, .dp-advertising.invisalign-clear-aligners, .item.dental-implants, .dp-advertising.dental-implants, .item.dental-braces, .dp-advertising.dental-braces, .item.veneers, .dp-advertising.veneers, .item.crowns-and-bridges, .dp-advertising.crowns-and-bridges, .item.teeth-whitening, .dp-advertising.teeth-whitening, .item.fillers, .dp-advertising.fillers, .item.emergency-treatment, .dp-advertising.emergency-treatment').css('display', 'none')
    //     });
    //     $('li.dental-braces').mouseenter( function(){
    //         $('.item.dental-braces, .dp-advertising.dental-braces').css('display', 'contents')
    //     });

    //     // -------------
    //     $('li.veneers').mouseenter( function(){
    //         $('.item.invisalign-clear-aligners, .dp-advertising.invisalign-clear-aligners, .item.dental-implants, .dp-advertising.dental-implants, .item.dental-braces, .dp-advertising.dental-braces, .item.dental-braces, .dp-advertising.dental-braces, .item.crowns-and-bridges, .dp-advertising.crowns-and-bridges, .item.teeth-whitening, .dp-advertising.teeth-whitening, .item.fillers, .dp-advertising.fillers, .item.emergency-treatment, .dp-advertising.emergency-treatment').css('display', 'none')
    //     });
    //     $('li.veneers').mouseenter( function(){
    //         $('.item.veneers, .dp-advertising.veneers').css('display', 'contents')
    //     });

    //     // -------------
    //     $('li.crowns-and-bridges').mouseenter( function(){
    //         $('.item.invisalign-clear-aligners, .dp-advertising.invisalign-clear-aligners, .item.dental-implants, .dp-advertising.dental-implants, .item.dental-braces, .dp-advertising.dental-braces, .item.dental-braces, .dp-advertising.dental-braces, .item.veneers, .dp-advertising.veneers, .item.teeth-whitening, .dp-advertising.teeth-whitening, .item.fillers, .dp-advertising.fillers, .item.emergency-treatment, .dp-advertising.emergency-treatment').css('display', 'none')
    //     });
    //     $('li.crowns-and-bridges').mouseenter( function(){
    //         $('.item.crowns-and-bridges, .dp-advertising.crowns-and-bridges').css('display', 'contents')
    //     });

    //     // -------------
    //     $('li.teeth-whitening').mouseenter( function(){
    //         $('.item.invisalign-clear-aligners, .dp-advertising.invisalign-clear-aligners, .item.dental-implants, .dp-advertising.dental-implants, .item.dental-braces, .dp-advertising.dental-braces, .item.dental-braces, .dp-advertising.dental-braces, .item.veneers, .dp-advertising.veneers, .item.crowns-and-bridges, .dp-advertising.crowns-and-bridges, .item.fillers, .dp-advertising.fillers, .item.emergency-treatment, .dp-advertising.emergency-treatment').css('display', 'none')
    //     });
    //     $('li.teeth-whitening').mouseenter( function(){
    //         $('.item.teeth-whitening, .dp-advertising.teeth-whitening').css('display', 'contents')
    //     });

    //     // -------------
    //     $('li.fillers-anti-wrinkle-injections').mouseenter( function(){
    //         $('.item.invisalign-clear-aligners, .dp-advertising.invisalign-clear-aligners, .item.dental-implants, .dp-advertising.dental-implants, .item.dental-braces, .dp-advertising.dental-braces, .item.dental-braces, .dp-advertising.dental-braces, .item.veneers, .dp-advertising.veneers, .item.crowns-and-bridges, .dp-advertising.crowns-and-bridges, .item.teeth-whitening, .dp-advertising.teeth-whitening, .item.emergency-treatment, .dp-advertising.emergency-treatment').css('display', 'none')
    //     });
    //     $('li.fillers-anti-wrinkle-injections').mouseenter( function(){
    //         $('.item.fillers, .dp-advertising.fillers').css('display', 'contents')
    //     });

    //     // -------------
    //     $('li.emergency-treatment').mouseenter( function(){
    //         $('.item.invisalign-clear-aligners, .dp-advertising.invisalign-clear-aligners, .item.dental-implants, .dp-advertising.dental-implants, .item.dental-braces, .dp-advertising.dental-braces, .item.dental-braces, .dp-advertising.dental-braces, .item.veneers, .dp-advertising.veneers, .item.crowns-and-bridges, .dp-advertising.crowns-and-bridges, .item.teeth-whitening, .dp-advertising.teeth-whitening, .item.fillers, .dp-advertising.fillers').css('display', 'none')
    //     });
    //     $('li.emergency-treatment').mouseenter( function(){
    //         $('.item.emergency-treatment, .dp-advertising.emergency-treatment').css('display', 'contents')
    //     });
    // });
  }, []);

  useEffect(() => {
    console.log("Treatments", subMenuData1?.[0]?.link);
    subMenuData1.map((subMenuItem, index) => {
      console.log("i m another useeffect");
      console.log(subMenuItem);
      $(`${subMenuItem.Submenu_name[0]?.Submenu_name_class}`).addClass(
        "active"
      );
      $(
        `.item.${subMenuItem.Submenu_name[0]?.Submenu_name_class},.dp-advertising.${subMenuItem.Submenu_name[0]?.Submenu_name_class}`
      ).css("display", "contents");
      subMenuItem.Submenu_name.filter((menuitem, index) => {
        return (
          index > 0 &&
          $(
            `.item.${menuitem?.Submenu_name_class}, .dp-advertising.${menuitem?.Submenu_name_class}`
          ).css("display", "none")
        );
      });
    });
    $(document).ready(function () {
      console.log("i m from console");
      console.log(arrayClass);
      console.log("...oo");
      console.log(subMenuClassName);
      arrayClass.map((menuClassName, index) => {
        console.log("inner console");
        console.log(menuClassName);
        menuClassName.forEach(myfunction);
        //  alert('jii')
        function myfunction(item) {
          $(`li.${item}`).mouseenter(function () {
            $(`.item.${item},.dp-advertising.${item}`).css(
              "display",
              "contents"
            );
            menuClassName.filter((menuitem, index) => {
              return (
                menuitem !== item &&
                $(`.item.${menuitem}, .dp-advertising.${menuitem}`).css(
                  "display",
                  "none"
                )
              );
            });
          });
        }
      });
    });
  });

  const DataGet = async () => {
    await axios
      .get("https://trustedclinics.co.uk/backend/api/header_footer_logo_text")
      // ('https://arowiz.com/trustedclinics/api/header_footer_logo_text').

      .then((response) => {
        // setFooterLinkData(response.data?.detail)
        setFooterData(response.data?.detail);
      })
      .catch((error) => console.log("error", error));
  };
  useEffect(() => {
    DataGet();
  }, []);

  console.log(footerData?.header_logo);

const LogoutFunc=()=>{
  setToggleLogout(!toggleLogout)
  setToggleMenu(!toggleMenu)

}
const navigate = useNavigate();

const logoutDone=()=>{
  localStorage.clear()
  window.location.href='https://trustedclinics.co.uk/#/'
  console.log('clear local ')
  // navigate('/')
  
}

const handleClick = () => {
  setToggleMenu(!toggleMenu)
  setToggleMenu1(!toggleMenu1)
  Swal.fire({
    title: "Are you sure you want to logout?",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#BE93D1",
    cancelButtonColor: "#d33",
    confirmButtonText: "Yes",
  }).then((result) => {
    if (result.isConfirmed) {
      Swal.fire({
        title: "success",
        text: "Logout Successfully.",
        icon: "success",
        confirmButtonColor: "#BE93D1",
      });

      localStorage.clear();
      window.location.reload()
      navigate("/");
      // window.location.href='https://trustedclinics.co.uk/pwa_web/#/'
      console.log('clear local ')
    }
  });
};


// useEffect(() => {
//   if(toggleMenu===true){
//   setTimeout(() => setMenuToggle(false), 1000)
//   }
// }, [])
  return (
    <Fragment>
      <div id="preloader-wrap" style={{ display: "none" }}>
        <div className="preloader" style={{ display: "none" }} />
      </div>

      <header>
        <nav className="navbar navbar-inverse navbar-expand-lg header-nav light-header _nav-head">
          <div className="container">
            <NavLink className="navbar-brand" to="/">
            {footerData?.header_logo && 
              <img src={footerData?.header_logo} alt="logo" />
            }
            </NavLink>
            <div className="d-flex">
              {userData?.length !== 0 ? (
                <div
                  className="login-signup-mobile "   onClick={() => setToggleMenu1(!toggleMenu1)}
                  style={{ marginRight: "-14px", paddingTop: "5px" ,marginTop:'6px'}}
                >
                  <img
                     src={
                        (userData?.image && userData?.image !== null && userData?.image !== 'null') ?
                        userData?.image :"https://en.gravatar.com/avatar/f9c8e7a2311313de7b27e8b42cf885a5?d=https%3A%2F%2Fdashboard.render.com%2Fdefault_gravatar.png&r=g&s=76"
                      }
                    style={{
                      padding: "2px",
                      height: "36px",
                      borderRadius: "50%",
                      width: "36px",
                      margin: "auto",
                      cursor: "pointer",
                      border: "1px solid gray",
                    }}
                  
                  />{" "}
                
                </div>
              ) : (
                ''
              )}

              {toggleMenu1 && (
                      <div className="login-signup-mobile" style={{ position: "relative" }}>
                        <ul
                          className="profile__men card"
                          style={{
                            listStyle: "none",
                            backgroundColor: "white",
                            padding: "10px",
                            border: "0.5px solid gray",
                            position: "absolute",
                            top: "50px",
                            right: "-50px",
                            zIndex: 1,
                            cursor: "pointer",
                            width: "150px",
                            textAlign: "center",
                          }}
                        >
                        {(userData.role_status===1)&&
                        <li className="profile__item pt-2"  
                            // onClick={() => setToggleMenu1(!toggleMenu1)}
                            >    <NavLink to={"/doctor-app"} >App Home</NavLink></li>
                        }
                        {(userData.role_status===2)&&
                          <li className="profile__item pt-2"  
                            // onClick={() => setToggleMenu1(!toggleMenu1)}
                            >  <NavLink to={"/patient-app"} >App Home </NavLink></li>
                        }
                        {(userData.role_status===3)&&
                          <li className="profile__item pt-2"  
                            // onClick={() => setToggleMenu1(!toggleMenu1)}
                            >  <NavLink to={"/lab-app"} >App Home </NavLink></li>
                        }
                        {(userData.role_status===5)&&
                          <li className="profile__item pt-2"  
                            // onClick={() => setToggleMenu1(!toggleMenu1)}
                            >  <NavLink to={"/clinic-app"} >App Home </NavLink></li>
                        }
                          <li className="profile__item pt-2 pb-1"   onClick={handleClick}>Logout</li>
                        </ul>
                      </div>
                    )}

              <button
                className="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#navbarCodeply"
                onClick={() => setToggle(!toggle)}
              >
                <i>
                  {toggle ? (
                    <RxCross1 color={"#d4a54e"} />
                  ) : (
                    <HiViewList color={"#d4a54e"} />
                  )}
                </i>
              </button>
              
            </div>

            {
              //     !toggle ? '' :   <div className="banner-mobile-advert-out-mibleshow" style={{overflowX:'hidden'}}>
              //     <div className="banner-mobile-advert" style={{background:'#fff',width:'100%',borderRadius:'7px',border:'1px solid #684286',clear:'both',margin:'0',display:'flex',flexDirection:'row',boxSizing:'border-box'}}>
              //         <div className="inner" style={{width:'100%',padding:'15px'}}>
              //             <div  style={{display:'flex' ,alignItems:"center",justifyContent:'center',margin:'auto',maxWidth:'400px'}}>
              //                 <div>
              //                     <img src="assets/img/pig-icon.png" alt="Saving pig icon" style={{maxWidth:'90px',paddingLeft:'20px'}}/>
              //                 </div>
              //                 <div style={{marginLeft:"30px",marginRight:'-10px'}}>
              //                     <p className="big"  style={{fontSize:'23px'}}>SAVE <small>UP TO</small> 50%</p>
              //                     <p>Compare Clinics &amp; Pricing In Your Area.</p>
              //                     <a href="https://trustedclinics.com/dental/price-comparison/"  className="btn btn-gold btn-rounded btn-md" >Compare Now</a>
              //                 </div>
              //             </div>
              //         </div>

              //     </div>
              // </div>

              !toggle ? (
                ""
              ) : (
                <div className="banner-mobile-advert-out-mibleshow">
                  <div className="banner-mobile-advert-mibleshow">
                    <div className="inner-mibleshow">
                      <div className="pig-area-mibleshow">
                        <img
                          src="assets/img/pig-icon.png"
                          alt="Saving pig icon"
                        />
                      </div>
                      <div className="big-mibleshow">
                        <p>
                          SAVE <small>UP TO</small> 50%
                        </p>
                        <p>Compare Clinics &amp; Pricing In Your Area.</p>
                        <a href="" className="btn btn-gold btn-rounded btn-md">
                          Compare Now
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              )
            }

            <div
              className={
                toggle
                  ? "collapse navbar-collapse show"
                  : "collapse navbar-collapse "
              }
              id="navbarCodeply"
            >
              {/* to={'/Web_Ifreame_Signin'} */}
              {userData?.length === 0 ? (
                <>
                  {/* <button  className='login-signup-mobile'    style={{
            marginTop: '15px',
            fontSize: '18px',
            border: '1px solid #d4a54e',
            width: '100%',
            padding: '10px',
            color:'#000',
            marginLeft:0,

      }}>   <NavLink   to={'/Web_Ifreame'} style={{ color: '#000' }}   >  Login </NavLink> </button> */}

                  <button
                    className="login-signup-mobile"
                    style={{
                      marginTop: "15px",
                      fontSize: "18px",
                      border: "1px solid #d4a54e",
                      width: "100%",
                      padding: "10px",
                      background: " #d4a54e",
                      color: "#fff",
                      marginLeft: 0,
                    }}
                  >
                    {" "}
                    <NavLink to={"/patient-app"} style={{ color: "#fff" }}>
                      {" "}
                    Patient Login{" "}
                    </NavLink>{" "}
                  </button>
                </>
              ) : (
            ''
              )}

              {/* <NavLink to="/Dentists Partner/232" className="header-btn text-white">
        Dentist Area
      </NavLink> */}

              {/* desktop login */}

           






{/* logout design code  */}
{/* {
  (toggleLogout)&&

               <div className="overla modal123" 
              // style={{top:'' ,position:'relative' ,background:'gray'}}
              >

             <div className="popu card modal-content123" 
            //  style={{width:'',position:'absolute',top:'50%',left:'50%',zIndex:'1'}}
             >
              
                  <span class="close"  onClick={() => setToggleLogout(!toggleLogout)}>&times;</span>
                  <div className="">
                    Are You Sure You Want to Logout ?
                  </div>
                  <div className="pt-4 text-center">
                    <span
                      type="button"
                      className
                      style={{
                        cursor: "pointer",
                        padding: "6px 20px",
                        borderRadius: "7px",
                        border: "2px solid rgb(212, 165, 78)",
                      }}
                      onClick={() => setToggleLogout(!toggleLogout)}
                    >
                      No
                    </span>
                    <span
                      type="button"
                      className="ml-4 text-white"
                      style={{
                        padding: "8px 20px",
                        borderRadius: "7px",
                        background: "rgb(212, 165, 78)",
                        cursor: "pointer",
                        boxShadow:
                          "rgba(0, 0, 0, 0.2) 0px 0px 5px, rgba(192, 141, 47, 0.8) 0px -3px 5px 2px inset, rgba(232, 207, 160, 0.8) 0px 3px 5px 0px inset",
                      }}
                      onClick={logoutDone}
                    >
                      Yes
                    </span>
                  </div>
                </div>
              </div> 
} */}


 

              {/* <div className="" style={{position:'relative'}}>
                  <ul className="profile__men card" style={{listStyle:'none',backgroundColor:'white' ,padding:'10px',border:'0.5px solid gray',position:'absolute',top:'-20px',right:'-50px',zIndex:1,cursor:'pointer',width:'150px',textAlign:'center'}}>
                  <li className="profile__item pt-2">App Home</li>
                  <li className="profile__item pt-2 pb-1">Logout</li>
                  </ul>
                  </div> */}
              {/* <div className='login-signup-page' style={{ marginRight: '-14px'}} > 

<img src={'https://trustedclinics.co.uk/backend/public/images/patient_image/image-1686546881.jpg'}  
style={{    padding: '5px',
    height: '46px',
    borderRadius: '50%',
    width: 'auto',
    margin: 'auto', cursor:'pointer',border:"1.5px solid gray"}}  />  

<span>Patient</span>
      </div>  */}

              {/* desktop menu */}

              <ul className="nav navbar-nav mr-auto mt-4">
                {testMenuData.slice(0, 6).map((item, index) => {
                  console.log(item.menu_name);
                  return item.submenu_check === null ? (
                    <>
                      <li>
                        <NavLink
                          className="nav-link "
                          to={`/${item.page_name}`}
                          onClick={() => {
                          setToggle(!toggle)
                          setMenuToggle(false)
                          setToggleMenu(false)
                          }

                          }
                        >
                          {item.menu_name}
                        </NavLink>
                      </li>
                    </>
                  ) : (
                    <>

                      <li className="dropdown">
                        <NavLink
                          className="nav-link"
                          to={`/${item.page_name}`}
                          onClick={() => {
                            setToggle(!toggle)
                            setMenuToggle(false)
                            setToggleMenu(false)
                          }}
                          // onTouchMove={()=>setMenuToggle(false)}
                          // onMouseDown={()=>setMenuToggle(false)}
                        >
                          {item.menu_name}
                          {toggle ? <MdOutlineKeyboardArrowDown /> : ""}
                        </NavLink>

                        <div className="dropdown-menu dropdown-menu-treatments clearfix show"    
                    
                    style={{
                           marginTop:'-50px'
                                  }}    >
                          <div className="new-dropdown"
                          >
                            <div className="container">
                              <div className="row"
                              >
                                <div className="col-3 purple">
                                  <h5>
                                  {item.menu_name}
                                  </h5>
                                  {toggle ? (
                                    <span
                                      style={{
                                        color: "#684286",
                                        fontWeight: "bold",
                                        cursor: "pointer",
                                      }}
                                      onClick={() => {
                                      setToggle(!toggle)
                                      setMenuToggle(false)
                                      setToggleMenu(false)
                                      }
                                      }
                                    >
                                      <IoIosArrowBack size={20} />
                                      Back
                                    </span>
                                  ) : (
                                    ""
                                  )}

                                  {/* */}

                                  <ul className="main-nav-list" 
                               
                                  >
                                    <li className="nav-link active view-all-page">
                                      <NavLink
                                        to={`/${item.page_name}`}
                                        style={{textTransform: "capitalize"}}
                                        onClick={
                                          () => {
                                         setToggle(!toggle)
                                         setMenuToggle(false)
                                         setToggleMenu(false)
                                        }
                                        }
                                      >
                                        <span className="span-in-header">
                                          {/* view {item.menu_name} */}
                                        </span>{" "}
                                      </NavLink>
                                    </li>

                                    {item.Submenu_name.map((subitem, index) => {
                                      return (
                                        <li
                                          className={`${subitem.Submenu_name_class}`}
                                          style={{
                                            textTransform: "capitalize",
                                          }}
                                        >
                                          {subitem.submenu_name}
                                          {toggle ? (
                                            <MdOutlineKeyboardArrowDown />
                                          ) : (
                                            ""
                                          )}
                                        </li>
                                      );
                                    })}
                                    {/* <li
                            className='dental-implants'
                            style={{ textTransform: "capitalize" }}
                          >
                            dental-implants{toggle? <MdOutlineKeyboardArrowDown /> : ''}
                          </li> */}
                                    {/* <li
                            className='invisalign-clear-aligners'
                            style={{ textTransform: "capitalize" }}
                          >
                            invisalign-clear-aligners{toggle? <MdOutlineKeyboardArrowDown /> : ''}
                          </li>
                          <li
                            className='dental-implants'
                            style={{ textTransform: "capitalize" }}
                          >
                            dental-implants{toggle? <MdOutlineKeyboardArrowDown /> : ''}
                          </li> */}
                                    {/* {
                      menuData.map((item,index)=>{
                        return (
                        
                          <>
                          <li
                            className={`${item.menu_name_class}`}
                            style={{ textTransform: "capitalize" }}
                          >
                            {item.menu_name}{toggle? <MdOutlineKeyboardArrowDown /> : ''}
                          </li>
                          </>
                        )
                      })
                    } */}
                                  </ul>
                                  <h5 className="bottom">
                                    <NavLink
                                      to={`/${item.page_name}`}
                                      onClick={() => {
                                        setToggle(!toggle)
                                        setMenuToggle(false)
                                        setToggleMenu(false)
                                        }}
                                    >
                                      View All {item.menu_name}
                                    </NavLink>
                                  </h5>
                                </div>
                                <div className="col-6 middle-section">
                                  {item.Submenu_name.map((item, index) => {
                                    return (
                                      <>
                                        <div
                                          className={`item ${item.Submenu_name_class}`}
                                          style={{}}
                                        >
                                          {
                                            <h6
                                              style={{
                                                color: "rgb(104, 66, 134)",
                                                fontWeight: "bold",
                                              }}
                                            >
                                              {item.submenu_name}{" "}
                                            </h6>
                                          }
                                          <div className="">
                                            <ul className="d-flex flex-wrap submenu">
                                              {item.Submenuitem_name.map(
                                                (subitem, index) => {
                                                  return (
                                                    item.menu_name_class !==
                                                      "Crowns-&-bridges" && (
                                                      <>
                                                        {/* <li>
              <NavLink className="nav-link" to={`/Page/${item.page_name}`}>
                {item.menu_name}
              </NavLink>
            </li> */}
                                                        <li>
                                                          <NavLink
                                                            to={`/${subitem.page_name}`}
                                                            // to={`/${subitem.page_name}`}
                                                            style={{
                                                              textTransform:
                                                                "capitalize",
                                                            }}
                                                            onClick={
                                                              () =>{
                                                              setToggle(!toggle)
                                                              setMenuToggle(false)
                                                              setToggleMenu(false)
                                                            }
                                                            }
                                                          >
                                                            {
                                                              subitem.submenu_item_name
                                                            }
                                                          </NavLink>
                                                        </li>
                                                      </>
                                                    )
                                                  );
                                                }
                                              )}
                                            </ul>
                                          </div>
                                        </div>
                                      </>
                                    );
                                  })}
                                  {/* {
                    item.Submenu_name.map((subitem,index)=>{
                      return <div className = {`item ${subitem.Submenu_name_class}`} style={{}}>
                      <h6 style={{color:'rgb(104, 66, 134)',fontWeight: 'bold'}}> Treatments</h6>
                      <div className="">
                      <ul className="d-flex flex-wrap submenu">

                      <li>
                          <NavLink to={'/'} style={{ textTransform: "capitalize" }}>{subitem.submenu_name}</NavLink>
                      </li>
                      
                      </ul>
                      </div>
                      </div>
                      
                    })
                  } */}
                                  {/* <div className = 'item dental-implants' style={{}}>
                  <h6 style={{color:'rgb(104, 66, 134)',fontWeight: 'bold'}}> Treatments</h6>
                  <div className="">
                  <ul className="d-flex flex-wrap submenu">

                  <li>
                      <NavLink to={'/'} style={{ textTransform: "capitalize" }}>dental-implants</NavLink>
                  </li>
                  
                  </ul>
                  </div>
                  </div> */}
                                  {/* <div className = 'item invisalign-clear-aligners' style={{}}>
                  <h6 style={{color:'rgb(104, 66, 134)',fontWeight: 'bold'}}> Treatments</h6>
                  <div className="">
                  <ul className="d-flex flex-wrap submenu">
                  <li>
                      <NavLink to={'/'} style={{ textTransform: "capitalize" }}>invisalign-clear-aligners</NavLink>
                  </li>
                  
                  </ul>
                  </div>
                  </div> */}
                                  {/* {
                    menuData.map((item,index)=>{
                      return (
                        <>
                        <div className={`item ${item.menu_name_class}`} style={{}}>
                        { <h6 style={{color:'rgb(104, 66, 134)',fontWeight: 'bold'}}>{item.menu_name} Treatments</h6>}
                        <div className="">
                          <ul className="d-flex flex-wrap submenu">
                          
                          {
                            item.submenu.map((subitem,index)=>{
                              return(
                                (item.menu_name_class  !== 'Crowns-&-bridges') &&
                                <>
                                <li>
                                 <NavLink to={`/${subitem.submenu_name_class}/${subitem.id}`} style={{ textTransform: "capitalize" }}>{subitem.submenu_name}</NavLink>
                                </li>
                                
                                </>
                              )
                            })
                          }
                         
                          </ul>
                        </div>
                        </div>
                        </>
                      )
                    })
                  } */}
                                </div>
                                <div className="col-3 pl-0">
                                  <div className="dropdown-advert-outer">
                                    <div className="dropdown-advert mb-5">
                                      {/* <div className='dp-advertising dental-implants' style={{}}>
                            <div className="inner">
                              <div className="image-contain">
                                <img
                                  src="https://trustedclinics.com/images/menu/new-menu/implants-banner2.jpg"
                                  alt="Dental Implants"
                                />
                              </div>
                              <div className="text">
                              
                                <h4>dental-implants</h4>
                                <p>
                                  The permanent solution to replacing missing
                                  teeth.
                                </p>
                                <NavLink
                                  to={'/'}
                                  className="btn btn-md btn-rounded btn-gold"
                                >
                                  Find out more
                                </NavLink>
                              </div>
                            </div>
                          </div> */}

                                      {/* submenu other sections */}
                                      {/* <div class="dp-advertising greater-london save-banner" style={{}}>
    <div class="inner">
        <div class="pig-area">
            <div>
                <img src="https://trustedclinics.com/images/menu/new-menu/banner-pig.png" alt="Saving pig icon" />
            </div>
            <div class="align-self-center">
                <p>SAVE <small>UP TO</small> 50%</p>
            </div>
        </div>

        <div class="text">
            <p>Compare Clinics &amp; Pricing In Your Area.</p>
            <form action="https://trustedclinics.com/dental/price-comparison/" method="POST" class="form" id="findYourClinicForm">
                <input type="hidden" name="CRAFT_CSRF_TOKEN" value="x8kE_Dbi3yefx4dq6YwBjGsc2opLkBcY2RdLmtX0vk9BXxWZ7i-c7rCiTMVSg4xD75K3K6S7TMg5UbHGZvhkSelYH-2YhYwmN29y6pdZ-p8=" />
                <div class="form-group mb-2">
                    <label for="postcode" class="sr-only">Postcode</label>
                    <div class="input-group">
                        <input type="text" name="postcode" id="postcode" class="form-control" placeholder="Enter Your Post Code" />
                        <div class="input-group-append text-purple">
                            <span class="input-group-text" id="basic-addon2"><i class="fas fa-map-marker-alt"></i></span>
                        </div>
                    </div>
                </div>

                <div class="form-group mb-3">
                    <div id="rollerLoader" class="text-center" style={{display:'none'}}>
                        <div data-v-01d979ac="" class="lds-roller" style={{width: '24px', height: '24px'}}>
                            <div data-v-01d979ac="" style={{animationDuration: '1.2s', transformOrigin: '12px 12px', animationDelay: '-0.036s'}}>
                                <div data-v-01d979ac="" class="div-after" style={{width: '2.1px', height: '2.1px', margin: '-1.2px 0px 0px -1.2px', background: 'rgb(255, 255, 255)', top: '18.9px', left: '18.9px'}}></div>
                            </div>
                            <div data-v-01d979ac="" style={{animationDuration: '1.2s', transformOrigin: '12px 12px', animationDelay: '-0.072s'}}>
                                <div data-v-01d979ac="" class="div-after" style={{width: '2.1px', height: '2.1px', margin: '-1.2px 0px 0px -1.2px', background: 'rgb(255, 255, 255)', top: '18.9px', left: '18.9px'}}></div>
                            </div>
                            <div data-v-01d979ac="" style={{animationDuration: '1.2s', transformOrigin: '12px 12px', animationDelay: '-0.108s'}}>
                                <div data-v-01d979ac="" class="div-after" style={{width: '2.1px', height: '2.1px', margin: '-1.2px 0px 0px -1.2px', background: 'rgb(255, 255, 255)', top: '20.4px', left: '16.8px'}}></div>
                            </div>
                            <div data-v-01d979ac="" style={{animationDuration: '1.2s', transformOrigin: '12px 12px', animationDelay: '-0.144s'}}>
                                <div data-v-01d979ac="" class="div-after" style={{width: '2.1px', height: '2.1px', margin: '-1.2px 0px 0px -1.2px', background: 'rgb(255, 255, 255)', top: '21.3px', left: '14.4px'}}></div>
                            </div>
                            <div data-v-01d979ac="" style={{animationDuration: '1.2s', transformOrigin: '12px 12px', animationDelay: '-0.18s'}}>
                                <div data-v-01d979ac="" class="div-after" style={{width: '2.1px', height: '2.1px', margin: '-1.2px 0px 0px -1.2px', background: 'rgb(255, 255, 255)', top: '21.6px' ,left: '12px'}}></div>
                            </div>
                            <div data-v-01d979ac="" style={{animationDuration: '1.2s', transformOrigin: '12px 12px', animationDelay: '-0.216s'}}>
                                <div data-v-01d979ac="" class="div-after" style={{width: '2.1px', height: '2.1px', margin: '-1.2px 0px 0px -1.2px', background: 'rgb(255, 255, 255)', top: '21.3px' ,left: '9.6px'}}></div>
                            </div>
                            <div data-v-01d979ac="" style={{animationDuration: '1.2s', transformOrigin: '12px 12px', animationDelay: '-0.252s'}}>
                                <div data-v-01d979ac="" class="div-after" style={{width: '2.1px', height: '2.1px', margin: '-1.2px 0px 0px -1.2px', background: 'rgb(255, 255, 255)', top: '20.4px' ,left: '7.2px'}}></div>
                            </div>
                            <div data-v-01d979ac="" style={{animationDuration: '1.2s', transformOrigin: '12px 12px', animationDelay: '-0.288s'}}>
                                <div data-v-01d979ac="" class="div-after" style={{width: '2.1px', height: '2.1px', margin: '-1.2px 0px 0px -1.2px', background: 'rgb(255, 255, 255)', top: '18.9px', left: '5.1px'}}></div>
                            </div> 
                        </div>
                        <span class="waiting-msg">Please wait while we get your location</span>
                    </div>
                    <button type="button" id="useLocationBtn" class="btn text-white"><i class="fas fa-crosshairs text-white"></i> Use my current location</button>
                </div>

                <button type="submit" class="btn btn-gold btn-rounded btn-lg">Search Trusted Clinics</button>
            </form>
        </div>
    </div>
</div> */}
                                      {/* <div class="dp-advertising dental-implants-articles" style={{}}>
    <div class="inner p-2">
        <div class="text new pt-3">
            <h4 class="pb-2">Find your nearest Clinic and compare prices in your area</h4>
            <a href="https://trustedclinics.com/dental/price-comparison" class="btn btn-md btn-rounded btn-gold mt-2 mb-4">Find Your Clinic Now</a>

            <div class="card-area">
                <div class="card w-100 mb-3 " style={{backgroundImage: 'url(https://trustedclinics.com//images/menu/new-menu/articles-implants-one.png)',backgroundRepeat:'no-repeat'}}>
                    <h5>Single Dental Implant</h5>
                    <a href="https://trustedclinics.com/dental/treatments/dental-implants/single-tooth-dental-implant" class="btn btn-sm btn-rounded btn-gold" title="Single Dental Implant - Learn More">Learn More</a>
                </div>
                <div class="card w-100 mb-3" style={{backgroundImage: 'url(https://trustedclinics.com//images/menu/new-menu/articles-implants-two.png)'}}>
                    <h5>Multiple Dental Implant</h5>
                    <a href="https://trustedclinics.com/dental/treatments/dental-implants/multiple-dental-implants" class="btn btn-sm btn-rounded btn-gold" title="Multiple Dental Implant - Learn More">Learn More</a>
                </div>
                <div class="card w-100 mb-3" style={{backgroundImage: 'url(https://trustedclinics.com//images/menu/new-menu/articles-implants-three.png)'}}>
                    <h5>Full Jaw Restoration</h5>
                    <a href="https://trustedclinics.com/dental/treatments/dental-implants/implant-supported-bridge" class="btn btn-sm btn-rounded btn-gold" title="Full Jaw Restoration - Learn More">Learn More</a>
                </div>
                <div class="card w-100 mb-3" style={{backgroundImage: 'url(https://trustedclinics.com//images/menu/new-menu/articles-implants-four.png)'}}>
                    <h5>Full Mouth Reconstruction</h5>
                    <a href="https://trustedclinics.com/dental/treatments/dental-implants/full-mouth-reconstruction" class="btn btn-sm btn-rounded btn-gold" title="Full Mouth Reconstruction - Learn More">Learn More</a>
                </div>
            </div>
        </div>
    </div>
</div> */}

                                      {item.Submenu_name.map((item, index) => {
                                        return (
                                          <>
                                            <div
                                              className={`dp-advertising ${item.Submenu_name_class}`}
                                              style={{}}
                                            >
                                              <div className="inner">
                                                <div className="image-contain">
                                                  <img
                                                    // src="https://trustedclinics.com/images/menu/new-menu/implants-banner2.jpg"
                                                    src="https://trustedclinics.co.uk/backend/public/admin/header_footer_logo/home2-1683802990.png"
                                                    alt="Dental Implants"
                                                  />
                                                </div>
                                                <div className="text">
                                                  <h4>{item.submenu_name}</h4>
                                                  <p>
                                                    The permanent solution to
                                                    replacing missing teeth.
                                                  </p>
                                                  <NavLink
                                                    to={"/"}
                                                    className="btn btn-md btn-rounded btn-gold"
                                                  >
                                                    Find out more
                                                  </NavLink>
                                                </div>
                                              </div>
                                            </div>
                                          </>
                                        );
                                      })}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>

                      {/* <li>
              <NavLink className="nav-link" to={`/Page/${item.page_name}`}>
                {item.menu_name}
              </NavLink>
            
        <div className="dropdown-menu dropdown-menu-treatments clearfix">
  <div className="new-dropdown">
    <div className="container">
      <div className="row">
        <div className="col-3 purple">
          <h5>Treatments</h5>
          <ul className="main-nav-list">
            <li className="treatments" style={{ textTransform: "capitalize" }}>
              All Treatments
            </li>
            <li
              className="dental-implants"
              style={{ textTransform: "capitalize" }}
            >
              Dental implants
            </li>
            <li
              className="invisalign-clear-aligners"
              style={{ textTransform: "capitalize" }}
            >
              Invisalign clear aligners
            </li>
            <li
              className="dental-braces"
              style={{ textTransform: "capitalize" }}
            >
              Dental braces
            </li>
            <li className="veneers" style={{ textTransform: "capitalize" }}>
              Veneers
            </li>
            <li
              className="crowns-and-bridges"
              style={{ textTransform: "capitalize" }}
            >
              Crowns &amp; bridges
            </li>
            <li
              className="teeth-whitening"
              style={{ textTransform: "capitalize" }}
            >
              Teeth whitening
            </li>
            <li
              className="fillers-anti-wrinkle-injections"
              style={{ textTransform: "capitalize" }}
            >
              Fillers And Anti Wrinkle Injections
            </li>
            <li
              className="emergency-treatment"
              style={{ textTransform: "capitalize" }}
            >
              Emergency treatment
            </li>
          </ul>
          <h5 className="bottom">
            <a href="https://trustedclinics.com/dental/treatments">
              View All Treatments
            </a>
          </h5>
        </div>
        <div className="col-6 middle-section">
      
          <div className="item dental-implants">
            <h6>Dental Implant Treatments</h6>
            <div className="">
              <ul className="d-flex flex-wrap">
                <li>
                  <a href="https://trustedclinics.com/dental/treatments/dental-implants/">
                    Dental implants
                  </a>
                </li>
                <li>
                  <a
                    href="https://trustedclinics.com/dental/treatments/dental-implants/single-tooth-dental-implant/"
                    style={{ textTransform: "capitalize" }}
                  >
                    Single tooth dental implant
                  </a>
                </li>
                <li>
                  <a
                    href="https://trustedclinics.com/dental/treatments/dental-implants/multiple-dental-implants/"
                    style={{ textTransform: "capitalize" }}
                  >
                    Multiple dental implants
                  </a>
                </li>
                <li>
                  <a
                    href="https://trustedclinics.com/dental/treatments/dental-implants/implant-retained-denture/"
                    style={{ textTransform: "capitalize" }}
                  >
                    Implant retained denture
                  </a>
                </li>
                <li>
                  <a
                    href="https://trustedclinics.com/dental/treatments/dental-implants/implant-supported-bridge/"
                    style={{ textTransform: "capitalize" }}
                  >
                    Implant supported bridge
                  </a>
                </li>
                <li>
                  <a
                    href="https://trustedclinics.com/dental/treatments/dental-implants/teeth-in-a-day/"
                    style={{ textTransform: "capitalize" }}
                  >
                    Teeth in a Day
                  </a>
                </li>
                <li>
                  <a
                    href="https://trustedclinics.com/dental/treatments/dental-implants/full-mouth-reconstruction/"
                    style={{ textTransform: "capitalize" }}
                  >
                    Full mouth reconstruction
                  </a>
                </li>
                <li>
                  <a
                    href="https://trustedclinics.com/dental/treatments/dental-implants/reviews/"
                    style={{ textTransform: "capitalize" }}
                  >
                    Dental implant reviews
                  </a>
                </li>
                <li>
                  <a
                    href="https://trustedclinics.com/dental/treatments/dental-implants/pricing/"
                    style={{ textTransform: "capitalize" }}
                  >
                    Dental implants pricing
                  </a>
                </li>
                <li>
                  <a
                    href="https://trustedclinics.com/dental/treatments/dental-implants/faqs/"
                    style={{ textTransform: "capitalize" }}
                  >
                    Dental implant FAQs
                  </a>
                </li>
                <li>
                  <a
                    href="https://trustedclinics.com/dental/treatments/dental-implants/gallery/"
                    style={{ textTransform: "capitalize" }}
                  >
                    Dental implant gallery
                  </a>
                </li>
              </ul>
            </div>
          </div>
         
          <div
            className="item invisalign-clear-aligners"
            style={{ display: "none" }}
          >
            <h6>Invisalign Clear Aligners Treatments</h6>
            <div className="">
              <ul className="d-flex flex-wrap">
                <li>
                  <a
                    href="https://trustedclinics.com/dental/treatments/invisalign-clear-aligners/"
                    style={{ textTransform: "capitalize" }}
                  >
                    Invisalign clear aligners
                  </a>
                </li>
                <li>
                  <a
                    href="https://trustedclinics.com/dental/treatments/invisalign-clear-aligners/invisalign-express/"
                    style={{ textTransform: "capitalize" }}
                  >
                    Invisalign Express
                  </a>
                </li>
                <li>
                  <a
                    href="https://trustedclinics.com/dental/treatments/invisalign-clear-aligners/invisalign-lite/"
                    style={{ textTransform: "capitalize" }}
                  >
                    Invisalign Lite
                  </a>
                </li>
                <li>
                  <a
                    href="https://trustedclinics.com/dental/treatments/invisalign-clear-aligners/invisalign-full/"
                    style={{ textTransform: "capitalize" }}
                  >
                    Invisalign Full
                  </a>
                </li>
                <li>
                  <a
                    href="https://trustedclinics.com/dental/treatments/invisalign-clear-aligners/reviews/"
                    style={{ textTransform: "capitalize" }}
                  >
                    Invisalign reviews
                  </a>
                </li>
                <li>
                  <a
                    href="https://trustedclinics.com/dental/treatments/invisalign-clear-aligners/pricing/"
                    style={{ textTransform: "capitalize" }}
                  >
                    Invisalign pricing
                  </a>
                </li>
                <li>
                  <a
                    href="https://trustedclinics.com/dental/treatments/invisalign-clear-aligners/faqs/"
                    style={{ textTransform: "capitalize" }}
                  >
                    Invisalign FAQs
                  </a>
                </li>
                <li>
                  <a
                    href="https://trustedclinics.com/dental/treatments/invisalign-clear-aligners/gallery/"
                    style={{ textTransform: "capitalize" }}
                  >
                    Invisalign gallery
                  </a>
                </li>
              </ul>
            </div>
          </div>
         
          <div className="item dental-braces" style={{ display: "none" }}>
            <h6>Dental Braces Treatments</h6>
            <div className="">
              <ul className="d-flex flex-wrap">
                <li>
                  <a
                    href="https://trustedclinics.com/dental/treatments/dental-braces/"
                    style={{ textTransform: "capitalize" }}
                  >
                    Dental braces
                  </a>
                </li>
                <li>
                  <a
                    href="https://trustedclinics.com/dental/treatments/dental-braces/fixed-braces/"
                    style={{ textTransform: "capitalize" }}
                  >
                    Fixed braces
                  </a>
                </li>
                <li>
                  <a
                    href="https://trustedclinics.com/dental/treatments/dental-braces/fixed-braces/quick-straight-teeth/"
                    style={{ textTransform: "capitalize" }}
                  >
                    Quick Straight Teeth
                  </a>
                </li>
                <li>
                  <a
                    href="https://trustedclinics.com/dental/treatments/dental-braces/fixed-braces/inman-aligner/"
                    style={{ textTransform: "capitalize" }}
                  >
                    Inman Aligners
                  </a>
                </li>
                <li>
                  <a
                    href="https://trustedclinics.com/dental/treatments/dental-braces/fixed-braces/damon/"
                    style={{ textTransform: "capitalize" }}
                  >
                    Damon Braces
                  </a>
                </li>
                <li>
                  <a
                    href="https://trustedclinics.com/dental/treatments/dental-braces/fixed-braces/six-month-smiles/"
                    style={{ textTransform: "capitalize" }}
                  >
                    Six Month Smiles
                  </a>
                </li>
                <li>
                  <a
                    href="https://trustedclinics.com/dental/treatments/dental-braces/lingual-braces/"
                    style={{ textTransform: "capitalize" }}
                  >
                    Lingual braces
                  </a>
                </li>
                <li>
                  <a
                    href="https://trustedclinics.com/dental/treatments/dental-braces/lingual-braces/incognito/"
                    style={{ textTransform: "capitalize" }}
                  >
                    Incognito Lingual Braces
                  </a>
                </li>
                <li>
                  <a
                    href="https://trustedclinics.com/dental/treatments/dental-braces/reviews/"
                    style={{ textTransform: "capitalize" }}
                  >
                    Dental braces reviews
                  </a>
                </li>
                <li>
                  <a
                    href="https://trustedclinics.com/dental/treatments/dental-braces/faqs/"
                    style={{ textTransform: "capitalize" }}
                  >
                    Dental braces FAQs
                  </a>
                </li>
                <li>
                  <a
                    href="https://trustedclinics.com/dental/treatments/dental-braces/gallery/"
                    style={{ textTransform: "capitalize" }}
                  >
                    Dental braces gallery
                  </a>
                </li>
              </ul>
            </div>
          </div>
      
          <div className="item veneers" style={{ display: "none" }}>
            <h6>Veneers Treatments</h6>
            <div className="">
              <ul className="d-flex flex-wrap">
                <li>
                  <a
                    href="https://trustedclinics.com/dental/treatments/veneers/"
                    style={{ textTransform: "capitalize" }}
                  >
                    Veneers
                  </a>
                </li>
                <li>
                  <a
                    href="https://trustedclinics.com/dental/treatments/veneers/reviews/"
                    style={{ textTransform: "capitalize" }}
                  >
                    Veneers reviews
                  </a>
                </li>
                <li>
                  <a
                    href="https://trustedclinics.com/dental/treatments/veneers/pricing/"
                    style={{ textTransform: "capitalize" }}
                  >
                    Veneers pricing
                  </a>
                </li>
                <li>
                  <a
                    href="https://trustedclinics.com/dental/treatments/veneers/faqs/"
                    style={{ textTransform: "capitalize" }}
                  >
                    Veneers FAQs
                  </a>
                </li>
                <li>
                  <a
                    href="https://trustedclinics.com/dental/treatments/veneers/gallery/"
                    style={{ textTransform: "capitalize" }}
                  >
                    Veneers gallery
                  </a>
                </li>
              </ul>
            </div>
          </div>
  
          <div className="item crowns-and-bridges" style={{ display: "none" }}>
            <h6>Crowns &amp; Bridges Treatments</h6>
            <div className="">
              <ul className="d-flex flex-wrap">
                <li>
                  <a
                    href="https://trustedclinics.com/dental/treatments/crowns-and-bridges/"
                    style={{ textTransform: "capitalize" }}
                  >
                    Crowns &amp; bridges
                  </a>
                </li>
                <li>
                  <a
                    href="https://trustedclinics.com/dental/treatments/crowns-and-bridges/reviews/"
                    style={{ textTransform: "capitalize" }}
                  >
                    Crowns &amp; bridges reviews
                  </a>
                </li>
                <li>
                  <a
                    href="https://trustedclinics.com/dental/treatments/crowns-and-bridges/pricing/"
                    style={{ textTransform: "capitalize" }}
                  >
                    Crowns &amp; bridges pricing
                  </a>
                </li>
                <li>
                  <a
                    href="https://trustedclinics.com/dental/treatments/crowns-and-bridges/faqs/"
                    style={{ textTransform: "capitalize" }}
                  >
                    Crowns &amp; bridges FAQs
                  </a>
                </li>
                <li>
                  <a
                    href="https://trustedclinics.com/dental/treatments/crowns-and-bridges/gallery/"
                    style={{ textTransform: "capitalize" }}
                  >
                    Crowns &amp; bridges gallery
                  </a>
                </li>
              </ul>
            </div>
          </div>
      
          <div className="item teeth-whitening" style={{ display: "none" }}>
            <h6>Teeth Whitening Treatments</h6>
            <div className="">
              <ul className="d-flex flex-wrap">
                <li>
                  <a
                    href="https://trustedclinics.com/dental/treatments/teeth-whitening/"
                    style={{ textTransform: "capitalize" }}
                  >
                    Teeth whitening
                  </a>
                </li>
                <li>
                  <a
                    href="https://trustedclinics.com/dental/treatments/teeth-whitening/reviews/"
                    style={{ textTransform: "capitalize" }}
                  >
                    Teeth whitening reviews
                  </a>
                </li>
                <li>
                  <a
                    href="https://trustedclinics.com/dental/treatments/teeth-whitening/pricing/"
                    style={{ textTransform: "capitalize" }}
                  >
                    Teeth whitening pricing
                  </a>
                </li>
                <li>
                  <a
                    href="https://trustedclinics.com/dental/treatments/teeth-whitening/faqs/"
                    style={{ textTransform: "capitalize" }}
                  >
                    Teeth whitening FAQs
                  </a>
                </li>
                <li>
                  <a
                    href="https://trustedclinics.com/dental/treatments/teeth-whitening/gallery/"
                    style={{ textTransform: "capitalize" }}
                  >
                    Teeth whitening gallery
                  </a>
                </li>
              </ul>
            </div>
          </div>
   
          <div className="item fillers" style={{ display: "none" }}>
            <h6>Fillers &amp; Anti-Wrinkle Injections Treatments</h6>
            <div className="">
        
              <ul className="d-flex flex-wrap">
                <li>
                  <a
                    href="https://trustedclinics.com/dental/treatments/fillers-anti-wrinkle-injections/"
                    style={{ textTransform: "capitalize" }}
                  >
                    Fillers And Anti Wrinkle Injections
                  </a>
                </li>
                <li>
                  <a
                    href="https://trustedclinics.com/dental/treatments/fillers-anti-wrinkle-injections/reviews/"
                    style={{ textTransform: "capitalize" }}
                  >
                    Lip, cheeks and chin fillers reviews
                  </a>
                </li>
                <li>
                  <a
                    href="https://trustedclinics.com/dental/treatments/fillers-anti-wrinkle-injections/faqs/"
                    style={{ textTransform: "capitalize" }}
                  >
                    Lip, cheeks and chin fillers FAQs
                  </a>
                </li>
                <li>
                  <a
                    href="https://trustedclinics.com/dental/treatments/fillers-anti-wrinkle-injections/gallery/"
                    style={{ textTransform: "capitalize" }}
                  >
                    Lip, cheeks and chin fillers gallery
                  </a>
                </li>
              </ul>
            </div>
          </div>
        
          <div className="item emergency-treatment" style={{ display: "none" }}>
            <h6>Emergency Treatments</h6>
            <div className="">
              <ul className="d-flex flex-wrap">
                <li>
                  <a
                    href="https://trustedclinics.com/dental/treatments/emergency-treatment/"
                    style={{ textTransform: "capitalize" }}
                  >
                    Emergency treatment
                  </a>
                </li>
                <li>
                  <a
                    href="https://trustedclinics.com/dental/treatments/emergency-treatment/reviews/"
                    style={{ textTransform: "capitalize" }}
                  >
                    Emergency treatment reviews
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="col-3 pl-0">
          <div className="dropdown-advert-outer">
            <div className="dropdown-advert">
     
              <div className="dp-advertising dental-implants">
                <div className="inner">
                  <div className="image-contain">
                    <img
                      src="./Reviews_files/implants-banner2.jpg"
                      alt="Dental Implants"
                    />
                  </div>
                  <div className="text">
                    <h4>Dental implants.</h4>
                    <p>The permanent solution to replacing missing teeth.</p>
                    <a
                      href="https://trustedclinics.com/dental/treatments/dental-implants"
                      className="btn btn-md btn-rounded btn-gold"
                    >
                      Find out more
                    </a>
                  </div>
                </div>
              </div>
 
              <div
                className="dp-advertising invisalign-clear-aligners"
                style={{ display: "none" }}
              >
                <div className="inner">
                  <div className="image-contain">
                    <img
                      src="./Reviews_files/invisalign-banner2.jpg"
                      alt="Invisalign Banner"
                    />
                  </div>
                  <div className="text">
                    <h4>Invisalign.</h4>
                    <p>The most invisible way to a straighter smile.</p>
                    <a
                      href="https://trustedclinics.com/dental/treatments/invisalign-clear-aligners"
                      className="btn btn-md btn-rounded btn-gold"
                    >
                      Find out more
                    </a>
                  </div>
                </div>
              </div>

              <div
                className="dp-advertising dental-braces"
                style={{ display: "none" }}
              >
                <div className="inner">
                  <div className="image-contain">
                    <img
                      src="./Reviews_files/dentalbraces-banner2.jpg"
                      alt="Dental Braces banner"
                    />
                  </div>
                  <div className="text">
                    <h4>Dental Braces.</h4>
                    <p>Our full range teeth straightening solutions.</p>
                    <a
                      href="https://trustedclinics.com/dental/treatments/dental-braces"
                      className="btn btn-md btn-rounded btn-gold"
                    >
                      Find out more
                    </a>
                  </div>
                </div>
              </div>

              <div
                className="dp-advertising veneers"
                style={{ display: "none" }}
              >
                <div className="inner">
                  <div className="image-contain">
                    <img
                      src="./Reviews_files/veneers-banner2.jpg"
                      alt="Veneers Banner"
                    />
                  </div>
                  <div className="text">
                    <h4>Veneers.</h4>
                    <p>
                      The instant solution for achieving a natural looking
                      perfect smile.
                    </p>
                    <a
                      href="https://trustedclinics.com/dental/treatments/veneers"
                      className="btn btn-md btn-rounded btn-gold"
                    >
                      Find out more
                    </a>
                  </div>
                </div>
              </div>
 
              <div
                className="dp-advertising crowns-and-bridges"
                style={{ display: "none" }}
              >
                <div className="inner">
                  <div className="image-contain">
                    <img
                      src="./Reviews_files/crowns-and-bridges-banner2.jpg"
                      alt="Crowns and bridges banner"
                    />
                  </div>
                  <div className="text">
                    <h4>Crowns &amp; Bridges.</h4>
                    <p>
                      The least invasive way to repair &amp; replace missing
                      teeth.
                    </p>
                    <a
                      href="https://trustedclinics.com/dental/treatments/crowns-and-bridges"
                      className="btn btn-md btn-rounded btn-gold"
                    >
                      Find out more
                    </a>
                  </div>
                </div>
              </div>

              <div
                className="dp-advertising teeth-whitening"
                style={{ display: "none" }}
              >
                <div className="inner">
                  <div className="image-contain">
                    <img
                      src="./Reviews_files/teeth-whitening-banner2.jpg"
                      alt="Teeth Whitening banner"
                    />
                  </div>
                  <div className="text">
                    <h4>Teeth Whitening.</h4>
                    <p>The most cost-effective way to improve your smile.</p>
                    <a
                      href="https://trustedclinics.com/dental/treatments/teeth-whitening"
                      className="btn btn-md btn-rounded btn-gold"
                    >
                      Find out more
                    </a>
                  </div>
                </div>
              </div>

              <div
                className="dp-advertising fillers"
                style={{ display: "none" }}
              >
                <div className="inner">
                  <div className="image-contain">
                    <img
                      src="./Reviews_files/fillers-banner2.jpg"
                      alt="Fillers & Anti-Wrinkle Injections banner"
                    />
                  </div>
                  <div className="text">
                    <h4>Fillers &amp; Anti-Wrinkle Injections.</h4>
                    <p>
                      The perfect solution to reduce wrinkles &amp; fight thee
                      signs of ageing.
                    </p>
                    <a
                      href="https://trustedclinics.com/dental/treatments/fillers-anti-wrinkle-injections"
                      className="btn btn-md btn-rounded btn-gold"
                    >
                      Find out more
                    </a>
                  </div>
                </div>
              </div>
      
              <div
                className="dp-advertising emergency-treatment"
                style={{ display: "none" }}
              >
                <div className="inner">
                  <div className="image-contain">
                    <img
                      src="./Reviews_files/emergency-treatment-banner2.jpg"
                      alt="Emergency Treatment banner"
                    />
                  </div>
                  <div className="text">
                    <h4>Emergency Treatments.</h4>
                    <p>
                      For those in unmanageable pain and who need to be seen
                      immediately.
                    </p>
                    <a
                      href="https://trustedclinics.com/dental/treatments/emergency-treatment"
                      className="btn btn-md btn-rounded btn-gold"
                    >
                      Find out more
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</li> */}
                    </>
                  );
                })}


{/* mobaile more button code */}


{testMenuData.length > 7 && (
                  <li
                    className="nav-link showmobaile"
                    onClick={() => setMenuToggle1(!menuToggle1)}
                  >
                    <span
                      className=""
                      style={{
                        fontWeight: 600,
                        marginTop: "2px",
                        cursor: "pointer",
                        fontWeight:"550"
                      }}
                    >
                      More
                      <AiFillCaretDown size={18} style={{marginTop:'-2px'}}/>
                    </span>
                  </li>
                )}

                {menuToggle1 &&
                  testMenuData.slice(7).map((item, index) => {
                    console.log(item.menu_name);
                    return item.submenu_check === null ? (
                      <>
                        <li>
                          <NavLink
                            className="nav-link "
                            to={`/${item.page_name}`}
                            onClick={() => setMenuToggle1(!menuToggle1)}
                          >
                            {item.menu_name}
                          </NavLink>
                        </li>
                      </>
                    ) : (
                      <>
                        <li className="dropdown">
                          <NavLink
                            className="nav-link"
                            to={`/${item.page_name}`}
                            onClick={() => setMenuToggle1(!menuToggle1)}
                          >
                            {item.menu_name}
                            {toggle ? <MdOutlineKeyboardArrowDown /> : ""}
                          </NavLink>

                          <div className="dropdown-menu dropdown-menu-treatments clearfix show">
                            <div className="new-dropdown">
                              <div className="container">
                                <div className="row">
                                  <div className="col-3 purple">
                                    <h5>
                                    {item.menu_name}
                                    </h5>
                                    {toggle ? (
                                      <span
                                        style={{
                                          color: "#684286",
                                          fontWeight: "bold",
                                          cursor: "pointer",
                                        }}
                                        onClick={() => setToggle(!toggle)}
                                      >
                                        <IoIosArrowBack size={20} />
                                        Back
                                      </span>
                                    ) : (
                                      ""
                                    )}

                                    {/* */}

                                    <ul className="main-nav-list ">
                                      <li className="nav-link active view-all-page">
                                        <NavLink
                                          to={`/${item.page_name}`}
                                          style={{
                                            textTransform: "capitalize",
                                          }}
                                          onClick={() => setToggle(!toggle)}
                                        >
                                          <span className="span-in-header">
                                            view {item.menu_name}
                                          </span>{" "}
                                        </NavLink>
                                      </li>

                                      {item.Submenu_name.map(
                                        (subitem, index) => {
                                          return (
                                            <li
                                              className={`${subitem.Submenu_name_class}`}
                                              style={{
                                                textTransform: "capitalize",
                                              }}
                                            >
                                              {subitem.submenu_name}
                                              {toggle ? (
                                                <MdOutlineKeyboardArrowDown />
                                              ) : (
                                                ""
                                              )}
                                            </li>
                                          );
                                        }
                                      )}
                                    </ul>
                                    <h5 className="bottom">
                                      <NavLink
                                        to={`/${item.page_name}`}
                                        onClick={() => setToggle(!toggle)}
                                      >
                                        View All {item.menu_name}
                                      </NavLink>
                                    </h5>
                                  </div>
                                  <div className="col-6 middle-section">
                                    {item.Submenu_name.map((item, index) => {
                                      return (
                                        <>
                                          <div
                                            className={`item ${item.Submenu_name_class}`}
                                            style={{}}
                                          >
                                            {
                                              <h6
                                                style={{
                                                  color: "rgb(104, 66, 134)",
                                                  fontWeight: "bold",
                                                }}
                                              >
                                                {item.submenu_name}{" "}
                                              </h6>
                                            }
                                            <div className="">
                                              <ul className="d-flex flex-wrap submenu">
                                                {item.Submenuitem_name.map(
                                                  (subitem, index) => {
                                                    return (
                                                      item.menu_name_class !==
                                                        "Crowns-&-bridges" && (
                                                        <>
                                                          <li>
                                                            <NavLink
                                                              to={`/${subitem.page_name}`}
                                                              style={{
                                                                textTransform:
                                                                  "capitalize",
                                                              }}
                                                              onClick={() =>
                                                                setToggle(
                                                                  !toggle
                                                                )
                                                              }
                                                            >
                                                              {
                                                                subitem.submenu_item_name
                                                              }
                                                            </NavLink>
                                                          </li>
                                                        </>
                                                      )
                                                    );
                                                  }
                                                )}
                                              </ul>
                                            </div>
                                          </div>
                                        </>
                                      );
                                    })}
                                  </div>
                                  <div className="col-3 pl-0">
                                    <div className="dropdown-advert-outer">
                                      <div className="dropdown-advert mb-5">
                                        {item.Submenu_name.map(
                                          (item, index) => {
                                            return (
                                              <>
                                                <div
                                                  className={`dp-advertising ${item.Submenu_name_class}`}
                                                  style={{}}
                                                >
                                                  <div className="inner">
                                                    <div className="image-contain">
                                                      <img
                                                        src="https://trustedclinics.com/images/menu/new-menu/implants-banner2.jpg"
                                                        alt="Dental Implants"
                                                      />
                                                    </div>
                                                    <div className="text">
                                                      <h4>
                                                        {item.submenu_name}
                                                      </h4>
                                                      <p>
                                                        The permanent solution
                                                        to replacing missing
                                                        teeth.
                                                      </p>
                                                      <NavLink
                                                        to={"/"}
                                                        className="btn btn-md btn-rounded btn-gold"
                                                      >
                                                        Find out more
                                                      </NavLink>
                                                    </div>
                                                  </div>
                                                </div>
                                              </>
                                            );
                                          }
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                      </>
                    );
                  })}




                {/* desktop more button code */}

                {testMenuData.length > 7 && (
                  <li
                    className="nav-link hidemore"
                    // onMouseEnter={() =>{ 
                    // setMenuToggle(!menuToggle)
                    // setToggleMenu(false)
                    // }
                    // }
                    onMouseEnter={() => {
                      setMenuToggle(true);
                      setToggleMenu(false);
                    }}
                    onMouseLeave={() => {
                      setMenuToggle(false);
                    }}
                    
                  >
                    <span
                      className=""
                      style={{
                        fontWeight: 600,
                        marginTop: "2px",
                        cursor: "pointer",
                        fontWeight:"550"
                      }}
                    >
                      More
                      <AiFillCaretDown size={20} style={{
                        marginTop:'-2px'
                      }}/>
                      {/* <AiOutlineMore size={20}/> */}
                      {/* <img
                      src="https://www.google.co.in/intl/en/about/products?tab=rh"
                      alt="">

                      </img> */}
                    </span>
                  </li>
                )}

                { (menuToggle)&&
                <div className="card d-flex" 
                 onMouseEnter={() => {
                      setMenuToggle(true);
                      setToggleMenu(false);
                    }}
                onMouseLeave={() => {
                      setMenuToggle(false);
                    }}
  //  className="profile__men card"
                          style={{
                            listStyle: "none",
                            backgroundColor: "white",
                            padding: "10px",
                            border: "0.5px solid gray",
                            position: "absolute",
                            top: "77%",
                            right: "20%",
                            zIndex: 1,
                            cursor: "pointer",
                            width: "250px",
                            textAlign: "center",
                          }}>

               {menuToggle &&
             
                  testMenuData.slice(7).map((item, index) => {
                    console.log(item.menu_name);
                    return item.submenu_check === null ? (
                      <>
                        <li 
                        >
                          <NavLink
                            className="nav-link text-center pl-5"
                            to={`/${item.page_name}`}
                            onClick={() =>{ 
                            setMenuToggle(!menuToggle)
                           setToggleMenu(false)
                            }
                            }
                          >
                            {item.menu_name}
                          </NavLink>
                        </li>
                      </>
                    ) : (
                      <>
                        <li className="dropdown">
                          <NavLink
                            className="nav-link"
                            to={`/${item.page_name}`}
                            onClick={() => {

                            setMenuToggle(!menuToggle)
                            setToggleMenu(false)
                            }}
                          >
                            {item.menu_name}
                            {toggle ? <MdOutlineKeyboardArrowDown /> : ""}
                          </NavLink>

                          <div className="dropdown-menu dropdown-menu-treatments clearfix show">
                            <div className="new-dropdown">
                              <div className="container">
                                <div className="row">
                                  <div className="col-3 purple">
                                    <h5>{item.menu_name}</h5>
                                    {toggle ? (
                                      <span
                                        style={{
                                          color: "#684286",
                                          fontWeight: "bold",
                                          cursor: "pointer",
                                        }}
                                        onClick={() => 
                                        {setToggle(!toggle)
                                          setMenuToggle(false)
                                          setToggleMenu(false)
                                        }
                                        }
                                      >
                                        <IoIosArrowBack size={20} />
                                        Back
                                      </span>
                                    ) : (
                                      ""
                                    )}

                                    {/* */}

                                    <ul className="main-nav-list ">
                                      <li className="nav-link active view-all-page">
                                        <NavLink
                                          to={`/${item.page_name}`}
                                          style={{
                                            textTransform: "capitalize",
                                          }}
                                          onClick={() => {setToggle(!toggle)
                                            setMenuToggle(false)
                                          setToggleMenu(false)}}
                                        >
                                          <span className="span-in-header">
                                            view {item.menu_name}
                                          </span>{" "}
                                        </NavLink>
                                      </li>

                                      {item.Submenu_name.map(
                                        (subitem, index) => {
                                          return (
                                            <li
                                              className={`${subitem.Submenu_name_class}`}
                                              style={{
                                                textTransform: "capitalize",
                                              }}
                                            >
                                              {subitem.submenu_name}
                                              {toggle ? (
                                                <MdOutlineKeyboardArrowDown />
                                              ) : (
                                                ""
                                              )}
                                            </li>
                                          );
                                        }
                                      )}
                                    </ul>
                                    <h5 className="bottom">
                                      <NavLink
                                        to={`/${item.page_name}`}
                                        onClick={() => {setToggle(!toggle)
                                          setMenuToggle(false)
                                          setToggleMenu(false)}}
                                      >
                                        View All {item.menu_name}
                                      </NavLink>
                                    </h5>
                                  </div>
                                  <div className="col-6 middle-section">
                                    {item.Submenu_name.map((item, index) => {
                                      return (
                                        <>
                                          <div
                                            className={`item ${item.Submenu_name_class}`}
                                            style={{}}
                                          >
                                            {
                                              <h6
                                                style={{
                                                  color: "rgb(104, 66, 134)",
                                                  fontWeight: "bold",
                                                }}
                                              >
                                                {item.submenu_name}{" "}
                                              </h6>
                                            }
                                            <div className="">
                                              <ul className="d-flex flex-wrap submenu">
                                                {item.Submenuitem_name.map(
                                                  (subitem, index) => {
                                                    return (
                                                      item.menu_name_class !==
                                                        "Crowns-&-bridges" && (
                                                        <>
                                                          <li>
                                                            <NavLink
                                                              to={`/${subitem.page_name}`}
                                                              style={{
                                                                textTransform:
                                                                  "capitalize",
                                                              }}
                                                              onClick={() =>{
                                                                setToggle(
                                                                  !toggle
                                                                )
                                                                setMenuToggle(false)
                                                                setToggleMenu(false)
                                                              }}
                                                            >
                                                              {
                                                                subitem.submenu_item_name
                                                              }
                                                            </NavLink>
                                                          </li>
                                                        </>
                                                      )
                                                    );
                                                  }
                                                )}
                                              </ul>
                                            </div>
                                          </div>
                                        </>
                                      );
                                    })}
                                  </div>
                                  <div className="col-3 pl-0">
                                    <div className="dropdown-advert-outer">
                                      <div className="dropdown-advert mb-5">
                                        {item.Submenu_name.map(
                                          (item, index) => {
                                            return (
                                              <>
                                                <div
                                                  className={`dp-advertising ${item.Submenu_name_class}`}
                                                  style={{}}
                                                >
                                              
                                                  <div className="inner">
                                                    <div className="image-contain">
                                                      <img
                                                        src="https://trustedclinics.com/images/menu/new-menu/implants-banner2.jpg"
                                                        alt="Dental "
                                                      />
                                                    </div>
                                                    <div className="text">
                                                      <h4>
                                                        {item.submenu_name}
                                                      </h4>
                                                      <p>
                                                        The permanent solution
                                                        to replacing missing
                                                        teeth.
                                                      </p>
                                                      <NavLink
                                                        to={"/"}
                                                        className="btn btn-md btn-rounded btn-gold"
                                                      >
                                                        Find out more
                                                      </NavLink>
                                                    </div>
                                                  </div>
                                                </div>
                                              </>
                                            );
                                          }
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                      </>
                    );
                  })}
                  </div>
                }


                {/* desktop image code and button patient login */}

                  {userData?.length === 0 ? (
                <div
                  className=" login-signup-page"
                  style={{ marginRight: "-14px", position:'relative',top:'-10px'}}
                >
                  {/* <NavLink    to={'/Web_Ifreame'} style={{ padding: '10px 14px',color: '#000' }} className='login-text'>  Login </NavLink> */}

                  <NavLink
                    to={"/patient-app"}
                    style={{
                      color: "#fff",
                      backgroundColor: "#d4a54e",
                      border: "none",
                      outline: "none",
                      boxShadow:
                        " 0 0 5px rgb(0 0 0 / 20%), inset 0 -3px 5px 2px rgb(192 141 47 / 80%), inset 0 3px 5px 0 rgb(232 207 160 / 80%)",
                      margin: "0px 0 10px 15px",
                      padding: "10px 11px",
                      borderRadius: "5px",
                      // marginTop:'100px'
                    }}
                  >
                  Patient Login
                  </NavLink>
                </div>
              ) : (
                <>
                  <div className=" login-signup-page">
                  <div className="" style={{position:'relative',top:'-10px',left:'30px'}}>
                    <img
                      src={
                        (userData?.image && userData?.image !== null && userData?.image !== 'null') ?
                        userData?.image :"https://en.gravatar.com/avatar/f9c8e7a2311313de7b27e8b42cf885a5?d=https%3A%2F%2Fdashboard.render.com%2Fdefault_gravatar.png&r=g&s=76"
                        // "https://trustedclinics.co.uk/backend/public/admin/header_footer_logo/home2-1683796209.png"
                      }
                      style={{
                        padding: "4px",
                        // paddingLeft:'8px',
                        // paddingRight:'8px',
                        height: "36px",
                        borderRadius: "50%",
                        width: "36px",
                        // margin: "auto",
                        cursor: "pointer",
                        border: "1px solid gray",
                        // margin: "0px 0 10px 15px",
                      }}
                      onClick={() => setToggleMenu(!toggleMenu)}
                      // onMouseEnter={()=>{
                      //   setToggleMenu(true)
                      // }}
                    />
                    </div>
                    {/* <span>{userData?.name}</span> */}
                    {toggleMenu && (
                      <div className="" 
                      //  onMouseEnter={()=>{
                      //   setToggleMenu(true)
                      //   }}
                      //   onMouseLeave={()=>{
                      //     setToggleMenu(false)
                      //   }}
                      style={{ position: "relative" }}>
                        <ul
                          className="profile__men card"
                          style={{
                            listStyle: "none",
                            backgroundColor: "white",
                            padding: "10px",
                            border: "0.5px solid gray",
                            position: "absolute",
                            top: "-5px",
                            right: "-50px",
                            zIndex: 1,
                            cursor: "pointer",
                            width: "150px",
                            textAlign: "center",
                          }}
                        >
                           {(userData.role_status===1)&&
                        <li className="profile__item pt-2"  
                            // onClick={() => setToggleMenu1(!toggleMenu1)}
                            >    <NavLink to={"/doctor-app"} >App Home</NavLink></li>
                        }
                        {(userData.role_status===2)&&
                          <li className="profile__item pt-2"  
                            // onClick={() => setToggleMenu1(!toggleMenu1)}
                            >  <NavLink to={"/patient-app"} >App Home </NavLink></li>
                        }
                        {(userData.role_status===3)&&
                           <li className="profile__item pt-2"  
                            // onClick={() => setToggleMenu1(!toggleMenu1)}
                            >  <NavLink to={"/lab-app"} >App Home </NavLink></li>
                        }
                        {(userData.role_status===5)&&
                           <li className="profile__item pt-2"  
                            // onClick={() => setToggleMenu1(!toggleMenu1)}
                            >  <NavLink to={"/clinic-app"} >App Home </NavLink></li>
                        }
                         <li className="profile__item pt-2 pb-1"   onClick={handleClick}>Logout</li>
                        </ul>
                      </div>
                    )}
                  </div>
                </>
              )}
              </ul>
            </div>
          </div>
          <Outlet />
        </nav>
      </header>
    </Fragment>
  );
};
export default Header;


// const header=()=>{
//   {userData?.length === 0 ? (
//     <div
//       className=" login-signup-page"
//       style={{ marginRight: "-14px" }}
//     >
//       {/* <NavLink    to={'/Web_Ifreame'} style={{ padding: '10px 14px',color: '#000' }} className='login-text'>  Login </NavLink> */}

//       <NavLink
//         to={"/Web_Ifreame"}
//         style={{
//           color: "#fff",
//           backgroundColor: "#d4a54e",
//           border: "none",
//           outline: "none",
//           boxShadow:
//             " 0 0 5px rgb(0 0 0 / 20%), inset 0 -3px 5px 2px rgb(192 141 47 / 80%), inset 0 3px 5px 0 rgb(232 207 160 / 80%)",
//           margin: "12px 0 10px 15px",
//           padding: "10px 11px",
//           borderRadius: "5px",
//         }}
//       >
//       Patient Login
//       </NavLink>
//     </div>
//   ) : (
//     <>
//       <div className=" login-signup-page">
//         <img
//           src={
//             (userData?.image && userData?.image !== null && userData?.image !== 'null') ?
//             userData?.image :"https://trustedclinics.co.uk/backend/public/admin/header_footer_logo/home2-1683796209.png"
//           }
//           style={{
//             padding: "8px",
//             // paddingLeft:'8px',
//             // paddingRight:'8px',
//             height: "46px",
//             borderRadius: "50%",
//             width: "46px",
//             margin: "auto",
//             cursor: "pointer",
//             border: "2px solid gray",
//           }}
//           onClick={() => setToggleMenu(!toggleMenu)}
//         />
//         {/* <span>{userData?.name}</span> */}
//         {toggleMenu && (
//           <div className="" style={{ position: "relative" }}>
//             <ul
//               className="profile__men card"
//               style={{
//                 listStyle: "none",
//                 backgroundColor: "white",
//                 padding: "10px",
//                 border: "0.5px solid gray",
//                 position: "absolute",
//                 top: "-5px",
//                 right: "-50px",
//                 zIndex: 1,
//                 cursor: "pointer",
//                 width: "150px",
//                 textAlign: "center",
//               }}
//             >
//                {(userData.role_status===1)&&
//             <li className="profile__item pt-2"  
//                 // onClick={() => setToggleMenu1(!toggleMenu1)}
//                 >    <NavLink to={"/Web_Ifreame_doctorhome"} >App Home</NavLink></li>
//             }
//             {(userData.role_status===2)&&
//               <li className="profile__item pt-2"  
//                 // onClick={() => setToggleMenu1(!toggleMenu1)}
//                 >  <NavLink to={"/Web_Ifreame_patienthome"} >App Home </NavLink></li>
//             }
//             {(userData.role_status===3)&&
//       <li className="profile__item pt-2"  
//                 // onClick={() => setToggleMenu1(!toggleMenu1)}
//                 >  <NavLink to={"/Web_Ifreame_patienthome"} >App Home </NavLink></li>
//             }
//              <li className="profile__item pt-2 pb-1"   onClick={handleClick}>Logout</li>
//             </ul>
//           </div>
//         )}
//       </div>
//     </>
//   )}
// }
