import { Markup } from 'interweave'
import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'

function SectionFourtyFive(props) {
  const [data,setdata]= useState(props.data)
  console.log('data')
 console.log(data)
  useEffect(() => {
    setdata(props.data)
  }, [props.data])
 
    console.log('section 44')
    console.log(data)
  return (

<>
{/* <div className="section news-nav-section bg-white" >
    <div className="container">
      <div className="row">
      {
        (data?.section_44_heading1 || data?.section_44_detail1) &&
        <div className="col-md-4 min-h-full">
          <a
            href="https://trustedclinics.com/dental/articles/advice-on-getting-dental-braces-as-adults/"
            className="d-flex card card-body bg-linear-white-grey card-prev-entry h-100"
          >
            <h3>{data?.section_44_heading1}</h3>
            <p>
              {data?.section_44_detail1}
            </p>
          </a>
        </div>
      }
    
     {
        (data?.section_44_headingimage2 || data?.section_44_heading2 || data?.section_44_button) &&
        <div className="col-md-4">
          <div className="card card-news-book-consult card-body bg-linear-white-grey text-center h-100">
            {
                (data?.section_44_headingimage2) &&
                <img
                src={data?.section_44_headingimage2}
                alt="Trusted Clinics"
                className="img-fluid mb-3 smaller"
                />
            }
            
            <p>{data?.section_44_heading2}</p>
            {
                (data?.section_44_button) &&
                <a
                href="https://trustedclinics.com/dental/price-comparison/"
                className="btn btn-sm btn-outline-purple-transparent btn-rounded px-4"
                >
                {data?.section_44_button}
                </a>
            }
            
          </div>
        </div>
     }
       
    {
        (data?.section_44_heading3 || data?.section_44_detail3) &&
        <div className="col-md-4 min-h-full">
          <a
            href="https://trustedclinics.com/dental/articles/the-pros-and-cons-of-lingual-braces/"
            className="d-flex card card-body bg-linear-white-grey card-next-entry h-100"
          >
            <h3>{data?.section_44_heading3}</h3>
            <p>
             {data?.section_44_detail3}
            </p>
          </a>
        </div>
    }
        
      </div>
    </div>
  </div> */}
  <div className="section range-section section-29226 bg-off-white" style={{background:data?.section_45_color || ''}}>
    <div className="container">
      <div className="row">
        <div className="col-xl-8 offset-xl-2 col-lg-10 offset-lg-1 pb-0 text-center mb-5">
          <h2 className="mb-4">{data?.section_45_title}</h2>
          <div>
            <p>
              Quick Straight Teeth can straighten teeth quickly with their fixed
              brace and clear aligners.
            </p>
          </div>
        </div>
        <div className="col-12">
          <div className="row text-center mb-4 d-flex justify-content-center mt-5">
            <div className="col-sm-12 col-md-6 col-lg-4 pb-3 px-sm-3 mt-70">
              <div className="card card-treatment-range custom h-100">
                <div
                  className="card-body-bg"
                  style={{
                    backgroundImage:
                      "url(https://cloud1.coe-website.securestaging.co.uk/images/treatment-range/Crowded_braces_124.png?mtime=20200703111043&focal=none)",
                    backgroundPosition: "bottom left",
                    backgroundSize: 170
                  }}
                >
                  <div className="card-body card-body-teatments">
                    <a href={data?.sec_45_link1}>
                    <h3>{data?.section_45_title1}</h3>
                    <div className="desc desc-treatment">
                      <p>
                       {data?.section_45_detail1}
                      </p>
                    </div>
                    <span className="total-price-teatments">
                      From
                      <br />
                      <b>{data?.section_3_price1}</b>
                      <br />
                      Per Month
                    </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-4 pb-3 px-sm-3 mt-70">
              <div className="card card-treatment-range custom h-100">
                <div
                  className="card-body-bg"
                  style={{
                    backgroundImage:
                      "url(https://cloud1.coe-website.securestaging.co.uk/images/treatment-range/Crowded_braces_124.png?mtime=20200703111043&focal=none)",
                    backgroundPosition: "bottom right",
                    backgroundSize: 170
                  }}
                >
                  <div className="card-body card-body-teatments">
                    <a href={data?.sec_45_link2}>
                    <h3>{data?.section_45_title2}</h3>
                    <div className="desc desc-treatment">
                      <p>
                      {data?.section_45_detail2}
                      </p>
                    </div>
                    <span className="total-price-teatments">
                      From
                      <br />
                      <b>{data?.section_3_price1}</b>
                      <br />
                      Per Month
                    </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-8 offset-xl-2 col-lg-10 offset-lg-1 text-center">
          <a
            href={data?.sec_45_button_link}
            className="btn btn-lg btn-rounded btn-pink book-consult-button text-white"
          >
            {data?.section_45_button}
          </a>
        </div>
      </div>
    </div>
  </div>
</>
  )
}

export default SectionFourtyFive