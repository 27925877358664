import { Markup } from 'interweave'
import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'

function SectionThirtySeven(props) {
  const [data,setdata]= useState(props.data)
  console.log('data')
 console.log(data)
  useEffect(() => {
    setdata(props.data)
  }, [props.data])
 
    console.log('section 37')
    console.log(data)

  return (
<>
<div className="section reviews-section section-5297 bg-" style={{background:data?.section_37_color || 'white'}}>
    <div className="container">
      <div className="row">
        <div className="col-xl-8 offset-xl-2 col-lg-10 offset-lg-1 pb-0 text-center">
          <h2 className="mb-4">{data?.section_37_title}</h2>
          <div>
          {
            <Markup content={data?.section_37_description} /> 
          }
            
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12 text-center">
          {/* <p className="text-center">
            <a
              href="https://trustedclinics.com/dental/treatments/invisalign-clear-aligners/reviews/"
              className="text-link"
            >
              Read more Invisalign reviews
              <i className="fas fa-angle-right" />
            </a>
          </p> */}
          <a
            href={data?.sec_37_button_link}
            className="btn btn-md btn-rounded btn-pink book-consult-button text-white"
          >
            {data?.section_37_button}
          </a>
        </div>
      </div>
    </div>
  </div>
</>

  )
}

export default SectionThirtySeven