// import { Markup } from 'interweave'
// import React, { useEffect, useState } from 'react'
// import { NavLink } from 'react-router-dom'

// function SectionTen(props) {
//   const [data,setdata]= useState(props.data)
//   console.log('data')
//  console.log(data)
//   useEffect(() => {
//     setdata(props.data)
//   }, [props.data])
 
//     console.log('sectionone')
//     console.log(data)
  
//   return (
//     <div className="bg-faq" style={{background:data?.section_10_color || ''}}>
//           <div className="container">
//             <h2 className="text-center mb-5">{data?.section_ten_title}</h2>
//             <div className="row text-center my-3">
//             {
//               (data?.section_ten_image1 ||  data?.section_ten_data1 || data?.section_ten_date1 || data?.section_ten_category1 || data?.section_ten_button1) &&
//               <div className="col-sm-6 col-lg-4 mb-3 mt-md-60 pr-2 pr-lg-3">
//                 <div  className="card">
//                 <a  className="card-image">
//                                 <img src={data?.section_ten_image1}  alt={data?.section_ten_image1_alt}  style={{ width: '575px',height: '350px'}} className="img-fluid" />
//                             </a>
//                   <div className="card-body">
//                      { <Markup content={ data?.section_ten_data1} /> 
                      
//                       }
//                     <hr />
//                     <p>Date :{data?.section_ten_date1}</p>
//                     <p>Category :{data?.section_ten_category1}</p>
//                     <div className="text-center mt-2">
//                       <button type="submit" className="btn btn-md btn-rounded btn-pink book-consult-button-inline">
//                         <a href={data?.sec_10_button_link1} style={{color:'#ffffff'}}>
//                           {data?.section_ten_button1?.substring(0,25)}
//                         </a>
                      
//                       </button>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             }
              
//             {
              
//               (data?.section_ten_image2 ||  data?.section_ten_data2 || data?.section_ten_date2 || data?.section_ten_category2 || data?.section_ten_button2) &&
//               <div className="col-sm-6 col-lg-4 mb-3 mt-md-60 pr-2 pr-lg-3">
//                 <div  className="card">
//                 <a  className="card-image">
//                                 <img src={data?.section_ten_image2} alt={data?.section_ten_image2_alt} style={{ width: '575px',height: '350px'}} className="img-fluid" />
//                             </a>
//                   <div className="card-body">
//                  {   <Markup content={ data?.section_ten_data2} /> }
//                      <hr/>
//                      <p>Date :{data?.section_ten_date2}</p>
//                     <p>Category :{data?.section_ten_category2}</p>
//                     <div className="text-center mt-2">
//                       <button type="submit" className="btn btn-md btn-rounded btn-pink book-consult-button-inline">
//                         <a href={data?.sec_10_button_link2} style={{color:'#ffffff'}}>
//                         {data?.section_ten_button2?.substring(0,25)}
//                         </a>
                        
//                       </button>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             }
              
//             </div>
//           </div>
//     </div>
//   )
// }

// export default SectionTen

import { Markup } from 'interweave'
import React, { useEffect, useState } from 'react'

function SectionTen(props) {
  const [data, setData] = useState(props.data)

  useEffect(() => {
    setData(props.data)
  }, [props.data])

  const truncateDescription = (content, limit) => {
    const div = document.createElement("div")
    div.innerHTML = content
    const text = div.textContent || div.innerText || ""
    return text.length > limit ? text.substring(0, limit) + "..." : text
  }

  return (
    <div className="bg-faq" style={{ background: data?.section_10_color || '' }}>
      <div className="container">
        <h2 className="text-center mb-5">{data?.section_ten_title}</h2>
        <div className="row text-center my-3">
          {(data?.section_ten_image1 || data?.section_ten_data1 || data?.section_ten_date1 || data?.section_ten_category1 || data?.section_ten_button1) &&
            <div className="col-sm-6 col-lg-4 mb-3 mt-md-60 pr-2 pr-lg-3">
              <div className="card">
                <a className="card-image">
                  <img src={data?.section_ten_image1} alt={data?.section_ten_image1_alt} style={{ width: '575px', height: '350px' }} className="img-fluid" />
                </a>
                <div className="card-body">
                  <Markup content={truncateDescription(data?.section_ten_data1, 100)} />
                  <hr />
                  <p>Date: {data?.section_ten_date1}</p>
                  <p>Category: {data?.section_ten_category1}</p>
                  <div className="text-center mt-2">
                    <button type="submit" className="btn btn-md btn-rounded btn-pink book-consult-button-inline">
                      <a href={data?.sec_10_button_link1} style={{ color: '#ffffff' }}>
                        {data?.section_ten_button1?.substring(0, 25)}
                      </a>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          }

          {(data?.section_ten_image2 || data?.section_ten_data2 || data?.section_ten_date2 || data?.section_ten_category2 || data?.section_ten_button2) &&
            <div className="col-sm-6 col-lg-4 mb-3 mt-md-60 pr-2 pr-lg-3">
              <div className="card">
                <a className="card-image">
                  <img src={data?.section_ten_image2} alt={data?.section_ten_image2_alt} style={{ width: '575px', height: '350px' }} className="img-fluid" />
                </a>
                <div className="card-body">
                  <Markup content={truncateDescription(data?.section_ten_data2, 100)} />
                  <hr />
                  <p>Date: {data?.section_ten_date2}</p>
                  <p>Category: {data?.section_ten_category2}</p>
                  <div className="text-center mt-2">
                    <button type="submit" className="btn btn-md btn-rounded btn-pink book-consult-button-inline">
                      <a href={data?.sec_10_button_link2} style={{ color: '#ffffff' }}>
                        {data?.section_ten_button2?.substring(0, 25)}
                      </a>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          }
        </div>
      </div>
    </div>
  )
}

export default SectionTen
