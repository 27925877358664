import { Markup } from 'interweave'
import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'


function SectionTwentyFive(props) {
  const [data,setdata]= useState(props.data)
  console.log('data')
 console.log(data)
  useEffect(() => {
    setdata(props.data)
  }, [props.data])
 
    console.log('section 25')
    console.log(data)
  return (
<>
<div className="section reviews-section section-4000 bg-white" style={{background:data?.section_25_color || ''}}>
            <div className="container">
              <div className="row">
                <div className="col-xl-8 offset-xl-2 col-lg-10 offset-lg-1 pb-0 text-center">
                  <h2 className="mb-4">{data?.section_25_title}</h2>
                  <div className=" " ><p>{<Markup content={data?.section_25_description} />}</p></div>
                </div>
              </div>
              <div className="row ">
                <div className="col-12 text-center form-css-hidden">
                  <p className="text-center">
                    {/* <a href="https://trustedclinics.com/dental/treatments/dental-implants/reviews/" className="text-link">
                      Read More Dental Implant Reviews
                      <i className="fas fa-angle-right" />
                    </a> */}
                  </p>
                  {
                    (data?.section_25_button) && 
                    <a href={data?.sec_25_button_link} className="btn btn-md btn-rounded btn-pink book-consult-button text-white ">
                    {data?.section_25_button}
                    </a>
                  }
                  
                </div>
              </div>
            </div>
          </div>
</>
  )
}

export default SectionTwentyFive