import { Markup } from 'interweave'
import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'


function SectionTwentyFour(props) {
  const [data,setdata]= useState(props.data)
  console.log('data')
 console.log(data)
  useEffect(() => {
    setdata(props.data)
  }, [props.data])
 
    console.log('section 24')
    console.log(data)
  return (
<>
<div className="section procedure-section section-3996 bg-inverse" style={{background:data?.section_24_color || '#684286'}}>
            <div className="container">
              <div className="row">
                <div className="col-xl-8 offset-xl-2 col-lg-10 offset-lg-1 pb-2 text-center">
                  <h2 className="mb-4">{data?.section_24_title}</h2>
                  <div><p style={{color:'#ffffff'}}>{ <Markup content={data?.section_24_description} className='text-color-for-all-page '/>}</p></div>
                </div>
              </div>
              <div className="row text-center d-flex justify-content-center">
                <div className="col-sm-6 col-lg-4 pb-3 px-sm-3">
                  <div className="simple-block">
                  {
                    (data?.section_24_image1) &&
                    <div className="icon-container mb-3">
                      <img src={data?.section_24_image1 } alt={data?.section_24_image1_alt } style={{maxWidth:'70px'}} />
                    </div>
                  }
                    
                    <h3>{data?.section_24_head1}</h3>
                    <p /><p style={{color:'#ffffff'}} >{data?.section_24_detail1}</p><p />
                  </div>
                </div>
                <div className="col-sm-6 col-lg-4 pb-3 px-sm-3">
                  <div className="simple-block">
                  {
                    (data?.section_24_image2) && 
                    <div className="icon-container mb-3">
                      <img src={data?.section_24_image2 } alt={data?.section_24_image2_alt }  style={{maxWidth:'70px'}} />
                    </div>
                  }
                    
                    <h3>{data?.section_24_head2}</h3>
                    <p /><p style={{color:'#ffffff'}}>{data?.section_24_detail2}</p><p />
                  </div>
                </div>
                <div className="col-sm-6 col-lg-4 pb-3 px-sm-3">
                  <div className="simple-block">
                  {
                    (data?.section_24_image3) && 
                    <div className="icon-container mb-3">
                      <img src={data?.section_24_image3 } alt={data?.section_24_image3_alt } style={{maxWidth:'70px'}}  />
                    </div>
                  }
                    
                    <h3>{data?.section_24_head3}</h3>
                    <p /><p style={{color:'#ffffff'}}>{data?.section_23_detail3}</p><p />
                  </div>
                </div>

                {/* <div className="col-sm-6 col-lg-4 pb-3 px-sm-3">
               
                  <div className="simple-block">
                  {
                  (data?.section_24_image3) &&
                    <div className="icon-container mb-3">
                      <img src={data?.section_24_image3} alt="issue occured" />
                    </div>
                  }
                    
                    <h3>{data?.section_24_head3}</h3>
                    <p /><p style={{color:'#ffffff'}}>{data?.section_24_detail3 }</p><p />
                  </div>
                </div> */}
              </div>
            </div>
          </div>
</>
  )
}

export default SectionTwentyFour