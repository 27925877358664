import { Markup } from 'interweave'
import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'

function SectionSixtyFive(props) {
    const [divId,setDivId] = useState('profile-tab')
  const [data,setdata]= useState(props.data)
  console.log('data')
 console.log(data)
  useEffect(() => {
    setdata(props.data)
  }, [props.data])
  const CheckData=(event)=>{

    setDivId(event.target.id)
   
  }
    console.log('section 65')
    console.log(data)
  return (
<>

    <div className="container tab-btn-css" style={{background:data?.section_65_color || ''}}>
    <ul className="row row-p-5 nav nav-tabs" id="myTab" role="tablist">
      <li className="nav-item col-sm-4 col-md-2 mb-2 mb-md-4">
        <a  

        // className="nav-link btn btn-nav btn-block active"
        className={divId === "profile-tab" ?"nav-link btn btn-nav btn-block active" :"nav-link btn btn-nav btn-block "  }
         id="profile-tab" onClick={CheckData} data-toggle="tab" role="tab" aria-controls="profile" aria-selected="true">Practice Info</a>
      </li>
      <li className="nav-item col-sm-4 col-md-2 mb-2 mb-md-4">
        <a  
        className={divId === "treatments-tab" ?"nav-link btn btn-nav btn-block active" :"nav-link btn btn-nav btn-block "  }
         id="treatments-tab" onClick={CheckData} data-toggle="tab" role="tab" aria-controls="treatments" aria-selected="false">Treatments &amp; Pricing</a>
      </li>
      <li className="nav-item col-sm-4 col-md-2 mb-2 mb-md-4">
        <a  
         className={divId === "team-tab" ? "nav-link btn btn-nav btn-block active" :"nav-link btn btn-nav btn-block "  }
        id="team-tab"  onClick={CheckData} data-toggle="tab" role="tab" aria-controls="team" aria-selected="false">Team</a>
      </li>
      <li className="nav-item col-sm-4 col-md-2 mb-2 mb-md-4">
        <a   className={divId === "gallery-tab" ?"nav-link btn btn-nav btn-block active" :"nav-link btn btn-nav btn-block "  }
        id="gallery-tab" onClick={CheckData} data-toggle="tab" role="tab" aria-controls="gallery" aria-selected="false">Gallery</a>
      </li>
      <li className="nav-item col-sm-4 col-md-2 mb-2 mb-md-4">
        <a   className={divId === "reviews-tab" ?"nav-link btn btn-nav btn-block active" :"nav-link btn btn-nav btn-block "  }
         id="reviews-tab" onClick={CheckData} data-toggle="tab" role="tab" aria-controls="reviews" aria-selected="false">Reviews</a>
      </li>
      <li className="nav-item col-sm-4 col-md-2 mb-4">
        <a   className={divId === "contact-tab" ?"nav-link btn btn-nav btn-block active" :"nav-link btn btn-nav btn-block "  }
         id="contact-tab" onClick={CheckData} data-toggle="tab" role="tab" aria-controls="contact" aria-selected="false">Contact</a>
      </li>
    </ul>
  </div>

  <div className="tab-content" id="myTabContent">
  {divId === "profile-tab" &&  <div className="tab-pane fade active show" id="profile" role="tabpanel" aria-labelledby="profile-tab">
      <div className="bg-grey-bg pt-4 pt-md-5">
        <div className="container text-center">
          <h2 className="mb-3 mb-md-4">Practice Information</h2>
          <div className="maintext-clinics">Located in Hemel Hempstead, Alex Dental provides a full range of dental services for the whole family. They offer the latest technology and treatments for everything from cosmetic procedures to emergency general care.</div>
          <hr className="mb-0 mt-4 mt-md-5" />
        </div>
      </div>
      <div className="bg-grey-bg mt-0 practice-info pt-0" id="contactSection">
        <div id="carouselExampleControls" className="carousel slide" data-ride="carousel">
          <div className="carousel-inner p-space">
            <div className="carousel-item active">
              <div className="container text-center">
                <h3 className=" mb-4 mt-4 pt-0 pt-md-3">Languages</h3>
                <div className="row row-languages mb-2 mb-md-3 justify-content-center">
                  <div className="col-6 col-md-4 col-lg-3 col-xl-3 mb-3">
                    <div className="card card-body bg-white h-100 align-items-center">
                      <div className="icon-container">
                        <img src="https://trustedclinics.com/images/flags/united-kingdom.png" alt="English" style={{width: '50px',height:'50px'}} />
                      </div>
                      <p>English</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>   
        </div>}

        {divId === "treatments-tab" &&  <div className="tab-pane fade active show" id="treatments" role="tabpanel" aria-labelledby="treatments-tab">
      <div className="bg-grey-bg pt-4 pt-md-5">
        <div className="container text-center">
          <h2 className="mb-3 mb-md-4">Our Treatments &amp; Pricing</h2>
          <div className="maintext-clinics" />
          <hr className="mb-0 mt-4 mt-md-5" />
        </div>
      </div>
      <div className="bg-grey-bg pt-0 pb-4 pb-md-5">
        <div className="container text-center">
        </div>
      </div>
    </div>}

    {divId === "team-tab" &&  <div className="tab-pane fade active show" id="team" role="tabpanel" aria-labelledby="team-tab">
      <div className="bg-grey-bg pt-4 pt-md-5 pb-md-5">
        <div className="container text-center">
          <h2 className="mb-3 mb-md-4">The Alexandra Dental Team</h2>
          <div className="bg-grey-bg pt-4 pt-md-5 pb-md-5">
  <div className="container text-center">
    <h2 className="mb-3 mb-md-4">The  Dental Team</h2>
    <div className="row">
      <div className="col-sm-6 col-md-4 col-lg-3 mb-3">
        <div className="team-member h-100">
          <div className="profile-pic">
            <img src="https://crm.trustedclinics.com/storage/images/users/U66-DrNeehalShaham.jpg.jpeg" className="img-fluid" alt="Neehal Shah"
              style={{
                width:'200px',
                height:'200px',
                borderRadius:'50%',
                marginLeft:'10px'

              }}
            />
          </div>
          <h3 className="name mt-2">Neehal Shah</h3>
          <p className="job-title">Dentist</p>
          <div className="bio">Neehal Shah- Principal Dentist qualified in dentistry from the University of Manchester and 2 years later received his Membership of the Joint Dental Faculties (Royal College of Surgeons). Over the years, he developed a particular interest in dental implants and has since completed his Masters in Dental Implantology from the University of Central Lancashire.</div>                          </div>
      </div>
      <div className="col-sm-6 col-md-4 col-lg-3 mb-3">
        <div className="team-member h-100">
          <div className="profile-pic">
            <img src="https://crm.trustedclinics.com/storage/images/users/U67.jpg" className="img-fluid" alt="Arfeen Aslam"
                            style={{
                width:'200px',
                height:'200px',
                borderRadius:'50%',
                marginLeft:'10px'

              }}
             />
          </div>
          <h3 className="name mt-2">Arfeen Aslam</h3>
          <p className="job-title">Dentist/Implant Surgeon</p>
          <div className="bio" />                          </div>
      </div>
      <div className="col-sm-6 col-md-4 col-lg-3 mb-3">
        <div className="team-member h-100">
          <div className="profile-pic">
            <img src="https://crm.trustedclinics.com/images/user-placeholder.jpg" className="img-fluid" alt="Emma Yates"
                            style={{
                width:'200px',
                height:'200px',
                borderRadius:'50%',
                marginLeft:'10px'

              }} />
          </div>
          <h3 className="name mt-2">Emma Yates</h3>
          <p className="job-title">Treatment Coordinator</p>
          <div className="bio" />                          </div>
      </div>
    </div>
  </div>
</div>
        </div>
      </div>
    </div>}

    {divId === "gallery-tab" &&   <div className="tab-pane fade active show" id="gallery" role="tabpanel" aria-labelledby="gallery-tab">
      <div className="bg-grey-bg pt-4 pt-md-5 pb-4 pb-md-5">
        <div className="container text-center">
          <h2 className="mb-4">Why Choose Alexandra Dental?</h2>
          <div className="alert alert-info text-center">
            <h3 className="pt-4">Coming soon</h3>
            <p>We'll be uploading new pictures of out patients soon.</p>
          </div>
        </div>
      </div>
    </div>}

    {divId === "reviews-tab" &&  <div className="tab-pane fade active show" id="reviews" role="tabpanel" aria-labelledby="reviews-tab">
      <div className="bg-grey-bg pt-4 pt-md-5 pb-4 pb-md-5">
        <div className="container text-center">
          <h2 className="mb-4">Reviews</h2>
          <div className="alert alert-info text-center">
            <h3 className="pt-4">Coming soon</h3>
            <p>We'll be syndicating all of our patient reviews soon.</p>
          </div>
        </div>
      </div>
    </div>}

    {divId === "contact-tab" && <div className="tab-pane fade active show" id="contact" role="tabpanel" aria-labelledby="contact-tab">
      <div className="bg-grey-bg pt-4 pt-md-5 pb-5 clinic-contact-info">
        <div className="container">
          <div className="row">
            <div className="col-md-4 contact-details-clinics">
              <h2 className="name">{data
              ?.section_65_title}</h2>
              <a href="tel:01442 256335" className="tel">{data?.section_65_phone}</a>
              <p className="address">{data?.section_65_address}</p>
              <h3>{data?.section_65_opening_time}</h3>
              <ul className="list-unstyled hours">
                <li>{data?.section_65_heading1}</li>
                <li>{data?.section_65_heading2}</li>
                <li>{data?.section_65_heading3}</li>
                <li>{data?.section_65_heading4}</li>
                <li>{data?.section_65_heading5}</li>
                <li>{data?.section_65_heading6}</li>
                <li>{data?.section_65_heading7}</li>
              </ul>
            </div>
            <div className="col-md-8">
              <div className="card card-map" id="clinicMap" style={{position: 'relative', overflow: 'hidden'}}>
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2469.8835526153157!2d-0.47186448394596664!3d51.753452879676345!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487641597e41ad5b%3A0x8798e641e4adad7f!2s98%20Alexandra%20Rd%2C%20Hemel%20Hempstead%20HP2%204AG%2C%20UK!5e0!3m2!1sen!2sin!4v1681123899848!5m2!1sen!2sin" width="100%" height={340} style={{border: 0}} allowFullScreen loading="lazy" referrerPolicy="no-referrer-when-downgrade" />
              </div>
            </div>
          </div>
        </div>
        <div className="modal fade" id="remoteClinicModal" tabIndex={-1} role="dialog" aria-labelledby="remoteClinicModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-xl">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="remoteClinicModalLabel" />
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <iframe src frameBorder={0} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>}
  </div>
  </>
  )
}

export default SectionSixtyFive