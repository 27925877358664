// import axios from 'axios';
// import { useEffect, useState } from 'react';
// import Header from '../Pages/Header&Footer/Header';
// import Footer from '../Pages/Header&Footer/Footer';

// function GetUserLocation() {
//   const [currLocation, setCurrLocation] = useState({});
//   const [currLocationJs, setCurrLocationJs] = useState({});
//   useEffect(() => {
//     // getLocation();
//     // getLocationJs();
//   }, []);

//   const getLocation = async () => {
//     const location = await axios.get("https://ipapi.co/json");
//     setCurrLocation(location.data);
//     console.log(location)
//   };

//   const getLocationJs = () => {
//     navigator.geolocation.getCurrentPosition((position) => {
//       console.log(position);
//       const { latitude, longitude } = position.coords;
//       setCurrLocationJs({ latitude, longitude });
//     });
//   };

//   return (
//     <>
//     <Header/>
//     <div>

//         <div style={{textAlign:'center', marginTop:'25px'}}> 
        


//       <h1>Current Location</h1>
//       <p>Latitude: {currLocation.latitude}</p>
//       <p>Longitude: {currLocation.longitude}</p>
//       <p>City: {currLocation.city}</p>
//       <p>State: {currLocation.region}</p>
//       <p>Country Capital : {currLocation.country_capital}</p>
//       <p>Country : {currLocation.country_name}</p>

//       <button onClick={getLocation} style={{background:'#4a4a4a',width:'20%',height:'40px'}} >Get Location</button>
//     </div>
//     </div>
//     <Footer />
//     </>
//   );
// }

// export default GetUserLocation;


import axios from 'axios';
import { useEffect, useState } from 'react';
import Header from '../Pages/Header&Footer/Header';
import Footer from '../Pages/Header&Footer/Footer';

function GetUserLocation() {
  const [currLocation, setCurrLocation] = useState({});
  const [currLocationJs, setCurrLocationJs] = useState({});
  useEffect(() => {
    // getLocation();
    // getLocationJs();
  }, []);

  const getLocation = async () => {
    const location = await axios.get("https://ipapi.co/json");
    setCurrLocation(location.data);
    console.log(location)
  };

  const getLocationJs = () => {
    navigator.geolocation.getCurrentPosition((position) => {
      console.log(position);
      const { latitude, longitude } = position.coords;
      setCurrLocationJs({ latitude, longitude });
    });
  };

  return (
    <>
    <Header/>
      <div className="container d-flex flex-column justify-content-center align-items-center" style={{ minHeight: '80vh' }}>
        <div className="text-center">
          <h1>Current Location</h1>
          <p>Latitude: {currLocation.latitude}</p>
          <p>Longitude: {currLocation.longitude}</p>
          <p>City: {currLocation.city}</p>
          <p>State: {currLocation.region}</p>
          <p>Country Capital: {currLocation.country_capital}</p>
          <p>Country: {currLocation.country_name}</p>
          <button onClick={getLocation} className="btn btn-lg btn-rounded btn-pink book-consult-button m-auto home-desktop d-table text-white">Get Location</button>
        </div>
      </div>
    <Footer />
    </>
  );
}

export default GetUserLocation;
