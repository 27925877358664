import { Markup } from 'interweave'
import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'

function SectionSixtyEight(props) {
  const [data,setdata]= useState(props.data)
  console.log('data')
 console.log(data)
  useEffect(() => {
    setdata(props.data)
  }, [props.data])
 
    console.log('section 68')
    console.log(data)
  
  return (

    <div className="tab-content treatment-sections" style={{background:data?.section_68_color || ''}}>

<div className="tab-pane fade active show" id="implants" role="tabpanel" aria-labelledby="implants-tab">
          <div className="section bg-linear-inverse" style={{background:'#684286'}}>
            <div className="section-background" data-style="background-image:url(https://cloud1.coe-website.securestaging.co.uk/images/backgrounds/purple/implant-img1.png?mtime=20200514033318&focal=none);background-position:right bottom;" style={{backgroundImage: 'url("https://cloud1.coe-website.securestaging.co.uk/images/backgrounds/purple/implant-img1.png?mtime=20200514033318&focal=none")', backgroundPosition: 'right bottom'}}>
              <div className="container">
                <div className="row">
                  <div className="col-xl-8 offset-xl-2 col-lg-10 offset-lg-1 text-center">
                    <h2 className="mb-4 text-white">{data?.section_68_heading1}</h2>
                    <div>
                        {/* <p><a href="https://trustedclinics.com/dental/treatments/dental-implants/">Dental implants</a> are the most common solution for severely damaged or missing teeth. Depending on your requirements, there are several options available for restoring your
                         smile with implants. For those with one or more missing teeth in different areas of the mouth, individual implants
                          will be used to replace these teeth. For several missing teeth in the same area of the mouth, there is the option of an implant supported
                           bridge or a permanent implant retained denture to effectively replace the group of missing teeth. If you require a full arch (jaw) of replacement teeth, 
                           the Teeth in a Day procedure may be best suited as it allows for your new teeth to be fitted on the same day as surgery, removing the need for frequent dental 
                           appointments.</p> */}
                           <Markup content={data?.section_68_description1} className='review-div-center-span' />
                           </div>
                    <a href={data?.section_68_button_link1} type="button" className="btn btn-lg btn-rounded btn-gold mt-3 text-white">
                      {data?.section_68_button1?.substring(0,30)}                                              </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="section bg-white">
            <div className="section-background" data-style="background-image:url(https://cloud1.coe-website.securestaging.co.uk/images/backgrounds/implant-img2.png?mtime=20200514033312&focal=none);background-position:left bottom;" style={{backgroundImage: 'url("https://cloud1.coe-website.securestaging.co.uk/images/backgrounds/implant-img2.png?mtime=20200514033312&focal=none")', backgroundPosition: 'left bottom'}}>
              <div className="container">
                <div className="row">
                  <div className="col-xl-8 offset-xl-2 col-lg-10 offset-lg-1 text-center">
                    <h2 className="mb-4">{data?.section_68_heading2}</h2>
                    <div>
                        {/* <p>If you have a tooth that is either damaged or missing, a <a href="https://trustedclinics.com/dental/treatments/dental-implants/single-tooth-dental-implant/">single implant</a> is the treatment that will restore your smile with minimal disruptiveness. Titanium implants are proven to have no negative effect on the body and allow the jawbone to bond successfully to ensure a smooth healing process.</p> */}
                        <Markup content={data?.section_68_description2}/> </div>
                        <a href={data?.section_68_button_link2} type="button" className="btn btn-lg btn-rounded btn-gold mt-3 text-white">
                      {data?.section_68_button2?.substring(0,30)}                                              </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="section bg-off-white">
            <div className="section-background" data-style="background-image:url(https://cloud1.coe-website.securestaging.co.uk/images/backgrounds/implant-img3.png?mtime=20200514033312&focal=none);background-position:right bottom;" style={{backgroundImage: 'url("https://cloud1.coe-website.securestaging.co.uk/images/backgrounds/implant-img3.png?mtime=20200514033312&focal=none")', backgroundPosition: 'right bottom'}}>
              <div className="container">
                <div className="row">
                  <div className="col-xl-8 offset-xl-2 col-lg-10 offset-lg-1 text-center">
                    <h2 className="mb-4">{data?.section_68_heading3}</h2>
                    <div>
                        {/* <p>For those who require more than 2 individual dental implants, the <a href="https://trustedclinics.com/dental/treatments/dental-implants/multiple-dental-implants/">multiple dental implant</a> option may be the most suitable. Each titanium implant will be placed where a previous tooth existed until you once again have a full set of teeth.</p> */}
                        <Markup content={data?.section_68_description3}/> </div>
                        <a href={data?.section_68_button_link3} type="button" className="btn btn-lg btn-rounded btn-gold mt-3 text-white">
                      {data?.section_68_button3?.substring(0,30)}   </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="section bg-white">
            <div className="section-background" data-style="background-image:url(https://cloud1.coe-website.securestaging.co.uk/images/backgrounds/implant-img4.png?mtime=20200514033312&focal=none);background-position:left top;" style={{backgroundImage: 'url("https://cloud1.coe-website.securestaging.co.uk/images/backgrounds/implant-img4.png?mtime=20200514033312&focal=none")', backgroundPosition: 'left top'}}>
              <div className="container">
                <div className="row">
                  <div className="col-xl-8 offset-xl-2 col-lg-10 offset-lg-1 text-center">
                    <h2 className="mb-4">{data?.section_68_heading4}</h2>
                    <div>
                        {/* <p>Far from the traditional removable dentures of the past, a <a href="https://trustedclinics.com/dental/treatments/dental-implants/implant-retained-denture/">permanent implant retained denture</a> requires no sticky adhesives or pastes to hold it in place. The denture is fixed in the mouth by titanium implants and provides a much more secure and comfortable solution to missing teeth.</p> */}
                        <Markup content={data?.section_68_description4}/>  </div>
                        <a href={data?.section_68_button_link4} type="button" className="btn btn-lg btn-rounded btn-gold mt-3 text-white">
                      {data?.section_68_button4?.substring(0,30)}                                              </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="section bg-off-white">
            <div className="section-background" data-style="background-image:url(https://cloud1.coe-website.securestaging.co.uk/images/backgrounds/implant-img5.png?mtime=20200514033312&focal=none);background-position:right bottom;" style={{backgroundImage: 'url("https://cloud1.coe-website.securestaging.co.uk/images/backgrounds/implant-img5.png?mtime=20200514033312&focal=none")', backgroundPosition: 'right bottom'}}>
              <div className="container">
                <div className="row">
                  <div className="col-xl-8 offset-xl-2 col-lg-10 offset-lg-1 text-center">
                    <h2 className="mb-4">{data?.section_68_heading5}</h2>
                    <div>
                        {/* <p>If you have several missing or damaged teeth in the same area of the mouth, an <a href="https://trustedclinics.com/dental/treatments/dental-implants/implant-supported-bridge/">implant supported bridge</a> might be most suited for you. Resting on fewer rods than individual multiple implants do, the bridge gives a permanent solution to gaps in the mouth whilst also reducing pressure on each implant by spreading it across the entire bridge.</p> */}
                        <Markup content={data?.section_68_description5}/> </div>
                        <a href={data?.section_68_button_link6} type="button" className="btn btn-lg btn-rounded btn-gold mt-3 text-white">
                      {data?.section_68_button6?.substring(0,30)}                                              </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="section bg-white">
            <div className="section-background" data-style="background-image:url(https://cloud1.coe-website.securestaging.co.uk/images/backgrounds/implant-img6.png?mtime=20200514033312&focal=none);background-position:left top;" style={{backgroundImage: 'url("https://cloud1.coe-website.securestaging.co.uk/images/backgrounds/implant-img6.png?mtime=20200514033312&focal=none")', backgroundPosition: 'left top'}}>
              <div className="container">
                <div className="row">
                  <div className="col-xl-8 offset-xl-2 col-lg-10 offset-lg-1 text-center">
                    <h2 className="mb-4">{data?.section_68_heading6}</h2>
                    <div>
                        {/* <p>For those who require a full arch (jaw) reconstruction, the <a href="https://trustedclinics.com/dental/treatments/dental-implants/teeth-in-a-day/">Teeth in a Day</a> may be the option best suited to you. Dispelling the need for frequent dental appointments, the Teeth in a Day procedure sees you attending surgery and having your entire new set of teeth fitted in the same day.</p> */}
                        <Markup content={data?.section_68_description6}/>  </div>
                        <a href={data?.section_68_button_link6} type="button" className="btn btn-lg btn-rounded btn-gold mt-3 text-white">
                      {data?.section_68_button6?.substring(0,30)}                                              </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="section bg-off-white">
            <div className="section-background" data-style="background-image:url(https://cloud1.coe-website.securestaging.co.uk/images/backgrounds/implant-img7.png?mtime=20200514033312&focal=none);background-position:right bottom;" style={{backgroundImage: 'url("https://cloud1.coe-website.securestaging.co.uk/images/backgrounds/implant-img7.png?mtime=20200514033312&focal=none")', backgroundPosition: 'right bottom'}}>
              <div className="container">
                <div className="row">
                  <div className="col-xl-8 offset-xl-2 col-lg-10 offset-lg-1 text-center">
                    <h2 className="mb-4">{data?.section_68_heading7}</h2>
                    <div>
                        {/* <p>For those with several damaged teeth, having a <a href="https://trustedclinics.com/dental/treatments/dental-implants/full-mouth-reconstruction/">full mouth restoration</a> may be the most suitable option. Treatments for the full mouth reconstruction can include dental implants for missing teeth, crowns and bridges for damaged teeth or veneers for stained or discoloured teeth. Your dentist will assess your individual requirements and create a plan specifically tailored to you. This procedure not only improves the appearance of your smile, but also increases functionality that other treatments such as dentures may not allow for.</p> */}
                        <Markup content={data?.section_68_description7}/>   </div>
                        <a href={data?.section_68_button_link7} type="button" className="btn btn-lg btn-rounded btn-gold mt-3 text-white">
                      {data?.section_68_button7?.substring(0,30)}                                              </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>


  )
}

export default SectionSixtyEight