import { Markup } from 'interweave'
import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'

function SectionFiftySix(props) {
  const [data,setdata]= useState(props.data)
  console.log('data')
 console.log(data)
  useEffect(() => {
    setdata(props.data)
  }, [props.data])
 
    console.log('section 56')
    console.log(data)
  
  return (


<>
<div 
style={{background:data?.section_56_color || ''}}
>
    <div className='container'>
    
    <div className="section bg-white partners" id="Features">
        <div className="container">
          <div className="row">
            <div className="col-xl-8 offset-xl-2 col-lg-10 offset-lg-1 pb-0 text-center">
              <h2 className="mb-4 initial-text-transform">{data?.section_56_title?.substring(0,45)}</h2>
              <div>
                {<Markup content={data?.section_56_description} />}
                {/* <p>Make use of the exclusive Trusted Clinics features to help give your business a boost.</p> */}
              </div>
            </div>
            <div className="col-12">
              <div className="row text-center mt-1 mt-md-3 mb-5 mb-md-4 d-flex justify-content-center">
                <div className="col-md-6 col-lg-4">
                  <img className="icons eligible-img-fluid" src={data?.section_56_image1} alt={data?.section_56_image1_alt} />
                  <h4 className="initial-text-transform">
                    {/* Clinicaaaafv aaaaaaaaaa aaaaaaaaaaaaaaaaa aaaaaaaaaaaaa aaaaaaaaaaaaaaa aaaaaaaaaa aaaaaaaaaaaa aaaaaaaa<br />profile */}
                    {<Markup content={data?.section_56_description1} />}
                  </h4>
                  {/* <p>Make your clinic stand out by having its own clinic profile on the Trusted Clinics site.</p> */}
                  {<Markup content={data?.section_56_left_head2} />}
                 
                </div>
                <div className="col-md-6 col-lg-4">
                  <img className="icons eligible-img-fluid " src={data?.section_56_image2} alt={data?.section_56_image2_alt}  />
                  <h4 className="initial-text-transform">
                  {<Markup content={data?.section_56_head2} />}{/* Exclusive discounts on everyday items for your clinic */}
                  </h4>
                  {/* <p>Trusted Clinics can offer discounted prices on products you use daily in your clinic. From dental implants to clear aligners, Trusted Clinics source the highest-quality items at the best price for your clinic.</p> */}
                  {<Markup content={data?.section_56_description2} />}
                </div>
                <div className="col-md-6 col-lg-4">
                  <img className="icons smaller eligible-img-fluid" src={data?.section_56_image3} alt={data?.section_56_image3_alt}  />
                  <h4 className="initial-text-transform">{<Markup content={data?.section_56_head3} />}</h4>
                  {<Markup content={data?.section_56_description3} />}
                  {/* <p>All calls through Trusted Clinics will be recorded for safety and indemnity purposes. This ensures any calls can be monitored in the event of any issues, to protect both the patient and clinic.</p> */}
                </div>
              </div>
              <div className="row text-center mb-md-4 d-flex justify-content-center">
                <div className="col-md-6 col-lg-4">
                  <img className="icons eligible-img-fluid" src={data?.section_56_image4} alt={data?.section_56_image4_alt}  />
                  <h4 className="initial-text-transform">{<Markup content={data?.section_56_head4} />}</h4>
                  {<Markup content={data?.section_56_description4} />}
                  {/* <p>As part of the Trust Clinics package, you will have access to free monthly courses to ensure your clinic is kept up to date with the latest updates and training available.</p> */}
                </div>
                <div className="col-md-6 col-lg-4">
                  <img className="icons eligible-img-fluid" src={data?.section_56_image5} alt={data?.section_56_image5_alt}  />
                  <h4 className="initial-text-transform">{<Markup content={data?.section_56_head5} />}</h4>
                  {/* <p>With automated SMS and emails, you will be able to market to both new and existing patients.</p> */}
                  {<Markup content={data?.section_56_description5} />}
                </div>
                <div className="col-md-6 col-lg-4">
                  <img className="icons eligible-img-fluid" src={data?.section_56_image6} alt={data?.section_56_image6_alt}  />
                  <h4 className="initial-text-transform">{<Markup content={data?.section_56_head6} />}</h4>
                  {/* <p>You will receive a monthly report clearly illustrating how well your practice is achieving compared to other clinics.</p> */}
                  {<Markup content={data?.section_56_description6} />}
                </div>
              </div>
              <div className="row text-center mb-0 mb-md-0 d-flex justify-content-center">
                <div className="col-md-12 col-lg-4 mb-4 mb-lg-0">
                  <img src={data?.section_56_banner1} className="images-laptop" alt={data?.section_56_image7_alt}  />
                </div>
                <div className="col-md-6 col-lg-4">
                  <img className="icons eligible-img-fluid" src={data?.section_56_image7} alt={data?.section_56_image8_alt}  />
                  <h4 className="initial-text-transform">{<Markup content={data?.section_56_head7} />}</h4>
                  {/* <p>As part of the onboarding process, you will have unique video and media creation to help boost your clinic’s profile.</p> */}
                  {<Markup content={data?.section_56_description7} />}
                </div>
                <div className="col-md-6 col-lg-4">
                  <img className="icons eligible-img-fluid" src={data?.section_56_image8} alt={data?.section_56_image9_alt}  />
                  <h4 className="initial-text-transform">{<Markup content={data?.section_56_head8} />}</h4>{<Markup content={data?.section_56_description8} />}
                  {/* <p>Add a Trusted Clinics widget to your website, allowing you to collect enquiries from your website and manage them through the Trusted Clinics system.</p> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</>
  )
}

export default SectionFiftySix