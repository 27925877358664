import { useEffect } from "react"
import { useLocation } from "react-router-dom"

export  const ScrollToTop = () => {
    const { pathname } = useLocation()
    useEffect(() => {
        // console.log(pathname)
    
        setTimeout(() => {
            window.scrollTo({ top: 0, behavior:'auto'})
        }, 0)
    }, [pathname])
    return null
  }
  