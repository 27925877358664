// import { Markup } from 'interweave'
// import React, { useEffect, useState } from 'react'
// import { NavLink } from 'react-router-dom'


// function SectionTwentySeven(props) {
//   const [data,setdata]= useState(props.data)
//   console.log('data')
//  console.log(data)
//   useEffect(() => {
//     setdata(props.data)
//   }, [props.data])
 
//     console.log('section 27')
//     console.log(data)
//   return (
// <>
// <div className="section find-out-more-section section-4016 bg-white" style={{background:data?.section_27_color || ''}}>
//     <div className="container">
//       <div className="row">
//         <div className="col-xl-8 offset-xl-2 col-lg-10 offset-lg-1 pb-1 text-center">
//           <h2 className="mb-4">{data?.section_27_title}</h2>
//           <div>{<Markup content={data?.section_27_description} /> }</div>
//         </div>
//       </div>
//       <div className="row text-center d-flex justify-content-center hide-score">
//       {
//         (data?.section_27_heading1 || data?.section_27_button1) && 
//         <div className="col-6 col-sm-6 col-lg-4 pb-3 px-lg-3 mb-2 mb-md-1 mb-lg-2 pr-2 pr-lg-3">
//           <div className="card card-body card-find-out-more h-100">
//             <div className="icon-container mb-3 green-stars pt-3">
//               <i className="fas fa-star" /><i className="fas fa-star" /><i className="fas fa-star" /><i className="fas fa-star" /><i className="fas fa-star" />
//             </div>
//             <h3>{data?.section_27_heading1} </h3>
//             {
//               (data?.section_27_button1) &&
//               <a href={data?.section_27_link1} className="btn btn-md btn-rounded btn-purple text-white ">{data?.section_27_button1}</a>
//             }
           
//           </div>
//         </div>
//       }
        
//       {
//         (data?.section_27_heading2 || data?.section_27_button2 || data?.section_27_image1) && 
//         <div className="col-6 col-sm-6 col-lg-4 pb-3 px-lg-3 mb-2 mb-md-1 mb-lg-2 pr-2 pr-lg-3">
//           <div className="card card-body card-find-out-more h-100">
//             <div className="icon-container mb-3 ">
//               <img src={data?.section_27_image1} alt={data?.section_27_image1_alt}  />
//             </div>
//             <h3>{data?.section_27_heading2}</h3>
//             {
//               (data?.section_27_button2) &&
//               <a href={data?.section_27_link2}  className="btn btn-md btn-rounded btn-purple text-white ">{data?.section_27_button2}</a>
//             }
            
//           </div>
//         </div>
//       }

//       {
//         (data?.section_27_heading3 || data?.section_27_button3 || data?.section_27_image2) && 
//         <div className="col-6 col-sm-6 col-lg-4 pb-3 px-lg-3 mb-2 mb-md-1 mb-lg-2 pr-2 pr-lg-3">
//           <div className="card card-body card-find-out-more h-100">
//             <div className="icon-container mb-3 ">
//               <img src={data?.section_27_image2} alt={data?.section_27_image2_alt}  />
//             </div>
//             <h3>{data?.section_27_heading3} </h3>
//             {
//               (data?.section_27_button3) &&
//               <a href={data?.section_27_link3}  className="btn btn-md btn-rounded btn-purple text-white ">{data?.section_27_button3}</a>
//             }
            
//           </div>
//         </div>
//       }
        
//       {
//         (data?.section_27_heading4 || data?.section_27_button4 || data?.section_27_image3) &&
//         <div className="col-6 col-sm-6 col-lg-4 pb-3 px-lg-3 mb-2 mb-md-1 mb-lg-2 pr-2 pr-lg-3">
//           <div className="card card-body card-find-out-more h-100">
//             <div className="icon-container mb-3 ">
//               <img src={data?.section_27_image3} alt={data?.section_27_image3_alt}  />
//             </div>
//             <h3>{data?.section_27_heading4}</h3>
//             {
//               (data?.section_27_button4) &&
//               <a href={data?.section_27_link4}  className="btn btn-md btn-rounded btn-purple text-white  text-white ">{data?.section_27_button4}</a>
//             }
            
//           </div>
//         </div>
//       }
        
//         <div className="col-12 col-sm-12 col-lg-4 pb-3 px-sm-3 mb-2">
//           <div className="card card-body card-find-out-more bg-linear-white-grey h-100">
//             <h3>{data?.section_27_imagetitle5}</h3>
//             <p className="mb-md-3 pt-3">
//               <span className="text-from">From</span>
//               <span className="text-price">{data?.section_27_heading5}</span>
//               <span className="text-per-month">Per Month</span>
//             </p>
//             {
//               (data?.section_27_button5) &&
//               <a href={data?.section_27_link5}  className="btn btn-md btn-rounded btn-pink book-consult-button mt-1 px-2 text-white form-css-hidden">
//               {data?.section_27_button5}
//               </a>
//             }
           
//           </div>
//         </div>
//       </div>
//     </div>
//   </div>
// </>
//   )
// }

// export default SectionTwentySeven

import React, { useEffect, useState } from 'react';
import { Markup } from 'interweave';
import { NavLink } from 'react-router-dom';

function SectionTwentySeven(props) {
  const [data, setData] = useState(props.data);

  useEffect(() => {
    setData(props.data);
  }, [props.data]);

  return (
    <div className="section find-out-more-section section-4016 bg-white" style={{ background: data?.section_27_color || '' }}>
      <div className="container">
        <div className="row">
          <div className="col-xl-8 offset-xl-2 col-lg-10 offset-lg-1 pb-1 text-center">
            <h2 className="mb-4">{data?.section_27_title}</h2>
            <div>
              <Markup content={data?.section_27_description} />
            </div>
          </div>
        </div>

        <div className="row text-center d-flex justify-content-center hide-score">
          {/* Column 1 */}
          {(data?.section_27_heading1 || data?.section_27_button1) && (
            <div className="col-12 col-sm-6 col-lg-4 pb-3 px-lg-3 mb-2 mb-md-1 mb-lg-2 pr-2 pr-lg-3">
              <div className="card card-body card-find-out-more h-100">
                <div className="icon-container mb-3 green-stars pt-3">
                  <i className="fas fa-star" /><i className="fas fa-star" /><i className="fas fa-star" /><i className="fas fa-star" /><i className="fas fa-star" />
                </div>
                <h3>{data?.section_27_heading1}</h3>
                {data?.section_27_button1 && (
                  <a href={data?.section_27_link1} className="btn btn-md btn-rounded btn-purple text-white">{data?.section_27_button1}</a>
                )}
              </div>
            </div>
          )}

          {/* Column 2 */}
          {(data?.section_27_heading2 || data?.section_27_button2 || data?.section_27_image1) && (
            <div className="col-12 col-sm-6 col-lg-4 pb-3 px-lg-3 mb-2 mb-md-1 mb-lg-2 pr-2 pr-lg-3">
              <div className="card card-body card-find-out-more h-100">
                <div className="icon-container mb-3">
                  <img src={data?.section_27_image1} alt={data?.section_27_image1_alt} className="img-fluid" />
                </div>
                <h3>{data?.section_27_heading2}</h3>
                {data?.section_27_button2 && (
                  <a href={data?.section_27_link2} className="btn btn-md btn-rounded btn-purple text-white">{data?.section_27_button2}</a>
                )}
              </div>
            </div>
          )}

          {/* Column 3 */}
          {(data?.section_27_heading3 || data?.section_27_button3 || data?.section_27_image2) && (
            <div className="col-12 col-sm-6 col-lg-4 pb-3 px-lg-3 mb-2 mb-md-1 mb-lg-2 pr-2 pr-lg-3">
              <div className="card card-body card-find-out-more h-100">
                <div className="icon-container mb-3">
                  <img src={data?.section_27_image2} alt={data?.section_27_image2_alt} className="img-fluid" />
                </div>
                <h3>{data?.section_27_heading3}</h3>
                {data?.section_27_button3 && (
                  <a href={data?.section_27_link3} className="btn btn-md btn-rounded btn-purple text-white">{data?.section_27_button3}</a>
                )}
              </div>
            </div>
          )}

          {/* Column 4 */}
          {(data?.section_27_heading4 || data?.section_27_button4 || data?.section_27_image3) && (
            <div className="col-12 col-sm-6 col-lg-4 pb-3 px-lg-3 mb-2 mb-md-1 mb-lg-2 pr-2 pr-lg-3">
              <div className="card card-body card-find-out-more h-100">
                <div className="icon-container mb-3">
                  <img src={data?.section_27_image3} alt={data?.section_27_image3_alt} className="img-fluid" />
                </div>
                <h3>{data?.section_27_heading4}</h3>
                {data?.section_27_button4 && (
                  <a href={data?.section_27_link4} className="btn btn-md btn-rounded btn-purple text-white">{data?.section_27_button4}</a>
                )}
              </div>
            </div>
          )}

          {/* Column 5 */}
          <div className="col-12 col-sm-12 col-lg-4 pb-3 px-sm-3 mb-2">
            <div className="card card-body card-find-out-more bg-linear-white-grey h-100">
              <h3>{data?.section_27_imagetitle5}</h3>
              <p className="mb-md-3 pt-3">
                <span className="text-from">From</span>
                <span className="text-price">{data?.section_27_heading5}</span>
                <span className="text-per-month">Per Month</span>
              </p>
              {data?.section_27_button5 && (
                <a href={data?.section_27_link5} className="btn btn-md btn-rounded btn-pink book-consult-button mt-1 px-2 text-white form-css-hidden">
                  {data?.section_27_button5}
                </a>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SectionTwentySeven;
