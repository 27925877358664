import { Markup } from 'interweave'
import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'

function SectionNineteen(props) {
  const [data,setdata]= useState(props.data)
  console.log('data')
 console.log(data)
  useEffect(() => {
    setdata(props.data)
  }, [props.data])
 
    console.log('section 19')
    console.log(data)
  
  return (
<>
{/* <div className='container'>
<div className="row text-center mt-50 dental-imp " style={{margin:'100px'}} >
     {
      (data?.sec_19_image1 || data?.sec_19_imagedes1 || data?.sec_19_price1) &&
      <div className="col-6 col-sm-6 col-lg-3 mb-3 mt-md-60 pr-2 pr-lg-3 dental1" style={{minWidth:'200px',position:'relative'}} >
            <NavLink
              to={'/'}
              className="card card-treatment h-100 " state={{width:'150px'}}
            >
           
              <div className="card-body" style={{marginTop:'50px'}}>
              {
                (data?.sec_19_image1) &&
                <div className="treatment-icon">
                <img
                  src={data?.sec_19_image1}
                  style={{width:'85px',height:'50px'}}
                  alt="issue occured"
                />
                </div>
              }
               
              
              
              {
                <Markup content={data?.sec_19_imagedes1} /> 
              }
              
              </div>
            
              
              
                <span className="price-from-circle" style={{marginTop:'30px'}}>
                From
                <br />
                <b>{data?.sec_19_price1}</b>
                <br />
                Per Month
              </span>
             
              
           
            </NavLink>
          </div>
     }
          
      {
        (data?.sec_19_image2 || data?.sec_19_imagedes2 || data?.sec_19_price2) &&
        <div className="col-6 col-sm-6 col-lg-3 mb-3 mt-md-60 pr-2 pr-lg-3 dental1" style={{minWidth:'200px',position:'relative'}} >
            <NavLink
              to={'/'}
              className="card card-treatment h-100 " state={{width:'150px'}}
            >
            
              <div className="card-body" style={{marginTop:'50px'}}>
              {
                (data?.sec_19_image2) &&
                <div className="treatment-icon">
                <img
                  src={data?.sec_19_image2}
                  style={{width:'85px',height:'50px'}}
                  alt="issue occured"
                />
                </div>
              }
                
             
              
              {
                <Markup content={data?.sec_19_imagedes2} /> 
              }
              
              </div>
           
            
                <span className="price-from-circle" style={{marginTop:'30px'}}>
                From
                <br />
                <b>{data?.sec_19_price2}</b>
                <br />
                Per Month
              </span>
              
              
           
            </NavLink>
          </div>
      }

      
      {
        (data?.sec_19_image3 || data?.sec_19_imagedes3 || data?.sec_19_price3) &&
        <div className="col-6 col-sm-6 col-lg-3 mb-3 mt-md-60 pr-2 pr-lg-3 dental1" style={{minWidth:'200px',position:'relative'}} >
            <NavLink
              to={'/'}
              className="card card-treatment h-100 " state={{width:'150px'}}
            >
          
              <div className="card-body" style={{marginTop:'50px'}}>
              {
                (data?.sec_19_image3) &&
                <div className="treatment-icon">
                <img
                  src={data?.sec_19_image3}
                  style={{width:'85px',height:'50px'}}
                  alt="issue occured"
                />
                </div>
              }
                
             
              
              {
                <Markup content={data?.sec_19_imagedes3} /> 
              }
              
              </div>
           
              
           
                <span className="price-from-circle" style={{marginTop:'30px'}}>
                From
                <br />
                <b>{data?.sec_19_price3}</b>
                <br />
                Per Month
              </span>
             
              
           
            </NavLink>
          </div>
      }    
       
        
    {
      (data?.sec_19_image4 || data?.sec_19_imagedes4 || data?.sec_19_price4) &&
      <div className="col-6 col-sm-6 col-lg-3 mb-3 mt-md-60 pr-2 pr-lg-3 dental1" style={{minWidth:'200px',position:'relative'}} >
            <NavLink
              to={'/'}
              className="card card-treatment h-100 " state={{width:'150px'}}
            >
   
              <div className="card-body" style={{marginTop:'50px'}}>
              {
                (data?.sec_19_image4) &&
                <div className="treatment-icon">
                <img
                  src={data?.sec_19_image4}
                  style={{width:'85px',height:'50px'}}
                  alt="issue occured"
                />
                </div>
              }
                
              
              
              {
                <Markup content={data?.sec_19_imagedes4} /> 
              }
              
              </div>
           
              
       
                <span className="price-from-circle" style={{marginTop:'30px'}}>
                From
                <br />
                <b>{data?.sec_19_price4}</b>
                <br />
                Per Month
              </span>
              
              
           
            </NavLink>
          </div>
    }
         
       

        
          
        
         
        </div> 
         
        <div className="row text-center dental-imp m-0 p-0">
      {
        (data?.sec_19_imagefooter1) &&
        <div className="col-12 p-sm-0  col-sm-6 col-lg-3 mb-3 pr-2 pr-lg-3 " >
            <NavLink
              to={'/'}              className="card card-treatment h-100"
            >
              <div className="card-body no-icon ">
                <h4>{data?.sec_19_imagefooter1}</h4>
                
              </div>
            </NavLink>
          </div>
      }
          
      {
        (data?.sec_19_imagefooter2) &&
        <div className="col-12 p-sm-0 col-sm-6 col-lg-3 mb-3 pl-2 pl-lg-3 left5">
            <NavLink
              to={'/'}              className="card card-treatment h-100"
            >
              <div className="card-body no-icon">
                <h4>{data?.sec_19_imagefooter2 }</h4>
              </div>
            </NavLink>
         </div>
      }

      {
        (data?.sec_19_imagefooter3) &&
        <div className="col-12 col-sm-6 col-lg-3 mb-3 pr-2 pr-lg-3 right2">
            <NavLink
              to={'/'}              className="card card-treatment h-100"
            >
              <div className="card-body no-icon">
                <h4>{data?.sec_19_imagefooter3}</h4>
              </div>
            </NavLink>
          </div>
      }  
          
      {
        (data?.sec_19_imagefooter4) &&
        <div className="col-12 col-sm-6 col-lg-3 mb-3 pl-2 pl-lg-3 left5">
            <NavLink
              to={'/'}              className="card card-treatment h-100"
            >
              <div className="card-body no-icon">
                <h4>{data?.sec_19_imagefooter4}</h4>
              </div>
            </NavLink>
          </div>
      }  
         
            
        
        
          
        
        
        </div>
        <div className="row mt-4">
        <div className="col-xl-8 offset-xl-2 col-lg-10 offset-lg-1">
                        {/* <div className="intro-mobile">
                        { <Markup content={data?.sec_19_footerdes  } /> }</div> */}
                        {/* <p className="text-center">
                         { <Markup content={data?.sec_19_footerdes} />}</p>
                    </div>
        </div>
        </div>  */}

<div className='container price-set' style={{background:data?.section_19_color || ''}}>

<div className="row text-center mt-50 dental-imp hide-score" >





             {  (data?.sec_19_image1 || data?.sec_19_imagedes1 || data?.sec_19_price1) &&
                <div className="col-6 col-sm-6 col-lg-3 mb-3 mt-md-60 pr-2 pr-lg-3 ">
                  <a href={data?.sec_19_link1}
              
              className="card card-treatment h-100  " 
            >
                    <div className="card-body">
                   
                     {  (data?.sec_19_image1) &&   <div className="treatment-icon">
                      <img 
                     src={data?.sec_19_image1}
                     width={26} height={43}
                     alt={data?.sec_19_image1_alt} />
                      </div>
                      }
                  {
                <Markup content={data?.sec_19_imagedes1} className="teatments-markup" /> 
              }
                    </div>
                    <span className="price-from-circle">
                      From<br />
                      <b>{data?.sec_19_price1}</b><br />
                      Per Month
                    </span>
                    </a>
                </div>
                }

{  (data?.sec_19_image2 || data?.sec_19_imagedes2 || data?.sec_19_price2) &&
                <div className="col-6 col-sm-6 col-lg-3 mb-3 mt-md-60 pr-2 pr-lg-3">
                  <a href={data?.sec_19_link2}
              to={'/'}
              className="card card-treatment h-100 " 
            >
                    <div className="card-body">
                   
                     {  (data?.sec_19_image2) &&   <div className="treatment-icon">
                      <img 
                     src={data?.sec_19_image2}
                     width={69 } height={42} alt={data?.sec_19_image2_alt} />
                      </div>
                      }
                  {
                <Markup content={data?.sec_19_imagedes2} className="teatments-markup"  /> 
              }
                    </div>
                    <span className="price-from-circle">
                      From<br />
                      <b>{data?.sec_19_price2}</b><br />
                      Per Month
                    </span>
                    </a>
                </div>
                }
                    {  (data?.sec_19_image3 || data?.sec_19_imagedes3 || data?.sec_19_price3) &&
                <div className="col-6 col-sm-6 col-lg-3 mb-3 mt-md-60 pr-2 pr-lg-3">
                  < a href={data?.sec_19_link3}
              to={'/'}
              className="card card-treatment h-100 " 
            >
                    <div className="card-body">  
                   
                     {  (data?.sec_19_image3) &&   <div className="treatment-icon">
                      <img 
                     src={data?.sec_19_image3}
                     width={153} height={35} alt={data?.sec_19_image3_alt}  />
                      </div>
                      }
                  {
                <Markup content={data?.sec_19_imagedes3} className="teatments-markup"   /> 
              }
                    </div>
                    <span className="price-from-circle">
                      From<br />
                      <b>{data?.sec_19_price3}</b><br />
                      Per Month
                    </span>
                    </a>
                </div>
                }
                    {  (data?.sec_19_image4 || data?.sec_19_imagedes4 || data?.sec_19_price4) &&
                <div className="col-6 col-sm-6 col-lg-3 mb-3 mt-md-60 pr-2 pr-lg-3">
                  < a href={data?.sec_19_link4}
              to={'/'}
              className="card card-treatment h-100 " 
            >
                    <div className="card-body">
                   
                     {  (data?.sec_19_image4) &&   <div className="treatment-icon">
                      <img 
                     src={data?.sec_19_image4}
                        width={89} height={36} alt={data?.sec_19_image4_alt} />
                      </div>
                        }
                  {
                <Markup content={data?.sec_19_imagedes4} className="teatments-markup"  /> 
              }
                    </div>
                    <span className="price-from-circle">
                      From<br />
                      <b>{data?.sec_19_price4}</b><br />
                      Per Month
                    </span>
                    </a>
                </div>
                }

                {/* <div className="col-6 col-sm-6 col-lg-3 mb-3 mt-md-60 pl-2 pl-lg-3">
                  <a href="#" className="card card-treatment h-100">
                    <div className="card-body">
                      <div className="treatment-icon">
                        <img 
                        // src="assets/img/fullmouth-icon.png"
                        src='https://trustedclinics.com/images/icons/fullmouth-icon.png' 
                        width={69} height={42} alt="Full Mouth Reconstruction" />
                      </div>
                      <h3>Full Mouth Reconstruction</h3>
                      <p>Permanently replace a full arch of teeth.</p>
                    </div>
                    <span className="price-from-circle">
                      From<br />
                      <b>£149</b><br />
                      Per Month
                    </span>
                  </a>
                </div>
                <div className="col-6 col-sm-6 col-lg-3 mb-3 mt-md-60 pr-2 pr-lg-3">
                  <a href="#" className="card card-treatment h-100">
                    <div className="card-body">
                      <div className="treatment-icon">
                        <img className="invisalign" src="assets/img/invisalign-icon.png" width={153} height={35} style={{marginTop: '10px'}} alt="Invisalign Clear Aligners" />
                      </div>
                      <h3>Clear Aligners</h3>
                      <p>The premium invisible solution for straightening teeth.</p>
                    </div>
                    <span className="price-from-circle">
                      From<br />
                      <b>£27</b><br />
                      Per Month
                    </span>
                  </a>
                </div>
                <div className="col-6 col-sm-6 col-lg-3 mb-3 mt-md-60 pl-2 pl-lg-3">
                  <a href="#" className="card card-treatment h-100">
                    <div className="card-body">
                      <div className="treatment-icon">
                        <img 
                        // src="assets/img/veneers-icon.png"
                        src='https://trustedclinics.com/images/icons/veneers-icon.png'
                         width={89} height={36} style={{marginTop: '5px'}} alt="Veneers" />
                      </div>
                      <h3>Veneers</h3>
                      <p>An instant Hollywood smile.</p>
                    </div>
                    <span className="price-from-circle">
                      From<br />
                      <b>£59</b><br />
                      Per Month
                    </span>
                  </a>
                </div> */}
              </div>

              <div className="row text-center dental-imp">
               {  (data?.sec_19_imagefooter1  ) && <div className="col-6 col-sm-6 col-lg-3 mb-3 pr-2 pr-lg-3">
                  <a href={data?.sec_19_link1_footer } className="card card-treatment h-100">
                    <div className="card-body no-icon">
                      <h4>{data?.sec_19_imagefooter1 }</h4>
                    </div>
                  </a>
                </div>}
                {  (data?.sec_19_imagefooter2  ) &&  <div className="col-6 col-sm-6 col-lg-3 mb-3 pl-2 pl-lg-3">
                  <a href={data?.sec_19_link2_footer } className="card card-treatment h-100">
                    <div className="card-body no-icon">
                      <h4>{data?.sec_19_imagefooter2 }</h4>
                    </div>
                  </a>
                </div>}
               {(data?.sec_19_imagefooter3  ) && <div className="col-6 col-sm-6 col-lg-3 mb-3 pr-2 pr-lg-3">
                  <a href={data?.sec_19_link3_footer } className="card card-treatment h-100">
                    <div className="card-body no-icon">
                      <h4>{data?.sec_19_imagefooter3 }</h4>
                    </div>
                  </a>
                </div>}
               {(data?.sec_19_imagefooter4  ) &&  <div className="col-6 col-sm-6 col-lg-3 mb-3 pl-2 pl-lg-3">
                  <a href={data?.sec_19_link4_footer } className="card card-treatment h-100">
                    <div className="card-body no-icon">
                      <h4>{data?.sec_19_imagefooter4 }</h4>
                    </div>
                  </a>
                </div>}
              </div>
              <div className="row mt-4">
        <div className="col-xl-8 offset-xl-2 col-lg-10 offset-lg-1">
                       <div className="intro-mobile text-center">
                        { <Markup content={data?.sec_19_footerdes  } /> }</div> 
                        {/* <p className="text-center">
                         { <Markup content={data?.sec_19_footerdes} />}</p> */}
                    </div>
       
                    </div>
                </div>
</>
 
  )
}

export default SectionNineteen