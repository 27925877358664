import { Markup } from 'interweave'
import React, { useEffect, useState } from 'react'
import { NavLink, useParams } from 'react-router-dom'

function SectionSixtySeven(props) {
  const [data,setdata]= useState(props.data)
    const route = useParams()
console.log('id',route?.id)

  console.log('data')
 console.log(data)
  useEffect(() => {
    setdata(props.data)
  }, [props.data])
 
    console.log('section 67')
    console.log(data)

  
  return (

<div className="section main-section" style={{background:data?.section_67_color || ''}}>
        <div className="container">
            <h1 className="text-center text-center-treatments" style={{color:'#253959'}}>{data?.section_67_title}</h1>

            
                        
                        <div className="tablist">
                <div className="row mt-md-4 mt-lg-5" role="tablist">
                                                            <div className="col-6 col-lg-3 mb-md-3 treatments-card">
                        <a href={data?.section_67_link1}  role="tab" id="implants-tab" data-toggle="tab"
                         className={route?.id == '234' ? "card card-body card-treatment-category h-100 active" : "card card-body card-treatment-category h-100 " } 
                        aria-controls="implants" aria-selected="false" style={{backgroundImage:"url(https://cloud1.coe-website.securestaging.co.uk/images/treatment-category-images/top-selection-image1.jpg?mtime=20200514033334&amp;focal=none)"}}>
                            <h3 className="treatment-name">{data?.section_67_heading1}</h3>
                            <span className="price-from-circle">
                                From<br/>
                                <b>{data?.section_67_price1}</b><br/>
                                Per Month
                            </span>
                        </a>
                    </div>
                                                            <div className="col-6 col-lg-3 mb-md-3 treatments-card">
                        <a href={data?.section_67_link2} role="tab" id="aligners-tab" data-toggle="tab" 
                        className={route?.id == '235' ? "card card-body card-treatment-category h-100 active" : "card card-body card-treatment-category h-100 " } 
                        aria-controls="aligners" aria-selected="true" style={{backgroundImage:"url(https://cloud1.coe-website.securestaging.co.uk/images/treatment-category-images/top-selection-image2.jpg?mtime=20200514033334&amp;focal=none)"}}>
                            <h3 className="treatment-name">{data?.section_67_heading2}</h3>
                            <span className="price-from-circle">
                                From<br/>
                                <b>{data?.section_67_price2}</b><br/>
                                Per Month
                            </span>
                        </a>
                    </div>
                                                            <div className="col-6 col-lg-3 mb-md-3 treatments-card">
                        <a href={data?.section_67_link3} role="tab" id="cosmetic-tab" data-toggle="tab" 
                        className={route?.id == '236' ? "card card-body card-treatment-category h-100 active" : "card card-body card-treatment-category h-100 " } 
                        aria-controls="cosmetic" aria-selected="false" style={{backgroundImage:"url(https://cloud1.coe-website.securestaging.co.uk/images/treatment-category-images/top-selection-image3.jpg?mtime=20200514033334&amp;focal=none)"}}>
                            <h3 className="treatment-name">{data?.section_67_heading3}</h3>
                            <span className="price-from-circle">
                                From<br/>
                                <b>{data?.section_67_price3}</b><br/>
                                Per Month
                            </span>
                        </a>
                    </div>
                                                            <div className="col-6 col-lg-3 mb-md-3 treatments-card">
                        <a href={data?.section_67_link4} role="tab" id="emergency-tab" data-toggle="tab" 
                        className={route?.id == '237' ? "card card-body card-treatment-category h-100 active" : "card card-body card-treatment-category h-100 " } 
                        aria-controls="emergency" aria-selected="false" style={{backgroundImage:"url(https://cloud1.coe-website.securestaging.co.uk/images/treatment-category-images/top-selection-image4.jpg?mtime=20200514033334&amp;focal=none)"}}>
                            <h3 className="treatment-name">{data?.section_67_heading4}</h3>
                            <span className="price-from-circle">
                                From<br/>
                                <b>{data?.section_67_price4}</b><br/>
                                Per Month
                            </span>
                        </a>
                    </div>
                                    </div>
            </div>

                                </div>
    </div>


  )
}

export default SectionSixtySeven