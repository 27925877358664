import { Markup } from 'interweave'
import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'

function SectionSeventy(props) {
  const [data,setdata]= useState(props.data)
  console.log('data')
 console.log(data)
  useEffect(() => {
    setdata(props.data)
  }, [props.data])
 
    console.log('section 70')
    console.log(data)
  
  return (

<div className="tab-content treatment-sections" style={{background:data?.section_70_color || ''}}>
<div className="tab-pane fade active show " id="cosmetic" role="tabpanel" aria-labelledby="cosmetic-tab">
          <div className="section bg-linear-inverse" style={{background:'#684286'}}>
            <div className="section-background" data-style="background-image:url(https://cloud1.coe-website.securestaging.co.uk/images/backgrounds/purple/cosmestic-img1.png?mtime=20200514033317&focal=none);background-position:right bottom;" style={{backgroundImage: 'url("https://cloud1.coe-website.securestaging.co.uk/images/backgrounds/purple/cosmestic-img1.png?mtime=20200514033317&focal=none")', backgroundPosition: 'right bottom'}}>
              <div className="container">
                <div className="row">
                  <div className="col-xl-8 offset-xl-2 col-lg-10 offset-lg-1 text-center">
                  <h2 className="mb-4"><span className="str-replace-invisalign text-white">{data?.section_70_heading1}</span></h2>
                    <div>
                        {/* <p><a href="https://trustedclinics.com/dental/treatments/invisalign-clear-aligners/">Invisalign invisible aligners</a> are an option for those who want to achieve straighter teeth without going down the route of traditional wire braces. The clear aligners are each worn for a period of time before progressing onto a new set, continuing over a period of several months until the desired teeth position is achieved. These near invisible braces offer the ideal solution for those seeking a more discreet approach to teeth straightening treatment, as they are virtually undetectable once in the mouth. You won’t have to make any dietary changes for fear of damaging your braces, as the clear aligners are removed for eating.</p> */}
                        
                        <Markup content={data?.section_70_description1} className='review-div-center-span' /></div>
                        <a href={data?.section_70_button_link1} type="button" className="btn btn-lg btn-rounded btn-gold mt-3 text-white">
                      {data?.section_70_button1?.substring(0,30)}                                              </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="section bg-white">
            <div className="section-background" data-style="background-image:url(https://cloud1.coe-website.securestaging.co.uk/images/backgrounds/cosmestic-img2.png?mtime=20200514033311&focal=none);background-position:left bottom;" style={{backgroundImage: 'url("https://cloud1.coe-website.securestaging.co.uk/images/backgrounds/cosmestic-img2.png?mtime=20200514033311&focal=none")', backgroundPosition: 'left bottom'}}>
              <div className="container">
                <div className="row">
                  <div className="col-xl-8 offset-xl-2 col-lg-10 offset-lg-1 text-center">
                  <h2 className="mb-4">{data?.section_70_heading2}</h2>
                    <div>
                        {/* <p><a href="https://trustedclinics.com/dental/treatments/invisalign-clear-aligners/">Invisalign invisible aligners</a> are an option for those who want to achieve straighter teeth without going down the route of traditional wire braces. The clear aligners are each worn for a period of time before progressing onto a new set, continuing over a period of several months until the desired teeth position is achieved. These near invisible braces offer the ideal solution for those seeking a more discreet approach to teeth straightening treatment, as they are virtually undetectable once in the mouth. You won’t have to make any dietary changes for fear of damaging your braces, as the clear aligners are removed for eating.</p> */}
                        
                        <Markup content={data?.section_70_description2}  /></div>
                        <a href={data?.section_70_button_link2} type="button" className="btn btn-lg btn-rounded btn-gold mt-3 text-white">
                      {data?.section_70_button2?.substring(0,30)}                                              </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="section bg-off-white">
            <div className="section-background" data-style="background-image:url(https://cloud1.coe-website.securestaging.co.uk/images/backgrounds/cosmestic-img3.png?mtime=20200514033311&focal=none);background-position:right bottom;" style={{backgroundImage: 'url("https://cloud1.coe-website.securestaging.co.uk/images/backgrounds/cosmestic-img3.png?mtime=20200514033311&focal=none")', backgroundPosition: 'right bottom'}}>
              <div className="container">
                <div className="row">
                  <div className="col-xl-8 offset-xl-2 col-lg-10 offset-lg-1 text-center">
                  <h2 className="mb-4">{data?.section_70_heading3}</h2>
                    <div>
                        {/* <p><a href="https://trustedclinics.com/dental/treatments/invisalign-clear-aligners/">Invisalign invisible aligners</a> are an option for those who want to achieve straighter teeth without going down the route of traditional wire braces. The clear aligners are each worn for a period of time before progressing onto a new set, continuing over a period of several months until the desired teeth position is achieved. These near invisible braces offer the ideal solution for those seeking a more discreet approach to teeth straightening treatment, as they are virtually undetectable once in the mouth. You won’t have to make any dietary changes for fear of damaging your braces, as the clear aligners are removed for eating.</p> */}
                        
                        <Markup content={data?.section_70_description3}  /></div>
                        <a href={data?.section_70_button_link3} type="button" className="btn btn-lg btn-rounded btn-gold mt-3 text-white">
                      {data?.section_70_button3?.substring(0,30)}                                              </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="section bg-white">
            <div className="section-background" data-style="background-image:url(https://cloud1.coe-website.securestaging.co.uk/images/backgrounds/cosmestic-img4.png?mtime=20200514033311&focal=none);background-position:left bottom;" style={{backgroundImage: 'url("https://cloud1.coe-website.securestaging.co.uk/images/backgrounds/cosmestic-img4.png?mtime=20200514033311&focal=none")', backgroundPosition: 'left bottom'}}>
              <div className="container">
                <div className="row">
                  <div className="col-xl-8 offset-xl-2 col-lg-10 offset-lg-1 text-center">
                  <h2 className="mb-4">{data?.section_70_heading4}</h2>
                    <div>
                        {/* <p><a href="https://trustedclinics.com/dental/treatments/invisalign-clear-aligners/">Invisalign invisible aligners</a> are an option for those who want to achieve straighter teeth without going down the route of traditional wire braces. The clear aligners are each worn for a period of time before progressing onto a new set, continuing over a period of several months until the desired teeth position is achieved. These near invisible braces offer the ideal solution for those seeking a more discreet approach to teeth straightening treatment, as they are virtually undetectable once in the mouth. You won’t have to make any dietary changes for fear of damaging your braces, as the clear aligners are removed for eating.</p> */}
                        
                        <Markup content={data?.section_70_description4}  /></div>
                        <a href={data?.section_70_button_link4} type="button" className="btn btn-lg btn-rounded btn-gold mt-3 text-white">
                      {data?.section_70_button4?.substring(0,30)}                                              </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>


  )
}

export default SectionSeventy