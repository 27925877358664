// import { Markup } from 'interweave'
// import React, { useEffect, useState } from 'react'
// import { NavLink } from 'react-router-dom'

// function SectionTwelve(props) {
//   const [data,setdata]= useState(props.data)
//   console.log('data')
//  console.log(data)
//   useEffect(() => {
//     setdata(props.data)
//   }, [props.data])
 
//     console.log('sectionone')
//     console.log(data)
  
//   return (

//   <div className="section treatments-section" style={{background:data?.section_12_color || ''}}>
//     <div className="container text-center">
//       <div className="row">
//         <div className="col-xl-8 offset-xl-2 col-lg-10 offset-lg-1" >
//           <h2 className="mb-4">{ data?.section_twelve_title}</h2>
//         </div>
//       </div>
//       <div className="row text-center my-3  hide-score">
//       {
//         (data?.section_twelve_image1 || data?.section_twelve_head1) && 
//         <div className="col-6 col-sm-6 col-lg-3 mb-3 mt-md-60 pr-2 pr-lg-3">
//           <a href={data?.section_12_link1}  className="card card-treatment-img dental-implants h-100 "  style={{backgroundImage:`url(${data?.section_twelve_image1}),linear-gradient(#fff 13%,#fff,#ecf2f2,#ecf2f2 60% 100%)`,backgroundSize: '135px 120px,100%',backgroundPosition: '100% 100%'}} >
//             <div className="card-body">
//               <h3>{ data?.section_twelve_head1}</h3>
//             </div>
//           </a>
//         </div>
//       }

//       {
//         (data?.section_twelve_image2 || data?.section_twelve_head2) && 
//         <div className="col-6 col-sm-6 col-lg-3 mb-3 mt-md-60 pr-2 pr-lg-3">
//           <a href={data?.section_12_link2} className="card card-treatment-img invisalign-aligners h-100" style={{backgroundImage:`url(${data?.section_twelve_image2}),linear-gradient(#fff 13%,#fff,#ecf2f2,#ecf2f2 60% 100%)`,backgroundSize: '135px 120px,100%',backgroundPosition: '100% 100%' }}>
//             <div className="card-body">
//               <div className="text-center mb-1">
//                 <img src={data?.section_twelve_image2_header} width={153} height={35} alt={data?.section_twelve_image2_alt} /> 
//               </div>
//               <h4>{ data?.section_twelve_head2}</h4>
//             </div>
//           </a>
//         </div>
//       }
        
//       {
//         (data?.section_twelve_image3 || data?.section_twelve_head3) && 
//         <div className="col-6 col-sm-6 col-lg-3 mb-3 mt-md-60 pl-2 pl-lg-3">
//           <a href={data?.section_12_link3}  className="card card-treatment-img veneers h-100" style={{backgroundImage:`url(${data?.section_twelve_image3}),linear-gradient(#fff 13%,#fff,#ecf2f2,#ecf2f2 60% 100%)`,backgroundSize: '135px 120px,100%',backgroundPosition: '100% 100%' }}>
//             <div className="card-body">
//               <h3>{data?.section_twelve_head3}</h3>
//             </div>
//           </a>
//         </div>
//       }
      
//       {
//         (data?.section_twelve_image4 || data?.section_twelve_head4) &&
//         <div className="col-6 col-sm-6 col-lg-3 mb-3 mt-md-60 pr-2 pr-lg-3">
//           <a href={data?.section_12_link4}  className="card card-treatment-img fixed-and-lingual-braces h-100" style={{backgroundImage:`url(${data?.section_twelve_image4}),linear-gradient(#fff 13%,#fff,#ecf2f2,#ecf2f2 60% 100%)`,backgroundSize: '135px 120px,100%',backgroundPosition: '100% 100%' }}>
//             <div className="card-body">
//               <h3>{data?.section_twelve_head4}</h3>
//             </div>
//           </a>
//         </div>
//       }
        
//       {
//         (data?.section_twelve_image5 || data?.section_twelve_head5) &&
//         <div className="col-6 col-sm-6 col-lg-3 mb-3 mt-md-60 pl-2 pl-lg-3">
//           <a href={data?.section_12_link5}  className="card card-treatment-img crowns-and-bridges h-100" style={{backgroundImage:`url(${data?.section_twelve_image5}),linear-gradient(#fff 13%,#fff,#ecf2f2,#ecf2f2 60% 100%)`,backgroundSize: '135px 120px,100%',backgroundPosition: '100% 100%' }}>
//             <div className="card-body">
//               <h3>{data?.section_twelve_head5}</h3>
//             </div>
//           </a>
//         </div>
//       }
      
//       {
//         (data?.section_twelve_image6 || data?.section_twelve_head6) &&
//         <div className="col-6 col-sm-6 col-lg-3 mb-3 mt-md-60 pr-2 pr-lg-3">
//           <a href={data?.section_12_link6}  className="card card-treatment-img fillers h-100" style={{backgroundImage:`url(${data?.section_twelve_image6}),linear-gradient(#fff 13%,#fff,#ecf2f2,#ecf2f2 60% 100%)`,backgroundSize: '135px 120px,100%',backgroundPosition: '100% 100%' }}>
//             <div className="card-body">
//               <h3>{data?.section_twelve_head6}</h3>
//             </div>
//           </a>
//         </div>
//       }
        
//       {
//         (data?.section_twelve_image7 || data?.section_twelve_head7) &&
//         <div className="col-6 col-sm-6 col-lg-3 mb-3 mt-md-60 pl-2 pl-lg-3 mx-auto">
//           <a href={data?.section_12_link7}  className="card card-treatment-img emergency-treatment h-100" style={{backgroundImage:`url(${data?.section_twelve_image7}),linear-gradient(#fff 13%,#fff,#ecf2f2,#ecf2f2 60% 100%)`,backgroundSize: '135px 120px,100%',backgroundPosition: '100% 100%' }}>
//             <div className="card-body">
//               <h3>{data?.section_twelve_head7}</h3>
//             </div>
//           </a>
//         </div>
//       }

      
        
//       </div>
//     </div>
//   </div>
//   )
// }

// export default SectionTwelve

import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';

function SectionTwelve(props) {
  const [data, setData] = useState(props.data);

  useEffect(() => {
    setData(props.data);
  }, [props.data]);

  const cardStyle = (image) => ({
    backgroundImage: `url(${image}),linear-gradient(#fff 13%,#fff,#ecf2f2,#ecf2f2 60% 100%)`,
    backgroundSize: '135px 120px,100%',
    backgroundPosition: '100% 100%',
    backgroundRepeat:"no-repeat",
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    border: 'none',
    backgroundColor: 'transparent',
    transition: 'transform 0.3s ease',
  });

  const cardBodyStyle = {
    padding: '20px',
  };

  const containerStyle = {
    padding: '20px 0',
  };

  const responsiveFontSize = {
    fontSize: '1.2rem',
  };

  return (
    <div className="section treatments-section" style={{ background: data?.section_12_color || '' }}>
      <div className="container text-center" style={containerStyle}>
        <div className="row">
          <div className="col-xl-8 offset-xl-2 col-lg-10 offset-lg-1">
            <h2 className="mb-4">{data?.section_twelve_title}</h2>
          </div>
        </div>
        <div className="row text-center my-3 hide-score">
          {(data?.section_twelve_image1 || data?.section_twelve_head1) && (
            <div className="col-12 col-sm-6 col-md-4 col-lg-3 mb-3">
              <a
                href={data?.section_12_link1}
                className="card card-treatment-img h-100"
                style={cardStyle(data?.section_twelve_image1)}
              >
                <div className="card-body" style={cardBodyStyle}>
                  <h3 style={responsiveFontSize}>{data?.section_twelve_head1}</h3>
                </div>
              </a>
            </div>
          )}

          {(data?.section_twelve_image2 || data?.section_twelve_head2) && (
            <div className="col-12 col-sm-6 col-md-4 col-lg-3 mb-3">
              <a
                href={data?.section_12_link2}
                className="card card-treatment-img h-100"
                style={cardStyle(data?.section_twelve_image2)}
              >
                <div className="card-body" style={cardBodyStyle}>
                  {data?.section_twelve_image2_header && (
                    <div className="text-center mb-1">
                      <img
                        src={data?.section_twelve_image2_header}
                        width={153}
                        height={35}
                        alt={data?.section_twelve_image2_alt}
                      />
                    </div>
                  )}
                  <h3 style={responsiveFontSize}>{data?.section_twelve_head2}</h3>
                </div>
              </a>
            </div>
          )}

          {(data?.section_twelve_image3 || data?.section_twelve_head3) && (
            <div className="col-12 col-sm-6 col-md-4 col-lg-3 mb-3">
              <a
                href={data?.section_12_link3}
                className="card card-treatment-img h-100"
                style={cardStyle(data?.section_twelve_image3)}
              >
                <div className="card-body" style={cardBodyStyle}>
                  <h3 style={responsiveFontSize}>{data?.section_twelve_head3}</h3>
                </div>
              </a>
            </div>
          )}

          {(data?.section_twelve_image4 || data?.section_twelve_head4) && (
            <div className="col-12 col-sm-6 col-md-4 col-lg-3 mb-3">
              <a
                href={data?.section_12_link4}
                className="card card-treatment-img h-100"
                style={cardStyle(data?.section_twelve_image4)}
              >
                <div className="card-body" style={cardBodyStyle}>
                  <h3 style={responsiveFontSize}>{data?.section_twelve_head4}</h3>
                </div>
              </a>
            </div>
          )}

          {(data?.section_twelve_image5 || data?.section_twelve_head5) && (
            <div className="col-12 col-sm-6 col-md-4 col-lg-3 mb-3">
              <a
                href={data?.section_12_link5}
                className="card card-treatment-img h-100"
                style={cardStyle(data?.section_twelve_image5)}
              >
                <div className="card-body" style={cardBodyStyle}>
                  <h3 style={responsiveFontSize}>{data?.section_twelve_head5}</h3>
                </div>
              </a>
            </div>
          )}

          {(data?.section_twelve_image6 || data?.section_twelve_head6) && (
            <div className="col-12 col-sm-6 col-md-4 col-lg-3 mb-3">
              <a
                href={data?.section_12_link6}
                className="card card-treatment-img h-100"
                style={cardStyle(data?.section_twelve_image6)}
              >
                <div className="card-body" style={cardBodyStyle}>
                  <h3 style={responsiveFontSize}>{data?.section_twelve_head6}</h3>
                </div>
              </a>
            </div>
          )}

          {(data?.section_twelve_image7 || data?.section_twelve_head7) && (
            <div className="col-12 col-sm-6 col-md-4 col-lg-3 mb-3">
              <a
                href={data?.section_12_link7}
                className="card card-treatment-img h-100"
                style={cardStyle(data?.section_twelve_image7)}
              >
                <div className="card-body" style={cardBodyStyle}>
                  <h3 style={responsiveFontSize}>{data?.section_twelve_head7}</h3>
                </div>
              </a>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default SectionTwelve;
