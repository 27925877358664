import axios from 'axios'
import { Markup } from 'interweave'
import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'

function SectionSixty(props) {
    const [fetchData,setfetchData] = useState(true)
    const[Doctor_name,setDoctor_name] = useState([])
    const[Doctor_List,setDoctor_List] = useState([])
    const [Category,setCategory] = useState([])
   


  const [data,setdata]= useState(props.data)
  console.log('data')
 console.log(data)
  useEffect(() => {
    setdata(props.data)
    GetDataByCategory()
  }, [props.data])
 
    console.log('section 60')
    console.log(data)

  const GetDataByCategory=async()=>{
    await axios.get(`${process.env.REACT_APP_BASE_URL}api/get_doctorcategory`).
    then(res=>{
        if(res.data.data === false){
            setfetchData(false)
        }
        else{
            setfetchData(true)
        }
        setCategory(res.data?.detail)
    }
    )
//////////////////// get_doctor_list
        await axios.get(`${process.env.REACT_APP_BASE_URL}api/get_doctor_list`).
    then(res=>{
        if(res.data.data === false){
            setfetchData(false)
        }
        else{
            setfetchData(true)
        }
        setDoctor_List(res.data?.detail)
    }
    )
  }

  // console.log(Doctor_List?.map(name=>name.doctor_detail?.category_name))

  let Doctor_Name=[] ;

  const GetDataByName=async(event)=>{
    setDoctor_name(event.target.value)
 
    // Category?.map((cate)=>   cate.category_name === event.target.value ?  Doctor_Name.push(cate) : ''   )
    Doctor_List?.map(cate=> cate.doctor_detail?.category_name === event.target.value ? Doctor_Name.push(cate.name) : ''  )
    setDoctor_name(Doctor_Name)
  }

  console.log(Doctor_name)
  return (

<div className="page-dentists-near-me" style={{background:data?.section_60_color || ''}}> 
          <div className="layout-main">
            <div className="section bg-grey">
            <div className="container">
              {/* <h2>
                To find a suitable clinic in Hemel Hempstead, search the results below:
              </h2> */}
              <Markup content={data?.section_60_title} />
              <hr />
             {fetchData &&  <div className="results-sorting">
                <div className="results-type mr-auto">Results: <strong>All</strong></div>
                <label htmlFor="sortBy">Sort By Category</label>
                <div className="select-field">
                  <select name="sort-by" id="sortBy" className="form-control max-width-200"  onChange={GetDataByName} >
                    <option >-- Please Select --</option>
                    {/* <option value>-- Please Select --</option>
                    <option value>-- Please Select --</option>
                    <option value>-- Please Select --</option>
                    <option value>-- Please Select --</option>
                    <option value>-- Please Select --</option>
                    <option value>-- Please Select --</option>
                    <option value>-- Please Select --</option>
                    <option value>-- Please Select --</option>
                    <option value>-- Please Select --</option>
                    <option value>-- Please Select --</option>
                    <option value>-- Please Select --</option> */}
                    {
                        Category?.map(   (cate)=> <option key={cate.id} value={cate.category_name} >{cate.category_name}</option>  )
                    }
                  </select>
                </div>
                <label htmlFor="filterGroup">Name</label>
                <div className="select-field">
                  <select name="sort-by" id="sortBy" className="form-control max-width-200">
                    <option value>-- Please Select --</option>
                    {
                      Doctor_name?.map(   (cate)=> <option key={cate.id} value={cate} >{cate}</option>  )
                    }
                  </select>
                </div>
              </div>}
              {/* <div className="clinics-results">
                <div className="card clinic-listing-card best-match">
                  <div className="card-row-1">
                    <div className="d-flex">
                      <div className="branding">
                        <div className="logo-container p-1">
                          <img 
                          // src="assets/img/dUUZ0GCCvGQrDUpZiM5p.png" 
                          src="https://crm.trustedclinics.com/storage/images/clinic-logos/dUUZ0GCCvGQrDUpZiM5p.png"
                          className="img-fluid max" alt="Alexandra Dental" />
                        </div>
                        <div className="logo-title" style={{float:'left'}}>
                          <h4><a href>Alexandra Dental</a></h4>
                        </div>
                      </div>
                      <div className="contact">
                        <a href="tel:01442 256335" className="tel">01442 256335</a><br />
                        <p className="address">98 Alexandra Road, Hemel Hempstead, HP2 4AG</p>
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div className="card-row-2">
                    <div className="d-flex">
                      <div className="img-block">
                      </div>
                      <div className="profile">
                        <div className="d-flex">
                          <div className="treatments" >
                           <div className="row">
                           <h4 style={{float:'left',paddingLeft:'10px'}}>Treatments</h4>
                           </div>
                            <div className="row">
                              <div className="col-6">
                              <NavLink  to={'/alexandra_dental'} className="text-primary" style={{cursor:'pointer',float:'left'}}>View all offered treatments <i className="fas fa-chevron-right" /></NavLink>
                              </div>
                            </div>
                          </div>
                          <div className="reviews">
                            <div className="card review-score-card card-body">
                              <p className="desc">Excellent</p>
                              <p className="reviews-score">(9.5/10)</p>
                              <p className="review-stars">
                                <i className="fas fa-star" /><i className="fas fa-star" /><i className="fas fa-star" /><i className="fas fa-star" /><i className="fas fa-star" />                          </p>
                              <p className="reviews-count">2,224 Reviews</p>
                            </div>
                          </div>
                        </div>
                        <div className="clinic-intro">
                          <p className="mb-0">The practice dates back to 1976 and has built a strong reputation based on strong patient care, a highly trained team and the latest technologies, all delivered in a caring and friendly environment. <br />
                            Today their services offered are general appointments, cosmetics, braces, implants and advanced straightening technologies such as Invisalign. <br />
                            The practice has invested a considerable amount of time and money over the past few years upgrading the practice and improving their facilities. They have embraced state of the art techniques designed to improve their service in every respect.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div className="card-row-3">
                    <div className="d-flex">
                      <div className="languages">
                        <b style={{float:'left'}}>Languages Spoken: </b>
                        <span style={{float:'left'}}>English </span>
                      </div>
                      <div className="buttons text-right">
                        <NavLink  to={'/alexandra_dental'} className="more-info mr-3" style={{cursor:'pointer'}}>View more information <i className="fas fa-chevron-right" /></NavLink>
                        <a href className="btn btn-pink btn-rounded btn-smaller text-white button-show-and-hide">Book your Free Consultation</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
            {/* <div className="modal fade" id="remoteClinicModal" tabIndex={-1} role="dialog" aria-labelledby="remoteClinicModalLabel" aria-hidden="true">
              <div className="modal-dialog modal-xl">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="remoteClinicModalLabel" />
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">×</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <iframe src="./Brentwood-Dentist near me_files/saved_resource.html" frameBorder={0} />
                  </div>
                </div>
              </div>
            </div>  */}
            {/* <div className="section bg-grey">
            <div className="container">
              <h2>
              Approved Trusted Clinics in Leicester
              </h2>
              <hr />
              <div className="results-sorting">
                <div className="results-type mr-auto">Results: <strong>All</strong></div>
                <label htmlFor="sortBy">Sort By</label>
                <div className="select-field">
                  <select name="sort-by" id="sortBy" className="form-control max-width-200">
                    <option value>-- Please Select --</option>
                  </select>
                </div>
                <label htmlFor="filterGroup">Reviews</label>
                <div className="select-field">
                  <select name="sort-by" id="sortBy" className="form-control max-width-200">
                    <option value>-- Please Select --</option>
                  </select>
                </div>
              </div>
              <div className="alert alert-info text-center">
                <h4>No results found</h4>
                <p>There are currently no results for this location. Check back again soon, we're adding more clinics all the time.</p>
              </div>
            </div>
          </div> */}
          </div>
        </div>
  )
}

export default SectionSixty