// import { Markup } from 'interweave'
// import React, { useEffect, useState } from 'react'
// import { NavLink } from 'react-router-dom'
// import defaultImg from './images/defaultimage.jpg';


// function SectionSeventyFive(props) {
//     const [data,setdata]= useState(props.data)
//     console.log('data')
//    console.log(data)
//     useEffect(() => {
//       setdata(props.data)
//     }, [props.data])
   
//       console.log('section 75')
//       console.log(data)
//     return (
//         <>
//         <style>
//         {`
//           .grid-container {
//             display: flex;
//             flex-wrap: wrap;
//             justify-content: space-between;
//           }

//           .grid-item {
//             display: flex;
//             flex-direction: column;
//             justify-content: space-between;
//             height: 400px;
//             margin-bottom: 20px;
//           }

//           .card-news-grid {
//             display: flex;
//             flex-direction: column;
//             {/* height: 100%; */}
//           }

//           .card-image img {
//             object-fit: cover;
//           }

//           .card-body {
//             flex-grow: 1;
//             display: flex;
//             flex-direction: column;
//             justify-content: space-between;
//           }

//           .card-title {
//             height: 60px; /* Fixed height for title */
//             overflow: hidden;
//           }

//           .preview-text {
//             height: 50px; /* Fixed height for description */
//             overflow: hidden;
//           }

//           .card-body .btn {
//             align-self: center;
//           }
//         `}
//       </style>
//         <div className="section faqs-entries-section bg-off-white" style={{background:data?.section_75_color || ''}}>
//       <div className="container text-center">
//         <h2 className="mb-4 mb-lg-5">{data?.section_75_title}</h2>
//         <div className='container'>
// {/* <div className='row'>
          

//       {data?.section_75_faqs &&
//         data?.section_75_faqs.map( (value)=> { 
//          return ( 
//              <div className="grid-item col-md-6 col-lg-4">
//         <div className="card card-faq-grid">
//           <div className="card-body text-center">
//             <div className="icon-container mb-3">
//               <img
//                 src={value?.image}
//                 alt={value?.image_alt}
//               />
//             </div>
//             <h3 className="card-title blue">
//              {value.title}
//             </h3>
//             <div className="answer">
//            <Markup content={value.description} />
//             </div>
//           </div>
//         </div>
//       </div>
//       )})
//       } 
//       </div> */}
//       <div className='row grid-container'>
//           {data?.section_75_faqs && data?.section_75_faqs.map((value, index) => (
//             <div className="grid-item col-md-6 col-lg-4" key={index}>
//               <div className="card card-news-grid p-2">
                
//                   <img
//                     style={{ width: '100%', height: "220px" }}
//                     src={value.image ? value.image : defaultImg}
//                     alt={value.image_alt}
//                     className="img-fluid"
//                   />
               
//                 <div className="card-body text-center">
//                   <h3 className="card-title blue">{value.title && value.title.length >= 50 ? value.title.substring(0, 50) + "..." : value.title}</h3>
//                   <div className="preview-text">
//                     <Markup content={value.description && value.description.length >= 70 ? value.description.substring(0, 70) + "..." : value.description} />
//                   </div>

        
//                 </div>
//               </div>
//             </div>
//           ))}
//         </div>
        
//         </div>
//       </div>
//     </div>
//         </>
//      )
// }

// export default SectionSeventyFive

// import { Markup } from 'interweave';
// import React, { useEffect, useState } from 'react';
// import { NavLink } from 'react-router-dom';
// import defaultImg from './images/defaultimage.jpg';

// function SectionSeventyFive(props) {
//     const [data, setData] = useState(props.data);
//     const [showFullText, setShowFullText] = useState({});

//     useEffect(() => {
//         setData(props.data);
//     }, [props.data]);

//     const toggleReadMore = (index) => {
//         setShowFullText((prevState) => ({
//             ...prevState,
//             [index]: !prevState[index]
//         }));
//     };

//     return (
//         <>
//             <style>
//                 {`
//                     .grid-container {
//                         display: flex;
//                         flex-wrap: wrap;
//                         justify-content: space-between;
//                     }

//                     .grid-item {
//                         display: flex;
//                         flex-direction: column;
//                         justify-content: space-between;
//                         margin-bottom: 20px;
//                         width: calc(33.333% - 20px); /* Adjust for gap */
//                     }

//                     .card-news-grid {
//                         display: flex;
//                         flex-direction: column;
//                         height: 100%;
//                     }

//                     .card-image img {
//                         object-fit: cover;
//                         width: 100%;
//                         height: 220px;
//                     }

//                     .card-body {
//                         flex-grow: 1;
//                         display: flex;
//                         flex-direction: column;
//                         justify-content: space-between;
//                     }

//                     .card-title {
//                         height: 60px; /* Fixed height for title */
//                         overflow: hidden;
//                     }

//                     .preview-text {
//                         height: 50px; /* Fixed height for description */
//                         overflow: hidden;
//                         text-overflow: ellipsis;
//                         display: -webkit-box;
//                         -webkit-line-clamp: 3; /* Number of lines to show */
//                         -webkit-box-orient: vertical;
//                     }

//                     .preview-text.full-text {
//                         height: auto; /* Auto height for full text */
//                         -webkit-line-clamp: unset;
//                     }

//                     .card-body .btn {
//                         align-self: center;
//                     }
//                 `}
//             </style>
//             <div className="section faqs-entries-section bg-off-white" style={{ background: data?.section_75_color || '' }}>
//                 <div className="container text-center">
//                     <h2 className="mb-4 mb-lg-5">{data?.section_75_title}</h2>
//                     <div className='container'>
//                         <div className='row grid-container'>
//                             {data?.section_75_faqs && data?.section_75_faqs.map((value, index) => (
//                                 <div className="grid-item col-md-6 col-lg-4" key={index}>
//                                     <div className="card card-news-grid p-2">
//                                         <img
//                                             src={value.image ? value.image : defaultImg}
//                                             alt={value.image_alt}
//                                             className="img-fluid"
//                                         />
//                                         <div className="card-body text-center">
//                                             <h3 className="card-title blue">
//                                                 {value.title && value.title.length >= 50 ? value.title.substring(0, 50) + "..." : value.title}
//                                             </h3>
//                                             <div className={`preview-text ${showFullText[index] ? 'full-text' : ''}`}>
//                                                 <Markup content={value.description} />
//                                             </div>
//                                             {value.description && value.description.length > 70 && (
//                                                 <button className="btn btn-link" onClick={() => toggleReadMore(index)}>
//                                                     {showFullText[index] ? "Read Less" : "Read More"}
//                                                 </button>
//                                             )}
//                                         </div>
//                                     </div>
//                                 </div>
//                             ))}
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </>
//     );
// }

// export default SectionSeventyFive;

import { Markup } from 'interweave';
import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import defaultImg from './images/defaultimage.jpg';

function SectionSeventyFive(props) {
    const [data, setData] = useState(props.data);
    const [showFullText, setShowFullText] = useState({});

    useEffect(() => {
        setData(props.data);
    }, [props.data]);

    const toggleReadMore = (index) => {
        setShowFullText((prevState) => ({
            ...prevState,
            [index]: !prevState[index]
        }));
    };

    return (
        <>
            <style>
                {`
                    .grid-item {
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        margin-bottom: 20px;
                    }

                    .card-news-grid {
                        display: flex;
                        flex-direction: column;
                        height: 100%;
                        padding: 10px;
                        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
                        border-radius: 8px;
                        overflow: hidden;
                    }

                    .card-image img {
                        object-fit: cover;
                        width: 100%;
                        height: 220px;
                        border-bottom: 1px solid #ddd;
                    }

                    .card-body {
                        flex-grow: 1;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        padding: 10px;
                    }

                    .card-title {
                        font-size: 1.25rem;
                        margin-bottom: 10px;
                        font-weight: bold;
                    }

                    .preview-text {
                        max-height: 50px; /* Limit max height for description */
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 3; /* Number of lines to show */
                        -webkit-box-orient: vertical;
                        margin-bottom: 10px;
                    }

                    .preview-text.full-text {
                        max-height: none; /* Remove max height for full text */
                        -webkit-line-clamp: unset;
                    }

                    .card-body .btn {
                        align-self: center;
                    }

                    @media (max-width: 576px) {
                        .preview-text {
                            -webkit-line-clamp: 2; /* Show 2 lines of text on smaller screens */
                        }
                    }
                `}
            </style>
            <div className="section faqs-entries-section bg-off-white" style={{ background: data?.section_75_color || '' }}>
                <div className="container text-center">
                    <h2 className="mb-4 mb-lg-5">{data?.section_75_title}</h2>
                    <div className='container'>
                        <div className='row'>
                            {data?.section_75_faqs && data?.section_75_faqs.map((value, index) => (
                                <div className="grid-item col-12 col-sm-6 col-md-6 col-lg-4" key={index}>
                                    <div className="card card-news-grid">
                                        <div className="card-image">
                                            <img
                                                src={value.image ? value.image : defaultImg}
                                                alt={value.image_alt}
                                                onError={(e)=>e.target.src = defaultImg}
                                                className="img-fluid"
                                            />
                                        </div>
                                        <div className="card-body text-center">
                                            <h3 className="card-title blue">
                                                {value.title}
                                            </h3>
                                            <div className={`preview-text ${showFullText[index] ? 'full-text' : ''}`}>
                                                <Markup content={value.description} />
                                            </div>
                                            {value.description && value.description.length > 70 && (
                                                <button className="btn btn-link" onClick={() => toggleReadMore(index)}>
                                                    {showFullText[index] ? "Read Less" : "Read More"}
                                                </button>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default SectionSeventyFive;

