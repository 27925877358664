import { Markup } from 'interweave'
import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'

function SectionFourty(props) {
  const [data,setdata]= useState(props.data)
  console.log('data')
 console.log(data)
  useEffect(() => {
    setdata(props.data)
  }, [props.data])
 
    console.log('section 40')
    console.log(data)
  
  return (

    <div className="section procedure-section section-6947 bg-white" style={{background:data?.section_40_color || ''}}>
    <div className="container">
        <div className="row">
            <div className="col-xl-8 offset-xl-2 col-lg-10 offset-lg-1 pb-2 text-center">
                <h2 className="mb-4">{data?.section_40_title}</h2>
                <div>
                {
                    <Markup content={data?.section_40_description} />
                }
                </div>
            </div>
        </div>


        <div className="row text-center d-flex justify-content-center">
        {
            (data?.section_40_image1 || data?.section_40_head1 ||  data?.section_40_detail1) &&
            <div className="col-sm-6 col-lg-4 pb-3 px-sm-3">
                <div className="simple-block">
                {
                    (data?.section_40_image1) &&
                    <div className="icon-container mb-3">
                        <img src={data?.section_40_image1} alt={data?.section_40_image1_alt} style={{width:'70px'}} />
                    </div>
                }
                    
                    <h3>{data?.section_40_head1}</h3>
                    <p></p><p>{data?.section_40_detail1}</p><p></p>
                </div>
            </div>

        }
            
        {
            (data?.section_40_image2 || data?.section_40_head2 || data?.section_40_detail2) &&
            <div className="col-sm-6 col-lg-4 pb-3 px-sm-3">
                <div className="simple-block">
                {
                    (data?.section_40_image2) &&
                    <div className="icon-container mb-3">
                        <img src={data?.section_40_image2} alt= {data?.section_40_image2_alt} style={{width:'70px'}}/>
                    </div>
                }
                    
                    <h3>{data?.section_40_head2}</h3>
                    <p></p><p>{data?.section_40_detail2}</p><p></p>
                </div>
            </div>

        }
        
        {
            (data?.section_40_image3 || data?.section_40_head3 || data?.section_40_detail3) &&
            <div className="col-sm-6 col-lg-4 pb-3 px-sm-3">
                <div className="simple-block">
                {
                    (data?.section_40_image3) &&
                    <div className="icon-container mb-3">
                        <img src={data?.section_40_image3} alt={data?.section_40_image3_alt} style={{width:'70px'}}/>
                    </div>
                }
                    
                    <h3>{data?.section_40_head3}</h3>
                    <p></p><p>{data?.section_40_detail3}</p><p></p>
                </div>
            </div>
        }

        {
            (data?.section_40_image4 || data?.section_40_head4 || data?.section_40_detail4) &&
            <div className="col-sm-6 col-lg-4 pb-3 px-sm-3">
                <div className="simple-block">
                {
                    (data?.section_40_image4) &&
                    <div className="icon-container mb-3">
                        <img src={data?.section_40_image4} alt={data?.section_40_image5_alt} style={{width:'70px'}}/>
                    </div>
                }
                    
                    <h3>{data?.section_40_head4}</h3>
                    <p></p><p>{data?.section_40_detail4}.</p><p></p>
                </div>
            </div>
        } 
            
        {
            (data?.section_40_image5 || data?.section_40_head5 || data?.section_40_detail5) &&
            <div className="col-sm-6 col-lg-4 pb-3 px-sm-3">
                <div className="simple-block">
                {
                    (data?.section_40_image5) &&
                    <div className="icon-container mb-3">
                        <img src={data?.section_40_image5} alt={data?.section_40_image5_alt} style={{width:'70px'}}/>
                    </div>
                }
                    
                    <h3>{data?.section_40_head5}</h3>
                    <p></p><p>{data?.section_40_detail5}</p><p></p>
                </div>
            </div>
        }
            
        </div>
    </div>
</div>
  )
}

export default SectionFourty