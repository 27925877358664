import { Markup } from 'interweave'
import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'

function SectionFifty(props) {
  const [data,setdata]= useState(props.data)
  console.log('data')
 console.log(data)
  useEffect(() => {
    setdata(props.data)
  }, [props.data])
 
    console.log('section 50')
    console.log(data)
  return (
<>


    
        <div class="layout-main" style={{background:data?.section_50_color || ''}}>
                


    <div class="section main-section">
        <div class="section-bg-overlay">
            <div class="container">
                <h1 class="text-center " style={{color:'#253959'}} >{data?.section_50_title}</h1>
                
                <div class="mt-4 strong-privecypolicy-page">
                    {<Markup content={data?.section_50_description} />}
                </div>
            </div>
        </div>
    </div>


            </div>


  
</>

  )
}

export default SectionFifty