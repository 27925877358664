import { Markup } from 'interweave'
import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'

function SectionTwo(props) {
  const [data,setdata]= useState(props.data)
  console.log('data')
 console.log(data)
  useEffect(() => {
    setdata(props.data)
  }, [props.data])
 
    console.log('sectiontwo')
    console.log(data)
  return (

  <div className="section restore-your-smile-section bg-inverse " style={{backgroundColor:data?.section_2_color || '#734994'}}>
  <div className="container text-center">
    <div className="row">
      <div className="col-xl-8 offset-xl-2 col-lg-10 offset-lg-1">
        <h2 className="mb-4">{data?.section_two_title }</h2>
     
        {<Markup content={data?.section_two_header} className='review-div-center-span' /> }
      <div className="card form-card bg-white form-css-hidden">
          <div className="card-body text-left">
            <form
    
              
              className="form"
            >
              <input type="hidden" name="CRAFT_CSRF_TOKEN" defaultValue="" />
              <div className="row">
                <div className="col-sm-6">
                  <div className="form-group">
                    <label  style={{  color: "#4a4a4a"}}>{data?.section_two_head1}</label>
                
                    <input
                      type="text"
                      className="form-control"
                      name="name"
                      id="restore-name"
                      defaultValue=""
                      placeholder={`${data?.section_two_head1}`}
                      required=""
                    />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label  style={{  color: "#4a4a4a"}} htmlFor="restore-email">{data?.section_two_head2}</label>
                    <input
                      type="email"
                      className="form-control"
                      name="email"
                      id="restore-email"
                      defaultValue=""
                      placeholder={`${data?.section_two_head2}`}
                      required=""
                    />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label  style={{  color: "#4a4a4a"}} htmlFor="restore-telephone">{data?.section_two_head3}</label>
                    <input
                      type="text"
                      className="form-control"
                      name="telephone"
                      id="restore-telephone"
                      defaultValue=""
                      placeholder={`${data?.section_two_head3}`}
                      required=""
                    />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label  style={{  color: "#4a4a4a"}} htmlFor="restore-postcode">{data?.section_two_head4}</label>
                    <input
                      type="text"
                      className="form-control"
                      name="postcode"
                      id="restore-postcode"
                      defaultValue=""
                      placeholder={`${data?.section_two_head4}`}
                      required=""
                    />
                  </div>
                </div>
              </div>
              <div className="form-group">
              {
                (data?.section_two_terms) && 
                <label  style={{  color: "#4a4a4a"}} htmlFor="agree_to_terms" className=" text-normal">
                  <input
                    type="checkbox"
                    name="agree_to_terms"
                    id="agree_to_terms"
                    className="float-left mt-1"
                    style={{height:"27px",width:'27px',maxWidth:'27px',marginRight:'5px'}}
                    required=""
                   
                  />
                             { <Markup content={data?.section_two_terms}  /> }
             {/* <span className="text-dark px-1   " style={{marginLeft:'5px'  }}>
                 <p style={{color:"#4a4a4a"}} className='sectiontwo-text-dark '> { <Markup content={data?.section_two_terms}  /> }</p></span>{" "} */}
                  {/* <NavLink
                    to={'/'}                      target="_blank"
                  >
                    Privacy Policy
                  </NavLink>{" "}
                  &amp;{" "}
                  <NavLink
                    to={'/'}                      target="_blank"
                  >
                    Terms &amp; Conditions
                  </NavLink> */}
                 . 
                </label>
              }
                
              </div>
              {
                (data?.section_two_button) && 
                <div className="text-center mt-2">
                <button
                  type="submit"
                  className="btn btn-lg btn-rounded btn-pink book-consult-button d-table"
                >
                 {data?.section_two_button}
                </button>
              </div>
              }
              
            </form>
          </div>
        </div> 
        {
          <Markup content={data?.section_two_footer } className='review-div-center-span'/>
        }
       
      </div>
    </div>
  </div>
  </div>
  )
}

export default SectionTwo