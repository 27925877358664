import { Markup } from 'interweave'
import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'


function SectionTwentyOne(props) {
  const [data,setdata]= useState(props.data)
  console.log('data')
 console.log(data)
  useEffect(() => {
    setdata(props.data)
  }, [props.data])
 
    console.log('section 21')
    console.log(data)
  return (
<>
<div className="main-section" style={{ backgroundImage: "url(https://cloud1.coe-website.securestaging.co.uk/images/header-images/_hero/herobanner-woman_200605_083756.jpg?mtime=20200605093756&amp;focal=none&amp;tmtime=20200729170441)",
 background: data?.section_21_color || "#6e92ac"
  }}>
          <div className="container" >
            <div className="row " >
              <div className="col-lg-10 col-xl-7 text-color-for-all-page  review-div-h1" style={{ marginTop:'50px'}}>
                <h1 className="page-title mb-3" style={{color:'#fff'}}>{data?.section_21_title}</h1>
                {
                  <Markup content={data?.section_21_description}   />
                }
                
                {/* <h2 className="mb-md-4" style={{color:'#fff',}}>Permanently replace missing teeth with dental implants at your local trusted clinic</h2> */}
              </div>

            {/* <div className="col-xl-3 pb-3 order-xl-2">
            <div className="treatment-header-image mobile">
<img src={data?.section_21_image1} className="" alt={data?.section_21_image1_alt}/>
<span className="monthly-price-from">
<span className="from" >From</span>
<span className="price" style={{fontWeight:'500'}}>{data?.section_21_circle_number}</span>
<span className="per-month">Per Month</span>
</span>
</div>
            </div> */}
      
              {/* <img className="mobile" src="https://cloud1.coe-website.securestaging.co.uk/images/header-images/_hero/herobanner-woman_200605_083756.jpg?mtime=20200605093756&amp;focal=none&amp;tmtime=20200729170441" alt="Dental implants" /> */}

              <div className="col-md-7 col-lg-7 col-xl-8 boxes-block-banner">
                <div className="row mb-3">
                {
                    (data?.section_21_price_month || data?.section_21_price_total) &&
                    <div className="col-xl-3 pb-3 order-xl-2">
        <div className="row h-100">
          <div className="col px-2">
            <div className="card card-body card-purple card-headline-pricing text-center h-100">
              <div className="row">
              {/* <div className='icon-container-myclinic mx-auto'>
                            <img src={data?.section_21_image2}  alt={data?.section_21_image2_alt} />
                            </div> */}
                <div className="col-12 col-lg-12">
                  <span>From</span>
                  <span className="monthly-price">{data?.section_21_price_month}</span>
                  <span className="monthly-price-pm">Per Month</span>
                </div>
                <div className="col-12 col-lg-12">
                  <span className="total-price">or {data?.section_21_price_total}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>}
                  <div className="col-xl-9">
                    <div className="row">
                    {
                      (data?.section_21_head1) && 
                      <div className="col-6 col-sm-4 pb-3 px-2">
                        <div className="card card-body card-headline  justify-content-center h-100 ">
                          {/* <div className='icon-container-myclinic'>
                            <img src={data?.section_21_image3}  alt={data?.section_21_image3_alt}  />
                            </div> */}
                          <h4>{data?.section_21_head1}</h4>
                        </div>
                      </div>

                    }
                
                    {
                      (data?.section_21_head2) && 
                      <div className="col-6 col-sm-4 pb-3 px-2">
                        <div className="card card-body card-headline  justify-content-center h-100">
                        {/* <div className='icon-container-myclinic'>
                            <img src={data?.section_21_image4}  alt={data?.section_21_image4_alt}  />
                            </div> */}
                          <h4>{data?.section_21_head2}</h4>
                        </div>
                      </div>
                    }

                    {
                      (data?.section_21_head3) && 
                      <div className="col-6 col-sm-4 pb-3 px-2">
                        <div className="card card-body card-headline  justify-content-center h-100">
                        {/* <div className='icon-container-myclinic'>
                            <img src={data?.section_21_image5}  alt={data?.section_21_image5_alt}  />
                            </div> */}
                          <h4>{data?.section_21_head3}</h4>
                        </div>
                      </div>
                    } 
                      
                    {
                      (data?.section_21_head4) &&
                      <div className="col-6 col-sm-4 pb-3 px-2">
                        
                        <div className="card card-body card-headline  justify-content-center h-100">
                        {/* <div className='icon-container-myclinic'>
                            <img src={data?.section_21_image6}  alt={data?.section_21_image6_alt}  />
                            </div> */}
                          <h4>{data?.section_21_head4}</h4>
                        </div>
                      </div>
                    }

                    {
                      (data?.section_21_head5) &&
                      <div className="col-6 col-sm-4 pb-3 px-2">
                        
                        <div className="card card-body card-headline  justify-content-center h-100">
                        {/* <div className='icon-container-myclinic'>
                            <img src={data?.section_21_image7}  alt={data?.section_21_image7_alt}  />
                            </div> */}
                          <h4>{data?.section_21_head5}</h4>
                        </div>
                      </div>
                    }

                    {
                      (data?.section_21_head6) &&
                      <div className="col-6 col-sm-4 pb-3 px-2">
                        <div className="card card-body card-headline  justify-content-center h-100 ">
                          <h4><span className="green-stars"><i className="fas fa-star"></i><i className="fas fa-star"></i><i className="fas fa-star"></i><i className="fas fa-star"></i><i className="fas fa-star"></i></span>{data?.section_21_head6}</h4>
                        </div>
                      </div>
                    }
                      
                      
                     
                    </div>
                  </div>
                  {/* {
                    (data?.section_21_price_month || data?.section_21_price_total) &&
                    <div className="col-xl-3 pb-3">
                    <div className="row h-100">
                      <div className="col px-2">
                        <div className="card card-body card-purple card-headline-pricing text-center h-100">
                          <div className="row">
                            <div className="col-12 col-lg-12">
                              <span>From</span>
                              <span className="monthly-price">{data?.section_21_price_month}</span>
                              <span className="monthly-price-pm">Per Month</span>
                            </div>
                            <div className="col-12 col-lg-12">
                              <span className="total-price">or {data?.section_21_price_total}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  } */}
                  
                </div>
                <div className='my-5'>
                <a href={data?.sec_21_button_link} className="btn btn-lg btn-rounded btn-pink book-consult-button py-3 px-5 button-show-and-hide text-white">
                 {data?.section_21_button}
                </a>
                </div>
                
           
              </div>
            </div>
          </div>
        </div>
</>
  )
}

export default SectionTwentyOne