import { Markup } from 'interweave'
import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'

function SectionThirtyOne(props) {
  const [data,setdata]= useState(props.data)
  const [isExpanded1, setIsExpanded1] = useState(false);
  const [isExpanded2, setIsExpanded2] = useState(false);
  const [isExpanded3, setIsExpanded3] = useState(false);
  const [isExpanded4, setIsExpanded4] = useState(false);
  const [isExpanded5, setIsExpanded5] = useState(false);
  console.log('data')
 console.log(data)
  useEffect(() => {
    setdata(props.data)
  }, [props.data])
 
    console.log('section 31')
    console.log(data)


  const toggleReadMore = (setIsExpanded, isExpanded) => {
    setIsExpanded(!isExpanded);
  };
  return (
<>
<div className="section pricing-section section-9774 bg-off-white" style={{background:data?.section_31_color || ''}}>
      <div className="container">
        <div className="row">
          <div className="col-xl-8 offset-xl-2 col-lg-10 offset-lg-1 pb-0 text-center">
            <h2 className="mb-4">{data?.section_31_title}</h2>
          </div>
          <div className="col-12 mt-5">
            {/* <div className="row text-center mb-3 d-flex justify-content-center hide-score">
            {
              (data?.section_31_title1 || data?.section_31_detail1 || data?.section_31_price1) &&
              <div className="col-6 col-sm-6 col-lg-4 pb-3 px-sm-3 mt-70 ">
                <a
                  href={data?.sec_31_link1}
                  className="noDecoration h-100 d-block"
                >
                  {" "}
                  <div className="card card-pricing-page custom h-100 card-9775">
                    <div className="card-body card-body-teatments">
                      <h3>{data?.section_31_title1}</h3>
                      <div className="desc desc-treatment">
                        <p>
                          {data?.section_31_detail1}
                        </p>
                      </div>
                      <span className=" total-price-teatments">
                        From
                        <br />
                        <b>{data?.section_31_price1}</b>
                        <br />
                        Per Month
                      </span>
                    </div>
                    <div className="card-image-container bottom-of-card text-right">
                      <img style={{float:'right'}}
                        src="https://myclinique.net/volumes/local-images/icons/fixed-braces.png"
                        alt=""
                        width={200}
                        height={111}
                      />
                    </div>
                  </div>
                </a>{" "}
              </div>
            }
              {
                (data?.section_31_title2 || data?.section_31_detail2 || data?.section_31_price2) &&
                <div className="col-6 col-sm-6 col-lg-4 pb-3 px-sm-3 mt-70 ">
                <a
                  href={data?.sec_31_link2}
                  className="noDecoration h-100 d-block"
                >
                  {" "}
                  <div className="card card-pricing-page custom h-100 card-10024">
                    <div className="card-body card-body-teatments">
                      <h3>{data?.section_31_title2}</h3>
                      <div className="desc  desc-treatment">
                        <p>
                          {data?.section_31_detail2}
                        </p>
                      </div>
                      <span className="total-price-teatments">
                        From
                        <br />
                        <b>{data?.section_31_price2}</b>
                        <br />
                        Per Month
                      </span>
                    </div>
                    <div className="card-image-container bottom-of-card text-right">
                      <img style={{float:'right'}}
                        src="https://myclinique.net/volumes/local-images/icons/fixed-braces.png"
                        alt=""
                        width={200}
                        height={129}
                      />
                    </div>
                  </div>
                </a>{" "}
              </div>
              }
              
              {
                (data?.section_31_title3 || data?.section_31_detail3 || data?.section_31_price3) &&
                <div className="col-6 col-sm-6 col-lg-4 pb-3 px-sm-3 mt-70 ">
                <a
                  href={data?.sec_31_link3}
                  className="noDecoration h-100 d-block"
                >
                  {" "}
                  <div className="card card-pricing-page custom h-100 card-10025">
                    <div className="card-body card-body-teatments">
                      <h3>{data?.section_31_title3}</h3>
                      <div className="desc  desc-treatment">
                        <p>
                          {data?.section_31_detail3}
                        </p>
                      </div>
                      <span className="total-price-teatments">
                        From
                        <br />
                        <b>{data?.section_31_price3}</b>
                        <br />
                        Per Month
                      </span>
                    </div>
                    <div className="card-image-container bottom-of-card text-center">
                      <img style={{float:'right'}}
                        src="https://myclinique.net/volumes/local-images/icons/fixed-braces.png"
                        alt=""
                        width={200}
                        height={86}
                      />
                    </div>
                  </div>
                </a>{" "}
              </div>
              }
              
              {
                (data?.section_31_title4 || data?.section_31_detail4 || data?.section_31_price4) && 
                <div className="col-6 col-sm-6 col-lg-4 pb-3 px-sm-3 mt-70 ">
                <a
                  href={data?.sec_31_link4}
                  className="noDecoration h-100 d-block"
                >
                  {" "}
                  <div className="card card-pricing-page custom h-100 card-10026">
                    <div className="card-body card-body-teatments">
                      <h3>{data?.section_31_title4}</h3>
                      <div className="card-image-container above-desc text-center">
                        <img  className='mx-auto'
                         src="https://myclinique.net/volumes/local-images/icons/fixed-braces.png"
                         alt=""
                          width={200}
                          height={124}
                        />
                      </div>
                      <div className="desc  desc-treatment">
              
                        <p>{data?.section_31_detail4}</p>
                      </div>
                      <span className="total-price-teatments">
                        From
                        <br />
                        <b>{data?.section_31_price4}</b>
                        <br />
                        Per Month
                      </span>
                    </div>
                  </div>
                </a>{" "}
              </div>
              }
              
              {
                (data?.section_31_title5 || data?.section_31_detail5 || data?.section_31_price5) && 
                <div className="col-6 col-sm-6 col-lg-4 pb-3 px-sm-3 mt-70 ">
                <a
                  href={data?.sec_31_link5}
                  className="noDecoration h-100 d-block"
                >
                  {" "}
                  <div className="card card-pricing-page custom h-100 card-10027">
                    <div className="card-body card-body-teatments">
                      <h3>{data?.section_31_title5}</h3>
                      <div className="card-image-container above-desc text-center">
                        <img className='mx-auto'
                          src="https://myclinique.net/volumes/local-images/icons/fixed-braces.png"
                          alt=""
                          width={200}
                          height={161}
                        />
                      </div>
                      <div className="desc  desc-treatment">
                 
                        <p>
                          {data?.section_31_detail5}
                        </p>
                      </div>
                      <span className="total-price-teatments">
                        From
                        <br />
                        <b>{data?.section_31_price5}</b>
                        <br />
                        Per Month
                      </span>
                    </div>
                  </div>
                </a>{" "}
                </div>
              }
              
            </div> */}
             {/* <div className="row text-center mb-3 d-flex justify-content-center hide-score" >
                  {
                  (data?.section_31_title1 || data?.section_31_detail1 || data?.section_31_price1) &&
                    <div className="col-6 col-sm-6 col-lg-4 pb-3 px-sm-3 mt-70 add-image-new  pt-lg-5 pt-md-5 pt-sm-5 mb-lg-5 mb-md-5">
                      <a href={data?.sec_31_link1} className="card card-treatment-range  h-100">
                        <div className="card-body-bg" style={{backgroundImage: 'url(https://cloud1.coe-website.securestaging.co.uk/images/treatment-range/_card/image2.png?mtime=20200514033336&focal=none&tmtime=20200729125406)', backgroundPosition: 'bottom right' }}>
                          <div className="card-body card-body-single-teatments " >
                            <h3>{data?.section_31_title1}</h3>
                            <div className="desc desc-treatment tarmandcondition-hide"><p>{data?.section_31_detail1}</p></div>
                            <span className=" total-price-single-teatments price-circal-new ">
                              From<br />
                              <b>{data?.section_31_price1}</b><br />
                              Per Month
                            </span>
                          </div>
                        </div>
                        <div className="col-auto" style={{position:'absolute',bottom:'0' }}>
                          <img src={data?.section_31_image1} 
                            alt={data?.section_31_image1_alt} style={{width:"130px"}} className='mx-auto'
                          />
                        </div>
                      </a>
                    </div>
                  }
                    
                  {
                    (data?.section_31_title2 || data?.section_31_detail2 || data?.section_31_price2) &&
                    <div className="col-6 col-sm-6 col-lg-4 pb-3 px-sm-3 mt-70 add-image-new  pt-lg-5 pt-md-5 pt-sm-5 mb-lg-5 mb-md-5">
                      <a href={data?.sec_31_link1} className="card card-treatment-range  h-100">
                        <div className="card-body-bg" style={{ backgroundImage: 'url(https://cloud1.coe-website.securestaging.co.uk/images/treatment-range/_card/image3.png?mtime=20200514033337&focal=none&tmtime=20200729125407)', backgroundPosition: 'bottom right' }}>
                          <div className="card-body card-body-single-teatments ">
                            <h3>{data?.section_31_title2}</h3>
                            <div className="desc desc-treatment tarmandcondition-hide"><p>{data?.section_31_detail2}</p></div>
                            <span className=" total-price-single-teatments price-circal-new ">
                              From<br />
                              <b>{data?.section_31_price2}</b><br />
                              Per Month
                            </span>
                          </div>
                        </div>
                        <div className="col-auto" style={{position:'absolute',bottom:'0' }}>
                          <img src={data?.section_31_image2} 
                            alt={data?.section_31_image2_alt} style={{width:"130px"}} className='mx-auto'
                          />
                        </div>
                      </a>
                    </div>
                  }

                  {
                    (data?.section_31_title3 || data?.section_31_detail3 || data?.section_31_price3) &&
                    <div className="col-6 col-sm-6 col-lg-4 pb-3 px-sm-3 mt-70 add-image-new  pt-lg-5 pt-md-5 pt-sm-5 mb-lg-5 mb-md-5">
                      <a href={data?.sec_31_link1} className="card card-treatment-range  h-100">
                        <div className="card-body-bg" style={{ backgroundImage: 'url(https://cloud1.coe-website.securestaging.co.uk/images/treatment-range/_card/image4.png?mtime=20200514033337&focal=none&tmtime=20200729125407)', backgroundPosition: 'bottom center' }}>
                          <div className="card-body card-body-single-teatments ">
                            <h3>{data?.section_31_title3}</h3>
                            <div className="desc desc-treatment tarmandcondition-hide"><p>{data?.section_31_detail3}</p></div>
                            <span className=" total-price-single-teatments price-circal-new ">
                              From<br />
                              <b>{data?.section_31_price3}</b><br />
                              Per Month
                            </span>
                          </div>
                        </div>
                        <div className="col-auto" style={{position:'absolute',bottom:'0' }}>
                          <img src={data?.section_31_image3} 
                            alt={data?.section_31_image3_alt} style={{width:"130px"}} className='mx-auto'
                          />
                        </div>
                      </a>
                    </div>
                  }
                    
                  {
                    (data?.section_31_title4 || data?.section_31_detail4 || data?.section_31_price4) &&
                    <div className="col-6 col-sm-6 col-lg-4 pb-3 px-sm-3 mt-70 add-image-new  pt-lg-5 pt-md-5 pt-sm-5 mb-lg-5 mb-md-5" >
                      <a href={data?.sec_31_link1} className="card card-treatment-range  h-100">
                        <div className="card-body-bg" style={{ backgroundImage: 'url(https://cloud1.coe-website.securestaging.co.uk/images/treatment-range/_card/image5.png?mtime=20200514033337&focal=none&tmtime=20200729125408)', backgroundPosition: 'bottom center' }}>
                          <div className="card-body card-body-single-teatments ">
                            <h3>{data?.section_31_title4}</h3>
                            <div className="desc desc-treatment tarmandcondition-hide"><p>{data?.section_31_detail4}</p></div>
                            <span className=" total-price-single-teatments price-circal-new ">
                              From<br />
                              <b>{data?.section_31_price4}</b><br />
                              Per Month
                            </span>
                          </div>
                        </div>
                        <div className="col-auto" style={{position:'absolute',bottom:'0' }}>
                          <img src={data?.section_31_image4} 
                            alt={data?.section_31_image4_alt} style={{width:"130px"}} className='mx-auto'
                          />
                        </div>
                      </a>
                    </div>
                  } 
                    
                  
                  {
                    (data?.section_31_title5 || data?.section_31_detail5 || data?.section_31_price5) &&
                    <div className="col-6 col-sm-6 col-lg-4 pb-3 px-sm-3 mt-70 add-image-new  pt-lg-5 pt-md-5 pt-sm-5">
                      <a href={data?.sec_31_link1} className="card card-treatment-range  h-100">
                        <div className="card-body-bg" style={{ backgroundImage: 'url(https://cloud1.coe-website.securestaging.co.uk/images/treatment-range/_card/image6.png?mtime=20200514033337&focal=none&tmtime=20200729125408)', backgroundPosition: 'bottom center' }}>
                          <div className="card-body card-body-single-teatments ">
                            <h3>{data?.section_31_title5}</h3>
                            <div className="desc desc-treatment tarmandcondition-hide "><p>{data?.section_31_detail5}</p></div>
                            <span className=" total-price-single-teatments price-circal-new ">
                              From<br />
                              <b>{data?.section_31_price5}</b><br />
                              Per Month
                            </span>
                          </div>
                        </div>
                        <div className="col-auto" style={{position:'absolute',bottom:'0' }}>
                          <img src={data?.section_31_image5} 
                            alt={data?.section_31_image5_alt} style={{width:"130px"}} className='mx-auto'
                          />
                        </div>
                      </a>
                    </div>
                  }
                    
                
                    
                  </div> */}

                  <div className="row text-center mb-3 d-flex justify-content-center hide-score">
      {(data?.section_31_title1 || data?.section_31_detail1 || data?.section_31_price1) && (
        <div className="col-6 col-sm-6 col-lg-4 pb-3 px-sm-3 mt-70 add-image-new pt-lg-5 pt-md-5 pt-sm-5 mb-lg-5 mb-md-5">
          <a href={data?.sec_31_link1} className="card card-treatment-range h-100">
            <div className="card-body-bg" style={{ backgroundImage: 'url(https://cloud1.coe-website.securestaging.co.uk/images/treatment-range/_card/image2.png?mtime=20200514033336&focal=none&tmtime=20200729125406)', backgroundPosition: 'bottom right' }}>
              <div className="card-body card-body-single-teatments">
                <h3>{data?.section_31_title1}</h3>
                <div className="desc desc-treatment tarmandcondition-hide">
                  <p>
                    {isExpanded1 ? data?.section_31_detail1 : data?.section_31_detail1.slice(0, 250)}
                    {data?.section_31_detail1.length > 250 && (
                      <span>
                        {!isExpanded1 ? '...' : ''}
                        <span style={{color:"blue"}} className="read-more-btn" onClick={() => toggleReadMore(setIsExpanded1, isExpanded1)}>
                          {isExpanded1 ? 'Read Less' : 'Read More'}
                        </span>
                      </span>
                    )}
                  </p>
                </div>
                <span className="total-price-single-teatments price-circal-new">
                  From<br />
                  <b>{data?.section_31_price1}</b><br />
                  Per Month
                </span>
              </div>
            </div>
            <div className="col-auto" style={{ position: 'absolute', bottom: '0' }}>
              <img src={data?.section_31_image1} alt={data?.section_31_image1_alt} style={{ width: "130px" }} className='mx-auto' />
            </div>
          </a>
        </div>
      )}

      {(data?.section_31_title2 || data?.section_31_detail2 || data?.section_31_price2) && (
        <div className="col-6 col-sm-6 col-lg-4 pb-3 px-sm-3 mt-70 add-image-new pt-lg-5 pt-md-5 pt-sm-5 mb-lg-5 mb-md-5">
          <a href={data?.sec_31_link1} className="card card-treatment-range h-100">
            <div className="card-body-bg" style={{ backgroundImage: 'url(https://cloud1.coe-website.securestaging.co.uk/images/treatment-range/_card/image3.png?mtime=20200514033337&focal=none&tmtime=20200729125407)', backgroundPosition: 'bottom right' }}>
              <div className="card-body card-body-single-teatments">
                <h3>{data?.section_31_title2}</h3>
                <div className="desc desc-treatment tarmandcondition-hide">
                  <p>
                    {isExpanded2 ? data?.section_31_detail2 : data?.section_31_detail2.slice(0, 250)}
                    {data?.section_31_detail2.length > 250 && (
                      <span>
                        {!isExpanded2 ? '...' : ''}
                        <span style={{color:"blue"}} className="read-more-btn" onClick={() => toggleReadMore(setIsExpanded2, isExpanded2)}>
                          {isExpanded2 ? 'Read Less' : 'Read More'}
                        </span>
                      </span>
                    )}
                  </p>
                </div>
                <span className="total-price-single-teatments price-circal-new">
                  From<br />
                  <b>{data?.section_31_price2}</b><br />
                  Per Month
                </span>
              </div>
            </div>
            <div className="col-auto" style={{ position: 'absolute', bottom: '0' }}>
              <img src={data?.section_31_image2} alt={data?.section_31_image2_alt} style={{ width: "130px" }} className='mx-auto' />
            </div>
          </a>
        </div>
      )}

      {(data?.section_31_title3 || data?.section_31_detail3 || data?.section_31_price3) && (
        <div className="col-6 col-sm-6 col-lg-4 pb-3 px-sm-3 mt-70 add-image-new pt-lg-5 pt-md-5 pt-sm-5 mb-lg-5 mb-md-5">
          <a href={data?.sec_31_link1} className="card card-treatment-range h-100">
            <div className="card-body-bg" style={{ backgroundImage: 'url(https://cloud1.coe-website.securestaging.co.uk/images/treatment-range/_card/image4.png?mtime=20200514033337&focal=none&tmtime=20200729125407)', backgroundPosition: 'bottom center' }}>
              <div className="card-body card-body-single-teatments">
                <h3>{data?.section_31_title3}</h3>
                <div className="desc desc-treatment tarmandcondition-hide">
                  <p>
                    {isExpanded3 ? data?.section_31_detail3 : data?.section_31_detail3.slice(0, 250)}
                    {data?.section_31_detail3.length > 250 && (
                      <span>
                        {!isExpanded3 ? '...' : ''}
                        <span style={{color:"blue"}} className="read-more-btn" onClick={() => toggleReadMore(setIsExpanded3, isExpanded3)}>
                          {isExpanded3 ? 'Read Less' : 'Read More'}
                        </span>
                      </span>
                    )}
                  </p>
                </div>
                <span className="total-price-single-teatments price-circal-new">
                  From<br />
                  <b>{data?.section_31_price3}</b><br />
                  Per Month
                </span>
              </div>
            </div>
            <div className="col-auto" style={{ position: 'absolute', bottom: '0' }}>
              <img src={data?.section_31_image3} alt={data?.section_31_image3_alt} style={{ width: "130px" }} className='mx-auto' />
            </div>
          </a>
        </div>
      )}

      {(data?.section_31_title4 || data?.section_31_detail4 || data?.section_31_price4) && (
        <div className="col-6 col-sm-6 col-lg-4 pb-3 px-sm-3 mt-70 add-image-new pt-lg-5 pt-md-5 pt-sm-5 mb-lg-5 mb-md-5">
          <a href={data?.sec_31_link1} className="card card-treatment-range h-100">
            <div className="card-body-bg" style={{ backgroundImage: 'url(https://cloud1.coe-website.securestaging.co.uk/images/treatment-range/_card/image5.png?mtime=20200514033337&focal=none&tmtime=20200729125408)', backgroundPosition: 'bottom center' }}>
              <div className="card-body card-body-single-teatments">
                <h3>{data?.section_31_title4}</h3>
                <div className="desc desc-treatment tarmandcondition-hide">
                  <p>
                    {isExpanded4 ? data?.section_31_detail4 : data?.section_31_detail4.slice(0, 250)}
                    {data?.section_31_detail4.length > 250 && (
                      <span>
                        {!isExpanded4 ? '...' : ''}
                        <span style={{color:"blue"}} className="read-more-btn" onClick={() => toggleReadMore(setIsExpanded4, isExpanded4)}>
                          {isExpanded4 ? 'Read Less' : 'Read More'}
                        </span>
                      </span>
                    )}
                  </p>
                </div>
                <span className="total-price-single-teatments price-circal-new">
                  From<br />
                  <b>{data?.section_31_price4}</b><br />
                  Per Month
                </span>
              </div>
            </div>
            <div className="col-auto" style={{ position: 'absolute', bottom: '0' }}>
              <img src={data?.section_31_image4} alt={data?.section_31_image4_alt} style={{ width: "130px" }} className='mx-auto' />
            </div>
          </a>
        </div>
      )}

      {(data?.section_31_title5 || data?.section_31_detail5 || data?.section_31_price5) && (
        <div className="col-6 col-sm-6 col-lg-4 pb-3 px-sm-3 mt-70 add-image-new pt-lg-5 pt-md-5 pt-sm-5">
          <a href={data?.sec_31_link1} className="card card-treatment-range h-100">
            <div className="card-body-bg" style={{ backgroundImage: 'url(https://cloud1.coe-website.securestaging.co.uk/images/treatment-range/_card/image6.png?mtime=20200514033337&focal=none&tmtime=20200729125408)', backgroundPosition: 'bottom center' }}>
              <div className="card-body card-body-single-teatments">
                <h3>{data?.section_31_title5}</h3>
                <div className="desc desc-treatment tarmandcondition-hide">
                  <p>
                    {isExpanded5 ? data?.section_31_detail5 : data?.section_31_detail5.slice(0, 250)}
                    {data?.section_31_detail5.length > 250 && (
                      <span>
                        {!isExpanded5 ? '...' : ''}
                        <span style={{color:"blue"}} className="read-more-btn" onClick={() => toggleReadMore(setIsExpanded5, isExpanded5)}>
                          {isExpanded5 ? 'Read Less' : 'Read More'}
                        </span>
                      </span>
                    )}
                  </p>
                </div>
                <span className="total-price-single-teatments price-circal-new">
                  From<br />
                  <b>{data?.section_31_price5}</b><br />
                  Per Month
                </span>
              </div>
            </div>
            <div className="col-auto" style={{ position: 'absolute', bottom: '0' }}>
              <img src={data?.section_31_image5} alt={data?.section_31_image5_alt} style={{ width: "130px" }} className='mx-auto' />
            </div>
          </a>
        </div>
      )}
    </div>


          </div>
          <div className="col-xl-8 offset-xl-2 col-lg-10 offset-lg-1 text-center form-css-hidden">
          {
            (data?.section_31_button) &&
         <a href={data?.sec_31_button_link}
              className="btn btn-lg btn-rounded btn-pink book-consult-button text-white"
            >
              {data?.section_31_button}
            </a>
          }
            
          </div>
        </div>
      </div>
    </div>
</>

  )
}

export default SectionThirtyOne