import { Markup } from 'interweave'
import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'

function SectionTwenty(props) {
  const [data,setdata]= useState(props.data)
  console.log('data')
 console.log(data)
  useEffect(() => {
    setdata(props.data)
  }, [props.data])
 
    console.log('section 20')
    console.log(data)
  return (
<>
  {/* <div className='container'>
    <div className="row text-center">
        <div className="row hide-score"> */}
         <div className="section cost-benefits-section bg-off-white" style={{background:data?.section_20_color || ''}}>
          <div className="container text-center">
            <div className="row hide-score">
              
     {/* <div className="col-6 col-sm-6 col-lg-3 mb-3 mt-md-60 pr-2 pr-lg-3">
      <a href className="card card-treatment-review h-100">
        
        <div className="card-body">
          <h4>Dental Implants<br />Reviews</h4>
          <div className="review-img">
            <img
          //    src="assets/img/dental-implant.png"
          src='https://trustedclinics.com/images/reviews/dental-implant.png' 
            className="img-fluid" alt="Dental Implants Reviews" />
          </div>
          <p className="review-desc">Excellent</p>
          <p className="review-rating">(9.5/10)</p>
          <p className="review-stars">
            <img src="assets/img/star.png" className="img-fluid" alt="star Reviews" />
          </p>
          <p className="review-count">2,224 reviews</p>
        </div>
      </a>
    </div> */}
    {/* <div className="col-6 col-sm-6 col-lg-3 mb-3 mt-md-60 pl-2 pl-lg-3">
      <a href className="card card-treatment-review h-100">
        <div className="card-body">
          <h4 className="allow-wider">Full Mouth Implant Reviews</h4>
          <div className="review-img">
            <img 
          //   src="assets/img/full-mouth-reconstruction2.png"
          src='https://trustedclinics.com/images/reviews/full-mouth-reconstruction.png'
             className="img-fluid" alt="Full Mouth Reconstruction Reviews" />
          </div>
          <p className="review-desc">Excellent</p>
          <p className="review-rating">(9.5/10)</p>
          <p className="review-stars">
            <img src="assets/img/star.png" className="img-fluid" alt="star Reviews" />
          </p>
          <p className="review-count">2,224 reviews</p>
        </div>
      </a>
    </div> */}
    {/* <div className="col-6 col-sm-6 col-lg-3 mb-3 mt-md-60 pr-2 pr-lg-3">
      <a href className="card card-treatment-review h-100">
        <div className="card-body">
          <h4>Invisalign <span>Aligners</span><br />Reviews</h4>
          <div className="review-img">
            <img 
          //   src="assets/img/invisalign.png"
          src='https://trustedclinics.com/images/reviews/invisalign.png'
             className="img-fluid" alt="Invisalign Reviews" />
          </div>
          <p className="review-desc">Excellent</p>
          <p className="review-rating">(9.5/10)</p>
          <p className="review-stars">
            <img 
            src="assets/img/star.png" 
          // src='https://trustedclinics.com/images/reviews/invisalign.png'
            className="img-fluid" alt="star Reviews" />
          </p>
          <p className="review-count">2,224 reviews</p>
        </div>
      </a>
    </div>  */}
     {/* { (data?.section_20_head1 || data?.section_20_image1 || data?.section_20_foot1 || data?.section_20_rating1) &&
    <div className="col-6 col-sm-6 col-lg-3 mb-3 mt-md-60 pl-2 pl-lg-3">
      <NavLink
          to={'/'}  className="card card-treatment-review h-100">
        <div className="card-body">
          <h4>  {  data?.section_20_head1}</h4>
          {
              (data?.section_20_image1) &&
              <div className="review-img">
              <img
                src={ data?.section_20_image1 }
                className="img-fluid"
                alt="issue occured"
              />
            </div>
            }
          <p className="review-desc">{ data?.section_20_foot1}</p>
          <p className="review-rating">(9.5/10)</p>
          <p className="review-stars">
            <img src="assets/img/star.png" className="img-fluid" alt="star Reviews" /> */}
          {/* </p>
          <p className="review-count">{data?.section_20_rating1 }</p>
        </div>
      </NavLink>
    </div>} */}


    {
      (data?.section_20_head1 || data?.section_20_image1 || data?.section_20_foot1 || data?.section_20_rating1) &&
      <div className="col-6 col-sm-6 col-lg-3 mb-3 mt-md-60 pr-2 pr-lg-3">
        <a   href={data?.section_20_link1}  className="card card-treatment-review h-100" >
          <div className="card-body">
            <h4>
            {  data?.section_20_head1}
              
            
            </h4>
            {
              (data?.section_20_image1) &&
              <div className="review-img">
              <img
                src={ data?.section_20_image1 }
                className="img-fluid"
                alt={data?.section_20_image1_alt}
              />
            </div>
            }
           
            <p className="review-desc">{ data?.section_20_foot1}</p>
        
            <p className="review-stars">
              <img
                src="assets/img/star.png"
                className="img-fluid"
                alt="issue occured"
              />
            </p>
            <p className="review-count">{data?.section_20_rating1 }</p>
          </div>
        </a>
      </div>
    }
    
    {
      (data?.section_20_head2 || data?.section_20_image2 || data?.section_20_foot2 || data?.section_20_rating2) &&
      <div className="col-6 col-sm-6 col-lg-3 mb-3 mt-md-60 pl-2 pl-lg-3 left5">
        <a   href={data?.section_20_link2}         className="card card-treatment-review h-100"
        >
          <div className="card-body">
            <h4 className="allow-wider">{data?.section_20_head2 }</h4>
            {
              (data?.section_20_image2) &&
              <div className="review-img">
              <img
                src={data?.section_20_image2 }
                className="img-fluid"
                alt={data?.section_20_image2_alt}
              />
            </div>
            }
            
            <p className="review-desc">{ data?.section_20_foot2}</p>
         
            <p className="review-stars">
              <img
                src="assets/img/star.png"
                className="img-fluid"
                alt="issue occured"
              />
            </p>
            <p className="review-count">{data?.section_20_rating2}</p>
          </div>
        </a>
      </div>
    }
    
    {
      (data?.section_20_head3 || data?.section_20_image3 || data?.section_20_foot3 || data?.section_20_rating3) &&
      <div className="col-6 col-sm-6 col-lg-3 mb-3 mt-md-60 pr-2 pr-lg-3 right5">
        <a   href={data?.section_20_link3}          className="card card-treatment-review h-100"
        >
          <div className="card-body">
          <h4>
            { data?.section_20_head3 }
            </h4>
            {
              (data?.section_20_image3) &&
              <div className="review-img">
              <img
                src={data?.section_20_image3}
                className="img-fluid"
                alt={data?.section_20_image3_alt}
              />
            </div>
            }
            
            <p className="review-desc">{ data?.section_20_foot3}</p>
        
            <p className="review-stars">
              <img
                src="assets/img/star.png"
                className="img-fluid"
                alt="issue occured"
              />
            </p>
            <p className="review-count">{ data?.section_20_rating3 }</p>
          </div>
        </a>
      </div>
    }
    
    {
      (data?.section_20_head4 || data?.section_20_image4 || data?.section_20_foot4 || data?.section_20_rating4) &&
      <div className="col-6 col-sm-6 col-lg-3 mb-3 mt-md-60 pl-2 pl-lg-3 left5">
        <a   href={data?.section_20_link4}       className="card card-treatment-review h-100"
        >
          <div className="card-body">
               <h4>
               {data?.section_20_head4}
            </h4>
            {
              (data?.section_20_image4) &&
              <div className="review-img">
              <img
                src={data?.section_20_image4 }
                className="img-fluid"
                alt={data?.section_20_image4_alt}
              />
            </div>
            }
            
            <p className="review-desc">{ data?.section_20_foot4}</p>
          
            <p className="review-stars">
              <img
                src="assets/img/star.png"
                className="img-fluid"
                alt="issue occured"
              />
            </p>
            <p className="review-count">{data?.section_20_rating4 }</p>
          </div>
        </a>
      </div>
    }
        </div>
    </div>
  </div> 


</>
  )
}

export default SectionTwenty