import { Markup } from 'interweave'
import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'

function SectionSix(props) {
  const [data,setdata]= useState(props.data)
  console.log('data')
 console.log(data)
  useEffect(() => {
    setdata(props.data)
  }, [props.data])
 
    console.log('sectionsix')
    console.log(data)
  
  return (

      // <div className="bg-price m-0">
      //     <div className="container">
      //       <h1 className="text-white text-center pt-3">{ data?.section_six_title}</h1>
      //       <div className="row mb-lg-5 py-4"> 
            
      //         <div className="col-12 col-xl-4 mb-2">
      //         {
      //         (data?.section_six_banner) &&
      //           <img className="mobile" src={data?.section_six_banner} alt="issue occured" />
      //         }
      //         </div>
            
              
            
      //         <div className="col-12 col-xl-8 text-center">
      //           <div className="row">
      //           {
      //             (data?.section_six_image1 || data?.section_six_head1) &&
      //             <div className="col-md-4 pr-2 pl-2 mb-3">
      //               <div className="card card-treatment h-100">
      //                 <div className="card-body">
      //                 {
      //                   (data?.section_six_image1) &&
      //                   <div className="treatment-icon">
      //                     <img className="icon-four" src={data?.section_six_image1} width={45} height={49} alt="issue occured" />
      //                   </div>
      //                 }
                        
      //                   <h4>{ data?.section_six_head1}</h4>
      //                 </div>
      //               </div>
      //             </div>
      //           }
                
      //           {
      //             (data?.section_six_image2 || data?.section_six_head2) &&
      //             <div className="col-md-4 pr-2 pl-2 mb-3">
      //               <div className="card card-treatment h-100">
      //                 <div className="card-body">
      //                 {
      //                   (data?.section_six_image2) &&
      //                   <div className="treatment-icon">
      //                     <img className="icon-four" src={data?.section_six_image2} width={45} height={49} alt="issue occured" />
      //                   </div>
      //                 }
                        
      //                   <h4>{data?.section_six_head2}</h4>
      //                 </div>
      //               </div>
      //             </div>
      //           }
                  
      //           {
      //             (data?.section_six_image3 || data?.section_six_head3) &&
      //             <div className="col-md-4 pr-2 pl-2 mb-3">
      //               <div className="card card-treatment h-100">
      //                 <div className="card-body ">
      //                 {
      //                   (data?.section_six_image3) &&
      //                   <div className="treatment-icon ">
      //                     <img className="icon-four" src={data?.section_six_image3} width={45} height={49} alt="issue occured" />
      //                   </div>
      //                 }
                        
      //                   <h4>{data?.section_six_head3}</h4>
      //                 </div>
      //               </div>
      //             </div>
      //           }
                  
                 
      //             <div className="row px-2">
      //               <div className="col-12 offset-xl-2  mt-3  text-center">
      //                 <h3 className="mb-4 text-white">{  data?.section_six_right_head}</h3>
      //               </div>
      //               <div className="col-12 offset-lg-2   offset-xl-2 text-center">
      //                 <form action method="POST" className="form" id="findYourClinicForm">
      //                   <input type="hidden" name="CRAFT_CSRF_TOKEN" defaultValue />
      //                   <div className="form-group mb-2">
      //                     <label htmlFor="postcode" className="sr-only">Postcode</label>
      //                     <div className="input-group">
      //                       <input type="text" name="postcode" id="postcode" className="form-control" placeholder={(data?.section_six_placeholder) && `${data?.section_six_placeholder}`} />
      //                       <div className="input-group-append text-purple">
      //                         <span className="input-group-text" id="basic-addon2"><i className="fas fa-map-marker-alt" /></span>
      //                       </div>
      //                     </div>
      //                   </div>
      //                   <div className="form-group mb-3">
      //                     <div id="rollerLoader" className="text-center" style={{display: 'none'}}>
      //                       <div data-v-01d979ac className="lds-roller" style={{width: '24px', height: '24px'}}><div data-v-01d979ac style={{animationDuration: '1.2s', transformOrigin: '12px 12px', animationDelay: '-0.036s'}}><div data-v-01d979ac className="div-after" style={{width: '2.1px', height: '2.1px', margin: '-1.2px 0px 0px -1.2px', background: 'rgb(255, 255, 255)', top: '18.9px', left: '18.9px'}} /></div><div data-v-01d979ac style={{animationDuration: '1.2s', transformOrigin: '12px 12px', animationDelay: '-0.072s'}}>
      //                         <div data-v-01d979ac className="div-after" style={{width: '2.1px', height: '2.1px', margin: '-1.2px 0px 0px -1.2px', background: 'rgb(255, 255, 255)', top: '18.9px', left: '18.9px'}} />
      //                       </div><div data-v-01d979ac style={{animationDuration: '1.2s', transformOrigin: '12px 12px', animationDelay: '-0.108s'}}><div data-v-01d979ac className="div-after" style={{width: '2.1px', height: '2.1px', margin: '-1.2px 0px 0px -1.2px', background: 'rgb(255, 255, 255)', top: '20.4px', left: '16.8px'}} /></div><div data-v-01d979ac style={{animationDuration: '1.2s', transformOrigin: '12px 12px', animationDelay: '-0.144s'}}><div data-v-01d979ac className="div-after" style={{width: '2.1px', height: '2.1px', margin: '-1.2px 0px 0px -1.2px', background: 'rgb(255, 255, 255)', top: '21.3px', left: '14.4px'}} />
      //                       </div><div data-v-01d979ac style={{animationDuration: '1.2s', transformOrigin: '12px 12px', animationDelay: '-0.18s'}}>
      //                         <div data-v-01d979ac className="div-after" style={{width: '2.1px', height: '2.1px', margin: '-1.2px 0px 0px -1.2px', background: 'rgb(255, 255, 255)', top: '21.6px', left: '12px'}} /></div><div data-v-01d979ac style={{animationDuration: '1.2s', transformOrigin: '12px 12px', animationDelay: '-0.216s'}}><div data-v-01d979ac className="div-after" style={{width: '2.1px', height: '2.1px', margin: '-1.2px 0px 0px -1.2px', background: 'rgb(255, 255, 255)', top: '21.3px', left: '9.6px'}} /></div><div data-v-01d979ac style={{animationDuration: '1.2s', transformOrigin: '12px 12px', animationDelay: '-0.252s'}}>
      //                           <div data-v-01d979ac className="div-after" style={{width: '2.1px', height: '2.1px', margin: '-1.2px 0px 0px -1.2px', background: 'rgb(255, 255, 255)', top: '20.4px', left: '7.2px'}} />
      //                       </div><div data-v-01d979ac style={{animationDuration: '1.2s', transformOrigin: '12px 12px', animationDelay: '-0.288s'}}><div data-v-01d979ac className="div-after" style={{width: '2.1px', height: '2.1px', margin: '-1.2px 0px 0px -1.2px', background: 'rgb(255, 255, 255)', top: '18.9px', left: '5.1px'}} /></div></div>         
      //                          <span className="waiting-msg">{ 'Please wait while we get your location'}</span>
      //                     </div>
      //                     {
      //                       (data?.section_six_location) && 
      //                       <button type="button" id="useLocationBtn" className="btn text-white" >
      //                       <i className="fas fa-crosshairs text-white" /> 
      //                       {data?.section_six_location?.substring(0,26)}
      //                       </button>
      //                     }
                          
      //                   </div>
      //                   {
      //                     (data?.section_six_botton) && 
      //                     <button type="submit" className="btn btn-gold btn-rounded btn-lg" >{data?.section_six_botton?.substring(0,25) ||  "Search Trusted Clinics"}</button>
      //                   }
                        
      //                 </form>   
      //               </div>
      //             </div>
      //           </div>
      //         </div>
      //       </div>
      //     </div>
      //   </div>
      <div className="bg-price page-dentists-near-me " style={{background:data?.section_6_color || ''}}>
        <div className="container">
          <h1 className="text-white text-center pt-3">
          { data?.section_six_title}
          </h1>

          <div className="row px-2 monthly-price-from-display">
                  <div className="col-sm-12 offset-xl-2 mt-3 text-center">
                    {/* <h3 className="mb-4 text-white">
                    {  data?.section_six_right_head}
                    </h3> */}
                    <h4 className="mb-lg-4 text-white"> {  data?.section_six_right_head}</h4>
                 
                    {/* <form  className="form" id="findYourClinicForm"> */}
                      {/* <input type="hidden" name="CRAFT_CSRF_TOKEN" defaultValue /> */}
                      <div className="form-group mb-2">
                        <label htmlFor="postcode" className="sr-only">Postcode</label>
                        <div className="input-group">
                          <input type="text" name="postcode" id="postcode" className="form-control" placeholder={(data?.section_six_placeholder) && `${data?.section_six_placeholder}`}  />
                          <div className="input-group-append text-purple">
                            <span className="input-group-text" id="basic-addon2"><i className="fas fa-map-marker-alt" /></span>
                          </div>
                        </div>
                      </div>
                      <div className="form-group mb-3">
                        <div id="rollerLoader" className="text-center" style={{display: 'none'}}>
                          <div data-v-01d979ac className="lds-roller" style={{width: '24px', height: '24px'}}>
                            <div data-v-01d979ac style={{animationDuration: '1.2s', transformOrigin: '12px 12px', animationDelay: '-0.036s'}}>
                              <div data-v-01d979ac className="div-after" style={{width: '2.1px', height: '2.1px', margin: '-1.2px 0px 0px -1.2px', background: 'rgb(255, 255, 255)', top: '18.9px', left: '18.9px'}} />
                            </div>
                            <div data-v-01d979ac style={{animationDuration: '1.2s', transformOrigin: '12px 12px', animationDelay: '-0.072s'}}>
                              <div data-v-01d979ac className="div-after" style={{width: '2.1px', height: '2.1px', margin: '-1.2px 0px 0px -1.2px', background: 'rgb(255, 255, 255)', top: '18.9px', left: '18.9px'}} />
                            </div>
                            <div data-v-01d979ac style={{animationDuration: '1.2s', transformOrigin: '12px 12px', animationDelay: '-0.108s'}}>
                              <div data-v-01d979ac className="div-after" style={{width: '2.1px', height: '2.1px', margin: '-1.2px 0px 0px -1.2px', background: 'rgb(255, 255, 255)', top: '20.4px', left: '16.8px'}} />
                            </div>
                            <div data-v-01d979ac style={{animationDuration: '1.2s', transformOrigin: '12px 12px', animationDelay: '-0.144s'}}>
                              <div data-v-01d979ac className="div-after" style={{width: '2.1px', height: '2.1px', margin: '-1.2px 0px 0px -1.2px', background: 'rgb(255, 255, 255)', top: '21.3px', left: '14.4px'}} />
                            </div>
                            <div data-v-01d979ac style={{animationDuration: '1.2s', transformOrigin: '12px 12px', animationDelay: '-0.18s'}}>
                              <div data-v-01d979ac className="div-after" style={{width: '2.1px', height: '2.1px', margin: '-1.2px 0px 0px -1.2px', background: 'rgb(255, 255, 255)', top: '21.6px', left: '12px'}} />
                            </div>
                            <div data-v-01d979ac style={{animationDuration: '1.2s', transformOrigin: '12px 12px', animationDelay: '-0.216s'}}>
                              <div data-v-01d979ac className="div-after" style={{width: '2.1px', height: '2.1px', margin: '-1.2px 0px 0px -1.2px', background: 'rgb(255, 255, 255)', top: '21.3px', left: '9.6px'}} />
                            </div>
                            <div data-v-01d979ac style={{animationDuration: '1.2s', transformOrigin: '12px 12px', animationDelay: '-0.252s'}}>
                              <div data-v-01d979ac className="div-after" style={{width: '2.1px', height: '2.1px', margin: '-1.2px 0px 0px -1.2px', background: 'rgb(255, 255, 255)', top: '20.4px', left: '7.2px'}} />
                            </div>
                            <div data-v-01d979ac style={{animationDuration: '1.2s', transformOrigin: '12px 12px', animationDelay: '-0.288s'}}>
                              <div data-v-01d979ac className="div-after" style={{width: '2.1px', height: '2.1px', margin: '-1.2px 0px 0px -1.2px', background: 'rgb(255, 255, 255)', top: '18.9px', left: '5.1px'}} />
                            </div>
                          </div>
                          <span className="waiting-msg">Please wait while we get your location</span>
                        </div>
                       {(data?.section_six_location) &&  
                       <button type="button" className="btn text-white">
                          <i className="fas fa-crosshairs text-white" /> 
                          {data?.section_six_location?.substring(0,26)}
                        </button>
                        }
                      
                      </div> 
                      
                     {     (data?.section_six_botton) &&   
                     <button type="button" className="btn btn-gold btn-rounded btn-lg">
                    {data?.section_six_botton?.substring(0,25) ||  "Search Trusted Clinics"}
                      </button>
                      } 
                    
                    {/* </form> */}
                  </div>
                </div> 
          <div className="row mb-lg-5 py-4 ">
            <div className="col-12 col-xl-4 mb-2 monthly-price-from-none">
            {
              (data?.section_six_banner) &&
                <img className="mobile" src={data?.section_six_banner} alt={data?.section_six_banner}
                style={{maxWidth: '349px',width: '90%'}} />
           }
            </div>
          
            <div className="col-12 col-xl-8 text-center">
              <div className="row">
              {  (data?.section_six_image1 || data?.section_six_head1) && <div className="col-md-4 p-circle">
                  <div className="circle circle-2">
                    <div className="content">
                    { (data?.section_six_image1) &&  <div className="image-holder">
                        <img height={40} src={data?.section_six_image1} alt={data?.section_six_image1_alt} />
                      </div>}
                      <p>{data?.section_six_head1}.</p>
                    </div>
                  </div>
                </div>}
               {  (data?.section_six_image2 || data?.section_six_head2) && <div className="col-md-4 p-circle">
                  <div className="circle circle-3">
                    <div className="content">
                     {(data?.section_six_image2) && <div className="image-holder">
                        <img height={40} src={data?.section_six_image2} alt={data?.section_six_image2_alt} />
                      </div>}
                      <p>{data?.section_six_head2}.</p>
                    </div>
                  </div>
                </div>}
             {  (data?.section_six_image3 || data?.section_six_head3) &&   <div className="col-md-4 p-circle">
                  <div className="circle circle-4">
                    <div className="content">
                      <div className="image-holder">
                        <img height={40} src={data?.section_six_image3} alt={data?.section_six_image3_alt} />
                      </div>
                      <p>{data?.section_six_head3}</p>
                    </div>
                  </div>
                </div>} 
                <div className="col-12 col-xl-4 mb-2 monthly-price-from-display">
            {
              (data?.section_six_banner) &&
                <img className="mobile" src={data?.section_six_banner} alt={data?.section_six_banner} style={{maxWidth: '349px',width: '90%',marginTop:'10px', marginLeft: 'auto', marginRight: 'auto'}} />
           }
            </div>
               <div className="row px-2 monthly-price-from-none">
                  <div className="col-12 offset-xl-2 mt-3 text-center">
                    {/* <h3 className="mb-4 text-white">
                    {  data?.section_six_right_head}
                    </h3> */}
                    <h4 className="mb-lg-4 text-white"> {  data?.section_six_right_head}</h4>
                  </div>
                  <div className="col-12 offset-lg-2 offset-xl-2 text-center">
                    {/* <form action method="POST" className="form" id="findYourClinicForm">
                      <input type="hidden" name="CRAFT_CSRF_TOKEN" defaultValue /> */}
                      <div className="form-group mb-2">
                        <label htmlFor="postcode" className="sr-only">Postcode</label>
                        <div className="input-group">
                          <input type="text" name="postcode" id="postcode" className="form-control" placeholder={(data?.section_six_placeholder) && `${data?.section_six_placeholder}`}  />
                          <div className="input-group-append text-purple">
                            <span className="input-group-text" id="basic-addon2"><i className="fas fa-map-marker-alt" /></span>
                          </div>
                        </div>
                      </div>
                      <div className="form-group mb-3">
                        <div id="rollerLoader" className="text-center" style={{display: 'none'}}>
                          <div data-v-01d979ac className="lds-roller" style={{width: '24px', height: '24px'}}>
                            <div data-v-01d979ac style={{animationDuration: '1.2s', transformOrigin: '12px 12px', animationDelay: '-0.036s'}}>
                              <div data-v-01d979ac className="div-after" style={{width: '2.1px', height: '2.1px', margin: '-1.2px 0px 0px -1.2px', background: 'rgb(255, 255, 255)', top: '18.9px', left: '18.9px'}} />
                            </div>
                            <div data-v-01d979ac style={{animationDuration: '1.2s', transformOrigin: '12px 12px', animationDelay: '-0.072s'}}>
                              <div data-v-01d979ac className="div-after" style={{width: '2.1px', height: '2.1px', margin: '-1.2px 0px 0px -1.2px', background: 'rgb(255, 255, 255)', top: '18.9px', left: '18.9px'}} />
                            </div>
                            <div data-v-01d979ac style={{animationDuration: '1.2s', transformOrigin: '12px 12px', animationDelay: '-0.108s'}}>
                              <div data-v-01d979ac className="div-after" style={{width: '2.1px', height: '2.1px', margin: '-1.2px 0px 0px -1.2px', background: 'rgb(255, 255, 255)', top: '20.4px', left: '16.8px'}} />
                            </div>
                            <div data-v-01d979ac style={{animationDuration: '1.2s', transformOrigin: '12px 12px', animationDelay: '-0.144s'}}>
                              <div data-v-01d979ac className="div-after" style={{width: '2.1px', height: '2.1px', margin: '-1.2px 0px 0px -1.2px', background: 'rgb(255, 255, 255)', top: '21.3px', left: '14.4px'}} />
                            </div>
                            <div data-v-01d979ac style={{animationDuration: '1.2s', transformOrigin: '12px 12px', animationDelay: '-0.18s'}}>
                              <div data-v-01d979ac className="div-after" style={{width: '2.1px', height: '2.1px', margin: '-1.2px 0px 0px -1.2px', background: 'rgb(255, 255, 255)', top: '21.6px', left: '12px'}} />
                            </div>
                            <div data-v-01d979ac style={{animationDuration: '1.2s', transformOrigin: '12px 12px', animationDelay: '-0.216s'}}>
                              <div data-v-01d979ac className="div-after" style={{width: '2.1px', height: '2.1px', margin: '-1.2px 0px 0px -1.2px', background: 'rgb(255, 255, 255)', top: '21.3px', left: '9.6px'}} />
                            </div>
                            <div data-v-01d979ac style={{animationDuration: '1.2s', transformOrigin: '12px 12px', animationDelay: '-0.252s'}}>
                              <div data-v-01d979ac className="div-after" style={{width: '2.1px', height: '2.1px', margin: '-1.2px 0px 0px -1.2px', background: 'rgb(255, 255, 255)', top: '20.4px', left: '7.2px'}} />
                            </div>
                            <div data-v-01d979ac style={{animationDuration: '1.2s', transformOrigin: '12px 12px', animationDelay: '-0.288s'}}>
                              <div data-v-01d979ac className="div-after" style={{width: '2.1px', height: '2.1px', margin: '-1.2px 0px 0px -1.2px', background: 'rgb(255, 255, 255)', top: '18.9px', left: '5.1px'}} />
                            </div>
                          </div>
                          <span className="waiting-msg">Please wait while we get your location</span>
                        </div>
                       {(data?.section_six_location) &&  <button type="button" id="useLocationBtn" className="btn text-white">
                          <i className="fas fa-crosshairs text-white" /> 
                          {data?.section_six_location?.substring(0,26)}
                        </button>
                        }
                      
                      </div> 
                      
                     {     (data?.section_six_botton) &&   <button type="button" className="btn btn-gold btn-rounded btn-lg">
                    {data?.section_six_botton?.substring(0,25) ||  "Search Trusted Clinics"}
                      </button>
                      } 
                    
                    {/* </form> */}
                  </div>
                </div> 
              
              </div>
            </div>
            
          </div>
        </div>
        
      </div>
  )
}

export default SectionSix