import { Markup } from 'interweave'
import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'

function SectionSixtyTwo(props) {
  const [data,setdata]= useState(props.data)
  console.log('data')
 console.log(data)
  useEffect(() => {
    setdata(props.data)
  }, [props.data])
 
    console.log('section 62')
    console.log(data)
  return (

    <div className=" bg-grey" style={{background:data?.section_62_color || '' ,paddingBottom: '20px'}}>
    <div className="container bg-grey">
            <div className="clinics-results">
                <div className="card clinic-listing-card best-match"  style={{ marginTop: 0,  marginBottom: '25px'}}>
                    <div className="card-row-1">
                    <div className="d-flex">
                      <div className="branding">
                        <div className="logo-container p-1">
                          <img 
                          // src="assets/img/dUUZ0GCCvGQrDUpZiM5p.png" 
                          src={data?.section_62_logo}
                          className="img-fluid max" alt="Alexandra Dental" />
                        </div>
                        <div className="logo-title" style={{float:'left'}}>
                          <h4><a href>{data?.section_62_name}</a></h4>
                        </div>
                      </div>
                      <div className="contact">
                        <a href="tel:01442 256335" className="tel">{data?.section_62_phone}</a><br />
                        <p className="address">{data?.section_62_address}</p>
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div className="card-row-2">
                    <div className="d-flex">
                      <div className="img-block">
                      </div>
                      <div className="profile">
                        <div className="d-flex">
                          <div className="treatments" >
                           <div className="row">
                           <h4 style={{float:'left',paddingLeft:'10px'}}>{data?.section_62_title}</h4>
                           </div>
                            <div className="row">
                              <div className="col-6">
                                {/* {
                                    <Markup content={data?.section_62_viewall} />
                                } */}
                              <a  to={'/alexandra_dental'} className="text-primary" style={{cursor:'pointer',float:'left'}}>View all offered treatments <i className="fas fa-chevron-right" /></a>
                              </div>
                            </div>
                          </div>
                          <div className="reviews">
                            <div className="card review-score-card card-body">
                              <p className="desc">Excellent</p>
                              <p className="reviews-score">{data?.section_62_excellent}</p>
                              <p className="review-stars">
                                <i className="fas fa-star" /><i className="fas fa-star" /><i className="fas fa-star" /><i className="fas fa-star" /><i className="fas fa-star" />                          </p>
                              <p className="reviews-count">{data?.section_62_reviews}</p>
                            </div>
                          </div>
                        </div>
                        <div className="clinic-intro text-left-alexandra_dental">
                          {/* <p className="mb-0">
                            The practice dates back to 1976 and has built a strong reputation based on strong patient care, a highly trained team and the latest technologies, all delivered in a caring and friendly environment. <br />
                            Today their services offered are general appointments, cosmetics, braces, implants and advanced straightening technologies such as Invisalign. <br />
                            The practice has invested a considerable amount of time and money over the past few years upgrading the practice and improving their facilities. They have embraced state of the art techniques designed to improve their service in every respect.</p> */}
                            <Markup content={data?.section_60_description}/>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div className="card-row-3">
                    <div className="d-flex">
                      <div className="languages">
                        <b style={{float:'left'}}>Languages Spoken: </b>
                        <span style={{float:'left'}}>{data?.section_62_languages} </span>
                      </div>
                      <div className="buttons text-right">
                        <NavLink  to={'/DentistsNearMe_New'} className="more-info mr-3" style={{cursor:'pointer'}}>View more information <i className="fas fa-chevron-right" /></NavLink>
                        <a href={data?.section_62_button_link} className="btn btn-pink btn-rounded btn-smaller text-white button-show-and-hide text-white">{data?.section_62_button?.substring(0,30)}</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
        </div>
        </div>
  )
}

export default SectionSixtyTwo