import { Markup } from 'interweave'
import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'

function SectionNine(props) {
  const [data,setdata]= useState(props.data)
  console.log('data')
 console.log(data)
  useEffect(() => {
    setdata(props.data)
  }, [props.data])
 
    console.log('sectionone')
    console.log(data)
  
  return (
 
  //   <div className="news-bg">
  //   <div className="container">
  //     <div className="row mb-lg-5 py-5">
  //       <div className="col-md-7 col-lg-6 col-xl-6 text-center">
  //         <h1>{data?.section_one_title || "Trusted Clinics News Stories"}</h1>
  //     { <Markup content={ data?.section_one_detail }/>  || <>
  //       <p>At Trusted Clinics, we are highly invested in the world of dentistry and are committed to keeping up to date with all the latest dental news. In turn, we share that news with you. Ensure you are always kept in the loop and made aware of all dental updates by taking a look through our dental stories. Learn more about current trends and
  //           developments in the world of dentistry.</p> 
  //         <p>The dental world is ever changing and developments are constantly being formulated. From progressive, innovative treatments to highly technical dentistry equipment, there are always new and exciting advancements being made. Whether you have an interest in teeth straightening treatments such as clear aligners, or more traditional dental work like crowns, bridges and dental implants, this is the place to receive updated information about the
  //           procedures and how they are ever improving.</p> 
  //         <p>
  //           Click on one of the news stories below to have a more in-depth look into the latest
  //           developments happening in world of dentistry.   
  //         </p> </>}
  //       </div>
  //       <div className="col-md-4">
  //         <img className="mobile" src={ data?.section_one_image || "https://trustedclinics.com/images/pages/pricing/new/ipad.png"} alt="News banner image" />
  //       </div>
  //     </div>
  //   </div>
  // </div>
  <div className="news-bg" style={{background:data?.section_9_color || ''}}>
  <div className="container">
    <div className="row mb-lg-5 py-5">
      <div className="col-md-7 col-lg-6 col-xl-6 text-left">
        <h1>{data?.section_nine_title}</h1>
    { <Markup content={ data?.section_nine_detail }/> }
      </div>
      <div className="col-md-4">
        {/* <img className="mobile" src={ data?.section_nine_image} alt="" /> */}
      </div>
    </div>
  </div>
</div>
  )
}

export default SectionNine