import React, { useEffect, useState } from 'react'
import { Markup } from 'interweave'
import './blogsection.css'
import { 
    FacebookShareButton, 
    TwitterShareButton, 
    WhatsappShareButton, 
    LinkedinShareButton,
    FacebookIcon, 
    TwitterIcon, 
    WhatsappIcon ,
    LinkedinIcon
} from 'react-share';
function SocialMediaSection(props) {
  const currentUrl = window.location.href;
  const [data,setdata]= useState(props.data)
  const [tagsArray, settagsArray] = useState([]);
  useEffect(() => {
    setdata(props.data)
    if(props.data.tags && props.data.tags!=="" && props.data.tags !==null){
        const tagsData = props?.data?.tags.split(',').map(tag => tag.trim());
        settagsArray(tagsData)
    }
   
  }, [props.data])
 
    console.log('SocialMediaSection')
    console.log(data)



  return (
    <>
       <section className='pb-5 pl-3 pr-3 blog_section_2'>
                {/* {
                  (data?.section_two_title) &&
                  <p className='blog-color-primary blog-main-heading'>{data?.section_two_title}</p>
                } */}
                
                

                <div className='container-fluid p-0'>
                {
                    (tagsArray && tagsArray?.length > 0) && <>
                    <div className="container my-4">
                    <span className='mb-3' style={{fontSize:"22px",fontWeight:"bold",color:"#001854"}}>Tags :</span>
                    
                    <div className='my-4'>
                        {tagsArray.map((tag, index) => (
                        <span key={index} className="badge badge-primary mr-2 p-2" style={{fontSize:"16px",background:"#A34687",cursor:"pointer"}}>
                            {tag}
                        </span>
                        ))}
                    </div>

                    </div>
                    <hr></hr>
                    </>
                }

                {/* {
                    (data?.social_media && data?.social_media ==="yes") &&
                    <>  */}
                    <div className='my-3'>
                        <span className='mb-3 ml-3' style={{fontSize:"22px",fontWeight:"bold",color:"#001854"}}>
                            Share blog on :
                        </span>
                        <div className="row col-12 col-md-6 my-4">
                        
                            <div className="col-3 p-0 m-0"  >
                            <FacebookShareButton url={currentUrl} quote="Check this out!">
                                <FacebookIcon size={40} round />
                            </FacebookShareButton>
                            </div>
                            <div className="col-3 p-0 m-0">
                            <TwitterShareButton url={currentUrl} title="Check this out!">
                                <TwitterIcon size={40} round />
                            </TwitterShareButton>
                            </div>
                            <div className="col-3 p-0 m-0">
                            <WhatsappShareButton url={currentUrl} title="Check this out!">
                                <WhatsappIcon size={40} round />
                            </WhatsappShareButton>
                            </div>
                            <div className="col-3 p-0 m-0">
                            <LinkedinShareButton url={currentUrl} title="Check this out!">
                                <LinkedinIcon size={40} round />
                            </LinkedinShareButton>
                            </div>
                        </div>
                        <hr></hr>
                    </div>   
                    {/* </>
                } */}
                </div>
                
               

                
                </section>
    </>
  )
}

export default SocialMediaSection
