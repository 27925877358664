import { Markup } from 'interweave'
import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'

function SectionFiftyFour(props) {
  const [data,setdata]= useState(props.data)
  console.log('data')
 console.log(data)
  useEffect(() => {
    setdata(props.data)
  }, [props.data])
 
    console.log('section 54')
    console.log(data)
  
  return (


<>
<div className="section bg-white partners" id="pricing" 
style={{background:data?.section_54_color || ''}}
>
        <div className="container">
          <div className="row">
            <div className="col-xl-8 offset-xl-2 col-lg-10 offset-lg-1 pb-0 text-center">
              <h2 className="mb-4 initial-text-transform">
                {/* Compare  */}
                {data?.section_54_title1}

              <img src={data?.section_54_banner} className='price-img' alt="Trusted Clinics" />
               {/* pricing plans */}
               {data?.section_54_title2}
               </h2>
              <p>{data?.section_54_header_description}</p>
            </div>
            {/*<div class="col-12 mb-2">
                    <div class="card card-body bg-purple text-center h-100">
                        <h2 class="bigger mb-4 initial-text-transform">Post-pandemic recovery offer</h2>

                        <h4>Supporting your business through uncertain times</h4>
                        <p>Trusted Clinics is committed to providing our partner clinics the support they need, when they need it most. Ensuring steady new business post-pandemic will be essential to the survival of many private clinics. Trusted Clinics have designed a stable growth platform which will allow clinics to regain momentum and support growth.</p>

                        <hr/>

                        <h3 class="white pt-2 pb-2">£1,000 booking request credit</h3>

                        <h3 class="gold pb-2">£6,800 worth of features included</h3>

                        <div>
                            <p>This will cover all your onboarding costs, including:</p>
                           <div class="d-flex flex-column flex-md-row">
                                <ul class="middle">
                                    <li>Building your clinic profile to optimise conversion</li>
                                    <li>Unique content creation</li>
                                    <li>Access to all features, including our enquiries</li>
                                </ul>
                                <ul class="middle">
                                    <li>Consultation and system training</li>
                                    <li>Individual practice re-marketing system and patient management system</li>
                                    <li>Video creation</li>
                                </ul>
                            </div>
                        </div>

                        <p class="bigger pb-2">Your £1,000 will be added to your booking request credit account and will cover your first 20 booking requests after your initial free period.</p>


                        <hr/>

                        <img src="https://trustedclinics.com/images/partners/pan-icon1.png" alt="Free Enquires Up Until 1st October 2020"/>
                        <h4 class="gold initial-text-transform">No charge until 1st October 2020</h4>
                        <p>Up until the 1st of October 2020, you will receive booking requests free of charge to help get your business back on its feet.</p>

                        <hr/>

                        <img src="https://trustedclinics.com/images/partners/pan-icon2.png" alt="No Monthly Fee Until January 2021"/>
                        <h4 class="gold initial-text-transform">No monthly fee until 1st January 2021</h4>
                        <p>Up until the 1st of January 2021, you will not be charged the monthly fee but will still be given full access to all of Trusted Clinics’ premium features. Enquiries will be charged at £50 per booking request after your initial £1,000 credit has been used.</p>

                        <hr/>

                        <img src="https://trustedclinics.com/images/partners/pan-icon3.png" alt="We still guarantee an additional £350,000 of revenue per practice."/>
                        <h4 class="gold initial-text-transform">Guaranteed additional £350,000 revenue per practice</h4>
                        <p>Throughout turbulent times where nothing is certain, we still honour our guarantee of £350,000 in additional income per clinic by 21/01/2022, or we work for free until it is achieved.</p>
                    </div>
                </div>*/}
            <div className="col-12 mt-3">
              <div className="row justify-content-center">
                <div className="col-md-6 col-lg-4 mb-4 mb-lg-2">
                  <div className="card card-body bg-off-white text-center h-100">
                    <h2>{data?.section_54_left1}</h2>
                    <p>{data?.section_54_left2}</p>
                    <div className="circle-holder">
                      <div className="circle-inner">
                        <div className="inner">
                          <h3>{data?.section_54_left3}</h3>
                        </div>
                      </div>
                    </div>
                    <ul>
                      <li><strong>{data?.section_54_left4}</strong></li>
                      <li>{data?.section_54_left5}</li>
                      <li><hr style={{marginBottom: 0}} /></li>
                    </ul>
                    <ul>
                      <li><strong>{data?.section_54_left6}</strong></li>
                      <li>{data?.section_54_left7}</li>
                      <li><hr style={{marginBottom: 0}} /></li>
                    </ul>
                    <ul>
                      <li><strong>{data?.section_54_left8}</strong></li>
                      <li>
                        {/* Minimum consultation requests<br />per month */}
                        <Markup content={data?.section_54_left9} />
                      </li>
                      <li><hr style={{marginBottom: 0}} /></li>
                    </ul>
                    <ul>
                      <li><strong>{data?.section_54_left10}</strong></li>
                      <li>{data?.section_54_left11}</li>
                      <li><hr style={{marginBottom: 0}} /></li>
                    </ul>
                    <ul>
                      <li><strong>{data?.section_54_left12}</strong></li>
                      <li>{data?.section_54_left13}</li>
                      <li><hr style={{marginBottom: 0}} /></li>
                    </ul>
                    <ul className="line-through">
                      <li>
                        <div className="line" />
                        <strong>{data?.section_54_left14}</strong>
                      </li>
                      <li>{data?.section_54_left15}</li>
                    </ul>
                    <p className="red">
                    {data?.section_54_left16}
                    </p>
                    <a href={data?.section_54_left_button_link}
                     target="_blank" className="btn btn-pink btn-rounded btn-lg ml-auto mr-auto mt-1 mb-2 d-table  text-white">{data?.section_54_left_button.substring(0,18)}</a>
                  </div>
                </div>
                <div className="col-md-6 col-lg-4 mb-4 mb-lg-2">
                  <div className="card card-body bg-off-white text-center h-100">
                    <h2>{data?.section_54_right1}</h2>
                    <p>
                      
                    {data?.section_54_right2} </p>
                    <div className="circle-holder">
                      <div className="circle-inner">
                        <div className="inner">
                          {/* <h3>{data?.section_54_right3}</h3>
                          <h4>{data?.section_54_right4}</h4> */}
                          {<Markup content={data?.section_54_right3} />}
                        </div>
                      </div>
                    </div>
                    <ul>
                      <li><strong>{data?.section_54_right4}</strong></li>
                      <li>{data?.section_54_right5}</li>
                      <li><hr style={{marginBottom: 0}} /></li>
                    </ul>
                    <ul>
                      <li><strong>{data?.section_54_right6}</strong></li>
                      <li>{data?.section_54_right7}</li>
                      <li><hr style={{marginBottom: 0}} /></li>
                    </ul>
                    <ul>
                      <li><strong>{data?.section_54_right8}</strong></li>
                      <li>
                        {/* Minimum consultation requests<br />
                      per month */}
                      {<Markup content={data?.section_54_right9}/>}
                      </li>
                      <li><hr style={{marginBottom: 0}} /></li>
                    </ul>
                    <ul>
                      <li><strong>{data?.section_54_right10}</strong></li>
                      <li>{data?.section_54_right11}</li>
                      <li><hr style={{marginBottom: 0}} /></li>
                    </ul>
                    <ul>
                      <li><strong>{data?.section_54_right12}</strong></li>
                      <li>{data?.section_54_right13}</li>
                      <li><hr style={{marginBottom: 0}} /></li>
                    </ul>
                    <ul className="line-through">
                      <li>
                        <strong>{data?.section_54_right14}</strong>
                      </li>
                      <li>{data?.section_54_right15}</li>
                    </ul>
                    <p className="red">
                      &nbsp;
                    </p>
                    <a href={data?.section_54_right_button_link} target="_blank" className="btn btn-pink btn-rounded btn-lg ml-auto mr-auto mt-1 mb-2 d-table text-white">{data?.section_54_right_button?.substring(0,17)}</a>
                  </div>
                </div>
                {/*<div class="col-md-6 col-lg-4 mb-2 mb-lg-2">
                            <div class="card card-body bg-off-white text-center h-100">
                                <h2>Enterprise</h2>
                                <p>For multi site clinics or clinics that want an income guarantee greater than £350,000.</p>

                                <p class="blue">
                                    What income guarantee do you require?
                                </p>
                                <label for="guarantee_amount" class="sr-only">Guarantee amount</label>
                                <div class="amount">
                                    <input type="number" name="guarantee_amount" id="guarantee_amount" placeholder="Enter Amount">
                                </div>
                                <p class="error" id="input_errors" style="display:none"></p>

                                <div class="circle-holder">
                                    <div class="circle-inner">
                                        <div class="inner">
                                            <h3>£<span id="monthly"></span></h3>
                                        </div>
                                    </div>
                                </div>

                                <ul>
                                    <li>12 month commitment</li>
                                </ul>
                                <ul>
                                    <li><strong>£<span id="per_enquiry">50</span></strong></li>
                                    <li>Per enquiry</li>
                                </ul>
                                <ul>
                                    <li><strong>£<span id="guarantee"></span></strong></li>
                                    <li>Annual income guarantee</li>
                                </ul>
                                <ul class="pb-2">
                                    <li><strong><span id="min_reqs_per_month"></span></strong></li>
                                    <li>Minimum consultation requests<br/>per month</li>
                                </ul>
                                <a href="https://outlook.office365.com/owa/calendar/TrustedClinics1@trustedclinics.com/bookings/" target="_blank" class="btn btn-pink btn-rounded btn-lg ml-auto mr-auto mt-1 mb-2 d-table">Book My Free Demo</a>
                            </div>
                        </div>*/}
              </div>
            </div>
          </div>
        </div>
      </div>
</>
  )
}

export default SectionFiftyFour