// import { Markup } from 'interweave'
// import React, { useEffect, useState } from 'react'
// import { NavLink } from 'react-router-dom'

// function SectionThirtyNine(props) {
//   const [data,setdata]= useState(props.data)
//   console.log('data')
//  console.log(data)
//   useEffect(() => {
//     setdata(props.data)
//   }, [props.data])
 
//     console.log('section 39')
//     console.log(data)

//   return (
// <>
// <div className="section faqs-section section-6478 bg-white" style={{background:data?.section_39_color || ''}}>
//       <div className="container text-center">
//         <h2 className="mb-4">{data?.section_39_title}</h2>
//         <div className="row text-center d-flex justify-content-center">
          
//           <div className="col-lg-10 pt-2 pb-2">
//             <div className="simple-block">
//               <div className="icon-container mb-3">
//               {
//                 (data?.section_39_image1) &&
//                 <img
//                   src={data?.section_39_image1}
//                   alt={data?.section_39_image1_alt} style={{maxWidth:'300px'}} 
//                 />
//               }
                
//               </div>
//               <h4>{data?.section_39_head1}</h4>
//               <div>
//                 <p>
//                   {data?.section_39_detail1}
//                 </p>
//               </div>
//             </div>
//           </div>
//           <div className="col-lg-10 pt-2 pb-2">
//             <div className="simple-block">
//               <div className="icon-container mb-3">
//               {
//                 (data?.section_39_image2) &&
//                 <img
//                   src={data?.section_39_image2}
//                   alt={data?.section_39_image2_alt} style={{maxWidth:'300px'}}
//                 />
//               }
                
//               </div>
//               <h4>{data?.section_39_head2}</h4>
//               <div>
//                 <p>
//                   {data?.section_39_detail2}
//                 </p>
//               </div>
//             </div>
//           </div>
//           <div className="col-lg-10 pt-2 pb-2">
//             <div className="simple-block">
//               <div className="icon-container mb-3">
//               {
//                 (data?.section_39_image3) &&
//                 <img
//                   src={data?.section_39_image3}
//                   alt={data?.section_39_image3_alt} style={{maxWidth:'300px'}}
//                 />
//               }
                
//               </div>
//               <h4>{data?.section_39_head3}</h4>
//               <div>
//                 <p>
//                   {data?.section_39_detail3}
//                 </p>
//               </div>
//             </div>
//           </div>
//           <div className="col-lg-10 pt-2 pb-2">
//             <div className="simple-block">
//               <div className="icon-container mb-3">
//               {
//                 (data?.section_39_image4) && 
//                 <img
//                   src={data?.section_39_image4}
//                   alt={data?.section_39_image4_alt} style={{maxWidth:'300px'}}
//                 />
//               }
                
//               </div>
//               <h4>{data?.section_39_head4}</h4>
//               <div>
//                 <p>
//                   {data?.section_39_detail4}
//                 </p>
//               </div>
//             </div>
//           </div>
//         </div>
//         <a
//           href={data?.sec_39_button_link}
//           className="btn btn-md btn-rounded btn-pink book-consult-button text-white form-css-hidden"
//         >
//           {data?.section_39_button}
//         </a>
//       </div>
//     </div>
// </>

//   )
// }

// export default SectionThirtyNine

import { Markup } from 'interweave'
import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'

function SectionThirtyNine(props) {
  const [data, setData] = useState(props.data)
  
  useEffect(() => {
    setData(props.data)
  }, [props.data])
  
  const ReadMore = ({ text }) => {
    const [isReadMore, setIsReadMore] = useState(true);
    const toggleReadMore = () => {
      setIsReadMore(!isReadMore);
    };

    const truncatedText = text.length > 200 ? text.slice(0, 200) + "..." : text;

    return (
      <div>
        <p>
          {isReadMore ? truncatedText : text}
        </p>
        {text.length > 200 && (
          <button onClick={toggleReadMore} className="btn btn-link">
            {isReadMore ? "Read More" : "Read Less"}
          </button>
        )}
      </div>
    );
  };

  return (
    <>
      <div className="section faqs-section section-6478 bg-white" style={{ background: data?.section_39_color || '' }}>
        <div className="container text-center">
          <h2 className="mb-4">{data?.section_39_title}</h2>
          <div className="row text-center d-flex justify-content-center">
            <div className="col-md-6 col-12 pt-2 pb-2">
              <div className="simple-block">
                <div className="icon-container mb-3">
                  {data?.section_39_image1 && (
                    <img
                      src={data?.section_39_image1}
                      alt={data?.section_39_image1_alt}
                      style={{ width:"200px",height:"200px" }}
                    />
                  )}
                </div>
                <h4>{data?.section_39_head1}</h4>
                <div>
                  <ReadMore text={data?.section_39_detail1} />
                </div>
              </div>
            </div>
            <div className="col-md-6 col-12 pt-2 pb-2">
              <div className="simple-block">
                <div className="icon-container mb-3">
                  {data?.section_39_image2 && (
                    <img
                      src={data?.section_39_image2}
                      alt={data?.section_39_image2_alt}
                      style={{ width:"200px",height:"200px" }}
                    />
                  )}
                </div>
                <h4>{data?.section_39_head2}</h4>
                <div>
                  <ReadMore text={data?.section_39_detail2} />
                </div>
              </div>
            </div>
            <div className="col-md-6 col-12 pt-2 pb-2">
              <div className="simple-block">
                <div className="icon-container mb-3">
                  {data?.section_39_image3 && (
                    <img
                      src={data?.section_39_image3}
                      alt={data?.section_39_image3_alt}
                      style={{ width:"200px",height:"200px" }}
                    />
                  )}
                </div>
                <h4>{data?.section_39_head3}</h4>
                <div>
                  <ReadMore text={data?.section_39_detail3} />
                </div>
              </div>
            </div>
            <div className="col-md-6 col-12 pt-2 pb-2">
              <div className="simple-block">
                <div className="icon-container mb-3">
                  {data?.section_39_image4 && (
                    <img
                      src={data?.section_39_image4}
                      alt={data?.section_39_image4_alt}
                      style={{ width:"200px",height:"200px" }}
                    />
                  )}
                </div>
                <h4>{data?.section_39_head4}</h4>
                <div>
                  <ReadMore text={data?.section_39_detail4} />
                </div>
              </div>
            </div>
          </div>
          <a
            href={data?.sec_39_button_link}
            className="btn btn-md btn-rounded btn-pink book-consult-button text-white form-css-hidden"
          >
            {data?.section_39_button}
          </a>
        </div>
      </div>
    </>
  )
}

export default SectionThirtyNine


