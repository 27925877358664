import { Markup } from 'interweave'
import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'

function SectionTwentyEight(props) {
  const [data,setdata]= useState(props.data)
  console.log('data')
 console.log(data)
  useEffect(() => {
    setdata(props.data)
  }, [props.data])
 
    console.log('section 28')
    console.log(data)
  
  return (
    <div className="section range-section section-3995 bg-white" style={{background:data?.section_28_color || ''}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-8 offset-xl-2 col-lg-10 offset-lg-1 pb-0 text-center">
                                {/* <h2 className="mb-4">{data?.section_28_title}</h2> */}
                                {<Markup content={data?.section_28_title} containerTagName='h2'/>}
                            </div>

                            <div className="row text-center d-flex justify-content-center pt-5">
                                                                    <div className="col-sm-6 col-lg-6 pb-3 px-sm-3">
                                        <div className="simple-block">
                                        {
                                            (data?.section_28_image1) && 
                                            <div className="icon-container mb-3">
                                                <img src={data?.section_28_image1} alt={data?.section_28_image1_alt} style={{maxWidth:'70px'}} width="250px"/>
                                            </div>
                                        }
                                            
                                            <h3>{data?.section_28_head1}</h3>
                                            <p>{data?.section_28_detail1}</p>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-lg-6 pb-3 px-sm-3 my-auto">
                                        <div className="simple-block">
                                        {
                                            (data?.section_28_image2) && 
                                            <div className="icon-container mb-3">
                                                <img src={data?.section_28_image2} alt={data?.section_28_image2_alt} style={{maxWidth:'50px'}}    width="200px"/>
                                            </div>
                                        }
                                            
                                            <h3>{data?.section_28_head2}</h3>
                                          <p>{data?.section_28_detail2}</p>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-lg-6 pb-3 px-sm-3">
                                        <div className="simple-block">
                                        {
                                            (data?.section_28_image3) && 
                                            <div className="icon-container mb-3">
                                                <img src={data?.section_28_image3} alt={data?.section_28_image3_alt} style={{maxWidth:'70px'}}    width="200"/>
                                            </div>
                                        }
                                            
                                            <h3>{data?.section_28_head3}</h3>
                                            <p>{data?.section_28_detail3}</p>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-lg-6 pb-3 px-sm-3 my-auto">
                                        <div className="simple-block">
                                        {
                                            (data?.section_28_image4) &&
                                            <div className="icon-container mb-3">
                                                <img src={data?.section_28_image4} alt={data?.section_28_image4_alt} style={{maxWidth:'60px'}}   width="200"/>
                                            </div>
                                        }
                                            
                                            <h3>{data?.section_28_head4}</h3>
                                       <p>{data?.section_28_detail4}</p>
                                        </div>
                                    </div>
                                    </div>
                            
                            
                        </div>
                    </div>
                </div>

  )
}

export default SectionTwentyEight