import { Markup } from 'interweave'
import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'

function SectionThirtyEight(props) {
  const [data,setdata]= useState(props.data)
  console.log('data')
 console.log(data)
  useEffect(() => {
    setdata(props.data)
  }, [props.data])
 
    console.log('section 38')
    console.log(data)

  return (
<>
<div className="section range-section section-6439 bg-off-white" style={{background:data?.section_38_color || ''}}>
      <div className="container">
        <div className="row">
          <div className="col-xl-8 offset-xl-2 col-lg-10 offset-lg-1 pb-0 text-center mb-5">
            <h2 className="mb-4">{data?.section_38_title}</h2>
            <div />
          </div>
          <div className="col-12 my-2">
            <div className="row text-center mb-3 d-flex justify-content-center">
            {
                (data?.section_38_title1 || data?.section_38_detail1 || data?.section_38_price1) &&
                <div className="col-sm-12 col-md-6 col-lg-4 pb-3 px-sm-3 mt-70" style={{position:"relative"}}>
                <a
                  href={data?.sec_38_link1}
                  className="card card-treatment-range card-braces bg-white h-100"
                >
                  {/* <div
                    className="card-body-bg"
                    style={{
                      backgroundImage:
                        "url(https://cloud1.coe-website.securestaging.co.uk/images/treatment-range/_card/fixed-braces.png?mtime=20200514033336&focal=none&tmtime=20200729170636)",
                      backgroundPosition: "bottom left"
                    }}
                  > */}
                     <div
                    className="card-body-bg"
                    style={{backgroundPosition: "bottom left"}}  >
                  
                    <div className="card-body">
                      <h3>{data?.section_38_title1}</h3>
                      <div className="desc">
                        <p>
                          {data?.section_38_detail1}
                        </p>
                      </div>
                      <span className="price-from-circle">
                        From
                        <br />
                        <b>{data?.section_38_price1}</b>
                        <br />
                        Per Month
                      </span>
                    </div>

                    <div className='preview-image-treatments ' >
                  <img src={data?.section_38_image1} 
                  style={{ maxWidth: '205px',maxHeight:'120px',
                  position:"absolute",left:"25%",bottom:"4%"}}
                    alt={data?.section_38_image1_alt} className="transform-card"  />
                  </div>
                  </div>
               
      
                </a>
                
              </div>
            }
            {
                (data?.section_38_title2 || data?.section_38_detail2 || data?.section_38_price2) &&
                <div className="col-sm-12 col-md-6 col-lg-4 pb-3 px-sm-3 mt-70">
                <a
                  href={data?.sec_38_link2}
                  className="card card-treatment-range card-braces bg-white h-100"
                >
                  <div
                    className="card-body-bg"
                    style={{
                      backgroundImage:
                        "url(https://cloud1.coe-website.securestaging.co.uk/images/treatment-range/_card/lingual-braces.png?mtime=20200514033338&focal=none&tmtime=20200729170636)",
                      backgroundPosition: "bottom right"
                    }}
                  >
                    <div className="card-body">
                      <h3>{data?.section_38_title2}</h3>
                      <div className="desc">
                        <p>
                          {data?.section_38_detail2}
                        </p>
                      </div>
                      <span className="price-from-circle">
                        From
                        <br />
                        <b>{data?.section_38_price2}</b>
                        <br />
                        Per Month
                      </span>
                    </div>
                    <div className='preview-image-treatments'>
                  <img src={data?.section_38_image2} style={{ maxWidth: '205px',maxHeight:'120px',
                  position:"absolute",left:"25%",bottom:"1%"}} alt={data?.section_38_image2_alt} />
                  </div>
                  </div>

              
                </a>
              </div>
            }
              
            </div>
          </div>
          <div className="col-xl-8 offset-xl-2 col-lg-10 offset-lg-1 text-center">
          {
            (data?.section_38_button) &&
            <a
              href={data?.sec_38_button_link}
              className="btn btn-md btn-rounded btn-pink book-consult-button text-white"
            >
              {data?.section_38_button}
            </a>
          }
            
          </div>
        </div>
      </div>
    </div>
</>

  )
}

export default SectionThirtyEight