import { Markup } from 'interweave'
import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'


function SectionTwentyNine(props) {
  const [data,setdata]= useState(props.data)
  console.log('data')
 console.log(data)
  useEffect(() => {
    setdata(props.data)
  }, [props.data])
 
    console.log('section 29')
    console.log(data)
  return (
<>
<div className="section range-section section-3995 bg-off-white" style={{background:data?.section_29_color || ''}}>
        <div className="container">
          <div className="row">
            <div className="col-xl-8 offset-xl-2 col-lg-10 offset-lg-1 pb-0 text-center">
              <h2 className="mb-4">{data?.section_29_title}</h2>
              <div>
                {
                  <Markup content={data?.section_29_description} />
                  }
              </div>
            </div>

            <div className="col-12">
              <div className="row text-center mb-3 d-flex justify-content-center box-sect">
              {
                (data?.section_29_title1 || data?.section_29_detail1 || data?.section_29_price1) &&
                <div className="col-sm-12 col-md-6 col-lg-4 pb-3 px-sm-3 mt-70">
                  <a href={data?.sec_29_link1} className="card card-treatment-range  h-100">
                    <div className="card-body-bg">
                      <div className="card-body">
                        <h3>{data?.section_29_title1}</h3>
                        <div className="desc">
                          <p>
                           {data?.section_29_detail1}
                          </p>
                        </div>
                        <span className="price-from-circle">
                          From
                          <br />
                          <b>{data?.section_29_price1}</b>
                          <br />
                          Per Month
                        </span>
                      </div>
                    </div>
                  </a>
                </div>
              }
                
              {
                (data?.section_29_title2 || data?.section_29_detail2 || data?.section_29_price2) &&
                <div className="col-sm-12 col-md-6 col-lg-4 pb-3 px-sm-3 mt-70">
                  <a href={data?.sec_29_link2} className="card card-treatment-range  h-100">
                    <div className="card-body-bg">
                      <div className="card-body">
                        <h3>{data?.section_29_title2}</h3>
                        <div className="desc">
                          <p>
                            {data?.section_29_detail2}
                          </p>
                        </div>
                        <span className="price-from-circle">
                          From
                          <br />
                          <b>{data?.section_29_price2}</b>
                          <br />
                          Per Month
                        </span>
                      </div>
                    </div>
                  </a>
                </div>
              }
              
              {
                (data?.section_29_title3 || data?.section_29_detail3 || data?.section_29_price3) && 
                <div className="col-sm-12 col-md-6 col-lg-4 pb-3 px-sm-3 mt-70">
                  <a href={data?.sec_29_link3} className="card card-treatment-range  h-100">
                    <div className="card-body-bg">
                      <div className="card-body">
                        <h3>{data?.section_29_title3}</h3>
                        <div className="desc">
                          <p>
                            {data?.section_29_detail3}
                          </p>
                        </div>
                        <span className="price-from-circle">
                          From
                          <br />
                          <b>{data?.section_29_price3}</b>
                          <br />
                          Per Month
                        </span>
                      </div>
                    </div>
                  </a>
                </div>
              }
                
              </div>
            </div>

            <div className="col-xl-8 offset-xl-2 col-lg-10 offset-lg-1 text-center form-css-hidden">
            {
              (data?.section_29_button) &&
              <a href={data?.sec_29_button_link} className="btn btn-md btn-rounded btn-pink book-consult-button text-white">
                {data?.section_29_button}
              </a>
            }
              
            </div>
          </div>
        </div>
      </div>
</>
  )
}

export default SectionTwentyNine