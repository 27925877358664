import { Markup } from 'interweave'
import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'

function SectionSeventyTwo(props) {
  const [data,setdata]= useState(props.data)
  console.log('data')
 console.log(data)
  useEffect(() => {
    setdata(props.data)
  }, [props.data])
 
    console.log('section 72')
    console.log(data)
  
  return (

<div className="page-dentists-near-me" style={{background:data?.section_72_color || ''}}>
        <div className="layout-main">
          <div className="bg-image-locations">
            <div className="section initial-section bg-inverse"  style={{paddingBottom:'0px'}}>
              <div className="container">
                <div className="dentist-near-me-top">


             


                <p className="text-center mb-5">
              <img  width='500px' height='500px' src={data?.section_72_header_logo} alt={data?.section_72_image1_alt} style={{
                    // float:'right'
                }} />
            </p>

                  {/* <div className="text-center text-xl-right mb-4">
                    <h1 className="page-title">
                      Are you looking for dental clinics in Hemel Hempstead? Review our list of approved dentists.
                    </h1>
                    <div className="desc"><p>
                        Dental implants are provided by dentists in Hemel Hempstead, along with other treatments such as veneers, crowns and bridges. Invisalign aligners and fixed braces are also available in Hemel Hempstead.
                      </p></div>
                  </div> */}
                  <div className="row text-center">
                    <div className="col-12 col-xl-5 p-big-circle form-css-hidden">
                      <div className="circle circle-1">
                        <div className="content ">
                          <div className="image-holder-pig">
                            <img src={data?.section_72_banner1} alt={data?.section_72_image2_alt} />
                          </div>
                          <p className="main-strapline">{data?.section_72_banner_title1} &amp; <em>{data?.section_72_banner_title2}</em> {data?.section_72_banner_title3}</p>
                          <img className="bottom form-css-hidden" src={data?.section_72_banner2} alt={data?.section_72_image3_alt} />
                        </div>
                      </div>
                    </div>
                 
                    <div className="col-12 col-xl-7">
                      <div className="row">

                        <div className="col-md-4 p-circle">
                          <div className="circle circle-2">
                            <div className="content">
                              <div className="image-holder">
                                <img height={40} src={data?.section_72_image1} alt={data?.section_72_image4_alt} />
                              </div>
                              <p>{data?.section_72_head1}</p>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4 p-circle">
                          <div className="circle circle-3">
                            <div className="content">
                              <div className="image-holder">
                                <img height={40} src={data?.section_72_image2} alt={data?.section_72_image5_alt} />
                              </div>
                              <p>{data?.section_72_head2}</p>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4 p-circle">
                          <div className="circle circle-4">
                            <div className="content">
                              <div className="image-holder">
                                <img height={40} src={data?.section_72_image3} alt={data?.section_72_image6_alt} />
                              </div>
                              <p>{data?.section_72_head3}</p>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-12">
                          <h3 className="mb-3 mt-5">{data?.section_72_right_heading}</h3>
                          <form action method="post" className="find-clinic-form-1 mx-auto mb-5" style={{maxWidth: '350px'}}>
                            <input type="hidden" name="CRAFT_CSRF_TOKEN" defaultValue />
                            <div className="form-group">
                              <label htmlFor="postcode" className="sr-only">Postcode</label>
                              <div className="input-group">
                                <input type="text" name="postcode" id="postcode" className="form-control" placeholder={data?.section_72_right_placeholder} />
                                <div className="input-group-append text-purple">
                                  <span className="input-group-text" id="basic-addon2"><i className="fas fa-map-marker-alt" /></span>
                                </div>
                              </div>
                            </div>
                            <div className="form-group">
                              <div id="rollerLoader" className="text-center" style={{display: 'none'}}>
                                <div data-v-01d979ac className="lds-roller" style={{width: '24px', height: '24px'}}>
                                <div data-v-01d979ac style={{animationDuration: '1.2s', transformOrigin: '12px 12px', animationDelay: '-0.036s'}}>
                                <div data-v-01d979ac className="div-after" style={{width: '2.1px', height: '2.1px', margin: '-1.2px 0px 0px -1.2px', background: 'rgb(255, 255, 255)', top: '18.9px', left: '18.9px'}} />
                                </div><div data-v-01d979ac style={{animationDuration: '1.2s', transformOrigin: '12px 12px', animationDelay: '-0.072s'}}>
                                  <div data-v-01d979ac className="div-after" style={{width: '2.1px', height: '2.1px', margin: '-1.2px 0px 0px -1.2px', background: 'rgb(255, 255, 255)', top: '18.9px', left: '18.9px'}} />
                                  </div><div data-v-01d979ac style={{animationDuration: '1.2s', transformOrigin: '12px 12px', animationDelay: '-0.108s'}}>
                                    <div data-v-01d979ac className="div-after" style={{width: '2.1px', height: '2.1px', margin: '-1.2px 0px 0px -1.2px', background: 'rgb(255, 255, 255)', top: '20.4px', left: '16.8px'}} />
                                    </div><div data-v-01d979ac style={{animationDuration: '1.2s', transformOrigin: '12px 12px', animationDelay: '-0.144s'}}>
                                      
                                      <div data-v-01d979ac className="div-after" style={{width: '2.1px', height: '2.1px', margin: '-1.2px 0px 0px -1.2px', background: 'rgb(255, 255, 255)', top: '21.3px', left: '14.4px'}} />
                                      </div><div data-v-01d979ac style={{animationDuration: '1.2s', transformOrigin: '12px 12px', animationDelay: '-0.18s'}}><div data-v-01d979ac className="div-after" style={{width: '2.1px', height: '2.1px', margin: '-1.2px 0px 0px -1.2px', background: 'rgb(255, 255, 255)', top: '21.6px', left: '12px'}} /></div><div data-v-01d979ac style={{animationDuration: '1.2s', transformOrigin: '12px 12px', animationDelay: '-0.216s'}}><div data-v-01d979ac className="div-after" style={{width: '2.1px', height: '2.1px', margin: '-1.2px 0px 0px -1.2px', background: 'rgb(255, 255, 255)', top: '21.3px', left: '9.6px'}} /></div><div data-v-01d979ac style={{animationDuration: '1.2s', transformOrigin: '12px 12px', animationDelay: '-0.252s'}}><div data-v-01d979ac className="div-after" style={{width: '2.1px', height: '2.1px', margin: '-1.2px 0px 0px -1.2px', background: 'rgb(255, 255, 255)', top: '20.4px', left: '7.2px'}} /></div><div data-v-01d979ac style={{animationDuration: '1.2s', transformOrigin: '12px 12px', animationDelay: '-0.288s'}}><div data-v-01d979ac className="div-after" style={{width: '2.1px', height: '2.1px', margin: '-1.2px 0px 0px -1.2px', background: 'rgb(255, 255, 255)', top: '18.9px', left: '5.1px'}} /></div></div>                                                <span className="waiting-msg">Please wait while we get your location</span>
                              </div>
                              <button type="button" id="useLocationBtn" className="btn text-white">
                                <i className="fas fa-crosshairs text-white" /> {data?.section_72_right_location?.substring(0,25)}
                              </button>
                            </div>
                            <a href={data?.sec_72_button_link}>
                            <button type='button' className="btn btn-gold btn-rounded btn-lg text-white">{data?.section_72_right_button?.substring(0,25)}</button>
                            </a>
                          </form>
                          <hr className="light" />
                        </div>
                      </div>
                    </div>
                    <br/>
                  
                  </div>
                  <div className="col-12 col-xl-8 text- prie-and-pig-circle">
              <div className="row">
              <div className="col-md-4 p-circle">
                  <div className="circle circle-2">
                    <div className="content">
                      <div className="image-holder-pig d-flex">
                            <img src={data?.section_72_banner1} alt={data?.section_72_image2_alt} width={'100px'} height={'auto'} />
                            <p className="main-strapline pt-3">{data?.section_72_banner_title1} &amp; <em>{data?.section_72_banner_title2}</em> {data?.section_72_banner_title3}</p>
                    
                          </div>
                        
                    </div>
                  </div>
                </div>
                </div>
                </div>
                  {/* <div className="section">
              <div className="row">
                <div className="col-lg-10 offset-lg-1 text-center">
                  <h1 className="page-title mb-3 text-white">Dentists Near Me</h1>
                  <div className="desc"><p>Use our dental directory to find GDC registered oral orthodontists &amp; surgeons across our UK clinics. Invisalign and dental implants specialists are located nationwide.</p>
                    <p>At Trusted Clinics, we aim to make dental work as accessible as possible for everyone. We have approved clinics located all over the UK, so wherever you are we can find a clinic practice near you that is suited to your needs. Our practices offer a wide range of dental and orthodontic work, from Invisalign clear aligners to implants and veneers, we are able to assist you in choosing the most appropriate treatment plan for your requirements.</p>
                    <p>All of our approved practices are equipped with high-end dentistry equipment created by industry-leading brands, to ensure that you receive the best treatment available. Each practice has its own team of highly qualified dentists and dental nurses who are always committed to providing the highest level of treatment possible. From the moment you enter one of our practices, you will be welcomed by staff who will advise and guide you through the process. </p>
                    <p>Your happiness is a priority for us, which is why we do everything we can to make your journey as hassle free and straightforward as can be.</p>
                    <p><strong>Popular dentist location searches:</strong></p>
                    <p><a href="https://trustedclinics.com/dental/dentists-near-me/northampton/">Find a dentist at a Northampton clinic</a></p>
                    <p><a href="https://trustedclinics.com/dental/dentists-near-me/london-liverpool-street/">London Liverpool Street dentist</a></p>
                    <p><a href="https://trustedclinics.com/dental/dentists-near-me/london-canon-street/">Dentist near London Cannon Street</a></p>
                    <p><a href="https://trustedclinics.com/dental/dentists-near-me/london-bermondsey/">Search for a dentist at a London Bermondsey clinic</a></p><p><a href="https://trustedclinics.com/dental/dentists-near-me/fulham/">Fulham dentist</a></p><p><a href="https://trustedclinics.com/dental/dentists-near-me/harley-street/">Harley Street dentist</a></p><p><a href="https://trustedclinics.com/dental/dentists-near-me/london/">London dental clinic</a></p><p><a href="https://trustedclinics.com/dental/dentists-near-me/milton-keynes/">Milton Keynes dentist</a></p><p><a href="https://trustedclinics.com/dental/dentists-near-me/bristol/">Bristol dentist</a></p><p><a href="https://trustedclinics.com/dental/dentists-near-me/manchester/">Manchester dentist</a></p></div>
                </div>
              </div>
            </div> */}




                </div>
              </div>
            </div>
          </div>
          

         
        </div>
      </div>

  )
}

export default SectionSeventyTwo