import React, { useEffect, useState } from 'react'
import ContactusSteps from './sections/contactus/ContactusSteps'
import Header from './Header&Footer/Header'
import Footer from './Header&Footer/Footer'
import ContactusForm from './sections/contactus/ContactusForm'
import ContactEmail from './sections/contactus/ContactEmail'
import AskAnExpert from './sections/contactus/AskAnExpert'
import DirectClinicContact from './sections/contactus/DirectClinicContact'
import FrequentlyAskQue from './sections/faqs/FrequentlyAskQue'
import IndividualFaq from './sections/faqs/IndividualFaq'
import NewsStories from './sections/news/NewsStories'
import SingleNews from './sections/news/SingleNews'
import AboutUs from './sections/aboutus/AboutUs'
import PrincingAndSearchUkClinic from './sections/pricing/PrincingAndSearchUkClinic'
import SectionOne from './sections/home/SectionOne'
import SectionTwo from './sections/home/SectionTwo'
import SectionThree from './sections/home/SectionThree'
import SectionFour from './sections/home/SectionFour'
import { NavLink, useParams } from 'react-router-dom'
import axios from 'axios'
import { Markup } from 'interweave'
import { BeatLoader } from "react-spinners";
import {MdOutlineKeyboardArrowDown} from 'react-icons/md'
function CommonTest() {
  const {id} = useParams()
  // let menuId = id || 1;
  const [faqs1 ,setFAQs1]=useState(true)
  const [faqs2 ,setFAQs2]=useState(true)
  const [testMenuData ,settestMenuData] = useState([])
  const [isLoading,setisLoading] = useState(false)
  const getTestMenu = async() =>{
    await axios.get('https://trustedclinics.co.uk/backend/api/header_links')
    .then(res=>{
      console.log(res.data?.detail)
      settestMenuData(res.data?.detail[0])
    })
    .catch(error=>{
      console.log(error)
    })
  }

  let menuId = id || testMenuData.link_id;
  console.log("menu_id"+id)
  // alert(id)
  // if(!menuId){
  //   window.location.href = '/'
  // }
  const [data,setData] = useState([])
  
  const [fetchData,setfetchData] = useState(true)
  const getData = async() =>{
    setisLoading(true)
    
    await axios.get(`https://trustedclinics.co.uk/backend/api/GetPageDetail/${menuId}`)
    .then(res=>{
      console.log('test')
      console.log(res)
      if(res.data.data === false){
        setfetchData(false)
      }else{
        setfetchData(true)
      }
      
     console.log('page')

     console.log(res.data?.detail[0])
     setData(res.data?.detail[0])
     console.log('section')
     console.log(res.data?.detail[0].section_one[0])
     setisLoading(false)
    }).
    catch(error=>{
      console.log(error)
      setisLoading(false)
    })
    
  }
  
  // getData()
  useEffect(() => {
    getData()
    getTestMenu()
  }, [menuId])
  return (
    <>
 
    <Header/>
   
    {/* Home section one */}


     {/* <SectionOne data={data?.section_one}/>
  

    <SectionTwo/>
    <SectionThree/>
    <SectionFour/> */}
    {/* Pricing */}
    {/* <PrincingAndSearchUkClinic/> */}
    {/* Aboutus section */}
    {/* <AboutUs/> */}
    {/* News start */}
    {/* <SingleNews/>
    <NewsStories/> */}
    {/* News end */}
    {/* Contact section start */}
        {/* <ContactusSteps/>
        <ContactusForm/>
        <ContactEmail/>
        <AskAnExpert/>
        <DirectClinicContact/> */}
    {/* Contact section end */}
    {/* FAQs start */}
    {/* <FrequentlyAskQue/>
    <IndividualFaq/> */}
    {/* FAQs end */}
   

{
  isLoading ? 
  <div style={{width:'100%',height:'100vh',display:'flex',alignItems:'center',justifyContent:'center'}}>
  <BeatLoader       
          loading={true}
          color="orange"
          size={15}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
  </div>
  :
  <>
    {/* section one  */}

{
  (fetchData === false ) ? ''
  :
  <>
 
    {/* {
        data.section_one[0] && <SectionOne data={data.section_one[0]}/>
    } */}
    {(data?.section_one_title) ? 
    <div className="main-section" style={{overflowX:'hidden'}}>
    <div className="section-bg-overlay">
      <div className="container">
        <div className="row home-imagespanel">
          <div className="col-12 text-center pb-2">
            <h2 className="h1 mb-2 mb-md-3 mb-lg-4">
             
              {data?.section_one_title}
            </h2>
          </div>
          <div className="col-12 col-md-6 col-lg-6 col-xl-6">
            <img
              className="new-home-imagesbanner-img"
              src={data?.section_one_banner1}
              alt="Instantly Compare The UK's Very Best Clinics"
            />
          </div>
          <div className="col-12 col-md-6 col-lg-6 col-xl-6">
         
           {/* <h2 className="h1 mb-3">
              Instantly Compare The UK&#039;s Very Best Clinics</h2> */}
           
            {
              <Markup content={data?.section_one_subtitle} /> 
            }
              
             
            
      
            <div className="offer-block align-items-center home-desktop ">
                             <div className="offer-circle desktop d-md-block d-lg-block d-none">
                                 <div className="in-circle ">
                                    {data?.section_one_right_heading }
                                 </div>
                             </div>
                             <div className="text-area d-flex flex-sm-cloumn home-desk-div">
                                 <div className="d-block d-lg-none d-md-none" style={{border:'1px solid white'}}>
                                     <div className="in-circle" >
                                        {data?.section_one_right_heading}
                                     </div>
                                 </div>
                                 <div className="pig-img">
                                     <img className="pig-home-banner " src={data?.section_one_right_image} alt="Save up to 50% on your treatment"   />
                                 </div>
                                 <div className="pr-3 pig-content ">
                                 {
                                  <Markup content={data?.section_one_right_content} />
                                 }
                                   
                        
                                 </div>
                             </div>
                         </div>
                     
                         <NavLink to={'/'} className="btn btn-lg btn-rounded btn-pink book-consult-button m-auto home-desktop d-table">{data?.section_one_button}</NavLink>
          </div>
         
        </div>

        <div className="row text-center mt-50 dental-imp " style={{margin:'100px'}} >
          <div className="col-6 col-sm-6 col-lg-3 mb-3 mt-md-60 pr-2 pr-lg-3 dental1" style={{minWidth:'200px',position:'relative'}} >
            <NavLink
              to={'/'}
              className="card card-treatment h-100 " state={{width:'150px'}}
            >
              <div className="card-body" style={{marginTop:'50px'}}>
                <div className="treatment-icon">
                  <img
                    src={data?.section_one_footer_image1}
                    style={{width:'85px',height:'50px'}}
                    alt="Dental Implants"
                  />
                </div>
                {
                  <Markup content={data?.section_one_footer_detail1} /> 
                }
                
              </div>
              <span className="price-from-circle" style={{marginTop:'30px'}}>
                From
                <br />
                <b>{data?.section_one_footer_head1}</b>
                <br />
                Per Month
              </span>
           
            </NavLink>
          </div>
          <div className="col-6 col-sm-6 col-lg-3 mb-3 mt-md-60 pl-2 pl-lg-3  dental1" style={{minWidth:'200px',position:'relative'}}>
            <NavLink
              to={'/'}
              className="card card-treatment h-100" 
            >
              <div className="card-body" style={{marginTop:'50px' }}>
                <div className="treatment-icon">
                  <img
                    src={data?.section_one_footer_image2}
                    style={{width:'85px',height:'50px'}}
                    alt="Full Mouth Reconstruction"
                  />
                </div>
                {data?.section_one_footer_detail2}
                
                
              </div>
              <span className="price-from-circle"  style={{marginTop:'30px'}}>
                From
                <br />
                <b>{data?.section_one_footer_head2}</b>
                <br />
                Per Month
              </span>
           
            </NavLink>
          </div>
          <div className="col-6 col-sm-6 col-lg-3 mb-3 mt-md-60 pr-2 pr-lg-3 dental1" style={{minWidth:'200px',position:'relative'}}>
            <NavLink
              to={'/'}
              className="card card-treatment h-100"
            >
              <div className="card-body" style={{marginTop:'50px'}}>
                <div className="treatment-icon">
                  <img
                    className="invisalign"
                    src={data?.section_one_footer_image3}
                    style={{width:'85px',height:'50px'}}
                    alt="Invisalign Clear Aligners"
                  />
                </div>
                {
                  <Markup content={data?.section_one_footer_detail3} /> 
                }
                
              </div>
              <span className="price-from-circle" style={{marginTop:'30px'}}>
                From
                <br />
                <b>{data?.section_one_footer_head3 }</b>
                <br />
                Per Month
              </span>
             
            </NavLink>
          </div>
          <div className="col-6 col-sm-6 col-lg-3 mb-3 mt-md-60 pl-2 pl-lg-3 dental1" style={{minWidth:'200px',position:'relative'}}>
            <NavLink
              to={'/'}
              className="card card-treatment h-100"
            >
              <div className="card-body"style={{marginTop:'50px'}}>
                <div className="treatment-icon">
                  <img
                    src={data?.section_one_footer_image4}
                    style={{width:'85px',height:'50px'}}
                    alt="Veneers"
                  />
                </div>
                
                  {data?.section_one_footer_detail4}
                
              </div>
              <span className="price-from-circle" style={{marginTop:'30px'}}>
                From
                <br />
                <b>{data?.section_one_footer_head4 }</b>
                <br />
                Per Month
              </span>
                     
            </NavLink>
          </div>
        </div> 
         
        <div className="row text-center dental-imp">
          <div className="col-12 p-sm-0  col-sm-6 col-lg-3 mb-3 pr-2 pr-lg-3 " >
            <NavLink
              to={'/'}              className="card card-treatment h-100"
            >
              <div className="card-body no-icon ">
                {/* <h4>{data?.section_one_footer_data1}</h4> */}
                {
                  <Markup content={data?.section_one_footer_data1} />
                }
              </div>
            </NavLink>
          </div>
          <div className="col-12 p-sm-0 col-sm-6 col-lg-3 mb-3 pl-2 pl-lg-3 left5">
            <NavLink
              to={'/'}              className="card card-treatment h-100"
            >
              <div className="card-body no-icon">
                <h4>{data?.section_one_footer_data2 }</h4>
              </div>
            </NavLink>
          </div>
          <div className="col-12 col-sm-6 col-lg-3 mb-3 pr-2 pr-lg-3 right2">
            <NavLink
              to={'/'}              className="card card-treatment h-100"
            >
              <div className="card-body no-icon">
                {/* <h4>{data?.section_one_footer_data3}</h4> */}
                {
                  <Markup content={data?.section_one_footer_data3} />
                }
              </div>
            </NavLink>
          </div>
          <div className="col-12 col-sm-6 col-lg-3 mb-3 pl-2 pl-lg-3 left5">
            <NavLink
              to={'/'}              className="card card-treatment h-100"
            >
              <div className="card-body no-icon">
                <h4>{data?.section_one_footer_data4}</h4>
              </div>
            </NavLink>
          </div>
        </div>
        <div className="row mt-4">
        <div className="col-xl-8 offset-xl-2 col-lg-10 offset-lg-1">
                        <div className="intro-mobile">
                        { <Markup content={data?.section_one_footer_mobile  } /> }</div>
                        <p className="text-center">
                         { <Markup content={data?.section_one_footer} />}</p>
                    </div>
                </div>
      </div>
    </div>
    </div>
    :''
  }
    {/* section one */}

    {/* section two */}
    {(data?.section_two_title) ?
    <div className="section restore-your-smile-section bg-inverse" style={{backgroundColor:'#734994'}}>
    <div className="container text-center">
      <div className="row">
        <div className="col-xl-8 offset-xl-2 col-lg-10 offset-lg-1">
          <h2 className="mb-4">{data?.section_two_title }</h2>
       
          {<Markup content={data?.section_two_header} /> }
        <div className="card form-card bg-white">
            <div className="card-body text-left">
              <form
      
                
                className="form"
              >
                <input type="hidden" name="CRAFT_CSRF_TOKEN" defaultValue="" />
                <div className="row">
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label  style={{  color: "#4a4a4a"}}>Name</label>
                  
                      <input
                        type="text"
                        className="form-control"
                        name="name"
                        id="restore-name"
                        defaultValue=""
                        placeholder='Name'
                        required=""
                      />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label  style={{  color: "#4a4a4a"}} htmlFor="restore-email">Email Address</label>
                      <input
                        type="email"
                        className="form-control"
                        name="email"
                        id="restore-email"
                        defaultValue=""
                        placeholder="Email Address"
                        required=""
                      />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label  style={{  color: "#4a4a4a"}} htmlFor="restore-telephone">Contact Number</label>
                      <input
                        type="text"
                        className="form-control"
                        name="telephone"
                        id="restore-telephone"
                        defaultValue=""
                        placeholder="Contact Number"
                        required=""
                      />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label  style={{  color: "#4a4a4a"}} htmlFor="restore-postcode">Postcode</label>
                      <input
                        type="text"
                        className="form-control"
                        name="postcode"
                        id="restore-postcode"
                        defaultValue=""
                        placeholder="Postcode"
                        required=""
                      />
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <label  style={{  color: "#4a4a4a"}} htmlFor="agree_to_terms" className=" text-normal">
                    <input
                      type="checkbox"
                      name="agree_to_terms"
                      id="agree_to_terms"
                      className="float-left mt-1"
                      style={{height:"27px",width:'27px',maxWidth:'27px',marginRight:'5px'}}
                      required=""
                     
                    />
               <span className="text-dark px-1" style={{marginLeft:'5px'}}>
                     I understand that by requesting a consultation through
                    Trusted Clinics I am agreeing with the Trusted Clinics</span>{" "}
                    <NavLink
                      to={'/'}                      target="_blank"
                    >
                      Privacy Policy
                    </NavLink>{" "}
                    &amp;{" "}
                    <NavLink
                      to={'/'}                      target="_blank"
                    >
                      Terms &amp; Conditions
                    </NavLink>
                   . 
                  </label>
                </div>
                <div className="text-center mt-2">
                  <button
                    type="submit"
                    className="btn btn-lg btn-rounded btn-pink book-consult-button d-table"
                  >
                   {data?.section_two_button}
                  </button>
                </div>
              </form>
            </div>
          </div>
          {
            <Markup content={data?.section_two_footer}/>
          }
         
        </div>
      </div>
    </div>
    </div>
    :

    ''
    }
    {/* section two */}

    {/* section three */}
    {(data?.section_three_title ) ? 
    <div className="section treatments-section">
    <div className="container text-center">
      <div className="row">
        <div className="col-xl-8 offset-xl-2 col-lg-10 offset-lg-1">
          <h2 className="mb-4">{data?.section_three_title}</h2>
        {
          <Markup content={data?.section_three_subtitle} />
        }
         
        </div>
      </div>
      <div className="row text-center my-3 ">
        <div className="col-6 col-sm-6 col-lg-3 mb-3 mt-md-60 pr-2 pr-lg-3">
          <NavLink
            to={'/'}  className="card card-treatment-img dental-implants h-100"
            style={{backgroundImage:`url(${data?.section_three_image1}),linear-gradient(#fff 13%,#fff,#ecf2f2,#ecf2f2 60% 100%)`,backgroundSize: '135px 120px,100%',backgroundPosition: '100% 100%'}} >
            <div className="card-body">
              <h3>{data?.section_three_head1}</h3>
            </div>
          </NavLink>
        </div>
        <div className="col-6 col-sm-6 col-lg-3 mb-3 mt-md-60 pl-2 pl-lg-3 left5">
          <NavLink
            to={'/'}            className="card card-treatment-img full-mouth-reconstruction h-100"
            style={{backgroundImage:`url(${data?.section_three_image2}),linear-gradient(#fff 13%,#fff,#ecf2f2,#ecf2f2 60% 100%)`,backgroundSize: '135px 120px,100%',backgroundPosition: '100% 100%'}}>
            <div className="card-body">
              <h3>{data?.section_three_head2 }</h3>
            </div>
          </NavLink>
        </div>
        <div className="col-6 col-sm-6 col-lg-3 mb-3 mt-md-60 pr-2 pr-lg-3 right5">
          <NavLink
            to={'/'}            className="card card-treatment-img invisalign-aligners h-100"
            style={{backgroundImage:`url(${data?.section_three_image3}),linear-gradient(#fff 13%,#fff,#ecf2f2,#ecf2f2 60% 100%)`,backgroundSize: '135px 120px,100%',backgroundPosition: '100% 100%'}}>
            <div className="card-body">
              <div className="text-center mb-1">
                <img
                  src="assets/img/invisalign-icon.png"
                  width={153}
                  height={35}
                  alt="Invisalign Clear Aligners"
                />
              </div>
              <h3>{data?.section_three_head3}</h3>
            </div>
          </NavLink>
        </div>
        <div className="col-6 col-sm-6 col-lg-3 mb-3 mt-md-60 pl-2 pl-lg-3 left5">
          <NavLink
            to={'/'}            className="card card-treatment-img veneers h-100"
            style={{backgroundImage:`url(${data?.section_three_image4}),linear-gradient(#fff 13%,#fff,#ecf2f2,#ecf2f2 60% 100%)`,backgroundSize: '135px 120px,100%',backgroundPosition: '100% 100%'}}>
            <div className="card-body">
              <h3>{data?.section_three_head4}</h3>
            </div>
          </NavLink>
        </div>
        <div className="col-6 col-sm-6 col-lg-3 mb-3 mt-md-60 pr-2 pr-lg-3 right5">
          <NavLink
            to={'/'}            className="card card-treatment-img fixed-and-lingual-braces h-100"
            style={{backgroundImage:`url(${data?.section_three_image5}),linear-gradient(#fff 13%,#fff,#ecf2f2,#ecf2f2 60% 100%)`,backgroundSize: '135px 120px,100%',backgroundPosition: '100% 100%'}}>
            <div className="card-body">
              <h3>{data?.section_three_head5}</h3>
            </div>
          </NavLink>
        </div>
        <div className="col-6 col-sm-6 col-lg-3 mb-3 mt-md-60 pl-2 pl-lg-3 left5">
          <NavLink
            to={'/'}            className="card card-treatment-img crowns-and-bridges h-100"
            style={{backgroundImage:`url(${data?.section_three_image6}),linear-gradient(#fff 13%,#fff,#ecf2f2,#ecf2f2 60% 100%)`,backgroundSize: '135px 120px,100%',backgroundPosition: '100% 100%'}}>
            <div className="card-body">
              <h3>{data?.section_three_head6 }</h3>
            </div>
          </NavLink>
        </div>
        <div className="col-6 col-sm-6 col-lg-3 mb-3 mt-md-60 pr-2 pr-lg-3 right5">
          <NavLink
            to={'/'}  className="card card-treatment-img fillers h-100"
            style={{backgroundImage:`url(${data?.section_three_image7}),linear-gradient(#fff 13%,#fff,#ecf2f2,#ecf2f2 60% 100%)`,backgroundSize: '135px 120px,100%',backgroundPosition: '100% 100%'}}>
            <div className="card-body">
              <h3>{data?.section_three_head7 }</h3>
            </div>
          </NavLink>
        </div>
        <div className="col-6 col-sm-6 col-lg-3 mb-3 mt-md-60 pl-2 pl-lg-3 left5">
          <NavLink
            to={'/'} className="card card-treatment-img emergency-treatment h-100"
            style={{backgroundImage:`url(${data?.section_three_image8}),linear-gradient(#fff 13%,#fff,#ecf2f2,#ecf2f2 60% 100%)`,backgroundSize: '135px 120px,100%',backgroundPosition: '100% 100%'}}>
            <div className="card-body">
              <h3>{data?.section_three_head8 }</h3>
            </div>
          </NavLink>
        </div>
      </div>
      <div className="row">
        <div className="col-xl-8 offset-xl-2 col-lg-10 offset-lg-1">
        {
          <Markup content={data?.section_three_footer} /> 
        }
         
          <NavLink
            to={'/'}            className="btn btn-lg btn-rounded btn-pink book-consult-button d-table"
          >
           {data?.section_three_footer_button}
          </NavLink>
        </div>
      </div>
    </div>
  </div>
  :
  ""
    }
    {/* section three */}

    {/* section four */}
    {(data?.section_four_title) ? 
    <div className="section cost-benefits-section bg-off-white">
    <div className="container text-center">
      <div className="row">
        <div className="col-xl-8 offset-xl-2 col-lg-10 offset-lg-1">
          <h2 className="mb-4">{data?.section_four_title }</h2>
        {
          <Markup content={data?.section_four_subtitle} /> 
        }
         
        </div>
      </div>
      <div className="row centered m-auto cost-benefits-section-icons pb-3">
        <div className="col-6 col-md-6 col-lg-3 pr-2 pl-2 mb-3">
          <div className="card card-treatment h-100">
            <div className="card-body">
              <div className="treatment-icon">
                <img
                  className="icon-one"
                  src={data?.section_four_image1 }
                  width={72}
                  height={39}
                  alt="Finance Available"
                  style={{maxHeight:'60px'}}
                />
              </div>
              <p  style={{marginTop:'15px'}}>{data?.section_four_head1 }</p>
            </div>
          </div>
        </div>
        <div className="col-6 col-md-6 col-lg-3 pr-2 pl-2 mb-3">
          <div className="card card-treatment h-100">
            <div className="card-body">
              <div className="treatment-icon">
                <img
                  src={data?.section_four_image2 }
                  className=""
                  width={72}
                  height={39}
                  alt="In-Clinic Private Payment Plans"
                  style={{maxHeight:'60px'}}
                />
              </div>
              <p   style={{marginTop:'15px'}}>{data?.section_four_head2 }</p>
            </div>
          </div>
        </div>
        <div className="col-6 col-md-6 col-lg-3 pr-2 pl-2 mb-3">
          <div className="card card-treatment h-100">
            <div className="card-body">
              <div className="treatment-icon">
                <img
                  src={data?.section_four_image3 }
                  className=""
                  width={72}
                  height={39}
                  style={{maxHeight:'60px'}}
                  alt="Exclusive Trusted Clinics Discounts"
                />
              </div>
              <p  style={{marginTop:'15px'}}>{data?.section_four_head3 }</p>
            </div>
          </div>
        </div>
        <div className="col-6 col-md-6 col-lg-3 pr-2 pl-2 mb-3">
          <div className="card card-treatment h-100">
            <div className="card-body">
              <div className="treatment-icon">
                <img
                  className="icon-four"
                  src={data?.section_four_image4}
                      width={72}
                  height={39}
                  style={{maxHeight:'60px'}}
                  alt="Spread The Cost Of Your Treatment Over (Up To) 5 Years."
                />
              </div>
              <p  style={{marginTop:'15px'}}>{data?.section_four_head4}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-xl-8 offset-xl-2 col-lg-10 offset-lg-1 text-center">
          {
            <Markup content={data?.section_four_footer} /> 
          }
          
          
         
        </div>
      </div>
    </div>
  </div>
  :
  ""
    }

{/* section five */}
{(data?.section_five_title) ? 
      <div className="section reviews-section">
    <div className="container text-center">
      <div className="row">
        <div className="col-xl-8 offset-xl-2 col-lg-10 offset-lg-1">
          <h2 className="mb-4">{data?.section_five_title }</h2>
          { <Markup content={data?.section_five_description} />}
        </div>
      </div>
      <div className="row my-3">
        <div className="col-md-6 col-lg-4 mb-3">
          <div className="review-quote">
            {<Markup content={data?.section_five_detail1} /> }
          </div>
      
        </div>
        <div className="col-md-6 col-lg-4 mb-3">
          <div className="review-quote">
           {<Markup content={data?.section_five_detail2} /> }
          </div>
         
        </div>
        <div className="col-md-6 col-lg-4 mb-3">
          <div className="review-quote">
           {<Markup content={data?.section_five_detail3} />}
          </div>
        
        </div>
      </div>
      <div className="row">
        <div className="col-6 col-sm-6 col-lg-3 mb-3 mt-md-60 pr-2 pr-lg-3">
          <NavLink
            to={'/'}            className="card card-treatment-review h-100"
          >
            <div className="card-body">
              <h4>
              {  data?.section_five_head1}
                <br />
              
              </h4>
              <div className="review-img">
                <img
                  src={ data?.section_five_image1 }
                  className="img-fluid"
                  alt="Dental Implants Reviews"
                />
              </div>
              <p className="review-desc">{ data?.section_five_foot1}</p>
          
              <p className="review-stars">
                <img
                  src="assets/img/star.png"
                  className="img-fluid"
                  alt="star Reviews"
                />
              </p>
              <p className="review-count">{data?.section_five_rating1 }</p>
            </div>
          </NavLink>
        </div>
        <div className="col-6 col-sm-6 col-lg-3 mb-3 mt-md-60 pl-2 pl-lg-3 left5">
          <NavLink
            to={'/'}            className="card card-treatment-review h-100"
          >
            <div className="card-body">
              <h4 className="allow-wider">{data?.section_five_head2 }</h4>
              <div className="review-img">
                <img
                  src={data?.section_five_image2 }
                  className="img-fluid"
                  alt="Full Mouth Reconstruction Reviews"
                />
              </div>
              <p className="review-desc">{ data?.section_five_foot2}</p>
           
              <p className="review-stars">
                <img
                  src="assets/img/star.png"
                  className="img-fluid"
                  alt="star Reviews"
                />
              </p>
              <p className="review-count">{data?.section_five_rating2}</p>
            </div>
          </NavLink>
        </div>
        <div className="col-6 col-sm-6 col-lg-3 mb-3 mt-md-60 pr-2 pr-lg-3 right5">
          <NavLink
            to={'/'}            className="card card-treatment-review h-100"
          >
            <div className="card-body">
            <h4>
              { data?.section_five_head3 }
              </h4>
              
              <div className="review-img">
                <img
                  src={data?.section_five_image3}
                  className="img-fluid"
                  alt="Invisalign Reviews"
                />
              </div>
              <p className="review-desc">{ data?.section_five_foot3}</p>
          
              <p className="review-stars">
                <img
                  src="assets/img/star.png"
                  className="img-fluid"
                  alt="star Reviews"
                />
              </p>
              <p className="review-count">{ data?.section_five_rating3 }</p>
            </div>
          </NavLink>
        </div>
        <div className="col-6 col-sm-6 col-lg-3 mb-3 mt-md-60 pl-2 pl-lg-3 left5">
          <NavLink
            to={'/'}            className="card card-treatment-review h-100"
          >
            <div className="card-body">
                 <h4>
                 {data?.section_five_head4}
              </h4>

              <div className="review-img">
                <img
                  src={data?.section_five_image4 }
                  className="img-fluid"
                  alt="Veneers Reviews"
                />
              </div>
              <p className="review-desc">{ data?.section_five_foot4}</p>
            
              <p className="review-stars">
                <img
                  src="assets/img/star.png"
                  className="img-fluid"
                  alt="star Reviews"
                />
              </p>
              <p className="review-count">{data?.section_five_rating4 }</p>
            </div>
          </NavLink>
        </div>
      </div>
    </div>
  </div>
  :
  ""
 }

  {/* section six */}
  {
  (data?.section_six_title) ? 
      <div className="bg-price">
          <div className="container">
            <h1 className="text-white text-center pt-3">{ data?.section_six_title}</h1>
            <div className="row mb-lg-5 py-4"> 
              <div className="col-12 col-xl-4 mb-2">
                <img className="mobile" src={data?.section_six_banner} alt="News banner image" />
              </div>
            
              <div className="col-12 col-xl-8 text-center">
                <div className="row">
                  <div className="col-md-4 pr-2 pl-2 mb-3">
                    <div className="card card-treatment h-100">
                      <div className="card-body">
                        <div className="treatment-icon">
                          <img className="icon-four" src={data?.section_six_image1} width={45} height={49} alt="Spread The Cost Of Your Treatment Over (Up To) 5 Years." />
                        </div>
                        <h4>{ data?.section_six_head1}</h4>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 pr-2 pl-2 mb-3">
                    <div className="card card-treatment h-100">
                      <div className="card-body">
                        <div className="treatment-icon">
                          <img className="icon-four" src={data?.section_six_image2} width={45} height={49} alt="Spread The Cost Of Your Treatment Over (Up To) 5 Years." />
                        </div>
                        <h4>{data?.section_six_head2}</h4>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 pr-2 pl-2 mb-3">
                    <div className="card card-treatment h-100">
                      <div className="card-body">
                        <div className="treatment-icon">
                          <img className="icon-four" src={data?.section_six_image3} width={45} height={49} alt="Spread The Cost Of Your Treatment Over (Up To) 5 Years." />
                        </div>
                        <h4>{data?.section_six_head1}</h4>
                      </div>
                    </div>
                  </div>
                 
                  <div className="row px-2">
                    <div className="col-12 offset-xl-2  mt-3  text-center">
                      <h3 className="mb-4 text-white">{  data?.section_six_right_head?.substring(0,30)}</h3>
                    </div>
                    <div className="col-12 offset-lg-2   offset-xl-2 text-center">
                      <form action method="POST" className="form" id="findYourClinicForm">
                        <input type="hidden" name="CRAFT_CSRF_TOKEN" defaultValue />
                        <div className="form-group mb-2">
                          <label htmlFor="postcode" className="sr-only">Postcode</label>
                          <div className="input-group">
                            <input type="text" name="postcode" id="postcode" className="form-control" placeholder="Enter Your Post Code" />
                            <div className="input-group-append text-purple">
                              <span className="input-group-text" id="basic-addon2"><i className="fas fa-map-marker-alt" /></span>
                            </div>
                          </div>
                        </div>
                        <div className="form-group mb-3">
                          <div id="rollerLoader" className="text-center" style={{display: 'none'}}>
                            <div data-v-01d979ac className="lds-roller" style={{width: '24px', height: '24px'}}><div data-v-01d979ac style={{animationDuration: '1.2s', transformOrigin: '12px 12px', animationDelay: '-0.036s'}}><div data-v-01d979ac className="div-after" style={{width: '2.1px', height: '2.1px', margin: '-1.2px 0px 0px -1.2px', background: 'rgb(255, 255, 255)', top: '18.9px', left: '18.9px'}} /></div><div data-v-01d979ac style={{animationDuration: '1.2s', transformOrigin: '12px 12px', animationDelay: '-0.072s'}}>
                              <div data-v-01d979ac className="div-after" style={{width: '2.1px', height: '2.1px', margin: '-1.2px 0px 0px -1.2px', background: 'rgb(255, 255, 255)', top: '18.9px', left: '18.9px'}} />
                            </div><div data-v-01d979ac style={{animationDuration: '1.2s', transformOrigin: '12px 12px', animationDelay: '-0.108s'}}><div data-v-01d979ac className="div-after" style={{width: '2.1px', height: '2.1px', margin: '-1.2px 0px 0px -1.2px', background: 'rgb(255, 255, 255)', top: '20.4px', left: '16.8px'}} /></div><div data-v-01d979ac style={{animationDuration: '1.2s', transformOrigin: '12px 12px', animationDelay: '-0.144s'}}><div data-v-01d979ac className="div-after" style={{width: '2.1px', height: '2.1px', margin: '-1.2px 0px 0px -1.2px', background: 'rgb(255, 255, 255)', top: '21.3px', left: '14.4px'}} />
                            </div><div data-v-01d979ac style={{animationDuration: '1.2s', transformOrigin: '12px 12px', animationDelay: '-0.18s'}}>
                              <div data-v-01d979ac className="div-after" style={{width: '2.1px', height: '2.1px', margin: '-1.2px 0px 0px -1.2px', background: 'rgb(255, 255, 255)', top: '21.6px', left: '12px'}} /></div><div data-v-01d979ac style={{animationDuration: '1.2s', transformOrigin: '12px 12px', animationDelay: '-0.216s'}}><div data-v-01d979ac className="div-after" style={{width: '2.1px', height: '2.1px', margin: '-1.2px 0px 0px -1.2px', background: 'rgb(255, 255, 255)', top: '21.3px', left: '9.6px'}} /></div><div data-v-01d979ac style={{animationDuration: '1.2s', transformOrigin: '12px 12px', animationDelay: '-0.252s'}}>
                                <div data-v-01d979ac className="div-after" style={{width: '2.1px', height: '2.1px', margin: '-1.2px 0px 0px -1.2px', background: 'rgb(255, 255, 255)', top: '20.4px', left: '7.2px'}} />
                            </div><div data-v-01d979ac style={{animationDuration: '1.2s', transformOrigin: '12px 12px', animationDelay: '-0.288s'}}><div data-v-01d979ac className="div-after" style={{width: '2.1px', height: '2.1px', margin: '-1.2px 0px 0px -1.2px', background: 'rgb(255, 255, 255)', top: '18.9px', left: '5.1px'}} /></div></div>         
                               <span className="waiting-msg">{ 'Please wait while we get your location'}</span>
                          </div>
                          <button type="button" id="useLocationBtn" className="btn text-white" >
                            <i className="fas fa-crosshairs text-white" /> 
                            {data?.section_six_location?.substring(0,26)}
                          </button>
                        </div>
                        <button type="submit" className="btn btn-gold btn-rounded btn-lg" >{data?.section_six_botton?.substring(0,25) ||  "Search Trusted Clinics"}</button>
                      </form>   
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        :
  ""
 }
    </>
}
    {/* section seven */}
 {
  (data?.section_seven_title) ? 
  <div className=''>
    <div className="container">
      <div className="row mb-lg-5 py-2">
        <div className="col-md-6 mt-2">
          <img className="mobile" src= {data?.section_seven_banner} alt="News banner image" />
        </div>
        <div className="col-md-6 text-center">
        <h1>{data?.section_seven_title}</h1>
       {  <Markup content={ data?.section_seven_detail }/> } 
          <div className="text-center mt-2">
            <button type="submit" className="btn btn-md btn-rounded btn-pink book-consult-button-inline">
            { data?.section_seven_button?.substring(0,27) }
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  :
  ""
 }

 {/* section eight */}

 {
  (data?.section_eight_title) ? 
  <div className="section cost-benefits-section bg-off-white">
    <div className="container text-center">
        <div className="row">
            <div className="col-xl-10  col-lg-10 offset-lg-1">
                <h1 className="mb-4">{data?.section_eight_title}</h1>
                {<Markup content={  data?.section_eight_detail}/>  
                     }
                     
            </div>
        </div>

      


        <div className="row centered m-auto cost-benefits-section-icons pb-3 d-flex justify-content-center">
            <div className="col-6 col-md-6 col-lg-4 pr-2 pl-2 mb-3">
                <div className="card card-treatment h-100">
                    <div className="card-body">
                        <div className="treatment-icon">
                            <img className="icon-one" src={data?.section_eight_image1} width="42" height="39" style={{maxHeight: '37px',marginTop: '6px'}} alt="Finance Available"/>
                        </div>
                        <h4>{data?.section_eight_data1}</h4>
                    </div>
                </div>
            </div>
            <div className="col-6 col-md-6 col-lg-4 pr-2 pl-2 mb-3">
                <div className="card card-treatment h-100">
                    <div className="card-body">
                        <div className="treatment-icon">
                            <img src={data?.section_eight_image2} className="" width="44" height="48"style={{maxHeight: '37px',marginTop: '6px'}} alt="In-Clinic Private Payment Plans"/>
                        </div>
                        <h4> {data?.section_eight_data2}</h4>
                    </div>
                </div>
            </div>
            <div className="col-6 col-md-6 col-lg-4 pr-2 pl-2 mb-3">
                <div className="card card-treatment h-100">
                    <div className="card-body">
                        <div className="treatment-icon">
                            <img src={data?.section_eight_image3} className="" width="35" height="49" style={{maxHeight: '37px',marginTop: '6px'}} alt="Exclusive Trusted Clinics Discounts"/>
                        </div>
                        <h4>{ data?.section_eight_data3}</h4>
                    </div>
                </div>
            </div>
            <div className="col-6 col-md-6 col-lg-4 pr-2 pl-2 mb-3">
                <div className="card card-treatment h-100">
                    <div className="card-body">
                        <div className="treatment-icon">
                            <img className="icon-four" src={data?.section_eight_image4}  width="45" height="49" style={{maxHeight: '37px',marginTop: '6px'}} alt="Spread The Cost Of Your Treatment Over (Up To) 5 Years."/>
                        </div>
                        <h4>{data?.section_eight_data4}</h4>
                    </div>
                </div>
            </div>
            <div className="col-6 col-md-6 col-lg-4 pr-2 pl-2 mb-3">
                <div className="card card-treatment h-100">
                    <div className="card-body">
                        <div className="treatment-icon">
                            <img className="icon-four" src={data?.section_eight_image5} width="45" height="49" style={{maxHeight: '37px',marginTop: '6px'}} alt="Spread The Cost Of Your Treatment Over (Up To) 5 Years."/>
                        </div>
                        <h4>{data?.section_eight_data5}</h4>
                    </div>
                </div>
            </div>
        </div>
        <div className="row">
            <div className="col-xl-10  col-lg-10 offset-lg-1 text-center">
                {
                  <Markup content={data?.section_eight_footer} />
                }
            </div>
        </div>
    </div>
</div>
  :
  ""
 }

 {
  (data?.section_nine_title) ? 
  <div className="news-bg">
    <div className="container">
      <div className="row mb-lg-5 py-5">
        <div className="col-md-7 col-lg-6 col-xl-6 text-center">
          <h1>{data?.section_nine_title || "Trusted Clinics News Stories"}</h1>
      { <Markup content={ data?.section_nine_detail }/> }
        </div>
        <div className="col-md-4">
          {/* <img className="mobile" src={ data?.section_nine_image} alt="News banner image" /> */}
        </div>
      </div>
    </div>
  </div>
  :
  ""
 }

{
  (data?.section_ten_title) ? 
  <div className="bg-faq">
          <div className="container">
            <h2 className="text-center mb-5">{data?.section_ten_title}</h2>
            <div className="row text-center my-3">
              <div className="col-sm-6 col-lg-4 mb-3 mt-md-60 pr-2 pr-lg-3">
                <div  className="card">
                <a  className="card-image">
                                <img src={data?.section_ten_image1}  alt="Artificial Intelligence within the dental industry"  style={{ width: '575px',height: '350px'}} className="img-fluid" />
                            </a>
                  <div className="card-body">
                     {/* { <Markup content={ data?.section_ten_data1} /> 
                      
                      } */}
                    <hr />
                    {/* { <Markup content={data?.section_ten_date_category1 } /> 
          } */}
                    <p>Date :{data?.section_ten_date1}</p>
                    <p>Category :{data?.section_ten_category1}</p>
                    <div className="text-center mt-2">
                      <button type="submit" className="btn btn-md btn-rounded btn-pink book-consult-button-inline">
                        {data?.section_ten_button1?.substring(0,25)}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-lg-4 mb-3 mt-md-60 pr-2 pr-lg-3">
                <div  className="card">
                <a  className="card-image">
                                <img src={data?.section_ten_image2} alt="Will dentists return to normal from Monday 8 June 2020 ?" style={{ width: '575px',height: '350px'}} className="img-fluid" />
                            </a>
                  <div className="card-body">
                 {/* {   <Markup content={ data?.section_ten_data2} /> } */}
                     <hr/>
                     <p>Date :{data?.section_ten_date2}</p>
                    <p>Category :{data?.section_ten_category2}</p>
                   {/* { <Markup content={data?.section_ten_date_category2} />} */}
                    <div className="text-center mt-2">
                      <button type="submit" className="btn btn-md btn-rounded btn-pink book-consult-button-inline">
                        {data?.section_ten_button2?.substring(0,25)}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
    </div>
  :
  "" 
}

{
  (data?.section_eleven_title) ? 
  <div className="bg-faq">
    <div className="container">
      <h2 className="text-center mb-3">{ data?.section_eleven_title}</h2>
      <div className="row dental-imp">
        <div className="col-lg-6 mb-3">
          <div className="card card-treatment pt-2">
            <div className="card-body no-icon h-100">
              <div className="accordion" id="accordionExample">
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingOne">
                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                      <div className="d-flex">
                        <img src={data?.section_eleven_image1} width={40} height={39} alt="Faqs" />
                        <h4 className="ml-4 pt-2">{data?.section_eleven_que1}</h4>
                        <i className="ml-4" onClick={()=>setFAQs1(!faqs1)}><MdOutlineKeyboardArrowDown color="#253959" size={35} /></i>
                      </div>
                    </button>
                  </h2>
                  <div id="collapseOne" className={ faqs1 ? "accordion-collapse collapse" : "accordion-collapse collapse show"} aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                      <p>
                      { data?.section_eleven_ans1}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-6 mb-3">
          <div className="card card-treatment pt-2">
            <div className="card-body no-icon h-100">
              <div className="accordion" id="accordionExample">
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingOne">
                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapsetwo" aria-expanded="true" aria-controls="collapsetwo">
                      <div className="d-flex">
                        <img src={data?.section_eleven_image2 } width={40} height={39} alt="Faqs" />
                        <h4 className="ml-4 pt-2">{data?.section_eleven_que2}</h4>
                        <i className="ml-4" onClick={()=>setFAQs2(!faqs2)}> <MdOutlineKeyboardArrowDown color="#253959" size={35} /> </i>
                      </div>
                    </button>
                  </h2>
                  <div id="collapsetwo" className={ faqs2 ?  "accordion-collapse collapse": "accordion-collapse collapse show" } aria-labelledby="headingtwo" data-bs-parent="#accordionExample2">
                    <div className="accordion-body">
                      <p>
                        { data?.section_eleven_ans2}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  :
  ""
}

{
  (data?.section_twelve_title) ? 
  <div className="section treatments-section">
    <div className="container text-center">
      <div className="row">
        <div className="col-xl-8 offset-xl-2 col-lg-10 offset-lg-1" >
          <h2 className="mb-4">{ data?.section_twelve_title}</h2>
        </div>
      </div>
      <div className="row text-center my-3  d-flex justify-content-center">
        <div className="col-6 col-sm-6 col-lg-3 mb-3 mt-md-60 pr-2 pr-lg-3">
          <a className="card card-treatment-img dental-implants h-100 "  style={{backgroundImage:`url(${data?.section_twelve_image1}),linear-gradient(#fff 13%,#fff,#ecf2f2,#ecf2f2 60% 100%)`,backgroundSize: '135px 120px,100%',backgroundPosition: '100% 100%'}} >
            <div className="card-body">
              <h3>{ data?.section_twelve_head1}</h3>
            </div>
          </a>
        </div>
        <div className="col-6 col-sm-6 col-lg-3 mb-3 mt-md-60 pr-2 pr-lg-3">
          <a className="card card-treatment-img invisalign-aligners h-100" style={{backgroundImage:`url(${data?.section_twelve_image2}),linear-gradient(#fff 13%,#fff,#ecf2f2,#ecf2f2 60% 100%)`,backgroundSize: '135px 120px,100%',backgroundPosition: '100% 100%' }}>
            <div className="card-body">
              <div className="text-center mb-1">
                {/* <img src={ "assets/img/invisalign-icon.png"} width={153} height={35} alt="Invisalign Clear Aligners" /> */}
              </div>
              <h3>{ data?.section_twelve_head2}</h3>
            </div>
          </a>
        </div>
        <div className="col-6 col-sm-6 col-lg-3 mb-3 mt-md-60 pl-2 pl-lg-3">
          <a className="card card-treatment-img veneers h-100" style={{backgroundImage:`url(${data?.section_twelve_image3}),linear-gradient(#fff 13%,#fff,#ecf2f2,#ecf2f2 60% 100%)`,backgroundSize: '135px 120px,100%',backgroundPosition: '100% 100%' }}>
            <div className="card-body">
              <h3>{data?.section_twelve_head3}</h3>
            </div>
          </a>
        </div>
        <div className="col-6 col-sm-6 col-lg-3 mb-3 mt-md-60 pr-2 pr-lg-3">
          <a className="card card-treatment-img fixed-and-lingual-braces h-100" style={{backgroundImage:`url(${data?.section_twelve_image4}),linear-gradient(#fff 13%,#fff,#ecf2f2,#ecf2f2 60% 100%)`,backgroundSize: '135px 120px,100%',backgroundPosition: '100% 100%' }}>
            <div className="card-body">
              <h3>{data?.section_twelve_head4}</h3>
            </div>
          </a>
        </div>
        <div className="col-6 col-sm-6 col-lg-3 mb-3 mt-md-60 pl-2 pl-lg-3">
          <a className="card card-treatment-img crowns-and-bridges h-100" style={{backgroundImage:`url(${data?.section_twelve_image5}),linear-gradient(#fff 13%,#fff,#ecf2f2,#ecf2f2 60% 100%)`,backgroundSize: '135px 120px,100%',backgroundPosition: '100% 100%' }}>
            <div className="card-body">
              <h3>{data?.section_twelve_head5}</h3>
            </div>
          </a>
        </div>
        <div className="col-6 col-sm-6 col-lg-3 mb-3 mt-md-60 pr-2 pr-lg-3">
          <a className="card card-treatment-img fillers h-100" style={{backgroundImage:`url(${data?.section_twelve_image6}),linear-gradient(#fff 13%,#fff,#ecf2f2,#ecf2f2 60% 100%)`,backgroundSize: '135px 120px,100%',backgroundPosition: '100% 100%' }}>
            <div className="card-body">
              <h3>{data?.section_twelve_head6}</h3>
            </div>
          </a>
        </div>
        <div className="col-6 col-sm-6 col-lg-3 mb-3 mt-md-60 pl-2 pl-lg-3">
          <a className="card card-treatment-img emergency-treatment h-100" style={{backgroundImage:`url(${data?.section_twelve_image7}),linear-gradient(#fff 13%,#fff,#ecf2f2,#ecf2f2 60% 100%)`,backgroundSize: '135px 120px,100%',backgroundPosition: '100% 100%' }}>
            <div className="card-body">
              <h3>{data?.section_twelve_head7}</h3>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
  :
  ""
}

{
  (data?.thirteen_title) ? 
  <div className="section cost-benefits-section bg-off-white bg-white">
    <div className="container text-center">
      <div className="row">
        <div className="col-xl-10  col-lg-10 offset-lg-1">
          <h1 className="mb-1">{data?.thirteen_title}</h1>
          <h2 className="mb-3">{<Markup  content={ data?.thirteen_discription}/>}</h2>
        </div>
      </div>
      <div className="row centered m-auto cost-benefits-section-icons pb-3 d-flex justify-content-center">
        <div className="col-6 col-md-6  pr-2 pl-2 mb-3">
          <div className="card card-treatment h-100">
            <div className="card-body">
              <div className="treatment-icon">
                <img className="icon-one" src={data?.thirteen_image1} width={42} height={39} alt="Finance Available" />
              </div>
              <h4 className="pt-2">{ data?.thirteen_image_detail1}</h4>
            </div>
          </div>
        </div>
        <div className="col-6 col-md-6  pr-2 pl-2 mb-3">
          <div className="card card-treatment h-100">
            <div className="card-body">
              <div className="treatment-icon">
                <img src={data?.thirteen_image2} className width={44} height={48} alt="In-Clinic Private Payment Plans" />
              </div>
              <h4>{ data?.thirteen_image_detail2}</h4>
            </div>
          </div>
        </div>
        <div className="col-6 col-md-6  pr-2 pl-2 mb-3">
          <div className="card card-treatment h-100">
            <div className="card-body">
              <div className="treatment-icon">
                <img className="icon-four" src={data?.thirteen_image3} width={45} height={49} alt="Spread The Cost Of Your Treatment Over (Up To) 5 Years." />
              </div>
              <h4>{ data?.thirteen_image_detail3}</h4>
            </div>
          </div>
        </div>
        <div className="col-6 col-md-6  pr-2 pl-2 mb-3">
          <div className="card card-treatment h-100">
            <div className="card-body">
              <div className="treatment-icon">
                <img className="icon-four" src={data?.thirteen_image4} width={45} height={49} alt="Spread The Cost Of Your Treatment Over (Up To) 5 Years." />
              </div>
              <h4>{data?.thirteen_image_detail4}</h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  :
  ""
}

{
  (data?.section_fourteen_title) ? 
  <div className="section contact-form bg-inverse">
    <div className="container text-center">
      <div className="row">
        <div className="col-xl-8 offset-xl-2 col-lg-10 offset-lg-1">
          <h2 className="mb-4">{data?.section_fourteen_title}</h2>
          <div className="card form-card bg-white">
            <div className="card-body text-left">
              <form  className="form"  >
                <input type="hidden" name="CRAFT_CSRF_TOKEN"  />
                <div className="row">
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label style={{  color: "#4a4a4a"}} >{data?.section_fourteen_head1}</label>
                      <input type="text" className="form-control" name="name" id="restore-name"  value=""   placeholder="Name"  required />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label style={{  color: "#4a4a4a"}}>{data?.section_fourteen_head2}</label>
                      <input type="email" className="form-control" name="email" id="restore-email"  value=""   placeholder="Email Address"   required />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label style={{  color: "#4a4a4a"}} >{data?.section_fourteen_head3}</label>
                      <input type="text" className="form-control" name="phone" id="restore-telephone"  value=""  placeholder="Contact Number"   required />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label style={{  color: "#4a4a4a"}}>{data?.section_fourteen_head4}</label>
                      <input type="text" className="form-control" name="treatment" id="restore-postcode"  value=""  placeholder="Please Select"   required />
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-group">
                      <label style={{  color: "#4a4a4a"}}>{data?.section_fourteen_head5}</label>
                      <textarea type="text" rows={4} className="form-control" name="comment" id="restore-postcode"   value= ""   placeholder="Comments"    required />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label style={{  color: "#4a4a4a"}}>{data?.section_fourteen_head6}</label>
                      <input type="text" className="form-control" name="postcode" id="restore-postcode"  value=""  placeholder="Postcode"  required />
                    </div>
                  </div>
                  <div className="col-sm-6 mt-3">
                    <div className="form-group">
                      <label htmlFor="agree_to_terms" className="text-normal"  style={{  color: "#4a4a4a"}}>
                      <input type="checkbox" name="agree_to_terms" style={{height:"27px",width:'27px',maxWidth:'27px',marginRight:'5px'}}  value=""    required />
                    {data?.section_fourteen_checkbox}
                      </label>
                    
                    </div>
                  </div>
                </div>
                <div className="text-center mt-2">
                  <button type="submit" className="btn btn-md btn-rounded btn-pink book-consult-button-inline">
                  {data?.section_fourteen_button}
                  </button>
                </div>
              </form>
            </div>
          </div>
          <p className="text-white">{data?.section_fourteen_footer}</p>
        </div>
      </div>
    </div>
  </div>
  :
  ""
}

{
  (data?.section_Fifteen_detail) ?
  <div className="section cost-benefits-section bg-off-white bg-white">
    <div className="container text-center">
      <div className="row">
        <div className="col-xl-10  col-lg-10 offset-lg-1">
       {<Markup content={ data?.section_Fifteen_detail}/>}
        </div>
      </div>
    </div>
    <div className="container-fluid">
      <div className="row">
        <div className="col-12 contact-relative">
          <img src={ data?.section_Fifteen_image}alt="speaker" width={200} height={200} className="contact-absolute" />
        </div>
      </div>
    </div>
  </div>
  :
  ""
}

{
  (data?.section_sixteen_title) ?
  <div className="section cost-benefits-section bg-off-white">
    <div className="container text-center">
      <div className="row">
        <div className="col-xl-10  col-lg-10 offset-lg-1">
          <h2 className="mb-3">{data?.section_sixteen_title}</h2>
        </div>
      </div>
      <div className="row centered m-auto cost-benefits-section-icons pb-3 d-flex justify-content-center">
        <div className="col-6 col-md-4  pr-2 pl-2 mb-3">
          <div className="card card-treatment h-100">
            <div className="card-body">
              <div className="treatment-icon">
                <img className="icon-one" src={data?.section_sixteen_image1} width={42} height={39} alt="Finance Available" />
              </div>
              <h4 className="pt-2">{data?.section_sixteen_description1}</h4>
            </div>
          </div>
        </div>
        <div className="col-6 col-md-4  pr-2 pl-2 mb-3">
          <div className="card card-treatment h-100">
            <div className="card-body">
              <div className="treatment-icon">
                <img src={ data?.section_sixteen_image2} className width={44} height={48} alt="In-Clinic Private Payment Plans" />
              </div>
              <h4>{data?.section_sixteen_description2}</h4>
              {/* <p>(Coming Soon)</p> */}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="container-fluid">
      <div className="row">
        <div className="col-12 contact-relative">
          <img src={data?.section_sixteen_side_image} alt="speaker" width height className="contact-absolute" />
        </div>
      </div>
    </div>
  </div>
  :
  ""
}

{
  (data?.section_seventeen_title) ? 
  <div class="section find-you-local-clinic-section bg-inverse bg-radial-inverse">
    <div class="container">
        <div class="row">
            <div class="col-xl-8 offset-xl-2 col-lg-10 offset-lg-1 text-center">
                <h2 class="mb-lg-4">{data?.section_seventeen_title}</h2>
              {
                <Markup content={data?.section_seventeen_description} />
              }
                {/* <p>You can contact your clinic directly by 
                    using the number found on the clinic listing:</p> */}

                    <div class="mb-3">
                        <img src={data?.section_seventeen_image} alt="012"/>
                    </div>
            </div>
          
        </div>

        <div class="row">
            <div class="col-md-10 offset-md-1 col-lg-6 offset-lg-3  col-xl-6 offset-xl-3 text-center">
             <button type="submit" class="yellow-contact btn-rounded btn-lg">{data?.section_seventeen_button}</button>

           </div>
        </div>

</div>
</div>
  :
  ""
}

{
  (data?.section_eighteen_title) ? 
        <div className="faq bg-linear-inverse">
          <div className="container">
            <div className="row py-5">
              <div className=" col-xl-8 offset-xl-2 col-lg-10 offset-lg-1 text-center" style={{color:'white'}}>
                <h1 className="mb-lg-1 text-white">{data?.section_eighteen_title}</h1>
                {
                  <Markup content={data?.section_eighteen_description} />
                }
                {/* <h2 className="mb-4 text-white">{ data?.section_eighteen_subtitle }</h2> */}
              </div>
            </div>
          </div>
        </div>
  :
  ""
}
    <Footer/>
    </>
}
    </>
  )
}

export default CommonTest