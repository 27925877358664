// import { Markup } from 'interweave'
// import React, { useEffect, useState } from 'react'
// import { NavLink } from 'react-router-dom'
// import {MdOutlineKeyboardArrowDown} from 'react-icons/md'
// function SectionEleven(props) {
  
//   const [faqs1 ,setFAQs1]=useState(true)
//   const [faqs2 ,setFAQs2]=useState(true)
//   const [data,setdata]= useState(props.data)
//   console.log('data')
//  console.log(data)
//   useEffect(() => {
//     setdata(props.data)
//   }, [props.data])
 
//     console.log('sectionone')
//     console.log(data)
  
//   return (
//   <div className="bg-faq" style={{background:data?.section_11_color || ''}}>
//   <div className="container">
//     <h2 className="text-center mb-3">{ data?.section_eleven_title}</h2>
//     <div className="row dental-imp">
//     {
//               (data?.section_eleven_image1 || data?.section_eleven_que1 || data?.section_eleven_ans1) &&
//       <div className="col-lg-6 mb-3">
//         <div className="card card-treatment pt-2">
//           <div className="card-body no-icon h-100">
//             <div className="accordion" id="accordionExample">
            
//               <div className="accordion-item">
//                 <h2 className="accordion-header" id="headingOne">
//                   <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
//                     <div className="d-flex">
//                       <img src={data?.section_eleven_image1} width={40} height={39} alt={data?.section_eleven_image1_alt}  />
//                       <h4 className="ml-4 pt-2">{data?.section_eleven_que1}</h4>
//                       <i className="ml-4" onClick={()=>setFAQs1(!faqs1)}><MdOutlineKeyboardArrowDown color="#253959" size={35} /></i>
//                     </div>
//                   </button>
//                 </h2>
//                 <div id="collapseOne" className={ faqs1 ? "accordion-collapse collapse" : "accordion-collapse collapse show"} aria-labelledby="headingOne" data-bs-parent="#accordionExample">
//                   <div className="accordion-body">
//                     <p>
//                     { data?.section_eleven_ans1}
//                     </p>
//                   </div>
//                 </div>
//               </div>
            
              
//             </div>
//           </div>
//         </div>
//       </div>
//     }
//       {
//               (data?.section_eleven_image2 || data?.section_eleven_que2 || data?.section_eleven_ans2) &&
//       <div className="col-lg-6 mb-3">
//         <div className="card card-treatment pt-2">
//           <div className="card-body no-icon h-100">
//             <div className="accordion" id="accordionExample">
            
//               <div className="accordion-item">
//                 <h2 className="accordion-header" id="headingOne">
//                   <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapsetwo" aria-expanded="true" aria-controls="collapsetwo">
//                     <div className="d-flex">
//                       <img src={data?.section_eleven_image2 } width={40} height={39}  alt={data?.section_eleven_image2_alt} />
//                       <h4 className="ml-4 pt-2">{data?.section_eleven_que2}</h4>
//                       <i className="ml-4" onClick={()=>setFAQs2(!faqs2)}> <MdOutlineKeyboardArrowDown color="#253959" size={35} /> </i>
//                     </div>
//                   </button>
//                 </h2>
//                 <div id="collapsetwo" className={ faqs2 ?  "accordion-collapse collapse": "accordion-collapse collapse show" } aria-labelledby="headingtwo" data-bs-parent="#accordionExample2">
//                   <div className="accordion-body">
//                     <p>
//                       { data?.section_eleven_ans2}
//                     </p>
//                   </div>
//                 </div>
//               </div>
           
              
//             </div>
//           </div>
//         </div>
//       </div>
//     }
//     </div>
//   </div>
// </div>
//   )
// }

// export default SectionEleven

import { Markup } from 'interweave'
import React, { useEffect, useState } from 'react'
import { MdOutlineKeyboardArrowDown } from 'react-icons/md'

function SectionEleven(props) {
  const [faqs1, setFAQs1] = useState(true)
  const [faqs2, setFAQs2] = useState(true)
  const [data, setdata] = useState(props.data)

  useEffect(() => {
    setdata(props.data)
  }, [props.data])

  const containerStyle = {
    display: 'flex',
    flexWrap: 'wrap'
  }

  const cardStyle = {
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
  }

  const cardBodyStyle = {
    flex: 1
  }

  return (
    <>
      <div className="bg-faq" style={{ background: data?.section_11_color || '' }}>
        <div className="container">
          <h2 className="text-center mb-3">{data?.section_eleven_title}</h2>
          <div className="row dental-imp" style={containerStyle}>
            {(data?.section_eleven_image1 || data?.section_eleven_que1 || data?.section_eleven_ans1) && (
              <div className="col-lg-6 mb-3">
                <div className="card card-treatment pt-2 h-100" style={cardStyle}>
                  <div className="card-body no-icon h-100" style={cardBodyStyle}>
                    <div className="accordion" id="accordionExample">
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="headingOne">
                          <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                            <div className="d-flex">
                              <img src={data?.section_eleven_image1} width={40} height={39} alt={data?.section_eleven_image1_alt} />
                              <h4 className="ml-4 pt-2">{data?.section_eleven_que1}</h4>
                              <i className="ml-4" onClick={() => setFAQs1(!faqs1)}>
                                <MdOutlineKeyboardArrowDown color="#253959" size={35} />
                              </i>
                            </div>
                          </button>
                        </h2>
                        <div id="collapseOne" className={faqs1 ? 'accordion-collapse collapse' : 'accordion-collapse collapse show'} aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                          <div className="accordion-body">
                            <p>{data?.section_eleven_ans1}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {(data?.section_eleven_image2 || data?.section_eleven_que2 || data?.section_eleven_ans2) && (
              <div className="col-lg-6 mb-3">
                <div className="card card-treatment pt-2 h-100" style={cardStyle}>
                  <div className="card-body no-icon h-100" style={cardBodyStyle}>
                    <div className="accordion" id="accordionExample2">
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="headingTwo">
                          <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="true" aria-controls="collapseTwo">
                            <div className="d-flex">
                              <img src={data?.section_eleven_image2} width={40} height={39} alt={data?.section_eleven_image2_alt} />
                              <h4 className="ml-4 pt-2">{data?.section_eleven_que2}</h4>
                              <i className="ml-4" onClick={() => setFAQs2(!faqs2)}>
                                <MdOutlineKeyboardArrowDown color="#253959" size={35} />
                              </i>
                            </div>
                          </button>
                        </h2>
                        <div id="collapseTwo" className={faqs2 ? 'accordion-collapse collapse' : 'accordion-collapse collapse show'} aria-labelledby="headingTwo" data-bs-parent="#accordionExample2">
                          <div className="accordion-body">
                            <p>{data?.section_eleven_ans2}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default SectionEleven
