import { Markup } from 'interweave'
import React, { useEffect, useState } from 'react'

function SectionFour(props) {
  const [data,setdata]= useState(props.data)
  console.log('data')
 console.log(data)
  useEffect(() => {
    setdata(props.data)
  }, [props.data])
 
    console.log('sectionfour')
    console.log(data)
  return (

//   <div className="section cost-benefits-section bg-off-white">
//   <div className="container text-center">
//     <div className="row">
//       <div className="col-xl-8 offset-xl-2 col-lg-10 offset-lg-1">
//         <h2 className="mb-4">{data?.section_four_title }</h2>
//       {
//         <Markup content={data?.section_four_subtitle} /> 
//       }
       
//       </div>
//     </div>
//     <div className="row centered m-auto cost-benefits-section-icons pb-3">
//     {
//       (data?.section_four_image1 || data?.section_four_head1) && 
//       <div className="col-6 col-md-6 col-lg-3 pr-2 pl-2 mb-3">
//         <div className="card card-treatment h-100">
//           <div className="card-body">
//             <div className="treatment-icon">
//               <img
//                 className="icon-one"
//                 src={data?.section_four_image1 }
//                 width={72}
//                 height={39}
//                 alt="issue occured"
//                 style={{maxHeight:'60px'}}
//               />
//             </div>
//             <p  style={{marginTop:'15px'}}>{data?.section_four_head1 }</p>
//           </div>
//         </div>
//       </div>
//     }

//     {
//       (data?.section_four_image2 || data?.section_four_head2) && 
//       <div className="col-6 col-md-6 col-lg-3 pr-2 pl-2 mb-3">
//         <div className="card card-treatment h-100">
//           <div className="card-body">
//             <div className="treatment-icon">
//               <img
//                 src={data?.section_four_image2 }
//                 className=""
//                 width={72}
//                 height={39}
//                 alt="issue occured"
//                 style={{maxHeight:'60px'}}
//               />
//             </div>
//             <p   style={{marginTop:'15px'}}>{data?.section_four_head2 }</p>
//           </div>
//         </div>
//       </div>
//     }
      
//     {
//       (data?.section_four_image3 || data?.section_four_head3) && 
//       <div className="col-6 col-md-6 col-lg-3 pr-2 pl-2 mb-3">
//         <div className="card card-treatment h-100">
//           <div className="card-body">
//             <div className="treatment-icon">
//               <img
//                 src={data?.section_four_image3 }
//                 className=""
//                 width={72}
//                 height={39}
//                 style={{maxHeight:'60px'}}
//                 alt="issue occured"
//               />
//             </div>
//             <p  style={{marginTop:'15px'}}>{data?.section_four_head3 }</p>
//           </div>
//         </div>
//       </div>
//     }
      
//     {
//       (data?.section_four_image4 || data?.section_four_head4) && 
//       <div className="col-6 col-md-6 col-lg-3 pr-2 pl-2 mb-3">
//         <div className="card card-treatment h-100">
//           <div className="card-body">
//             <div className="treatment-icon">
//               <img
//                 className="icon-four"
//                 src={data?.section_four_image4}
//                     width={72}
//                 height={39}
//                 style={{maxHeight:'60px'}}
//                 alt="issue occured"
//               />
//             </div>
//             <p  style={{marginTop:'15px'}}>{data?.section_four_head4}</p>
//           </div>
//         </div>
//       </div>
//     }
      
      
//     </div>
//     <div className="row">
//       <div className="col-xl-8 offset-xl-2 col-lg-10 offset-lg-1 text-center">
//         {
//           <Markup content={data?.section_four_footer} /> 
//         }
        
        
       
//       </div>
//     </div>
//   </div>
// </div>
 <div className="section cost-benefits-section bg-off-white" style={{background:data?.section_4_color || ''}}>
          <div className="container text-center">
            <div className="row">
              <div className="col-xl-8 offset-xl-2 col-lg-10 offset-lg-1">
              <h2 className="mb-4">{data?.section_four_title }</h2>
      {
       <Markup content={data?.section_four_subtitle} /> 
      }
              </div>
            </div>
            <div className="row centered m-auto cost-benefits-section-icons pb-3 hide-score">

            { (data?.section_four_image1 || data?.section_four_head1) && 
              <div className="col-6 col-md-6 col-lg-3 pr-2 pl-2 mb-3">
                <div className="card card-treatment h-100">
                  <div className="card-body">
                    <div className="treatment-icon">
                      <img className="icon-one"  src={data?.section_four_image1}  
                    
                        width={72}
                 height={39}
               
               style={{maxHeight:'60px'}} alt={data?.section_four_image1_alt} />
                    </div>
                    <p style={{marginTop:'15px'}}>{data?.section_four_head1}</p>
                  </div>
                </div>
              </div>}

              { (data?.section_four_image2 || data?.section_four_head2) && 
              <div className="col-6 col-md-6 col-lg-3 pr-2 pl-2 mb-3">
                <div className="card card-treatment h-100">
                  <div className="card-body">
                    <div className="treatment-icon">
                      <img className="icon-one"  src={data?.section_four_image2}     width={44}
                 height={48}
               
               style={{maxHeight:'60px'}}  alt={data?.section_four_image2_alt} />
                    </div>
                    <p style={{marginTop:'15px'}}>{data?.section_four_head2}</p>
                  </div>
                </div>
              </div>}

              { (data?.section_four_image3 || data?.section_four_head3) && 
              <div className="col-6 col-md-6 col-lg-3 pr-2 pl-2 mb-3">
                <div className="card card-treatment h-100">
                  <div className="card-body">
                    <div className="treatment-icon">
                      <img className="icon-one"  src={data?.section_four_image3}     width={35}
                 height={49}
               
               style={{maxHeight:'60px'}}  alt={data?.section_four_image3_alt} />
                    </div>
                    <p style={{marginTop:'15px'}}>{data?.section_four_head3}</p>
                  </div>
                </div>
              </div>}

              { (data?.section_four_image4 || data?.section_four_head4) && 
              <div className="col-6 col-md-6 col-lg-3 pr-2 pl-2 mb-3">
                <div className="card card-treatment h-100">
                  <div className="card-body">
                    <div className="treatment-icon">
                      <img className="icon-one"  src={data?.section_four_image4}    width={105}
                 height={29}
               
               style={{maxHeight:'60px'}}  alt={data?.section_four_image4_alt} />
                    </div>
                    <p style={{marginTop:'15px'}}>{data?.section_four_head4}</p>
                  </div>
                </div>
              </div>}
            </div>
            <div className="row">
              <div className="col-xl-8 offset-xl-2 col-lg-10 offset-lg-1 text-center">
             {
               <Markup content={data?.section_four_footer} /> 
             }
              </div>
            </div>
          </div>
        </div>
  )
}

export default SectionFour