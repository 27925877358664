import { Markup } from 'interweave'
import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'

function SectionSixtyOne(props) {
  const [data,setdata]= useState(props.data)
  console.log('data')
 console.log(data)
  useEffect(() => {
    setdata(props.data)
  }, [props.data])
 
    console.log('section 61')
    console.log(data)
  return (

<div className="page-dentists-near-me" style={{background:data?.section_61_color || ''}}> 
          <div className="layout-main">
 
            <div className="section bg-grey">
            <div className="container">
              <h2>
              Approved Trusted Clinics in Leicester
              </h2>
              <hr />
              <div className="results-sorting">
                <div className="results-type mr-auto">Results: <strong>All</strong></div>
                <label htmlFor="sortBy">Sort By</label>
                <div className="select-field">
                  <select name="sort-by" id="sortBy" className="form-control max-width-200">
                    <option value>-- Please Select --</option>
                  </select>
                </div>
                <label htmlFor="filterGroup">Reviews</label>
                <div className="select-field">
                  <select name="sort-by" id="sortBy" className="form-control max-width-200">
                    <option value>-- Please Select --</option>
                  </select>
                </div>
              </div>
              <div className="alert alert-info text-center">
                <h4>{data?.section_61_title}</h4>
                <p>{data?.section_61_heading}</p>
              </div>
            </div>
          </div>
          </div>
        </div>
  )
}

export default SectionSixtyOne