import { Markup } from 'interweave'
import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import ReactPlayer from 'react-player';


function SectionTwentyTwo(props) {
  const [data,setdata]= useState(props.data)
  console.log('data')
 console.log(data)
  useEffect(() => {
    setdata(props.data)
  }, [props.data])
 
    console.log('section 22')
    console.log(data)
  return (
<>
<div class="section section-background pageid-9 second-sec" 
style={{background:data?.section_22_color || ''}} 
>
                        <div class="container text-center">
                            <div class="row">
                                  <div class="col-xl-8 offset-xl-2 col-lg-10 offset-lg-1">
                                    <h2 class="mb-4">{data?.section_22_title}</h2>
                                    <div>
                                      {
                                        <Markup content={data?.section_22_description} />
                                      }
                                    </div>
                                    {/* <div><p>Dental implants are a long-term solution used to replace missing or damaged teeth. The implants are small screws that act as a base for replacement teeth. They are placed into the jawbone and act like roots by supporting the tooth, denture or bridge. Options include <a href="">single tooth implants</a>, <a href="">multiple tooth implants</a>, <a href="">implant retained denture</a>, <a href="">implant supported bridge</a> and <a href="">teeth in a day</a>. </p>
                                    <p>The dental implant screw fits into the bone and has a clip (abutment) that the tooth (crown) attaches on to. Titanium is the usual material that implants are made of as it has no ill effect on the human body and bonds readily with the new bone, which penetrates the titanium implant surface. By caring for your implants and maintaining good oral hygiene, they should last a lifetime.</p></div> */}
                                  </div>
                            </div>
                            {
                              (data?.section_22_video_url) && 
                              <div class="row video-frame-holder">
                                    <div class="col-md-10 offset-md-1 col-lg-8 offset-lg-2 col-xl-6 offset-xl-3 ">
                                        <div class="embed-responsive embed-responsive-16by9">
                                       
<div className='class="embed-responsive-item"'>
                  <ReactPlayer 
                  
                    controls
                    
                    width='480px'
                    height='100px'
                    url={data?.section_22_video_url }

                  /></div>
                                        </div>
                                    </div>
                              </div>
                            }
                            
                              
                                <div class="mobile-show-images">
                                    <div>
                                        <img src="https://mzuricosmetics.com/trustedclinics/assets/img/main-dental-implants-before.png" alt="Invisalign before photo"/>
                                    </div>
                                    <div>
                                        <img src="https://mzuricosmetics.com/trustedclinics/assets/img/main-dental-implants-after.png" alt="Invisalign after photo"/>
                                    </div>
                                </div>
                                {
                                  (data?.section_22_button) && 
                                  <a href={data?.sec_22_button_link} class="btn btn-md btn-rounded btn-gold book-consult-button mt-4">
                                    {data?.section_22_button}
                                  </a>
                                }
                               
                                                                                                            </div>
                    </div>
</>
  )
}

export default SectionTwentyTwo