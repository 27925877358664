import { Markup } from 'interweave'
import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'

function SectionThirtyThree(props) {
  const [data,setdata]= useState(props.data)
  console.log('data')
 console.log(data)
  useEffect(() => {
    setdata(props.data)
  }, [props.data])
 
    console.log('section 33')
    console.log(data)

  return (
    <div
    className="main-section"
    style={{
      backgroundImage:
        "url(https://cloud1.coe-website.securestaging.co.uk/images/header-images/_hero/hero-invisalign-clear-aligners.jpg?mtime=20200515132131&focal=none&tmtime=20200729131100)",
        background:data?.section_33_color ||  "#f4fcff"
    
    }}
  >
    <div className="container" >
      <div className="row mb-lg-5">
        <div className="col-lg-10 col-xl-7">
          <h1 className="page-title mb-3">
            <span className="str-replace-invisalign-blue " >{data?.section_33_title || 'Invisalign'}</span>{" "}
            {<Markup content={  data?.section_33_description} containerTagName='text-black'/>}
          </h1>
        </div>
        <div className="col-xl-3 pb-3 order-xl-2">
            <div className="treatment-header-image mobile">
<img     src={data?.section_33_image1}
          alt={data?.section_33_image1_alt}  />
{/* <span className="monthly-price-from">
<span className="from">From</span>
<span className="price">£21</span>
<span className="per-month">Per Month</span>
</span> */}
</div>
            </div>
        {/* <img
          className="mobile"
          src={data?.section_33_image1}
          alt={data?.section_33_image1_alt}
        /> */}
        <div className="col-md-7 col-lg-7 col-xl-8 boxes-block-banner">
          <div className="row mb-3">
            <div className="col-xl-9">
              <div className="row">
              {
                (data?.section_33_head1) &&
                <div className="col-6 col-sm-4 pb-3 px-2">
                  <div className="card card-body card-headline  justify-content-center h-100">
                    <h4>{data?.section_33_head1}</h4>
                  </div>
                </div>
              }
            
            {
                (data?.section_33_head2) &&
                <div className="col-6 col-sm-4 pb-3 px-2">
                  <div className="card card-body card-headline white-on-gold justify-content-center h-100">
                    <h4>
                      {/* <span className="percentage">
                        <span className="number">0</span>%
                      </span>{" "} */}
                     {data?.section_33_head2}
                    </h4>
                  </div>
                </div>
            }
                
                {
                    (data?.section_33_head3) &&
                    <div className="col-6 col-sm-4 pb-3 px-2">
                    <div className="card card-body card-headline  justify-content-center h-100">
                        <h4>{data?.section_33_head3}</h4>
                    </div>
                    </div>
                }
                
                {
                    (data?.section_33_head4) &&
                    <div className="col-6 col-sm-4 pb-3 px-2">
                    <div className="card card-body card-headline  justify-content-center h-100">
                        <h4>{data?.section_33_head4}</h4>
                    </div>
                    </div>
                }
                
                {
                    (data?.section_33_head5) &&
                    <div className="col-6 col-sm-4 pb-3 px-2">
                    <div className="card card-body card-headline  justify-content-center h-100">
                        <h4>{data?.section_33_head5}</h4>
                    </div>
                    </div>
                }
                
                {
                    (data?.section_33_head6) &&
                    <div className="col-6 col-sm-4 pb-3 px-2">
                    <div className="card card-body card-headline  justify-content-center h-100">
                        <h4>
                        <span className="green-stars">
                            <i className="fas fa-star" />
                            <i className="fas fa-star" />
                            <i className="fas fa-star" />
                            <i className="fas fa-star" />
                            <i className="fas fa-star" />
                        </span>{" "}
                        {data?.section_33_head6}
                        </h4>
                    </div>
                    </div>
                }
                
              </div>
            </div>
            {
                (data?.section_33_price_month || data?.section_33_price_total) &&
                <div className="col-xl-3 pb-3">
                <div className="row h-100">
                    <div className="col px-2">
                    <div className="card card-body card-purple card-headline-pricing text-center h-100">
                        <div className="row">
                        <div className="col-12 col-lg-12">
                            <span>From</span>
                            <span className="monthly-price">{data?.section_33_price_month}</span>
                            <span className="monthly-price-pm">Per Month</span>
                        </div>
                        <div className="col-12 col-lg-12">
                            <span className="total-price">{data?.section_33_price_total}</span>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            }
            
          </div>
          {
            (data?.section_33_button) &&
            <div className='mb-5 form-css-hidden'>
            <a
            href={data?.sec_33_button_link}
            className="btn btn-lg btn-rounded btn-pink book-consult-button text-white "
          >
            {data?.section_33_button}
          </a>
          </div>
          }
          
        </div>
      </div>
    </div>
  </div>

  )
}

export default SectionThirtyThree