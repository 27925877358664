import { Markup } from 'interweave'
import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'

function SectionFourtyFour(props) {
  const [data,setdata]= useState(props.data)
  console.log('data')
 console.log(data)
  useEffect(() => {
    setdata(props.data)
  }, [props.data])
 
    console.log('section 44')
    console.log(data)
  return (

<>
<div className="section news-nav-section bg-white" style={{background:data?.section_44_color || ''}}>
    <div className="container">
      <div className="row">
      {
        (data?.section_44_heading1 || data?.section_44_detail1) &&
        <div className="col-md-4 min-h-full">
          <a
            href={data?.sec_44_link1}
            className="d-flex card card-body bg-linear-white-grey card-prev-entry h-100"
          >
            <h3>{data?.section_44_heading1}</h3>
            <p>
              {data?.section_44_detail1}
            </p>
          </a>
        </div>
      }
    
     {
        (data?.section_44_headingimage2 || data?.section_44_heading2 || data?.section_44_button) &&
        <div className="col-md-4">
          <div className="card card-news-book-consult card-body bg-linear-white-grey text-center h-100">
            {
                (data?.section_44_headingimage2) &&
                <img
                src={data?.section_44_headingimage2}
                alt={data?.section_44_image1_alt}
                className="img-fluid mb-3 smaller"
                />
            }
            
            <p>{data?.section_44_heading2}</p>
            {
                (data?.section_44_button) &&
                <a
                href={data?.sec_44_button_link2}
                className="btn btn-sm btn-outline-purple-transparent btn-rounded px-4"
                >
                {data?.section_44_button}
                </a>
            }
            
          </div>
        </div>
     }
       
    {
        (data?.section_44_heading3 || data?.section_44_detail3) &&
        <div className="col-md-4 min-h-full">
          <a
            href={data?.sec_44_link3}
            className="d-flex card card-body bg-linear-white-grey card-next-entry h-100"
          >
            <h3>{data?.section_44_heading3}</h3>
            <p>
             {data?.section_44_detail3}
            </p>
          </a>
        </div>
    }
        
      </div>
    </div>
  </div>

</>
  )
}

export default SectionFourtyFour