import { Markup } from 'interweave'
import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'

function SectionOne(props) {
  const [data,setdata]= useState(props.data)
  console.log('data')
 console.log(data)
  useEffect(() => {
    setdata(props.data)
  }, [props.data])
 
    console.log('sectionone')
    console.log(data)
 
  return (

  <div className="main-section py-3" style={{overflowX:'hidden' ,background:data?.section_1_color || ''}}>
    <div className="section-bg-overlay">
      <div className="container">
        <div className="row home-imagespanel font-family-section">
        {data?.section_one_title && 
          <div className="col-12 text-center pb-2">
            <h2 className="h1 mb-2 mb-md-3 mb-lg-4">
             
              {data?.section_one_title}
            </h2>
          </div>
        }

        
          <div className="col-12 col-md-6 col-lg-6 col-xl-6">
          {
          (data?.section_one_banner1) &&
            <img
              className="new-home-imagesbanner-img"
              src={data?.section_one_banner1}
              alt={data?.section_one_banner_alt}
            />

            }
          </div>
       
          
          <div className="col-12 col-md-6 col-lg-6 col-xl-6">
         
           
            {
              <Markup content={data?.section_one_subtitle} /> 
            }
              
             
            
      {
        (data?.section_one_right_heading || data?.section_one_right_image || data?.section_one_right_content) &&
        <div className="offer-block align-items-center home-desktop ">
                            {
                              (data?.section_one_right_heading) && 
                              <div className="offer-circle desktop d-md-block d-lg-block d-none">
                                 <div className="in-circle ">
                                    {data?.section_one_right_heading }
                                 </div>
                              </div>
                            }
                             
                             {
                              (data?.section_one_right_heading || data?.section_one_right_image || data?.section_one_right_content) &&
                              <div className="text-area d-flex flex-sm-cloumn home-desk-div">
                                {
                                  (data?.section_one_right_heading) && 
                                  <div className="d-block d-lg-none d-md-none" style={{border:'1px solid white'}}>
                                     <div className="in-circle" >
                                        {data?.section_one_right_heading}
                                     </div>
                                  </div>
                                }
                                 
                                
                                    <div className="pig-img">
                                    {
                                      (data?.section_one_right_image) && 
                                     <img className="pig-home-banner " src={data?.section_one_right_image} alt={data?.section_one_right_image_alt}   />
                                    }
                                    </div>
                                 
                                 
                                 
                                  <div className="pr-3 pt-2 pig-content ">
                                  {
                                  (data?.section_one_right_content) && 
                                   
                                      <Markup content={data?.section_one_right_content} />
                                    
                                   
                                  }
                                 </div>
                                 
                                
                             </div>
                             }
                             
                         </div>
      }
            
                     
                        {(data?.section_one_right_button) && <a href={data?.sec_1_button_link} className="btn btn-lg btn-rounded btn-pink book-consult-button m-auto home-desktop d-table text-white">{data?.section_one_right_button}</a>}
          </div>
         
        </div>

   
      </div>
    </div>
    </div>
  )
}

export default SectionOne