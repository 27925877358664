import { Markup } from 'interweave'
import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'

function SectionFourtyTwo(props) {
  const [data,setdata]= useState(props.data)
  console.log('data')
 console.log(data)
  useEffect(() => {
    setdata(props.data)
  }, [props.data])
 
    console.log('section 42')
    console.log(data)
  return (
<>
<div
    className="hero-section"
    style={{
      backgroundImage:
        "https://trustedclinics.com/images/price-comparison/pig1.png",background:data?.section_42_color || ''
    }}
  >
    <div className="section-bg-overlay" >
      {/* <img
        className="mobile"
        src="https://cloud1.coe-website.securestaging.co.uk/images/header-images/hero-invisalign-woman.jpg?mtime=20200515132132&focal=none"
        alt="Article banner image"
      /> */}
      {
        (data?.section_42_title || data?.section_42_date) &&
        <div className="container my-5 "  >
        <div className="row mb-lg-5">
          <div className="col-md-7 col-lg-5 col-xl-5 Articles_div p-2 rounded" style={{backgroundColor:'#6D488A',color:'white'}}>
            <div className="text-overlay p-2 ">
              <h1 className="page-title mb-2 text-white">
                {data?.section_42_title}
              </h1>
              <p className="post-date text-white">{data?.section_42_date}</p>
            </div>
          </div>
        </div>
      </div>

      }
      
    </div>
  </div>
</>

  )
}

export default SectionFourtyTwo