import React, { useEffect, useState } from "react";

import { useNavigate, useParams} from 'react-router-dom'

import axios from "axios";


// const EnterPassword_page = () => {
//     const [passwordType, setPasswordType] = useState("password"); 
//     const [addPassword,setAddPassword] = useState('');
//     const [confirmPassword,setConfirmPassword] = useState('')
//     const {id} = useParams()
//     const navigate = useNavigate()


//     const PasswordSubmit= (event)=>{
//         event.preventDefault();

//         // if (addPassword.length > 6 || addPassword.length < 15  ) {
//         //     // alert('Mobile number must contains 10 digit',addPassword )
//         //     return false;
//         //   }
//         addPassword.length > 6 && addPassword.length  < 16  ?  SavePassword() : alert('Plase Enter Password 6 to 15 Digit')

          
//     } 
// const SavePassword=async()=>{
//     const formdata = new FormData();
//     formdata.append("user_id", Number(id) );
//     formdata.append("password", addPassword);
//     formdata.append('confirm_password', confirmPassword)
  
//     console.log(Number(id))
//        await axios.post(`${process.env.REACT_APP_BASE_URL}api/signup_password_update`,formdata, {
//         headers: {
//             "Content-Type": "multipart/form-data",
//           },
//     }).then(response=>{
//         console.log(response)
//         if (response.data.status === true) {
//             navigate("/thankyou-page");
//           } 
//         else{
//             alert('Password are not Match ')
//           }
//     }).catch(error => console.log(error))
    
// }

//     return (
//         <>
//             <Header />
//             <div className="layout-main">
             
//                 <div className="section static-page-main-section bg-light-blue">
//                     <div className="container">
//                         <h1 className="page-title ">Enter Password</h1>

//                         <form  className="form my-5" onSubmit={PasswordSubmit}>
//                          <div className="col-12 col-lg-4  mx-auto" >
//                   <div className="form-group">
//                                               <div className="flex" style={{ display: "flex" }}>
//                           <input type={passwordType} style={{    borderColor: '#684286'}} 
//                           className="form-control icon-pencil input-outline-purple input-square"
//                           name="password"    value={addPassword} onChange={(e)=>setAddPassword(e.target.value) } min={6} max={15}
//                           id="telephone" placeholder='Please Enter Your Password' aria-label="password"      required/>
//                           <PasswordCheck
//                             className="eyesIcon"
//                             setPasswordType={setPasswordType}
//                             passwordType={passwordType} />
//                       </div>

//                       <div className="flex pt-3" style={{ display: "flex" }}>
//                           <input type='password' style={{    borderColor: '#684286'}} 
//                           className="form-control icon-pencil input-outline-purple input-square"
//                           name="confirmPassword"    value={confirmPassword} onChange={(e)=>setConfirmPassword(e.target.value) } min={6} max={15}
//                           id="telephone" placeholder='Please Enter Your Password' aria-label="password"      required/>
//                           <PasswordCheck
//                             className="eyesIcon"
//                             setPasswordType={setPasswordType}
//                             passwordType={passwordType} />
//                       </div>

//                   </div>
               
//               </div>
//               <div className="text-center mt-3 mt-xl-2">
//               <button type="submit" className="btn btn-md btn-rounded btn-pink book-consult-button-inline ">
//                 <a  className='text-white'>
//                 {/* href={data?.section_51_right_button_link} */}
//                  Submit
//                   </a>
//               </button>
//           </div>
//               </form>

//                         <div className="main-content text-center"><p>Thank you for Submit  your Informetion  a book  free  consultation with My Clinique. One of the team will be in contact very shortly. </p></div>
//                     </div>
//                 </div>  
//                 {/* <div className="section reviews-section section-22620 bg-blue">
//                     <div className="container">
//                         <h2 className="section-title text-color">Reviews</h2>
//                         <div className="row">
//                             <div className="col-lg-10 col-xl-8 mx-auto text-center">
//                                 <div className="introduction text-color"><p className="text-color">Here are some reviews from real MyClinique patients...</p></div>
//                             </div>
//                         </div>
//                         <div className="row reviews justify-content-center">
//                             <div className="col-md-6 col-lg-4 mb-2">
//                                 <p className="review text-color text-color">I had my consultation with Dr Buddha. He was by far the most professional compared to the other clinics I went to for consultations this week. His colleague Lisa was very friendly and helpful.</p>
//                                 <p className="patient-name text-color">Sue Green</p>
//                             </div>
//                             <div className="col-md-6 col-lg-4 mb-2">
//                                 <p className="review text-color" style={{background:" url(/images/quote-open-white.png?e23bfcd…) 0 0 no-repeat,url(/images/quote-close-white.png?f6d47f6…) 100% 100% no-repeat"}}>So I went to see myclinique this week for a Invisalign consultation and I was very impressed, I saw a lady called Lisa and a man called Mark, I told them what a great consultation it was because I was informed of lot's of options and were given lots of information about Invisalign that other clinics never told me before.</p>
//                                 <p className="patient-name text-color">Natalia Marjovic</p>
//                             </div>
//                             <div className="col-md-6 col-lg-4 mb-2">
//                                 <p className="review text-color">MyClinique is the best clinic in Northampton my daughter got her Invisalign here with the gentleman and I get my anti-wrinkle injections here.</p>
//                                 <p className="patient-name text-color">Marianne Donovan</p>
//                             </div>
//                         </div>
//                     </div>
//                 </div> */}
                
//             </div>
//             <Footer />
//         </>
//     )
// }
// export default EnterPassword_page;

// const PasswordCheck= ({setPasswordType,passwordType})=>{
  

//     const PasswordText=()=>{
          
//            if(passwordType === "password" ){
//                 setPasswordType("text")
//            }else{
//                 setPasswordType("password")
//            }
//          }
//   return <span className="eyesIcon"  >
//   { passwordType === "password" ? <BsFillEyeSlashFill  onClick={PasswordText} style={{cursor:'pointer'}} />:
//   <IoEyeSharp  onClick={PasswordText} style={{cursor:'pointer'}} />}</span>
//   }



function Section_Page(props) {
  const [data,setdata]= useState(props.data)
    const [signinData,setSignInData] = useState([])
  const navigate = useNavigate()
  const [eamilErr, setEmailErr] = useState('');
  const [phoneErr, setPhoneErr] = useState('');

  useEffect(() => {
    setdata(props.data)
  }, [props.data])
 
    console.log('section 72')
  

  const SignInHandler=(event)=>{
    const names = event.target.name;
    const values = event.target.value;
    
    setSignInData( data=>({  ...data ,[names] : values    }))
  
  }

  const SubmitHandler= async(event)=>{
    event.preventDefault();
    // console.log(signinData.mobile.length > 10)


    if (signinData.mobile.length > 10 || signinData.mobile.length < 10  ) {
        // alert('Mobile number must contains 10 digit')
        setPhoneErr('Mobile number must contains 10 digit')
        return false;
      }else {
        setPhoneErr('')
      }

 

    console.log(signinData)
    const formdata = new FormData();
    formdata.append("name",signinData.name );
    formdata.append("email", signinData.email);
    formdata.append("phone", signinData.mobile);
    formdata.append("user_type", "2");
    
    await axios.post(`${process.env.REACT_APP_BASE_URL}api/signup`,formdata, {
        headers: {
            "Content-Type": "multipart/form-data",
          },
    }).then(response=>{
        console.log(response.data)
        if (response.data.status === true) {
            navigate(`/thankyou-page/${response.data.data.id}`); 
          } 
          else{
            setEmailErr('Email is Alredy exist')
          }
    }).catch(error => console.log(error))
 
    // navigate("/thankyou-page");
  }
 

console.log(eamilErr)
    return (
        <>

            <div class="layout-main">
            
                <div class="section treatment-main-section">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-6">
                                <h1 class="seo-page-title">{data?.section_72_title}</h1>
                                <h2 class="page-title"><span class="str-replace-invisalign-white">{data?.section_72_description}</span></h2>
                                <div class="treatment-header-image mobile monthly-price-from-display">
                                    <img src={data?.section_72_banner_image} class="" alt={data?.section_72_banner_image_alt} />
                                        <span class="monthly-price-from">
                                            <span class="from">From</span>
                                            <span class="price">{data?.section_72_circal_price}</span>
                                            <span class="per-month">Per Month</span>
                                        </span>
                                </div>
                                <div class="row row-p-10">
                                    <div class="col-6 col-sm-6 col-md-6 col-lg-6 mb-2 mb-md-3">
                                        <div class="card card-body headline-card h-100">
                                            <div class="icon-container">
                                                <img src={data?.section_72_image1} alt={data?.section_72_image1_alt}/>
                                            </div>
                                            <h4>{data?.section_72_head1}</h4>
                                        </div>
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-6 col-lg-6 mb-2 mb-md-3">
                                        <div class="card card-body headline-card h-100">
                                            <div class="icon-container">
                                                <img src={data?.section_72_image2} alt={data?.section_72_image2_alt}/>
                                            </div>
                                            <h4>{data?.section_72_head2}</h4>
                                        </div>
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-6 col-lg-6 mb-2 mb-md-3">
                                        <div class="card card-body headline-card h-100">
                                            <div class="icon-container">
                                                <img src={data?.section_72_image3} alt={data?.section_72_image3_alt}/>
                                            </div>
                                            <h4>{data?.section_72_head3}</h4>
                                        </div>
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-6 col-lg-6 mb-2 mb-md-3">
                                        <div class="card card-body headline-card h-100">
                                            <div class="icon-container">
                                                <img src={data?.section_72_image4} alt={data?.section_72_image4_alt}/>
                                            </div>
                                            <h4>{data?.section_72_head4}</h4>
                                        </div>
                                    </div>
                                    <div class="col-6 col-12 mb-2 mb-md-3">
                                        <div class="card card-body headline-card h-100">
                                            <div class="icon-container">
                                                <img src={data?.section_72_image5} alt={data?.section_72_image5_alt}/>
                                            </div>
                                            <h4>{data?.section_72_head5}</h4>
                                        </div>
                                    </div>
                                </div>
                                
                            </div>

                            <div class="col-lg-6 ">
                                <div class="treatment-header-image desktop monthly-price-from-none">
                                    <img  src={data?.section_72_banner_image} class="" alt={data?.section_72_banner_image_alt}/>
                                        <span class="monthly-price-from">
                                            <span class="from">From</span>
                                            <span class="price">{data?.section_72_circal_price}</span>
                                            <span class="per-month">Per Month</span>
                                        </span>
                                </div>
                                <div class="card card-body card-layered monthly-price-from-none">
<form onSubmit={SubmitHandler} class="form treatment-page-header-form js-form-book-consult">

<div class="text-center mb-4 imae-form">
<img src={data?.section_72_form_image} alt={data?.section_72_form_image_alt} class="img-fluid form-logo" />
</div>
<div class="row">
<div class="col-md-6">
<div class="form-group">
<input type="text" name="name"  id="full_name"  value={signinData.full_name } onChange={SignInHandler}  class="form-control" placeholder={data?.section_72_placeholder1} required=""/>
</div>
</div>
<div class="col-md-6">
<div class="form-group">
<input type="text" name="patient_name" id="practice_name"value={signinData.patient_name } onChange={SignInHandler}  class="form-control" placeholder={data?.section_72_placeholder2}required=""/>
</div>
</div>
<div class="col-md-6">
<div class="form-group">
<input type="number" name="mobile" id="telephone"  value={signinData.mobile } onChange={SignInHandler} class="form-control" placeholder={data?.section_72_placeholder3} required=""/>
<span className="login-page-web ">
                        {phoneErr}
                      </span>
</div>
</div>
<div class="col-md-6">
<div class="form-group">
<input type="email" name="email" value={signinData.email } onChange={SignInHandler}  class="form-control" placeholder={data?.section_72_placeholder4} required=""/>
<span className="login-page-web ">
                        {eamilErr}
                      </span>
</div>
</div>
</div>
{/* <div class="form-group">
<label for="agree_to_terms" class=" text-normal">
<input type="checkbox" name="agree_to_terms" id="agree_to_terms" required=""/>
<p style={{color:'black'}}> I understand that by requesting a consultation through MyClinique I am agreeing with the MyClinique <a href="https://myclinique.net/privacy-policy" 
target="_blank">Privacy Policy</a> &amp; <a href="https://myclinique.net/terms-and-conditions" target="_blank">Terms &amp; Conditions</a>. </p>
</label>
</div> */}

{/* <input type="submit" class="btn btn-lg btn-rounded btn-blue js-book-consult-form-btn" value="Book Your FREE Consultation Now"/> */}
<div className="text-center mt-3 mt-xl-2">
              <button type="submit" className="btn btn-md btn-rounded btn-pink book-consult-button-inline ">
                <a  className='text-white'>
                {/* href={data?.section_51_right_button_link} */}
                  {data?.section_72_button?.substring(0,19)}
                  </a>
              </button>
          </div>

</form>
</div>

                             
                            </div>
                      
                          
                            </div>
                          
                       
                     
           
</div>
</div>
</div>
</>
                       )
}
 

 export default Section_Page