import { Markup } from 'interweave'
import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'

function SectionFourtyEight(props) {
  const [data,setdata]= useState(props.data)
  console.log('data')
 console.log(data)
  useEffect(() => {
    setdata(props.data)
  }, [props.data])
 
    console.log('section 48')
    console.log(data)
  return (
<>
<div className="layout-main" style={{background:data?.section_48_color || ''}}>
  

  <div className="section faqs-section section-7516 bg-off-white">
    <div className="container text-center">
      <h2 className="mb-4">{data?.section_48_title}</h2>
      <div className="row text-center d-flex justify-content-center">
     { (data?.section_48_image1 || data?.section_48_head1 || data?.section_48_detail1) &&  <div className="col-lg-6 pt-2 pb-2">
          <div className="simple-block">
            <div className="icon-container mb-3">
              <img
                src={data?.section_48_image1}
                alt={data?.section_48_image1_alt}
              />
            </div>
            <h4>{data?.section_48_head1}</h4>
            <div>
              <p>
              {data?.section_48_detail1}
              </p>
            </div>
          </div>
        </div>}
      {(data?.section_48_image2 || data?.section_48_head2 || data?.section_48_detail2) &&   <div className="col-lg-6 pt-2 pb-2">
          <div className="simple-block">
            <div className="icon-container mb-3">
              <img
                src={data?.section_48_image2}
                alt={data?.section_48_image2_alt}
              />
            </div>
            <h4>{data?.section_48_head2}</h4>
            <div>
              <p>
              {data?.section_48_detail2}
              </p>
            </div>
          </div>
        </div>}
      </div>
      <a
        href={data?.sec_48_button_link}
        className="btn btn-md btn-rounded btn-pink book-consult-button tarmandcondition-hide text-white"
      >
        {data?.section_48_button || "Book Your Free Consultation Now"}
      </a>
    </div>
  </div>

 
</div>
</>

  )
}

export default SectionFourtyEight