import { Markup } from 'interweave'
import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'

function SectionSeventeen(props) {
  const [data,setdata]= useState(props.data)
  console.log('data')
 console.log(data)
  useEffect(() => {
    setdata(props.data)
  }, [props.data])
 
    console.log('sectionseventeen')
    console.log(data)
  
  return (

<div class="section find-you-local-clinic-section bg-inverse bg-radial-inverse mb-5" style={{background:data?.section_17_color || ''}}>
<div class="container">
    <div class="row">
    {
      (data?.section_seventeen_title || data?.section_seventeen_description || data?.section_seventeen_image) &&
      <div class="col-xl-8 offset-xl-2 col-lg-10 offset-lg-1 text-center">
            <h2 class="mb-lg-4">{data?.section_seventeen_title}</h2>
          {
            <Markup content={data?.section_seventeen_description} />
          }
          {
            (data?.section_seventeen_image) &&
            <div class="mb-3">
                <img src={data?.section_seventeen_image} alt={data?.section_seventeen_image1_alt}/>
            </div>
          }
                
        </div>
    }
        
      
    </div>

    <div class="row">
    {
      (data?.section_seventeen_button) &&
      <div class="col-md-10 offset-md-1 col-lg-6 offset-lg-3  col-xl-6 offset-xl-3 text-center">
           <a href={data?.sec_17_button_link} style={{color:'#ffffff'}}>
         <button type="submit" class="yellow-contact btn-rounded btn-lg">{data?.section_seventeen_button}</button>
         </a>
       </div>
    }
        
    </div>

</div>
</div>
  )
}

export default SectionSeventeen