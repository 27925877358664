import { Markup } from 'interweave'
import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'

function SectionFourtySix(props) {
  const [data,setdata]= useState(props.data)
  console.log('data')
 console.log(data)
  useEffect(() => {
    setdata(props.data)
  }, [props.data])
 
    console.log('section 46')
    console.log(data)
  return (

<>

  {/* <div className="section range-section section-29226 bg-off-white">
    <div className="container">
      <div className="row">
        <div className="col-xl-8 offset-xl-2 col-lg-10 offset-lg-1 pb-0 text-center mb-5">
          <h2 className="mb-4">{data?.section_45_title}</h2>
          <div>
            <p>
              Quick Straight Teeth can straighten teeth quickly with their fixed
              brace and clear aligners.
            </p>
          </div>
        </div>
        <div className="col-12">
          <div className="row text-center mb-4 d-flex justify-content-center mt-5">
            <div className="col-sm-12 col-md-6 col-lg-4 pb-3 px-sm-3 mt-70">
              <div className="card card-treatment-range custom h-100">
                <div
                  className="card-body-bg"
                  style={{
                    backgroundImage:
                      "url(https://cloud1.coe-website.securestaging.co.uk/images/treatment-range/Crowded_braces_124.png?mtime=20200703111043&focal=none)",
                    backgroundPosition: "bottom left",
                    backgroundSize: 170
                  }}
                >
                  <div className="card-body card-body-teatments">
                    <h3>{data?.section_45_title1}</h3>
                    <div className="desc desc-treatment">
                      <p>
                       {data?.section_45_detail1}
                      </p>
                    </div>
                    <span className="total-price-teatments">
                      From
                      <br />
                      <b>{data?.section_3_price1}</b>
                      <br />
                      Per Month
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-4 pb-3 px-sm-3 mt-70">
              <div className="card card-treatment-range custom h-100">
                <div
                  className="card-body-bg"
                  style={{
                    backgroundImage:
                      "url(https://cloud1.coe-website.securestaging.co.uk/images/treatment-range/Crowded_braces_124.png?mtime=20200703111043&focal=none)",
                    backgroundPosition: "bottom right",
                    backgroundSize: 170
                  }}
                >
                  <div className="card-body card-body-teatments">
                    <h3>{data?.section_45_title2}</h3>
                    <div className="desc desc-treatment">
                      <p>
                      {data?.section_45_detail2}
                      </p>
                    </div>
                    <span className="total-price-teatments">
                      From
                      <br />
                      <b>{data?.section_3_price1}</b>
                      <br />
                      Per Month
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-8 offset-xl-2 col-lg-10 offset-lg-1 text-center">
          <a
            href="https://trustedclinics.com/dental/price-comparison/"
            className="btn btn-lg btn-rounded btn-pink book-consult-button"
          >
            {data?.section_45_button}
          </a>
        </div>
      </div>
    </div>
  </div> */}
  <div className="layout-main layout-main-review">
<div
    className="main-section"
    style={{
      backgroundImage:
        "url(https://cloud1.coe-website.securestaging.co.uk/images/header-images/hero-bridges-woman-mouth.jpg?mtime=20200515132127&focal=none)",
        background:data?.section_46_color || ''
    }}
  >
   <div className="container">
      <div className="row pb-lg-5">
        <div className="col-md-7 col-lg-6 col-xl-5 review-div-h1 my-5">
          <h1 className="page-title mb-3 text-white">{data?.section_46_title}</h1>
          <div className="d-table m-auto d-md-flex" style={{display:'table'}}>
            <h5 className="blue mb-0 mr-md-2 blue" style={{color: '#253959'}}>Reviews {data?.section_46_review} - Excellent</h5>
            <div className="icon-container green-stars d-table d-md-flex green-stars-review ">
              <i className="fas fa-star" />
              <i className="fas fa-star" />
              <i className="fas fa-star" />
              <i className="fas fa-star" />
              <i className="fas fa-star" />
            </div>
          </div>
          <div className="mt-4 mb-md-5 review-div-center review-div-center-span ">
            {
                <Markup content={data?.section_46_detail} className='text-white' />
            }
          </div>
          {/* <img
            className="mobile mt-3 mb-0"
            src="https://cloud1.coe-website.securestaging.co.uk/images/header-images/hero-bridges-woman-mouth.jpg?mtime=20200515132127&focal=none"
            alt="Crowns & bridges reviews"
          /> */}
        </div>
      </div>
    </div> 
    </div>
    </div>
</>
  )
}

export default SectionFourtySix