import React, { useEffect, useState } from "react";

import { useNavigate, useParams } from 'react-router-dom'

import axios from "axios";
import { Markup } from "interweave";



function SectionSeventyFour(props) {
    const [data, setdata] = useState(props.data)
    const [signinData, setSignInData] = useState([])
    const navigate = useNavigate()
    const [eamilErr, setEmailErr] = useState('');
    const [phoneErr, setPhoneErr] = useState('');

    useEffect(() => {
        setdata(props.data)
    }, [props.data])

    console.log('section 74')


    const SignInHandler = (event) => {
        const names = event.target.name;
        const values = event.target.value;

        setSignInData(data => ({ ...data, [names]: values }))

    }

    const SubmitHandler = async (event) => {
        event.preventDefault();
        // console.log(signinData.mobile.length > 10)


        if (signinData.mobile.length > 10 || signinData.mobile.length < 10) {
            // alert('Mobile number must contains 10 digit')
            setPhoneErr('Mobile number must contains 10 digit')
            return false;
        } else {
            setPhoneErr('')
        }



        console.log(signinData)
        const formdata = new FormData();
        formdata.append("name", signinData.name);
        formdata.append("email", signinData.email);
        formdata.append("phone", signinData.mobile);
        formdata.append("user_type", "2");

        await axios.post(`${process.env.REACT_APP_BASE_URL}api/signup`, formdata, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        }).then(response => {
            console.log(response.data)
            if (response.data.status === true) {
                navigate(`/thankyou-page/${response.data.data.id}`);
            }
            else {
                setEmailErr('Email is Alredy exist')
            }
        }).catch(error => console.log(error))

        // navigate("/thankyou-page");
    }


    console.log(eamilErr)
    return (
        <>

            <div class="layout-main" style={{background:data?.section_74_color || ''}}>

                <div class="section treatment-main-section">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-6">
                                <h1 class="seo-page-title">{data?.sec_74_title}</h1>
                                <h2 class="page-title"><span class="str-replace-invisalign-white"><Markup content={data?.sec_74_description} /></span></h2>
                                {(data?.section_74_banner1_img || data?.section_74_circle_price) &&
                                    <div class="treatment-header-image mobile monthly-price-from-display">
                                        <img src={data?.section_74_banner1_img} class="" alt={data?.section_74_banner1_img_alt} />
                                        <span class="monthly-price-from">
                                            {/* <span class="from">From</span>
                                              <span class="price">{data?.section_74_circle_price}</span>
                                              <span class="per-month">Per Month</span> */}
                                            <span>{data?.section_74_circle_price}</span>
                                        </span>
                                    </div>}
                                <div class="row row-p-10">
                                    {
                                        (data?.section_74_image1 || data?.section_74_head1) &&
                                        <div class="col-6 col-sm-6 col-md-6 col-lg-6 mb-2 mb-md-3">
                                            <div class="card card-body headline-card h-100">
                                                <div class="icon-container">
                                                    <img src={data?.section_74_image1} alt={data?.section_74_image1_alt} />
                                                </div>
                                                <h4>{data?.section_74_head1}</h4>
                                            </div>
                                        </div>
                                    }

                                    {(data?.section_74_image2 || data?.section_74_head2) &&
                                        <div class="col-6 col-sm-6 col-md-6 col-lg-6 mb-2 mb-md-3">
                                            <div class="card card-body headline-card h-100">
                                                <div class="icon-container">
                                                    <img src={data?.section_74_image2} alt={data?.section_74_image2_alt} />
                                                </div>
                                                <h4>{data?.section_74_head2}</h4>
                                            </div>
                                        </div>}

                                    {

                                        (data?.section_74_image3 || data?.section_74_head3) &&
                                        <div class="col-6 col-sm-6 col-md-6 col-lg-6 mb-2 mb-md-3">
                                            <div class="card card-body headline-card h-100">
                                                <div class="icon-container">
                                                    <img src={data?.section_74_image3} alt={data?.section_74_image3_alt} />
                                                </div>
                                                <h4>{data?.section_74_head3}</h4>
                                            </div>
                                        </div>}

                                    {(data?.section_74_image4 || data?.section_74_head4) &&
                                        <div class="col-6 col-sm-6 col-md-6 col-lg-6 mb-2 mb-md-3">
                                            <div class="card card-body headline-card h-100">
                                                <div class="icon-container">
                                                    <img src={data?.section_74_image4} alt={data?.section_74_image4_alt} />
                                                </div>
                                                <h4>{data?.section_74_head4}</h4>
                                            </div>
                                        </div>}
                                    {(data?.section_74_image5 || data?.section_74_head5) &&
                                        <div class="col-6 col-12 mb-2 mb-md-3">
                                            <div class="card card-body headline-card h-100">
                                                <div class="icon-container">
                                                    <img src={data?.section_74_image5} alt={data?.section_74_image5_alt} />
                                                </div>
                                                <h4>{data?.section_74_head5}</h4>
                                            </div>
                                        </div>}
                                </div>

                            </div>

                            <div class="col-lg-6 ">
                                {(data?.section_74_banner1_img || data?.section_74_circle_price) &&
                                    <div class="treatment-header-image desktop monthly-price-from-none">
                                        <img src={data?.section_74_banner1_img} class="" alt={data?.section_74_banner1_img_alt} />
                                        <span class="monthly-price-from">
                                            {/* <span class="from">From</span>
                                              <span class="price">{data?.section_74_circle_price}</span>
                                              <span class="per-month">Per Month</span> */}
                                            <span>{data?.section_74_circle_price}</span>
                                        </span>
                                    </div>

                                }


                                <div class="card card-body card-layered monthly-price-from-none">
                                    <form onSubmit={SubmitHandler} class="form treatment-page-header-form js-form-book-consult">

                                        <div class="text-center mb-4 imae-form">
                                            <img src={data?.section_74_form_image} alt={data?.section_74_form_image_alt} class="img-fluid form-logo" />
                                        </div>
                                        <div class="row">
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <input type="text" name="name" id="full_name" value={signinData.full_name} onChange={SignInHandler} class="form-control" placeholder={data?.section_74_placeholder1} required />
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <input type="text" name="patient_name" id="practice_name" value={signinData.patient_name} onChange={SignInHandler} class="form-control" placeholder={data?.section_74_placeholder2} required />
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <input type="number" name="mobile" id="telephone" value={signinData.mobile} onChange={SignInHandler} class="form-control" placeholder={data?.section_74_placeholder3} required />
                                                    <span className="login-page-web ">
                                                        {phoneErr}
                                                    </span>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <input type="email" name="email" value={signinData.email} onChange={SignInHandler} class="form-control" placeholder={data?.section_74_placeholder4} required />
                                                    <span className="login-page-web ">
                                                        {eamilErr}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div class="form-group">
  <label for="agree_to_terms" class=" text-normal">
  <input type="checkbox" name="agree_to_terms" id="agree_to_terms" required/>
  <p style={{color:'black'}}> I understand that by requesting a consultation through MyClinique I am agreeing with the MyClinique <a href="https://myclinique.net/privacy-policy" 
  target="_blank">Privacy Policy</a> &amp; <a href="https://myclinique.net/terms-and-conditions" target="_blank">Terms &amp; Conditions</a>. </p>
  </label>
  </div> */}

                                        {/* <input type="submit" class="btn btn-lg btn-rounded btn-blue js-book-consult-form-btn" value="Book Your FREE Consultation Now"/> */}
                                        <div className="text-center mt-3 mt-xl-2">
                                            <button type="submit" className="btn btn-md btn-rounded btn-pink book-consult-button-inline ">
                                                <a className='text-white'>
                                                    {/* href={data?.section_51_right_button_link} */}
                                                    {data?.section_74_button?.substring(0, 19)}
                                                </a>
                                            </button>
                                        </div>

                                    </form>
                                </div>


                            </div>


                        </div>




                    </div>
                </div>
            </div>
        </>
    )
}


export default SectionSeventyFour