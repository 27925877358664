import React, { useEffect, useState } from 'react'
import Header from '../Pages/Header&Footer/Header'
import Footer from '../Pages/Header&Footer/Footer'
import axios from 'axios'
import { NavLink, useNavigate, useParams } from 'react-router-dom'
import { Markup } from 'interweave'
import { BeatLoader } from "react-spinners"; 
import LatestBlog from './LatestBlog'
import moment from 'moment/moment'
import { Helmet } from 'react-helmet';
import BlogSectionOne from './blog_sections/BlogSectionOne'
import BlogSectionTwo from './blog_sections/BlogSectionTwo'
import BlogSectionThree from './blog_sections/BlogSectionThree'
import BlogSectionFour from './blog_sections/BlogSectionFour'
import BlogCategory from './BlogCategory'
import SocialMediaSection from './blog_sections/SocialMediaSection'

const BlogById = () => {
  const navigate = useNavigate()
  const [BlogDataById,setBlogDataById] = useState({})
  const [fetchData,setfetchData] = useState(true)
  const [fetchData2,setfetchData2] = useState(true)
  const [data,setdata]= useState([])
  const [categoryBlogData,setcategoryBlogData]= useState([])
  const [blogCategoryId,setblogCategoryId] = useState("")
  const [sections,setsections]= useState([])
  const [sectionPositions,setsectionPositions]= useState([])
  const [isLoading,setisLoading] = useState(false)
  const DataById= useParams()
  const [videoIds, setVideoIds] = useState([]);
  const [isMobileView, setIsMobileView] = useState(false);

  // Check screen width on initial render and on resize
  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth < 768); // Adjust the width as needed
    };

    handleResize(); // Check the width initially

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

 
  useEffect(() => {
    
    Get_Blog_Data()
    if(DataById.id){
      localStorage.setItem('current_page_name',DataById.id)
    }
  }, [DataById.id])
 
  
  useEffect(() => {
    const youtubeRegex = /https:\/\/www\.youtube\.com\/embed\/([\w-]+)/g;
    const matches = BlogDataById?.description?.match(youtubeRegex);
    if (matches) {
      const videoIds = matches.map((match) => match.match(/https:\/\/www\.youtube\.com\/embed\/([\w-]+)/)[1]);
      setVideoIds(videoIds);
    } else {
      setVideoIds([]);
    }
  }, [BlogDataById?.description]);

  const getBlogCategoryData=async()=>{
    // setisLoading(true)
    await axios.get(`${process.env.REACT_APP_BASE_URL}api/get_blog_by_category/${blogCategoryId}`).
    then(res=>{
        if(res.data.data === false){
            setfetchData2(false)
        }
        else{
            setfetchData2(true)
        }
        
        setcategoryBlogData(res.data?.detail)
        // setFilter(res.data?.detail)
        // setisLoading(false)
  
      })
      .catch(error=>{
        console.log(error)
        // setisLoading(false)
      })
  }

  useEffect(() => {
    getBlogCategoryData()
  }, [blogCategoryId])

   // Function to replace YouTube links with video players in the paragraph
   const renderParagraphWithVideos = () => {
    const paragraphs = BlogDataById?.description?.split('\n');
    const updatedParagraphs = paragraphs.map((p) => {
      const youtubeMatch = p.match(/https:\/\/www\.youtube\.com\/embed\/([\w-]+)/);
      if (youtubeMatch) {
        return (
          <div key={youtubeMatch[1]} className="video">
            <iframe
              width="100%"
              height="315"
              src={`https://www.youtube.com/embed/${youtubeMatch[1]}`}
              title={`YouTube video player ${youtubeMatch[1]}`}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            ></iframe>
          </div>
        );
      } else {
        return <p key={p} dangerouslySetInnerHTML={{ __html: p }} />;
      }
    });

    return updatedParagraphs;
  };
   
  

  const Get_Blog_Data=async()=>{
    setisLoading(true)
    // get_blog_by_id
    // get_blog_section_by_id
    
    await axios.get(`${process.env.REACT_APP_BASE_URL}api/get_blog_section_by_pagename/${DataById.id}`).
    then(res=>{
        if(res.data.data === false){
            setfetchData(false)
        }
        else{
            setfetchData(true)
        }

        if(res.data?.detail){
          setBlogDataById(res.data?.detail)
          setblogCategoryId(res.data?.detail?.category_id)
        }
        
        if(res?.data?.sections){
          setsections(res?.data?.sections[0])
          if(res?.data?.sections[0]?.section_position){
            setsectionPositions(res?.data?.sections[0]?.section_position)
          }
          
        }
        
        setisLoading(false)
  
      })
      .catch(error=>{
        console.log(error)
        setisLoading(false)
      })
  }
  console.log("sectionPositionsssssssssssssssssssssssssssssssssssssssssssss",sectionPositions)
  console.log(sections)
  return (
 <>

          <Header/>
       

 {/* (userData?.image && userData?.image !== null && userData?.image !== 'null') ?
                        userData?.image */}
          {
    isLoading ? 
  <div style={{width:'100%',height:'100vh',display:'flex',alignItems:'center',justifyContent:'center'}}>
  <BeatLoader       
          loading={true}
          color="orange"
          size={15}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
  </div>
  :  
  <>   
     <div>
      <Helmet>
        <title>{(BlogDataById?.meta_keyword && BlogDataById?.meta_keyword !== null && BlogDataById?.meta_keyword !== 'null')? BlogDataById?.metakeyword :'Blog'}</title>
        <meta name={BlogDataById.meta_keyword} content={BlogDataById.meta_description} />
        {/* Add other meta tags as needed */}
      </Helmet>
      {/* blog-single gray-bg bg-faq */}
      {/* blog-single bg-faq */}
          <div className="blog-single" style={{background:"#FFFFFF"}}>
        <div className="container">
          <div className="row align-items-start">
            <div className="col-lg-8 m-15px-tb">
              <article className="" 
              style={{
                padding:'0px'
              }}
              >
                {/* <div className="article-img">
                  <img 
                  style={{
                    width:'800px',
                    height:'350px'
                  }}
                //   src="https://www.bootdey.com/image/800x350/87CEFA/000000" 
                // src='https://healthcoach.ancorathemes.com/wp-content/uploads/2016/05/recipe-8.jpg'
                src={BlogDataById?.image}
                  title={BlogDataById?.image_name}
                   alt={BlogDataById?.image_alt}

                   />
                </div>
                <div className="article-title"    
                style={{
                paddingLeft:'20px',

              }}>
                 
                  <h2 style={{fontSize:'18px'}} className='pl-2 m-0'>{BlogDataById?.title} </h2>
                  <div className="media">
             
                    <div className="media-body m-0 p-0">
              
                      
                   <div className="d-flex">
                      <span

className="text-white bg-blu px-2 py-1 mb-3"
style={{fontSize:'11px', fontWeight:'600',borderRadius:'999px',backgroundColor:'#d4a54e'}}
>{moment(BlogDataById?.created_at).format('MMMM Do YYYY')}</span>
                   </div>
                    </div>
                  </div>

                  
                </div> */}
                {/* <div className="article-content" 
                   style={{
               
                    paddingLeft:'12px',
                paddingRight:'12px',
                // paddingTop:'6px',
                // paddingBottom:'6px'
              }}
              >
                
                {videoIds.length > 0 ?(
        <div className="video-container"  style={{fontSize:'13px'}}>{renderParagraphWithVideos()}</div>):
                <p style={{fontSize:'13px',
               }}><Markup content= {BlogDataById?.description} /></p>

      }

  

                </div> */}
                {
                  (BlogDataById?.title) && 
                <>
                <nav aria-label="breadcrumb" style={{ marginLeft: '10px' }}>
                <ol className="breadcrumb">
                  <li className="breadcrumb-item"><a onClick={()=>navigate('/')}><span className='text-secondary'>Home</span></a></li>
                  <li className="breadcrumb-item"><a onClick={()=>navigate('/blog')}><span className='text-secondary'>Blog</span></a></li>
                  <li className="breadcrumb-item active" aria-current="page">
                    <span className='blog-color-primary'>
                      {isMobileView && BlogDataById?.title.length > 15
                        ? `${BlogDataById?.title.slice(0, 15)}...`
                        : BlogDataById?.title}
                    </span>
                  </li>
                </ol>
              </nav>
                {/* <nav aria-label="breadcrumb" style={{marginLeft:'10px'}}>
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="#" ><span className='text-secondary'>Home</span></a></li>
                    <li class="breadcrumb-item"><a href="#"><span className='text-secondary'>Blog</span></a></li>
                    <li class="breadcrumb-item active" aria-current="page"><span className='blog-color-primary'>
                    {(BlogDataById?.title && BlogDataById?.title.length > 20) ? `${BlogDataById?.title.slice(0,20)}...` : BlogDataById?.title}
                    </span></li>
                  </ol>
                </nav> */}
                </>
              }

    {
      (sectionPositions && sectionPositions.length > 0) && sectionPositions.map((item,index)=>{
          {
            if((item?.name) && (item?.name ==='section_one')){
             return <BlogSectionOne data={(sections?.section_one) && sections?.section_one}/>   
            }
            if((item?.name) && (item?.name ==='section_two')){
             return <BlogSectionTwo data={(sections?.section_two) && sections?.section_two}/>   
            }
            if((item?.name) && (item?.name ==='section_three')){
             return <BlogSectionThree  data={(sections?.section_three) && sections?.section_three}/>  
            }
            if((item?.name) && (item?.name ==='section_four')){
             return <BlogSectionFour data={(sections?.section_four) && sections?.section_four} />  
            }
          }
        })
    }
                <SocialMediaSection data={BlogDataById}/>
               {/* <BlogSectionOne /> */}
               {/* <BlogSectionTwo/> */}
               {/* <BlogSectionFour/> */}
               {/* <BlogSectionThree/> */}
              </article>
           
            </div>
            <div className="col-lg-4 m-15px-tb blog-aside">
             
      


           
             <LatestBlog/>
            {
              (categoryBlogData && BlogDataById) && <>
              <BlogCategory data={categoryBlogData} status={fetchData2} />
              </>
            }
              
            
            </div>
          </div>
        </div>
        </div> 
      </div> 
      <Footer/> 
  </>
      }
       
 </>
  )
}

export default BlogById



// const dummy=()=>{
//   const [paragraph, setParagraph] = useState(
//     "<p>Hello User ,</p>\r\n\r\n<p>https://www.youtube.com/embed/SnXjGLFGCQU?si=9T7UL_O3pLIOK4os</p>\r\n\r\n<p>Thank you</p><p>Hello User ,</p>\r\n\r\n<p>https://www.youtube.com/embed/SnXjGLFGCQU?si=9T7UL_O3pLIOK4os</p>\r\n\r\n<p>Thank you</p>"
//   );

//   const [videoIds, setVideoIds] = useState([]);

//   useEffect(() => {
//     const youtubeRegex = /https:\/\/www\.youtube\.com\/embed\/([\w-]+)/g;
//     const matches = paragraph.match(youtubeRegex);
//     if (matches) {
//       const videoIds = matches.map((match) => match.match(/https:\/\/www\.youtube\.com\/embed\/([\w-]+)/)[1]);
//       setVideoIds(videoIds);
//     } else {
//       setVideoIds([]);
//     }
//   }, [paragraph]);

//   // Function to replace YouTube links with video players in the paragraph
//   const renderParagraphWithVideos = () => {
//     const paragraphs = paragraph.split('\n');
//     const updatedParagraphs = paragraphs.map((p) => {
//       const youtubeMatch = p.match(/https:\/\/www\.youtube\.com\/embed\/([\w-]+)/);
//       if (youtubeMatch) {
//         return (
//           <div key={youtubeMatch[1]} className="video">
//             <iframe
//               width="560"
//               height="315"
//               src={`https://www.youtube.com/embed/${youtubeMatch[1]}`}
//               title={`YouTube video player ${youtubeMatch[1]}`}
//               frameBorder="0"
//               allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
//               allowFullScreen
//             ></iframe>
//           </div>
//         );
//       } else {
//         return <p key={p} dangerouslySetInnerHTML={{ __html: p }} />;
//       }
//     });

//     return updatedParagraphs;
//   };

//   return (
//     <div className="App">
//       <h1>Video Player</h1>
//       <textarea
//         placeholder="Enter a paragraph with video links"
//         value={paragraph}
//         onChange={(e) => setParagraph(e.target.value)}
//       />
//       {videoIds.length > 0 && (
//         <div className="video-container">{renderParagraphWithVideos()}</div>
//       )}
//     </div>
//   );

// }