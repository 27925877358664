import { Markup } from 'interweave'
import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'

function SectionThirtySix(props) {
  const [data,setdata]= useState(props.data)
  console.log('data')
 console.log(data)
  useEffect(() => {
    setdata(props.data)
  }, [props.data])
 
    console.log('section 36')
    console.log(data)

  return (
<>
<div className="section slider-videos-section section-16015 bg-inverse text-white" style={{background:data?.section_36_color || '#684286',color:'white'}}>
    <div className="container">
      <div className="row">
        <div className="col-xl-8 offset-xl-2 col-lg-10 offset-lg-1 pb-1 text-center">
          <h2 className="mb-4">{data?.section_36_title}</h2>
          <div style={{color:'white'}}>
          {
            <Markup content={data?.section_36_description} className='review-div-center-span' /> 
          }
            
          </div>
        </div>
      </div>
      <div className="row text-center d-flex justify-content-center">
        <div className="col-12 col-sm-6 col-lg-4 mb-2">
          <div className="video-block h-100">
            <div className="embed-responsive embed-responsive-4by3 video-container">
              <video
                className="video embed-responsive-item"
                playsInline=""
                muted=""
                preload="auto"
              >
                <source
                  src="https://cloud1.coe-website.securestaging.co.uk/videos/slider/invisalign-lingual-crowded_AkBz8dt7.compressed.mp4?mtime=20200608143019&focal=none#t=0.1"
                  type="video/mp4"
                />
                <p>Your browser doesn't support HTML5 video.</p>
              </video>
              <div className="video-controls">
                <button type="button" className="play-pause">
                  <span className="sr-only">Play</span>
                </button>
                <div className="seek-container">
                  <input
                    type="range"
                    className="seek-bar"
                    defaultValue={0}
                    min={0}
                    max={100}
                    step={5}
                  />
                </div>
              </div>
            </div>
            <h3 className="my-3">{data?.section_36_head1}</h3>
          </div>
        </div>
        <div className="col-12 col-sm-6 col-lg-4 mb-2">
          <div className="video-block h-100">
            <div className="embed-responsive embed-responsive-4by3 video-container">
              <video
                className="video embed-responsive-item"
                playsInline=""
                muted=""
                preload="auto"
              >
                <source
                  src="https://cloud1.coe-website.securestaging.co.uk/videos/slider/invisalign-lingual-openbite_LwSovk2R.compressed.mp4?mtime=20200608143018&focal=none#t=0.1"
                  type="video/mp4"
                />
                <p>Your browser doesn't support HTML5 video.</p>
              </video>
              <div className="video-controls">
                <button type="button" className="play-pause">
                  <span className="sr-only">Play</span>
                </button>
                <div className="seek-container">
                  <input
                    type="range"
                    className="seek-bar"
                    defaultValue={0}
                    min={0}
                    max={100}
                    step={5}
                  />
                </div>
              </div>
            </div>
            <h3 className="my-3">{data?.section_36_head2 }</h3>
          </div>
        </div>
        <div className="col-12 col-sm-6 col-lg-4 mb-2">
          <div className="video-block h-100">
            <div className="embed-responsive embed-responsive-4by3 video-container">
              <video
                className="video embed-responsive-item"
                playsInline=""
                muted=""
                preload="auto"
              >
                <source
                  src="https://cloud1.coe-website.securestaging.co.uk/videos/slider/invisalign-lingual-underbite_zTX75Wvv.compressed.mp4?mtime=20200608143018&focal=none#t=0.1"
                  type="video/mp4"
                />
                <p>Your browser doesn't support HTML5 video.</p>
              </video>
              <div className="video-controls">
                <button type="button" className="play-pause">
                  <span className="sr-only">Play</span>
                </button>
                <div className="seek-container">
                  <input
                    type="range"
                    className="seek-bar"
                    defaultValue={0}
                    min={0}
                    max={100}
                    step={5}
                  />
                </div>
              </div>
            </div>
            <h3 className="my-3">{data?.section_36_head3}</h3>
          </div>
        </div>
        <div className="col-12 col-sm-6 col-lg-4 mb-2">
          <div className="video-block h-100">
            <div className="embed-responsive embed-responsive-4by3 video-container">
              <video
                className="video embed-responsive-item"
                playsInline=""
                muted=""
                preload="auto"
              >
                <source
                  src="https://cloud1.coe-website.securestaging.co.uk/videos/slider/invisalign-lingual-crossbite_zBgDwggn.compressed.mp4?mtime=20200608143019&focal=none#t=0.1"
                  type="video/mp4"
                />
                <p>Your browser doesn't support HTML5 video.</p>
              </video>
              <div className="video-controls">
                <button type="button" className="play-pause">
                  <span className="sr-only">Play</span>
                </button>
                <div className="seek-container">
                  <input
                    type="range"
                    className="seek-bar"
                    defaultValue={0}
                    min={0}
                    max={100}
                    step={5}
                  />
                </div>
              </div>
            </div>
            <h3 className="my-3">{data?.section_36_head4}</h3>
          </div>
        </div>
        <div className="col-12 col-sm-6 col-lg-4 mb-2">
          <div className="video-block h-100">
            <div className="embed-responsive embed-responsive-4by3 video-container">
              <video
                className="video embed-responsive-item"
                playsInline=""
                muted=""
                preload="auto"
              >
                <source
                  src="https://cloud1.coe-website.securestaging.co.uk/videos/slider/invisalignlingual-gap_QEtaKCmR.compressed.mp4?mtime=20200608143017&focal=none#t=0.1"
                  type="video/mp4"
                />
                <p>Your browser doesn't support HTML5 video.</p>
              </video>
              <div className="video-controls">
                <button type="button" className="play-pause">
                  <span className="sr-only">Play</span>
                </button>
                <div className="seek-container">
                  <input
                    type="range"
                    className="seek-bar"
                    defaultValue={0}
                    min={0}
                    max={100}
                    step={5}
                  />
                </div>
              </div>
            </div>
            <h3 className="my-3">{data?.section_36_head5 }</h3>
          </div>
        </div>
        <div className="col-12 col-sm-6 col-lg-4 mb-2">
          <div className="video-block h-100">
            <div className="embed-responsive embed-responsive-4by3 video-container">
              <video
                className="video embed-responsive-item"
                playsInline=""
                muted=""
                preload="auto"
              >
                <source
                  src="https://cloud1.coe-website.securestaging.co.uk/videos/slider/invislalign-lingual-cosmetic_XBu77Jj9.compressed.mp4?mtime=20200608143016&focal=none#t=0.1"
                  type="video/mp4"
                />
                <p>Your browser doesn't support HTML5 video.</p>
              </video>
              <div className="video-controls">
                <button type="button" className="play-pause">
                  <span className="sr-only">Play</span>
                </button>
                <div className="seek-container">
                  <input
                    type="range"
                    className="seek-bar"
                    defaultValue={0}
                    min={0}
                    max={100}
                    step={5}
                  />
                </div>
              </div>
            </div>
            <h3 className="my-3">{data?.section_36_head6}</h3>
          </div>
        </div>
      </div>
    </div>
  </div>
</>

  )
}

export default SectionThirtySix;