import { Markup } from 'interweave'
import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'

function SectionFiftyThree(props) {
  const [data,setdata]= useState(props.data)
  console.log('data')
 console.log(data)
  useEffect(() => {
    setdata(props.data)
  }, [props.data])
 
    console.log('section 53')
    console.log(data)
  
  return (


<>
<div 
style={{background:data?.section_53_color || ''}}
>
        <div className=''>
        <div className="section partners bg-grey-bg" id="WhyTrustedClinics" >
        <div className="container">
          <div className="row">
            <div className="col-xl-8 offset-xl-2 col-lg-10 offset-lg-1 pb-0 text-center">
              <h2 className="mb-4 initial-text-transform "><img src={data?.section_53_header_image} alt={data?.section_53_image1_alt} className='price-img' /> {data?.section_53_title}</h2>
            </div>
            <div className="col-lg-7">
              <div className="row text-center mt-1 mt-md-3 mb-2 mb-md-4 d-flex justify-content-center">
                <div className="col-md-6 col-lg-6">
                  <img className="icons eligible-img-fluid" src={data?.section_53_image1} alt={data?.section_53_image2_alt} />
                  <h4 className="initial-text-transform">
                    {/* Highest quality<br />
                    enquiries*/}
                    {<Markup content={data?.section_53_description1?.substring(0,27)} />}
                    </h4> 
                  {/* <p>Trusted Clinics only sources the highest quality leads available due to its transparent, informative and direct marketing strategies. Trusted Clinics only sources high value leads with an average sale price of £3,000 and above.</p> */}
                  {<Markup content={data?.section_53_left_head2}/>}
                </div>
                <div className="col-md-6 col-lg-6">
                  <img className="icons eligible-img-fluid" src={data?.section_53_image2} alt={data?.section_53_image3_alt} />
                  <h4 className="initial-text-transform">
                    {/* Complete<br />
                    focus */}
                    {<Markup content={data?.section_53_head2?.substring(0,27)}/>}
                    </h4>
                  {/* <p>Unlike marketing agencies, Trusted Clinics does not have hundreds of clients requiring constant attention and management. Our complete focus is our platforms, giving our clinics the very best results possible.</p> */}
                  {<Markup content={data?.section_53_description2}/>}
                </div>
              </div>
              <div className="row text-center mt-1 mt-md-3 mb-2 mb-md-4 d-flex justify-content-center">
                <div className="col-md-6 col-lg-6">
                  <img className="icons eligible-img-fluid" src={data?.section_53_image3} alt={data?.section_53_image4_alt} />
                  <h4 className="initial-text-transform">
                    {/* Only pay<br />
                    for results */}
                     { <Markup content={data?.section_53_head3?.substring(0,27)}/>}
                    </h4>
                  {/* <p>Trusted Clinics works on a fee-per-enquiry basis. Decrease your risk, increase your turnover and practice value.</p> */}
                  {<Markup content={data?.section_53_description3}/>}
                </div>
                <div className="col-md-6 col-lg-6">
                  <img className="icons eligible-img-fluid2" src={data?.section_53_image4} alt={data?.section_53_image5_alt} width=''/>
                  <h4 className="initial-text-transform">
                    {/* 100% results<br />
                    guaranteed** */}
                       { <Markup content={data?.section_53_head4?.substring(0,27)}/>}
                    </h4>
                  {/* <p>Using proven marketing strategies and patient journeys, Trusted Clinics is able to offer each practice a results-guaranteed solution.</p> */}
                  {<Markup content={data?.section_53_description4}/>}
                </div>
              </div>
            </div>
            <div className="col-lg-5">
              <div className="row text-center mb-md-4">
                <div className="col-md-12 col-lg-4">
                  <img src= {data?.section_53_banner1}  className="iphone-img" alt={data?.section_53_image6_alt} />
                </div>
              </div>
            </div>
            <div className="last-block col-lg-5">
              <div className="row text-center mb-md-4">
                <div className="col-md-12 col-lg-4">
                  <img src= {data?.section_53_banner2} className="ipad-img" alt={data?.section_53_image7_alt}  width=''/>
                </div>
              </div>
            </div>
            <div className="last-block col-lg-7">
              <div className="row text-center mt-1 mt-md-3 mb-2 mb-md-4 d-flex justify-content-center">
                <div className="col-md-6 col-lg-6">
                  <img className="icons eligible-img-fluid" src={data?.section_53_image5} alt={data?.section_53_image8_alt} />
                  <h4 className="initial-text-transform">
                    {/* Education */}
                    { <Markup content= {data?.section_53_head5?.substring(0,27)}/>}
                    </h4>
                  
                  {/* <p>Trusted Clinics is dedicated to improving the standard of dentistry within the UK by offering free monthly courses. From consultation training to Teeth in a Day, Trusted Clinics is committed to ensuring partnering clinics are as informed as possible, earning patient trust and delivering exceptional results.</p> */}
                  {<Markup content={data?.section_53_description5}/>}
                </div>

                <div className="col-md-6 col-lg-6">
                  <img className="icons  eligible-img-fluid2" src={data?.section_53_image6} alt={data?.section_53_image9_alt}/>
                  <h4 className="initial-text-transform">
                    {/* UK dentistry */}   { <Markup content={data?.section_53_head6?.substring(0,27)}/>}
                  </h4>
                  {<Markup content={data?.section_53_description6}/>}
                  {/* <p>Trusted Clinics fully supports the fight against dental tourism. With a primary focus on quality, safety, aesthetics and patient satisfaction, Trusted Clinics is determined to educate patients into understanding that UK dentistry is the right choice.</p> */}
                </div>
              </div>
              <div className="row text-center mt-1 mt-md-3 mb-2 mb-md-4 d-flex justify-content-center">
                <div className="col-md-6 col-lg-6">
                  <img className="icons eligible-img-fluid" src={data?.section_53_image7} alt={data?.section_53_image10_alt} />
                  <h4 className="initial-text-transform">
                    {/* Discounts */}
                    { <Markup content={data?.section_53_head7?.substring(0,27)}/>}
                  </h4>
                  {<Markup content={data?.section_53_description7}/>}
                  {/* <p>Trusted Clinics’ members receive exclusive discounts from some of the best suppliers in the industry. An ever-developing list of suppliers that wish to partner with Trusted Clinics dentists has begun, driving down cost and increasing profitability, giving Trusted Clinics clinicians the competitive edge.</p> */}
                </div>
                <div className="col-md-6 col-lg-6">
                  <img className="icons eligible-img-fluid" src={data?.section_53_image8} alt={data?.section_53_image11_alt} />
                  <h4 className="initial-text-transform">
                    {/* Limited */}  { <Markup content= {data?.section_53_head8?.substring(0,27)} />}
                  </h4>

                  {<Markup content={data?.section_53_description8}/>}
                  {/* <p>Trusted Clinics is not for everyone. To ensure all levels of service and results are exceptional for every clinic we work with, there are limited regional priorities available nationwide.</p> */}
                </div>
              </div>
            </div>
            <div className="last-block col-12 col-lg-8 offset-lg-2 mt-4 mt-md-2 mb-4 mb-md-0">
              <div className="row justify-content-center">
                <div className="col-12 text-center">
                {<Markup content={data?.section_53_footer}/>}
                  {/* <p className="mb-0">
                    *Combined results achieved for various clinics the team have worked with over the last 12 months.<br />
                    ** Diamond package or above. Must have completed an induction and consultation training. Minimum booking rate of 30% and a minimum conversion rate of 45%.</p> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
        </div>
    </div>
</>
  )
}

export default SectionFiftyThree;
//100% results guaranteed**