import { Markup } from 'interweave'
import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'


function SectionTwentySix(props) {
  const [data,setdata]= useState(props.data)
  console.log('data')
 console.log(data)
  useEffect(() => {
    setdata(props.data)
  }, [props.data])
 
    console.log('section 26')
    console.log(data)
  return (
<>
<div className="section faqs-section section-4015 bg-off-white" style={{background:data?.section_26_color || ''}}>
            <div className="container text-center">
              <h2 className="mb-4">{data?.section_26_title}</h2>
              <div className="row text-center d-flex justify-content-center">
                <div className="col-lg-6 pt-2 pb-2">
                  <div className="simple-block">
                  {
                    (data?.section_26_image1) &&
                    <div className="icon-container mb-3">
                      <img src={data?.section_26_image1} alt={data?.section_26_image1_alt} style={{maxWidth: '220px',height: '125px'}} />
                    </div>
                  }
                    
                    <h4>{data?.section_26_head1}</h4>
                    <div><p>{data?.section_26_detail1}</p></div>
                  </div>
                </div>
                <div className="col-lg-6 pt-2 pb-2">
                  <div className="simple-block">
                  {
                    (data?.section_26_image2) &&
                    <div className="icon-container mb-3">
                      <img src={data?.section_26_image2} alt={data?.section_26_image2_alt} style={{maxWidth: '220px',height: '125px'}} />
                    </div>
                  }
                    
                    <h4>{data?.section_26_head2}</h4>
                    <div><p>{data?.section_26_detail2}</p></div>
                  </div>
                </div>
                <div className="col-lg-6 pt-2 pb-2">
                  <div className="simple-block">
                  {
                    (data?.section_26_image3) &&
                    <div className="icon-container mb-3">
                      <img src={data?.section_26_image3} alt={data?.section_26_image3_alt} style={{maxWidth: '220px',height: '125px'}} />
                    </div>
                  }
                    
                    <h4>{data?.section_26_head3}</h4>
                    <div><p>{data?.section_26_detail3 }</p></div>
                  </div>
                </div>
                <div className="col-lg-6 pt-2 pb-2">
                  <div className="simple-block">
                  {
                    (data?.section_26_image4) &&
                    <div className="icon-container mb-3">
                      <img src={data?.section_26_image4} alt={data?.section_26_image4_alt}  style={{maxWidth: '220px',height: '125px'}} />
                    </div>
                  }
                    
                    <h4>{data?.section_26_head4}</h4>
                    <div><p>{data?.section_26_detail4 }</p></div>
                  </div>
                </div>
              </div>
              <div className='form-css-hidden'>
              {
                (data?.section_26_button) && 
                <a href={data?.sec_26_button_link} className="btn btn-md btn-rounded btn-pink book-consult-button text-white">
                {data?.section_26_button}
                </a>
              }
              </div>
            </div>
          </div>
</>
  )
}

export default SectionTwentySix