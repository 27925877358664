import { Markup } from 'interweave'
import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'

function SectionSeventyThree(props) {
  const [data,setdata]= useState(props.data)
  console.log('data')
 console.log(data)
  useEffect(() => {
    setdata(props.data)
  }, [props.data])
 
    console.log('section 73')
    console.log(data)
  
  return (

<div className="page-dentists-near-me" style={{background:data?.section_73_color || ''}}>
        <div className="layout-main">
          <div className="bg-image-locations">
            <div className="section initial-section bg-inverse" style={{paddingTop:'0px'}}>
              <div className="container">
                <div className="dentist-near-me-top">
                

                
                 <div className="section">
              <div className="row">
                <div className="col-lg-10 offset-lg-1 text-center">
                  <h1 className="page-title mb-3 text-white">{data?.sec_73_title}</h1>
                  <div className="desc">
                    {/* <p>Use our dental directory to find GDC registered oral orthodontists &amp; surgeons across our UK clinics. Invisalign and dental implants specialists are located nationwide.</p>
                    <p>At Trusted Clinics, we aim to make dental work as accessible as possible for everyone. We have approved clinics located all over the UK, so wherever you are we can find a clinic practice near you that is suited to your needs. Our practices offer a wide range of dental and orthodontic work, from Invisalign clear aligners to implants and veneers, we are able to assist you in choosing the most appropriate treatment plan for your requirements.</p>
                    <p>All of our approved practices are equipped with high-end dentistry equipment created by industry-leading brands, to ensure that you receive the best treatment available. Each practice has its own team of highly qualified dentists and dental nurses who are always committed to providing the highest level of treatment possible. From the moment you enter one of our practices, you will be welcomed by staff who will advise and guide you through the process. </p>
                    <p>Your happiness is a priority for us, which is why we do everything we can to make your journey as hassle free and straightforward as can be.</p>
                    <p><strong>Popular dentist location searches:</strong></p>
                    <p><a href="https://trustedclinics.com/dental/dentists-near-me/northampton/">Find a dentist at a Northampton clinic</a></p>
                    <p><a href="https://trustedclinics.com/dental/dentists-near-me/london-liverpool-street/">London Liverpool Street dentist</a></p>
                    <p><a href="https://trustedclinics.com/dental/dentists-near-me/london-canon-street/">Dentist near London Cannon Street</a></p>
                    <p><a href="https://trustedclinics.com/dental/dentists-near-me/london-bermondsey/">Search for a dentist at a London Bermondsey clinic</a></p><p><a href="https://trustedclinics.com/dental/dentists-near-me/fulham/">Fulham dentist</a></p><p><a href="https://trustedclinics.com/dental/dentists-near-me/harley-street/">Harley Street dentist</a></p><p><a href="https://trustedclinics.com/dental/dentists-near-me/london/">London dental clinic</a></p><p><a href="https://trustedclinics.com/dental/dentists-near-me/milton-keynes/">Milton Keynes dentist</a></p><p><a href="https://trustedclinics.com/dental/dentists-near-me/bristol/">Bristol dentist</a></p><p><a href="https://trustedclinics.com/dental/dentists-near-me/manchester/">Manchester dentist</a></p>
                     */}

                    <Markup content= {data?.sec_73_description} />
                    </div>
                </div>
              </div>
            </div> 



                </div>
              </div>
            </div>
          </div>
          

         
        </div>
      </div>

  )
}

export default SectionSeventyThree