import React, { useEffect, useState } from 'react' ;
import { SuperSEO } from "react-super-seo";



import SectionOne from './sections/home/SectionOne' ;
import SectionTwo from './sections/home/SectionTwo' ;
import Header from './Header&Footer/Header' ;
import SectionFour from './sections/home/SectionFour' ;
import SectionThree from './sections/home/SectionThree' ;
import Footer from './Header&Footer/Footer' ;
import axios from 'axios' ;
import SectionFive from './sections/home/SectionFive' ;
import { useParams } from 'react-router-dom' ;
import { BeatLoader } from "react-spinners"; 
import SectionSix from './sections/home/SectionSix' ;
import SectionSeven from './sections/home/SectionSeven' ;
import SectionEight from './sections/home/SectionEight' ;
import SectionNine from './sections/home/SectionNine' ;
import SectionTen from './sections/home/SectionTen' ;
import SectionEleven from './sections/home/SectionEleven' ;
import SectionTwelve from './sections/home/SectionTwelve' ;
import SectionFifteen from './sections/home/SectionFifteen' ;
import SectionSixteen from './sections/home/SectionSixteen' ;
import SectionThirteen from './sections/home/SectionThirteen' ;
import SectionForteen from './sections/home/SectionForteen' ;
import SectionSeventeen from './sections/home/SectionSeventeen' ;
import SectionEighteen from './sections/home/SectionEighteen' ;
import SectionNineteen from './sections/home/SectionNineteen' ;
import SectionTwenty from './sections/home/SectionTwenty' ;
import SectionTwentyOne from './sections/home/SectionTwentyOne' ;
import SectionTwentyTwo from './sections/home/SectionTwentyTwo' ;
import SectionTwentyThree from './sections/home/SectionTwentyThree' ;
import SectionTwentyFour from './sections/home/SectionTwentyFour' ;
import SectionTwentyFive from './sections/home/SectionTwentyFive' ;
import SectionTwentySix from './sections/home/SectionTwentySix' ;
import SectionTwentySeven from './sections/home/SectionTwentySeven' ;
import SectionTwentyEight from './sections/home/SectionTwentyEight' ;
import SectionTwentyNine from './sections/home/SectionTwentyNine' ;
import SectionThirty from './sections/home/SectionThirty' ;
import SectionThirtyOne from './sections/home/SectionThirtyOne' ;
import SectionThirtyTwo from './sections/home/SectionThirtyTwo' ;
import SectionThirtyThree from './sections/home/SectionThirtyThree' ;
import SectionThirtyFour from './sections/home/SectionThirtyFour' ;
import SectionThirtyFive from './sections/home/SectionThirtyFive' ;
import SectionThirtySix from './sections/home/SectionThirtySix' ;
import SectionThirtySeven from './sections/home/SectionThirtySeven' ;
import SectionThirtyEight from './sections/home/SectionThirtyEight' ;
import SectionThirtyNine from './sections/home/SectionThirtyNine' ;
import SectionFourty from './sections/home/SectionFourty' ;
import SectionFourtyOne from './sections/home/SectionFourtyOne' ;
import SectionFourtyTwo from './sections/home/SectionFourtyTwo' ;
import SectionFourtyThree from './sections/home/SectionFourtyThree' ;
import SectionFourtyFour from './sections/home/SectionFourtyFour' ;
import SectionFourtyFive from './sections/home/SectionFortyFive' ;
import SectionFourtySix from './sections/home/SectionFourtySix' ;
import SectionFourtySeven from './sections/home/SectionFourtySeven' ;
import SectionFourtyEight from './sections/home/SectionFourtyEight' ;
import SectionFortyNine from './sections/home/SectionFortyNine' ;
import SectionFifty from './sections/home/SectionFifty' ;
import SectionFiftyOne from './sections/home/SectionFiftyOne' ;
import SectionFiftyTwo from './sections/home/SectionFiftyTwo' ;
import SectionFiftyThree from './sections/home/SectionFiftyThree' ;
import SectionFiftyFour from './sections/home/SectionFiftyFour' ;
import SectionFiftyFive from './sections/home/SectionFiftyFive' ;
import SectionFiftySix from './sections/home/SectionFiftySix' ;
import SectionFiftySeven from './sections/home/SectionFiftySeven'
import SectionFiftyEight from './sections/home/SectionFiftyEight';
import SectionSixtyThree from './sections/home/SectionSixtyThree';
import SectionSixtyFour from './sections/home/SectionSixtyFour';
import SectionSixtyFive from './sections/home/SectionSixtyFive';
import SectionSixty from './sections/home/SectionSixty';
import SectionSixtyOne from './sections/home/SectionSixtyOne';
import SectionSixtyTwo from './sections/home/SectionSixtyTwo';
import SectionSixtySeven from './sections/home/SectionSixtySeven';
import SectionSixtyEight from './sections/home/SectionSixtyEight';
import SectionSixtyNine from './sections/home/SectionSixtyNine';
import SectionSeventy from './sections/home/SetionSeventy';
import SectionSeventyOne from './sections/home/SectionSeventyOne';
import SectionSeventyTwo from './sections/home/SectionSeventyTwo';
import SectionSeventyThree from './sections/home/SectionSeventyThree';
import SectionSeventyFour from './sections/home/SectionSeventyFour';
import SectionSeventyFive from './sections/home/SectionSeventyFive';
import SectionSeventySix from './sections/home/SectionSeventySix';



function Test() {
  const {id} = useParams()
  const [isLoading,setisLoading] = useState(false)
  const [testMenuData ,settestMenuData] = useState([])
  
  const getTestMenu = async() =>{
    await axios.get(`${process.env.REACT_APP_BASE_URL}api/header_links`)
    .then(res=>{
     
      settestMenuData(res.data?.detail[0])
    })
    .catch(error=>{
      console.log(error)
    })
  }

  let menuId = id || testMenuData.page_name;
 
  // let id =33
  //   let data = [
  //     {
  //       name:'section_one',
  //       work:'test',
  //       test:1,
  //       demo:{
  //         name:'sachin1'
  //       }
  //     },
  //   {
  //     name:'section_two',
  //     work:'test2',
  //     test:2,
  //     demo:{
  //       name:'sachin2'
  //     }
  //   },
  //   {
  //     name:'section_three',
  //     work:'test2',
  //     test:3,
  //     demo:{
  //       name:'sachin3'
  //     }
  //   },
  //   {
  //     name:'section_four',
  //     work:'test2',
  //     test:4,
  //     demo:{
  //       name:'sachin4'
  //     }
  //   },
  //   {
  //     name:'section_five',
  //     work:'test2',
  //     test:5,
  //     demo:{
  //       name:'sachin5'
  //     }
  //   },
    
  // ]
  // function compare( a, b ) {
  //   if ( a.test < b.test ){
  //     return -1;
  //   }
  //   if ( a.test > b.test ){
  //     return 1;
  //   }
  //   return 0;
  // }
  
  // data.sort( compare );
  // console.log('testing mode on')
  // console.log(data)
  const [fetchData,setfetchData] = useState(true)
  const [apiData,setapiData] = useState('')
  const [sectionPosition,setsectionPosition] =useState([])
  const getData = async() =>{
    setisLoading(true)
    // app/trustedclinics/api/
    // await axios.get(`https://trustedclinics.co.uk/backend/api/GetPageDetail1/${menuId}`)
    await axios.get(`${process.env.REACT_APP_BASE_URL}api/GetPageDetailing/${menuId}`)
    .then(res=>{
      console.log('test page')
      console.log(res)
      if(res.data.data === false){
        setfetchData(false)
      }else{
        setfetchData(true)
        console.log(res.data?.detail)
      }
      setsectionPosition(res.data?.detail[0]?.section_position)
      // console.log('yaahoo')
      // console.log(res.data?.detail[0]?.section_position)
      setapiData(res.data?.detail[0])
      setisLoading(false)
  
    })
    .catch(error=>{
      console.log(error)
      setisLoading(false)
    })
    
  }
  console.log('apidata')
  console.log(apiData)
  // getData()

  function compare( a, b ) {
    if ( a.position < b.position ){
      return -1;
    }
    if ( a.position > b.position ){
      return 1;
    }
    return 0;
  }
  
  sectionPosition && sectionPosition.sort( compare );
  useEffect(() => {
    getData()
    getTestMenu()
  }, [menuId])

  // console.log(apiData)
  return (
    <>
    <Header/>


    <SuperSEO
  title={apiData?.page_title || 'trustedclinics'}
  // description={apiData?.meta_description || "An awesome page that does a lot of cool stuff"}
>
<meta name="name" content={apiData?.meta_name} />
  <meta name="keywords" content={apiData?.meta_keyword} />
  <meta name="description" content={apiData?.meta_description} />
</SuperSEO>

    {/* <h1 className='text-center'>Test Mode On</h1>
    <hr></hr> */}
  {/* <SectionOne data ={apiData.section_one} /> */}

   
   {
    isLoading ? 
  <div style={{width:'100%',height:'100vh',display:'flex',alignItems:'center',justifyContent:'center'}}>
  <BeatLoader       
          loading={true}
          color="orange"
          size={15}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
  </div>
  :
  <>
    
    {
      (fetchData === false) ? '' :
      sectionPosition && sectionPosition.map((item,index)=>{
        
          {
            if(item.name ==='section_one'){
             return (apiData.section_one?.check_status !== null) ? <SectionOne data={apiData.section_one}/> : ''   
            }
            else if(item.name ==='section_two')
            {
              return (apiData.section_two?.check_status !==null) ? <SectionTwo data={apiData.section_two}/>:'' 
            }
            else if(item.name === 'section_three'){
              return (apiData.section_three?.check_status !==null) ? <SectionThree data={apiData.section_three}/> : '' 
            }
            else if(item.name === 'section_four'){
              return (apiData.section_four?.check_status !==null) ? <SectionFour data={apiData.section_four}/> :'' 
            }
            else if(item.name === 'section_five'){
              return (apiData.section_five?.check_status !==null) ? <SectionFive data={apiData.section_five}/> :'' 
            }
            else if(item.name === 'section_six'){
              return (apiData.section_six?.check_status !==null) ? <SectionSix data={apiData.section_six}/> :'' 
            }
            else if(item.name === 'section_seven'){
              return (apiData.section_seven?.check_status !==null) ? <SectionSeven data={apiData.section_seven}/> :'' 
            }
            else if(item.name === 'section_eight'){
              return (apiData.section_eight?.check_status !==null) ? <SectionEight data={apiData.section_eight}/> :'' 
            }
            else if(item.name === 'section_nine'){
              return (apiData.section_nine?.check_status !==null) ? <SectionNine data={apiData.section_nine}/> :'' 
            }
            else if(item.name === 'section_ten'){
              return (apiData.section_ten?.check_status !==null) ? <SectionTen data={apiData.section_ten}/> :'' 
            }
            else if(item.name === 'section_eleven'){
              return (apiData.section_eleven?.check_status !==null) ? <SectionEleven data={apiData.section_eleven}/> :'' 
            }
            else if(item.name === 'section_twelve'){
              return (apiData.section_twelve?.check_status !==null) ? <SectionTwelve data={apiData.section_twelve}/> :'' 
            }
            else if(item.name === 'section_thirteen'){
              return (apiData.section_thirteen?.check_status !==null) ? <SectionThirteen data={apiData.section_thirteen}/> :''  
            }
            else if(item.name === 'section_fourteen'){
              return (apiData.section_fourteen?.check_status !==null) ? <SectionForteen data={apiData.section_fourteen}/> :'' 
            }
            else if(item.name === 'section_fifteen'){
              return (apiData.section_fifteen?.check_status !==null) ? <SectionFifteen data={apiData.section_fifteen}/> :''  
            }
            else if(item.name === 'section_sixteen'){
              return (apiData.section_sixteen?.check_status !==null) ? <SectionSixteen data={apiData.section_sixteen}/> :'' 
            }
            else if(item.name === 'section_seventeen'){
              return (apiData.section_seventeen?.check_status !==null) ? <SectionSeventeen data={apiData.section_seventeen}/> :'' 
            }
            else if(item.name === 'section_eighteen'){
              return (apiData.section_eighteen?.check_status !==null) ? <SectionEighteen data={apiData.section_eighteen}/> :'' 
            }
            else if(item.name === 'section_nineteen'){
              return (apiData.section_nineteen?.check_status !==null) ? <SectionNineteen data={apiData.section_nineteen}/> :'' 
            }
            else if(item.name === 'section_twenty'){
              return (apiData.section_twenty?.check_status !==null) ? <SectionTwenty data={apiData.section_twenty}/> :'' 
            }
            else if(item.name === 'section_twenty_one'){
              return (apiData.section_twenty_one?.check_status !==null) ? <SectionTwentyOne data={apiData.section_twenty_one}/> :'' 
            }
            else if(item.name === 'section_twenty_two'){
              return (apiData.section_twenty_two?.check_status !==null) ? <SectionTwentyTwo data={apiData.section_twenty_two}/> :'' 
            }
            else if(item.name === 'section_twenty_three'){
              return (apiData.section_twenty_three?.check_status !==null) ? <SectionTwentyThree data={apiData.section_twenty_three}/> :'' 
            }
            else if(item.name === 'section_twenty_four'){
              return (apiData.section_twenty_four?.check_status !==null) ? <SectionTwentyFour data={apiData.section_twenty_four}/> :'' 
            }
            else if(item.name === 'section_twenty_five'){
              return (apiData.section_twenty_five?.check_status !==null) ? <SectionTwentyFive data={apiData.section_twenty_five}/> :'' 
            }
            else if(item.name === 'section_twenty_six'){
              return (apiData.section_twenty_six?.check_status !==null) ? <SectionTwentySix data={apiData.section_twenty_six}/> :'' 
            }
            else if(item.name === 'section_twenty_seven'){
              return (apiData.section_twenty_seven?.check_status !==null) ? <SectionTwentySeven data={apiData.section_twenty_seven}/> :'' 
            }
            else if(item.name === 'section_twenty_eight'){
              return (apiData.section_twenty_eight?.check_status !==null) ? <SectionTwentyEight data={apiData.section_twenty_eight}/> :'' 
            }
            else if(item.name === 'section_twenty_nine'){
              return (apiData.section_twenty_nine?.check_status !==null) ? <SectionTwentyNine data={apiData.section_twenty_nine}/> :'' 
            }
            else if(item.name === 'section_thirty'){
              return (apiData.section_thirty?.check_status !==null) ? <SectionThirty data={apiData.section_thirty}/> :'' 
            }
            else if(item.name === 'section_thirty_one'){
              return (apiData.section_thirty_one?.check_status !==null) ? <SectionThirtyOne data={apiData.section_thirty_one}/> :'' 
            }
            else if(item.name === 'section_thirty_two'){
              return (apiData.section_thirty_two?.check_status !==null) ? <SectionThirtyTwo data={apiData.section_thirty_two}/> :'' 
            }
            else if(item.name === 'section_thirty_three'){
              return (apiData.section_thirty_three?.check_status !==null) ? <SectionThirtyThree data={apiData.section_thirty_three}/> :'' 
            }
            else if(item.name === 'section_thirty_four'){
              return (apiData.section_thirty_four?.check_status !==null) ? <SectionThirtyFour data={apiData.section_thirty_four}/> :'' 
            }
            else if(item.name === 'section_thirty_five'){
              return (apiData.section_thirty_five?.check_status !==null) ? <SectionThirtyFive data={apiData.section_thirty_five}/> :''  
            }
            else if(item.name === 'section_thirty_six'){
              return (apiData.section_thirty_six?.check_status !==null) ? <SectionThirtySix data={apiData.section_thirty_six}/> :'' 
            }
            else if(item.name === 'section_thirty_seven'){
              return (apiData.section_thirty_seven?.check_status !==null) ? <SectionThirtySeven data={apiData.section_thirty_seven}/> :'' 
            }
            else if(item.name === 'section_thirty_eight'){
              return (apiData.section_thirty_eight?.check_status !==null) ? <SectionThirtyEight data={apiData.section_thirty_eight}/> :'' 
            }
            else if(item.name === 'section_thirty_nine'){
              return (apiData.section_thirty_nine?.check_status !==null) ? <SectionThirtyNine data={apiData.section_thirty_nine}/> :'' 
            }
            else if(item.name === 'section_forty'){
              return (apiData.section_forty?.check_status !==null) ? <SectionFourty data={apiData.section_forty}/> :'' 
            }
            else if(item.name === 'section_forty_one'){
              return (apiData.section_forty_one?.check_status !==null) ? <SectionFourtyOne data={apiData.section_forty_one}/> :'' 
            }
            else if(item.name === 'section_forty_two'){
              return (apiData.section_forty_two?.check_status !==null) ? <SectionFourtyTwo data={apiData.section_forty_two}/> :'' 
            }
            else if(item.name === 'section_forty_three'){
              return (apiData.section_forty_three?.check_status !==null) ? <SectionFourtyThree data={apiData.section_forty_three}/> :'' 
            }
            else if(item.name === 'section_forty_four'){
              return (apiData.section_forty_four?.check_status !==null) ? <SectionFourtyFour data={apiData.section_forty_four}/> : '' 
            }
            else if(item.name === 'section_forty_five'){
              return (apiData.section_forty_five?.check_status !==null) ?  <SectionFourtyFive data={apiData.section_forty_five}/> : '' 
            }
             else if(item.name === 'section_forty_six'){
              return (apiData.section_forty_six?.check_status !==null) ?  <SectionFourtySix data={apiData.section_forty_six}/> : '' 
            }
           else if(item.name === 'section_forty_seven'){
              return (apiData.section_forty_seven?.check_status !==null) ?  <SectionFourtySeven data={apiData.section_forty_seven}/> : '' 
             }
             else if(item.name === 'section_forty_eight'){
              return (apiData.section_forty_eight?.check_status !==null) ?  <SectionFourtyEight data={apiData.section_forty_eight}/> : '' 
             }
             else if(item.name === 'section_forty_nine'){
             
              return (apiData.section_forty_nine?.check_status !==null) ?  <SectionFortyNine data={apiData.section_forty_nine}/> : '' 
               }
             else if(item.name === 'section_fifty'){
              return (apiData.section_fifty?.check_status !==null) ?  <SectionFifty data={apiData.section_fifty}/> : '' 
             }
             else if(item.name === 'section_fifty_one'){
              return (apiData.section_fifty_one?.check_status !==null) ?  <SectionFiftyOne data={apiData.section_fifty_one}/> : '' 
             }
             else if(item.name === 'section_fifty_two'){
              return (apiData.section_fifty_two?.check_status !==null) ?  <SectionFiftyTwo data={apiData.section_fifty_two}/> : '' 
             }
             else if(item.name === 'section_fifty_three'){
              return (apiData.section_fifty_three?.check_status !==null) ?  <SectionFiftyThree data={apiData.section_fifty_three}/> : '' 
             }
             
             else if(item.name === 'section_fifty_four'){
              return (apiData.section_fifty_four?.check_status !==null) ?  <SectionFiftyFour data={apiData.section_fifty_four}/> : '' 
             }
             else if(item.name === 'section_fifty_five'){
              return (apiData.section_fifty_five?.check_status !==null) ?  <SectionFiftyFive data={apiData.section_fifty_five}/> : '' 
             }
             else if(item.name === 'section_fifty_six'){
              return (apiData.section_fifty_six?.check_status !==null) ?  <SectionFiftySix data={apiData.section_fifty_six}/> : '' 
             }
             else if(item.name === 'section_fifty_seven'){
              return (apiData.section_fifty_seven?.check_status !==null) ?  <SectionFiftySeven data={apiData.section_fifty_seven}/> : '' 
             }
             else if(item.name === 'section_fifty_eight'){
              return (apiData.section_fifty_eight?.check_status !==null) ?  <SectionFiftyEight data={apiData.section_fifty_eight}/> : '' 
             }
             
             //section 59 are not create in backend that not show section 59 in fronted 

         
             else if(item.name === 'section_sixty'){
              return (apiData.section_sixty?.check_status !==null) ?  <SectionSixty data={apiData.section_sixty}/> : '' 
             }
             else if(item.name === 'section_sixty_one'){
              return (apiData.section_sixty_one?.check_status !==null) ?  <SectionSixtyOne data={apiData.section_sixty_one}/> : '' 
             }
             else if(item.name === 'section_sixty_two'){
              return (apiData.section_sixty_two?.check_status !==null) ?  <SectionSixtyTwo data={apiData.section_sixty_two}/> : '' 
             }
             else if(item.name === 'section_sixty_three'){
              return (apiData.section_sixty_three?.check_status !==null) ?  <SectionSixtyThree data={apiData.section_sixty_three}/> : '' 
             }
             else if(item.name === 'section_sixty_four'){
              return (apiData.section_sixty_four?.check_status !==null) ?  <SectionSixtyFour data={apiData.section_sixty_four}/> : '' 
             }
             else if(item.name === 'section_sixty_five'){
              return (apiData.section_sixty_five?.check_status !==null) ?  <SectionSixtyFive data={apiData.section_sixty_five}/> : '' 
             }
             else if(item.name === 'section_sixty_seven'){
              return (apiData.section_sixty_seven?.check_status !==null) ?  <SectionSixtySeven data={apiData.section_sixty_seven}/> : '' 
             }
             else if(item.name === 'section_sixty_eight'){
              return (apiData.section_sixty_eight?.check_status !==null) ?  <SectionSixtyEight data={apiData.section_sixty_eight}/> : '' 
             }
             else if(item.name === 'section_sixty_nine'){
              return (apiData.section_sixty_nine?.check_status !==null) ?  <SectionSixtyNine data={apiData.section_sixty_nine}/> : '' 
             }
             else if(item.name === 'section_seventy'){
              return (apiData.section_seventy?.check_status !==null) ?  <SectionSeventy data={apiData.section_seventy}/> : '' 
             }
             else if(item.name === 'section_seventy_one'){
              return (apiData.section_seventy_one?.check_status !==null) ?  <SectionSeventyOne data={apiData.section_seventy_one}/> : '' 
             }
             else if(item.name === 'section_seventy_two'){
              return (apiData.section_seventy_two?.check_status !==null) ?  <SectionSeventyTwo data={apiData.section_seventy_two}/> : '' 
             }
             else if(item.name === 'section_seventy_three'){
              return (apiData.section_seventy_three?.check_status !==null) ?  <SectionSeventyThree data={apiData.section_seventy_three}/> : '' 
             }
             else if(item.name === 'section_seventy_four'){
              return (apiData.section_seventy_four?.check_status !==null) ?  <SectionSeventyFour data={apiData.section_seventy_four} /> : '' 
             }
             else if(item.name === 'section_seventy_five'){
              return (apiData.section_seventy_five?.check_status !==null) ?  <SectionSeventyFive data={apiData.section_seventy_five} /> : '' 
             }
             else if(item.name === 'section_seventy_six'){
              return (apiData.section_seventy_six?.check_status !==null) ?  <SectionSeventySix data={apiData.section_seventy_six} /> : '' 
             }
            else{
              return '' //SectionSeventySix
            }
          }
       
      })
    }
    
    {/* <SectionOne/>
    <SectionTwo/>
    <SectionThree/>
    <SectionFour/>
   <SectionFive />
   <SectionSix/>
   <SectionSeven/>
   <SectionEight/>
   <SectionNine/>
   <SectionTen/>
   <SectionEleven/>
   <SectionTwelve/>
   <SectionThirteen/>
   <SectionForteen/>
   <SectionFifteen/>
   <SectionSixteen/>
   <SectionSeventeen/> */}
            {/* else if(item.name === 'sachin5'){
              return <SectionFive data={{}}/>
            } */}
    {/* {
        data[0] && <SectionOne data={data[0]}/>
    }
    {
        data[0] && <SectionTwo data={data[0]}/>
    }   */}
    <Footer/>
    </>
   }

    </>
  )
}

export default Test;

