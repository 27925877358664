import { Markup } from 'interweave'
import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'

function SectionSeven(props) {
  const [data,setdata]= useState(props.data)
  console.log('data')
 console.log(data)
  useEffect(() => {
    setdata(props.data)
  }, [props.data])
 
    console.log('sectionone')
    console.log(data)
  
  return (
  
 
  <div className='' style={{background:data?.section_7_color || ''}}>
  <div className="container">
    <div className="row mb-lg-5 py-2">
      <div className="col-md-6 mt-2 monthly-price-from-none">
      {
        (data?.section_seven_banner) &&
        <img className="mobile" src= {data?.section_seven_banner} alt="issue occured" />
      }
        
      </div>
      <div className="col-md-6 text-center">
      <h2 style={{color:'#253959'}}>{data?.section_seven_title}</h2>
     {  <Markup content={ data?.section_seven_detail }/> } 
        <div className="text-center mt-2">
        {
          (data?.section_seven_button) && 
          <button type="submit" className="btn btn-md btn-rounded btn-pink book-consult-button-inline button-show-and-hide">
            <a href={data?.sec_7_button_link} style={{color:'#ffffff'}}>
            { data?.section_seven_button?.substring(0,27) }
            </a>
      
          </button>
        }
          
        </div>
      </div>
      <div className="col-md-6 mt-2 monthly-price-from-display">
      {
        (data?.section_seven_banner) &&
        <img className="mobile" src= {data?.section_seven_banner} alt="issue occured" />
      }
        
      </div>
    </div>
  </div>
</div>
  )
}

export default SectionSeven