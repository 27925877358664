// import { Markup } from 'interweave'
// import React, { useEffect, useState } from 'react'
// import { NavLink } from 'react-router-dom'
// import defaultImg from './images/defaultimage.jpg'
// function SectionSeventySix (props) {
//   const [data,setdata]= useState(props.data)
//   console.log('data')
//  console.log(data)
//   useEffect(() => {
//     setdata(props.data)
//   }, [props.data])
 
//     console.log('SectionSeventySix 76')
//     console.log(data)
  
//   return (
// <>

//     <div className='container' style={{background:data?.section_76_color || ''}}>   
//     <div  className='row'>
//     <div className="col-md-10 col-lg-8 mx-auto text-center">
//     <h2 className='my-4'>{data?.section_76_title}</h2>
//     </div>

// </div>
//     <div className='row'>
   
//     { data?.section_76_article &&  data?.section_76_article.map((value)=>{
//         return(
//             <div className="grid-item col-md-6 col-lg-4" 
//             style={{height:'600px'}}
//     >
//                   <div className="card card-news-grid p-2">
//                     <a href={value.button_link} className="card-image">
//                       <img
//                       style={{width:'100%',height:"220px"}}
//                     src={(value.image) ? value.image : defaultImg}
//                        alt={value.image_alt} className="img-fluid" />
                    
//                     </a>
//                     <div className="card-body text-center">
//                       <h3 className="card-title blue">{value.title}</h3>
//                       <div className="preview-text">
//                       <Markup content={(value.description && value?.description.length >= 70) ? value?.description.substring(0, 70) + "..." : value?.description} />
//                       </div>
//                       <hr />
//           <div className='mb-2'>
//           <p className="post-date mb-0"><strong>Date:</strong> {value.date}</p>
//                       <p className="category mb-0"><strong>Category:</strong> {value.category}</p>
//                       </div>
    
//                       <a href={value.button_link} target='_blank' className="btn btn-pink btn-md btn-rounded mt-3 text-white">{value.button?.substring(0,25)}</a>
//                     </div>
//                   </div>
//                 </div>
//         )
//     })}
      
//     </div>
//     </div>
//     </>
//   )
// }

// export default SectionSeventySix 

import { Markup } from 'interweave';
import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import defaultImg from './images/defaultimage.jpg';

function SectionSeventySix(props) {
  const [data, setData] = useState(props.data);

  useEffect(() => {
    setData(props.data);
  }, [props.data]);

  return (
    <>
      <style>
        {`
          .grid-container {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
          }

          .grid-item {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 600px;
            margin-bottom: 20px;
          }

          .card-news-grid {
            display: flex;
            flex-direction: column;
            height: 100%;
          }

          .card-image img {
            object-fit: cover;
          }

          .card-body {
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
          }

          .card-title {
            height: 60px; /* Fixed height for title */
            overflow: hidden;
          }

          .preview-text {
            height: 50px; /* Fixed height for description */
            overflow: hidden;
          }

          .card-body .btn {
            align-self: center;
          }
        `}
      </style>
      <div className='container' style={{ background: data?.section_76_color || '' }}>
        <div className='row'>
          <div className="col-md-10 col-lg-8 mx-auto text-center">
            <h2 className='my-4'>{data?.section_76_title}</h2>
          </div>
        </div>
        <div className='row grid-container'>
          {data?.section_76_article && data?.section_76_article.map((value, index) => (
            <div className="grid-item col-md-6 col-lg-4" key={index}>
              <div className="card card-news-grid p-2">
                <a href={value.button_link} className="card-image">
                  <img
                    style={{ width: '100%', height: "220px" }}
                    src={value.image ? value.image : defaultImg}
                    onError={(e)=>e.target.src = defaultImg}
                    alt={value.image_alt}
                    className="img-fluid"
                  />
                </a>
                <div className="card-body text-center">
                  <h3 className="card-title blue">{value.title && value.title.length >= 50 ? value.title.substring(0, 50) + "..." : value.title}</h3>
                  <div className="preview-text">
                    <Markup content={value.description && value.description.length >= 70 ? value.description.substring(0, 70) + "..." : value.description} />
                  </div>
                  <hr />
                  <div className='mb-2'>
                    <p className="post-date mb-0"><strong>Date:</strong> {value.date}</p>
                    <p className="category mb-0"><strong>Category:</strong> {value.category}</p>
                  </div>
                  <a href={value.button_link} target='_blank' className="btn btn-pink btn-md btn-rounded mt-3 text-white">{value.button?.substring(0, 25)}</a>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default SectionSeventySix;
