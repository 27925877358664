import { Markup } from 'interweave'
import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'

function SectionSixtyFour(props) {
  const [data,setdata]= useState(props.data)
  console.log('data')
 console.log(data)
  useEffect(() => {
    setdata(props.data)
  }, [props.data])
 
    console.log('section 64')
    console.log(data)
  return (

<div className="container mt-4 mb-4" style={{background:data?.section_64_color || ''}}>
          <div className="row justify-content-center">
            <div className="col-md-6 col-lg-4 mb-3">
              <div className="card card-body card-review-score">
             <div className="d-flex justify-content-between">
             <div>
                  <h4>Excellent</h4>
                  <p className="score"><b>{data?.section_64_excellent}</b></p>
                </div>
                <div className="text-right">
                  <p className="review-stars mb-1">
                    <i className="fas fa-star" /><i className="fas fa-star" /><i className="fas fa-star" /><i className="fas fa-star" /><i className="fas fa-star" />
                  </p>
                  <p className="review-count">{data?.section_64_reviews}</p>
                </div>
             </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 mb-3">
              <div className="card card-body card-distance m-1" style={{backgroundColor:'#d4a54e'}}>
              <div className="d-flex justify-content-between">
              <div className="distance d-flex justify-content-between">
                  <img 
                  // src="assets/img/distancefrompractice.png" 
                  src={data?.section_64_logo}
                  alt="" style={{width:'60px' ,height:'60px'}} />
                  <h5 className="text-white ml-3 ">
                   <Markup content={data?.section_64_title} className='card-distance-span' /> {/* Distance<br />from Practice */}
                  </h5>
                </div>
                <div className="text-right">
                  <p className=""> <span  style={{fontSize:'30px',fontWeight:'400', marginRight:'5px'}}><Markup content={data?.section_64_distance}/></span> miles</p>
                
                </div>
              </div>
              </div>
            </div>
          </div>
          <hr className="mt-2" />
        </div>

  )
}

export default SectionSixtyFour