import React, { useEffect, useState } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import Header from '../Pages/Header&Footer/Header'
import Footer from '../Pages/Header&Footer/Footer'
import axios from 'axios'
import { Markup } from 'interweave'
import { BeatLoader } from "react-spinners"; 
import LatestBlog from './LatestBlog'
import moment from 'moment/moment'
import './blog.css'

const AllBlog = () => {
  
  const [BlogData,setBlogData] = useState([])
  const [BlogLatest,setBlogLatest] = useState([])
  const [fetchData,setfetchData] = useState(true)
  const [fetchLatest,setfetchLatest]=  useState(false)
  const [isLoading,setisLoading] = useState(false)
  const [filter, setFilter] = useState([]);
  const [cate,setCate]=useState([])
  const [style1,setStyle1]=useState('all')
  const navigate=useNavigate()

 
   
console.log(BlogData)

  const Get_Blog_Data=async()=>{
    setisLoading(true)
    await axios.get(`${process.env.REACT_APP_BASE_URL}api/get_blog`).
    then(res=>{
        if(res.data.data === false){
            setfetchData(false)
        }
        else{
            setfetchData(true)
        }
        setBlogData(res.data?.detail)
        setFilter(res.data?.detail)
        setisLoading(false)
  
      })
      .catch(error=>{
        console.log(error)
        setisLoading(false)
      })
    /// letest Blog API
    // await axios.get(`${process.env.REACT_APP_BASE_URL}api/get_latest_blog`).
    // then(res=>{
    //     if(res.data.data === false){
    //         setfetchLatest(false)
    //     }
    //     else{
    //         setfetchLatest(true)
    //     }
    //     setBlogData(res.data?.detail)
        
  
    //   })
    //   .catch(error=>{
    //     console.log(error)
        
    //   })
  }

  const GetCate=async()=>{
    setisLoading(true)
    await axios.get(`${process.env.REACT_APP_BASE_URL}api/get_doctorcategory`).
    then(res=>{
        if(res.data.data === false){
            setfetchData(false)
        }
        else{
            setfetchData(true)
        }
        setCate(res.data?.detail)
        // setFilter(res.data?.detail)
        setisLoading(false)
  
      })
      .catch(error=>{
        console.log(error)
        setisLoading(false)
      })
    /// letest Blog API
    // await axios.get(`${process.env.REACT_APP_BASE_URL}api/get_latest_blog`).
    // then(res=>{
    //     if(res.data.data === false){
    //         setfetchLatest(false)
    //     }
    //     else{
    //         setfetchLatest(true)
    //     }
    //     setBlogData(res.data?.detail)
        
  
    //   })
    //   .catch(error=>{
    //     console.log(error)
        
    //   })
  }

  useEffect(() => {
    
    Get_Blog_Data()
    GetCate()
  }, [])

    //   Filter Type 
    const filterType = (category) => {
      setStyle1(category)
      setBlogData(
        filter.filter((item) => {
          return item.category_name === category;
        })
      );
    };

    const allFunc=()=>{
      setBlogData(filter)
      setStyle1('all')
    }
    console.log("i am cate name")
    console.log(style1)
  return (
 <>



<Header />

{/* view all blog */}
{
    isLoading ? 
  <div style={{width:'100%',height:'100vh',display:'flex',alignItems:'center',justifyContent:'center'}}>
  <BeatLoader       
          loading={true}
          color="orange"
          size={15}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
  </div>
  :
      <section className="blog-listing gray-bg">
      { (fetchData === false) ? '' :  <div className="container">
          <div className="row align-items-start">
            <div className="col-lg-8 m-15px-tb">
              <div className="row mt-3">

              {
                (BlogData) && BlogData?.map((data)=>{
                    return(
                <>
          
      <div className="col-sm-6">
        <div className="blog-grid">
          <div className="blog-img">
       
          <NavLink to={`/blog/${data?.category_name}/${data?.page_name}`}>
              <img 
              // src="https://arowiz.com/wp-content/uploads/2022/12/bg7.jpg" 
              src={data?.image} 
              alt={data?.image_alt} title={data?.image_name} />

           </NavLink>
          </div>
          <div className="blg-bt">
            <div className="blg-cate-date">
              <div className="blg-date">
                <span>{moment(data.created_at).format('MMMM Do YYYY')}</span>
              </div>
              <div className="blg-cate">
                <span>{data?.category_name}</span>
              </div>
            </div>
            <div className="blog-info">
              <h5
                style={{fontSize:'16px',lineHeight:'19px',fontWeight:'600',
 whiteSpace: 'nowrap' ,
 width: '200px' ,
 overflow: 'hidden',
 textOverflow:'ellipsis',
 cursor:'pointer'}}
              >   <NavLink to={`/blog/${data?.category_name}/${data?.page_name}`}> {data.title}</NavLink></h5>
              <div className="btn-bar">
              <NavLink to={`/blog/${data?.category_name}/${data?.page_name}`} className="px-btn-arrow">
                  <span>Read More <i className="fas fa-chevron-right" /></span>
                  <i className="arrow" />
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </div>
          
          
        
                </>
                    )
                })
              }

              {
                (BlogData && BlogData?.length===0)&&
                (<>
                  <div className="card shadow-lg border-0 rounded-lg mt-5 mx-auto" style={{width: '30rem',fontWeight:'bold'}}>
          <h2 className="card-header display1 text-muted text-center text-primary">
          No  Data Available
          </h2>
        
        
        </div>
      
                </>)
              }
              
{/* <div className='col-sm-4'>
              <div className="wraperr-blog-card">
          <div>
            <figure><img src="./bgg.jpg" /></figure>
          </div>
          <div className="blog-bottom-container">
            <ul>
              <li>
                <a href="#" className="calnder">SEPTEMBER,29,2023</a>
              </li>
              <li>
                <a href="#"> <i className="bi bi-person-circle" /> BY ADMIN</a>
              </li>
            </ul>
            <h3><a href="#">How to Develop an MVP and Secure Funding in 2023</a>
            </h3>
            <h6>
              <a href="#">Read More <span> <i className="bi bi-arrow-right-circle" /></span></a>
            </h6>
          </div>
        </div>
        </div> */}
              {/* old blog code */}
              {/* <div className="col-sm-6" key={data?.id}>
                  <div className="blog-grid">
                    <div className="blog-img">
                      <div className="date">
                        <span>{data?.created_at?.substring(8,10)}</span>
                        <label>May</label>
                      </div>
                      <a >
                        <img
                        style={{
                            width:'400px',
                            height:'200px'
                        }}
                   src={data?.image}
                          title alt="" />
                      </a>
                    </div>
                    <div className="blog-info">
                   
                      <h5><a>{data?.name}</a></h5>
                      <h5 style={{fontSize:'18px',lineHeight:'19px',fontWeight:'600'}}>{data?.title}</h5>
                      <h6>{data?.category_name}</h6>
                      <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                     
                     <Markup content={data?.description?.substring(0,125)} />
                      <div className="btn-bar">
                        <NavLink to={`/blog_by_id/${data?.id}`} className="px-btn-arrow">
                          <span className='text-primary'>Read More</span>
                          <i className="arrow" />
                        </NavLink>
                      </div>
                    </div>
                  </div>
                </div> */}


               
                
              </div>
            </div>
            <div className="my-3 col-lg-4 m-15px-tb blog-aside">

 {/* widget Tags */}
 <div className="widget widget-tags p-3" >
                <div className="widget-title">
                  <h3> Categories</h3>
                </div>
                <div className="widget-body">
                 
                  <div className="box categories">
        <ul className="list-unstyled">
          <li  style={{cursor:'pointer'}} onClick={allFunc} className={`${style1==='all'?'text-primary':''}`}><a >All</a></li>
          {cate.map((val,i)=>{
            return(
            <li  style={{cursor:'pointer'}}   onClick={() => filterType(val.category_name)}><a className={`${style1===val.category_name?'text-primary':''}`}>{val.category_name}</a></li>
         ) })}

          {/* <li  style={{cursor:'pointer'}}  onClick={() => filterType('Pathologists')} ><a >Psychiatrist</a></li>
          <li  style={{cursor:'pointer'}}  onClick={() => filterType('Dermatologist')}><a >Dermatologist</a></li> */}
          {/* <li><a >Ayurveda</a></li>
          <li><a >Dentist</a></li>
          <li><a >Dental</a></li>
          <li><a >Ayurveda</a></li> */}
        </ul>
      </div>

                </div>
              </div>

          
              {/* End Trending Post */}
              {/* Latest Post */}
         {/* <LatestBlog /> */}
              {/* End Latest Post */}
             
              {/* End widget Tags */}
            </div>
          </div>
        </div> }
      </section>
}
<Footer />

      
 </>
  )
}

export default AllBlog


//  const blog=()=>{
//   <div className="col-sm-6" key={data?.id} onClick={()=>navigate(`/blog_by_id/${data?.id}`)}>
//   <div className="image-box image-box--shadowed white-bg style-2 mb-4">
//     <div className="overlay-container">
//          <img
//             style={{
//                 width:'400px',
//                 height:'200px'
//             }}
//        src={data?.image} 
//       // src='https://media.istockphoto.com/id/1198931639/photo/writing-a-blog-blogger-influencer-reading-text-on-screen.jpg?b=1&s=612x612&w=0&k=20&c=_C4iNvLOzKbbfbeTMsJ4mQf8OGQwYWJ8GWKLKRglrF8='
//        alt={data?.image_alt} title={data.image_name} />
//       <NavLink to={`/blog_by_id/${data?.id}`} className="overlay-link" />
//     </div>
//     <div className="body">
//     <span

// className="text-white bg-blue px-2 py-1 mb-3"
// style={{fontSize:'11px', fontWeight:'600',borderRadius:'999px'}}
// >{moment(data.created_at).format('MMMM Do YYYY')}</span>
//       <h6 className="font-weight-bold my-2"
//         style={{fontSize:'16px',lineHeight:'19px',fontWeight:'600',
// whiteSpace: 'nowrap' ,
// width: '200px' ,
// overflow: 'hidden',
// textOverflow:'ellipsis',
// cursor:'pointer'}}
//       >{data.title}</h6>
//       <p className="small" style={{fontSize:'14px',color:'#ab408a',fontWeight:500}}>{data?.category_name}</p>
//       <div className="row d-flex align-items-center">
//         {/* <div className="col-6">
//           <ul className="social-links small circle">
//             <li className="facebook"><a href="#"><i className="fa fa-facebook" /></a></li>
//             <li className="twitter"><a href="#"><i className="fa fa-twitter" /></a></li>
//             <li className="googleplus"><a href="#"><i className="fa fa-google-plus" /></a></li>
//             <li className="instagram"><a href="#"><i className="fa fa-instagram" /></a></li>
//           </ul>
//         </div> */}
//         <div className="col- text-right">
//           <NavLink to={`/blog_by_id/${data?.id}`} className="btn radius-50 btn-gray-transparent btn-animated">Read More <i className="fa fa-arrow-right" style={{marginTop:'6px'}} /></NavLink>
//         </div>
//       </div>
//     </div>
//   </div>
// </div>
// }