import React, { useEffect, useState } from 'react'
import './blogcategory.css'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
function BlogCategory(props) {
    const navigate = useNavigate()
    const [data,setdata]= useState([])
    const [status,setstatus] = useState(false)
    // useEffect(() => {
    //     if(props.data){
    //         setdata(props.data)
    //         setstatus(props.status)
    //     }
        
    //   }, [props.data])
      const [BlogLatest,setBlogLatest] = useState([])
      const [fetchLatest,setfetchLatest]=  useState(false)
  const pagename = localStorage.getItem("current_page_name") || ""
      
       
     const Get_Blog_Data=async()=>{
      
  
      await axios.get(`${process.env.REACT_APP_BASE_URL}api/get_diff_blog_by_pagename/${pagename}`).
      then(res=>{
          if(res.data.data === false){
            setstatus(false)
          }
          else{
            setstatus(true)
          }
          setdata(res.data?.detail)
          
    
        })
        .catch(error=>{
          console.log(error)
          
        })
    }

    useEffect(() => {
      
        Get_Blog_Data()
      }, [])

  return (
    <>
     
    {
        (status && data.length > 0) && <>
        <div class="container widget widget-latest-post p-2"  style={{borderRadius:"30px"}}>
    <div className="widget-title mb-1">
        <h3>ASSOCIATED BLOGS</h3>
    </div>
        <div className='p-2' >
        {
            (data) && data?.slice(0,8)?.map((item)=>{
                return <>
                <div class="row my-3" style={{cursor:"pointer"}} onClick={()=>{
                    navigate(`/blog/${item?.category_name}/${item?.page_name}`)
                }} >
                    <div class="col">
                        <div class="full-size-image">
                        {
                            (item?.image) &&  <>
                            <img 
                            src={item?.image} 
                            alt={item?.image_alt}
                            />
                            </>
                        }
                            
                            <div className="image-overlay"></div>
                            <div class="image-title">
                                <span className='text-uppercase font-weight-bold'>{item?.title}</span>
                            </div>
                        </div>
                    </div>
                </div>
                </>
            })
        }
            
        </div>
        
        </div>
        </>
    }
    </>
  )
}

export default BlogCategory
