import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const NotFound = () => {
  const history = useNavigate();

  useEffect(() => {
    history.push('/');
  }, [history]);

  return null;
};

export default NotFound;