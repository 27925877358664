import { Markup } from 'interweave'
import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'

function SectionThirty(props) {
  const [data,setdata]= useState(props.data)
  console.log('data')
 console.log(data)
  useEffect(() => {
    setdata(props.data)
  }, [props.data])
 
    console.log('section 30')
    console.log(data)

  return (
<>
<div className="summary-section bg-inverse bg-linear-inverse section-9773" style={{background:data?.section_30_color || '#684286'}}>
      <div className="section section-background pageid-9766" style={{color:'#ffffff !imortant'}}>
        <div className="container text-center">
          <div className="row">
            <div className="col-xl-8 offset-xl-2 col-lg-10 offset-lg-1 " style={{color:'#ffffff !imortant'}}>
              <h2 className="mb-4">{data?.section_30_title}</h2>
              <div className="service_div" >
             
          {
            <Markup content={data?.section_30_description} />
          }
              </div>
            </div>
          </div>
          <div className='form-css-hidden'>

          
          {
            (data?.section_30_button) &&
            <a
            href={data?.sec_30_button_link}
            className="btn btn-md btn-rounded btn-gold book-consult-button mt-4 text-white"
          >
           {data?.section_30_button}
          </a>
          }
          </div>
          
        </div>
      </div>
    </div>
</>

  )
}

export default SectionThirty